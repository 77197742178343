import React from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';

type props = {
	data: {
		total: number;
		cranesWithNotifications: number;
	};
};
const CranesWithNotificationsChart: React.FC<props> = props => {
	const { t } = useTranslation();

	const data = {
		labels: [t('cranesWithNotifications'), t('cranesWithoutNotifications')],

		datasets: [
			{
				label: t('cranesWithoutNotifications'),
				data: [props.data.cranesWithNotifications, props.data.total - props.data.cranesWithNotifications],
				backgroundColor: ['rgba(255, 99, 132, 0.4)', 'rgba(54, 162, 235, 0.4)'],
				borderColor: ['rgba(255,99,132,1)', 'rgba(54, 162, 235, 1)', 'rgba(75, 192, 192, 1)', 'rgba(250, 220, 122, 1)'],
				borderWidth: 1,
			},
		],
		text: '15%',
	};

	if (props.data.total == 0) {
		return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('noCranes')} />;
	}
	return (
		<div className='barchart-wrapper'>
			<Doughnut
				data={data}
				options={{
					legend: {
						reverse: true,
						display: true,
						position: 'bottom',
						fullWidth: true,
						// rtl: true,
						labels: {
							usePointStyle: true,
							padding: 30,
						},
					},
					plugins: {
						datalabels: {
							color: 'black',
							textAlign: 'center',
							font: {
								weight: 'bold',
							},
							display: function (context: any) {
								return context.dataset.data[context.dataIndex] !== 0; // or >= 1 or ...
							},
						},
					},
					responsive: true,
					maintainAspectRatio: true,
				}}
			/>
		</div>
	);
};

export default CranesWithNotificationsChart;
