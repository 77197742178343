import { PlusSquareFilled } from '@ant-design/icons';
import Modal from 'antd/lib/modal/Modal';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ISnapshot, ISnapshotsBulk } from 'src/DataTypes/Snapshot';
import styles from './Snapshots.module.scss';

type props = {
	snapshotsBulk: ISnapshotsBulk;
	date: Date;
};

const Snapshot: React.FC<props> = props => {
	const { t } = useTranslation();
	const [img, setImg] = useState<ISnapshot | null>(null);
	const [snapshots, setSnapshots] = useState<ISnapshot[]>([]);

	const snapshotsFormat = (snapshots: ISnapshot[]) => {
		return snapshots.filter(s => s?.blobUrl != null);
	};

	useEffect(() => {
		setSnapshots(snapshotsFormat(props.snapshotsBulk.snapshots));
	}, []);

	return (
		<>
			{img && (
				<Modal
					title={img.cameraName}
					width={900}
					visible
					maskClosable
					footer={null}
					closable
					centered
					onCancel={() => setImg(null)}
					className={styles.expandModal}>
					<img className={styles.image} src={img?.blobUrl} />
				</Modal>
			)}
			{snapshots.length > 0 && (
				<div
					key={props.snapshotsBulk.id}
					className={styles.snapshotWrapper}>
					<div className={styles.snapshotTitle}>
						<span>{t('date')}:</span>
						<span>
							{moment(props.date).format('DD/MM/YY HH:mm:ss')}
						</span>
					</div>
					<div className={styles.snapshotBody}>
						{snapshots.map(s => (
							<div
								className={styles.imgContainer}
								onClick={() => setImg(s)}>
								<img
									className={styles.image}
									src={s?.blobUrl}
								/>
								<div className={styles.middle}>
									<PlusSquareFilled />
								</div>
								<div className={styles.bottom}>
									<div className={styles.text}>
										{s.cameraName}
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			)}
		</>
	);
};

export default Snapshot;
