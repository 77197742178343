import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Registration from './components/Registration/Registration';
import Confirm from './components/Notification/Confirm/Confirm';
import * as Auth from './auth';
import './i18n/i18n';

let pathname = window.location.pathname;

switch (pathname) {
	case '/Confirm':
		ReactDOM.render(<Confirm />, document.getElementById('root'));
		break;
	case '/Invite':
		let params = new URL(window.location).searchParams;
		let token = params.get('token');
		ReactDOM.render(<Registration token={token} />, document.getElementById('root'));
		break;
	default:
		const acc = Auth.MsalObj.getAccount();
		if (acc) {
			ReactDOM.render(<App account={acc} />, document.getElementById('root'));
		} else {
			Auth.LoginRedirect();
		}
		break;
}

serviceWorker.unregister();
