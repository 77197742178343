/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { MyProfile } from '../../DataTypes/MyProfile';
import Spinner from '../UI/Spinner/Spinner';
import styles from './UserProfile.module.scss';
import LoadingModal from '../UI/LoadingModal/LoadingModal';
import { Hierarchy } from '../../DataTypes/Hierarchy';
import { Company } from '../../DataTypes/Company';
import UserDetailsUpdateModal from '../Modals/UserDetailsUpdateModal/UserDetailsUpdateModal';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { useTranslation } from 'react-i18next';

import ChangePasswordModal from '../Modals/ChangePasswordModal/ChangePasswordModal';

type props = {
	hierarchy: Hierarchy;
	myProfile: MyProfile;
};

const UserProfile: React.FC<props> = ({ hierarchy, myProfile }) => {
	const [userCompany] = useState<Company>(hierarchy.companies.find(x => x.organization == hierarchy.id)!);
	const [dummy, setDummy] = useState<boolean>();
	const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
	const [showPasswordModal, setShowPasswordModal] = useState<boolean>(false);
	const { t } = useTranslation();

	const onUpdateModalClosed = (result?: Promise<any>) => {
		setShowUpdateModal(false);

		result?.then(() => {
			setDummy(!dummy);
		});
	};

	const handleShowPasswordChangeModal = () => {
		setShowPasswordModal(!showPasswordModal);
	};

	const detailsParagraph = (
		<div className={styles.detailsParagraph}>
			<div className={styles.header}>
				<div className={styles.title}>{t('personalDetails')}</div>
				<EditIcon role='button' className={styles.editButton} onClick={() => setShowUpdateModal(true)} />
			</div>
			<br></br>
			<div className={styles.details}>
				<div>
					<span className={styles.detailsKey}>{t('fullName')}</span>
					<span className={styles.detailsValue}>{myProfile.fullName}</span>
				</div>
				<div>
					<span className={styles.detailsKey}>{t('mail')}</span>
					<span className={styles.detailsValue}>{myProfile.mail}</span>
				</div>
				<div>
					<span className={styles.detailsKey}>{t('company')}</span>
					<span className={styles.detailsValue}>{userCompany.name ?? '-'}</span>
				</div>
				<div>
					<span className={styles.detailsKey}>{t('role')}</span>
					<span className={styles.detailsValue}>{t(myProfile.role)}</span>
				</div>
				<div>
					<span className={styles.detailsKey}>{t('phone')}</span>
					<span className={styles.detailsValue}>{myProfile.phone?.number}</span>
				</div>
			</div>
		</div>
	);

	const configParagraph = (
		<div className={styles.configParagraph}>
			<div className={styles.title}>{t('settings')}</div>
			<div className={styles.subtitle}>{t('notifications')}</div>
			<div className={styles.checkboxParagraph}>
				<div className={styles.checkbox}>
					<input type='checkbox' className={styles.checkbox} checked={myProfile.notifications?.canConfirmNotifications} disabled />
					<span>{t('authorizedToConfirmNotifications')}</span>
				</div>
				<div className={styles.checkbox}>
					<input type='checkbox' className={styles.checkbox} checked={myProfile.notifications?.isSendMail} disabled />
					<span>{t('receiveEmailNotifications')}</span>
				</div>
				<div className={styles.checkbox}>
					<input type='checkbox' className={styles.checkbox} checked={myProfile.notifications?.isSendSms} disabled />
					<span>{t('receiveSmsNotifications')}</span>
				</div>
			</div>
			<div className={styles.subtitle}>{t('password')}</div>

			<div className={styles.password} onClick={handleShowPasswordChangeModal}>
				{t('changePassword')}
			</div>
		</div>
	);

	return (
		<div className={styles.wrapper}>
			{showUpdateModal && <UserDetailsUpdateModal closeHandler={onUpdateModalClosed} company={userCompany} myProfile={myProfile} />}
			{showPasswordModal && <ChangePasswordModal closeHandler={handleShowPasswordChangeModal} myProfile={myProfile} show={showPasswordModal} />}
			<Row>
				<Col md={4}>
					<div className={styles.card}>{detailsParagraph}</div>
					<div className={styles.card}>{configParagraph}</div>
					<LoadingModal />
				</Col>
			</Row>
		</div>
	);
};

export default UserProfile;
