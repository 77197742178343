import React, { useState, useEffect } from 'react';
import { License } from '../../../DataTypes/License';
import styles from './CraneDetailsTable.module.scss';
import CraneEditDetailsModal from '../../Modals/CraneEditDetailsModal/CraneEditDetailsModal';
import CraneRemoveModal from '../../Modals/CraneRemoveModal/CraneRemoveModal';
import Spinner from '../../UI/Spinner/Spinner';
import { Hierarchy } from '../../../DataTypes/Hierarchy';
import { Crane } from '../../../DataTypes/Crane';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { Blackbox } from 'src/DataTypes/Blackbox';
import { useTranslation } from 'react-i18next';

type props = {
	hierarchy: Hierarchy;
	crane: Crane;
};

const CraneDetailsTable: React.FC<props> = props => {
	const [showEditModal, setShowEditModal] = useState(false);
	const [showRemoveModal, setShowRemoveModal] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [license, setLicense] = useState<License | null>(null);
	const { t } = useTranslation();

	useEffect(() => {
		setLicense(findBindBlackbox() ?? null);
		setIsLoading(false);
	}, []);

	const showEditModalHandler = () => {
		const show = showEditModal;
		setShowEditModal(!show);
	};
	const showRemoveModalHandler = () => {
		const show = showRemoveModal;
		setShowRemoveModal(!show);
	};

	const findBindBlackbox = () => {
		return props.hierarchy.subscriptionCredit?.licenses?.find(l => l.deviceId === props.crane.deviceId);
	};

	const generateBlackboxType = (blackbox?: Blackbox): string => {
		if (!blackbox) {
			return '';
		}
		switch (blackbox.type) {
			case 'Feeder':
				return 'SBF';
			case 'Lite':
				return 'SBL';
			case 'Full':
				return 'SBC';
			default:
				return '';
		}
	};

	if (isLoading) {
		return <Spinner />;
	}
	return (
		<div className={styles.card}>
			<div className={styles.cardHeader}>
				<span className={styles.cardTitle}>{t('craneDetails')}</span>
				{/* <div role='button' className={styles.icons} hidden={true}>
				<img onClick={showRemoveModalHandler} src={require('../../../assets/icons/trash.svg')} className={styles.icon} />
			</div> */}
				<EditIcon onClick={showEditModalHandler} className={styles.icons} />
			</div>
			<div className={styles.craneDetailsTable}>
				<div className={styles.craneDataParagraph}>
					<span className={styles.paragraphTitle}>{t('craneParameters')}</span>
					<div className={styles.details}>
						<span className={styles.tableDetailsKey}>{t('sn')}</span>
						<span className={styles.tableDetailsValue}>{props.crane.sn}</span>
					</div>
					<div className={styles.details}>
						<span className={styles.tableDetailsKey}>{t('craneType')}</span>
						<span className={styles.tableDetailsValue}>{t(props.crane.type.toLowerCase())}</span>
					</div>
					<div className={styles.details}>
						<span className={styles.tableDetailsKey}>{t('status')}</span>
						<span className={styles.tableDetailsValue}>{t(props.crane.GetStatus())}</span>
					</div>
					<div className={styles.details}>
						<span className={styles.tableDetailsKey}>{t('model')}</span>
						<span className={styles.tableDetailsValue}>{props.crane.model}</span>
					</div>
					<div className={styles.details}>
						<span className={styles.tableDetailsKey}>{t('manufacturer')}</span>
						<span className={styles.tableDetailsValue}>{props.crane.manufacturer}</span>
					</div>
					<div className={styles.details}>
						<span className={styles.tableDetailsKey}>{t('craneHeight')}</span>
						<span className={styles.tableDetailsValue}>{`${props.crane.height} ${t('meters')}`}</span>
					</div>
					<div className={styles.details}>
						<span className={styles.tableDetailsKey}>{t('jibLength')}</span>
						<span className={styles.tableDetailsValue}>{`${props.crane.length} ${t('meters')}`}</span>
					</div>
					{/* <div className={styles.details}>
						<span className={styles.tableDetailsKey}>{'גובל כבל'}</span>
						<span className={styles.tableDetailsValue}></span>
					</div>
					<div className={styles.details}>
						<span className={styles.tableDetailsKey}>{'גובל סיבוב'}</span>
						<span className={styles.tableDetailsValue}></span>
					</div>
					<div className={styles.details}>
						<span className={styles.tableDetailsKey}>{'גובל עגלה'}</span>
						<span className={styles.tableDetailsValue}></span>
					</div> */}
				</div>
				<div className={styles.blackboxParagraph}>
					<span className={styles.paragraphTitle}>{t('blackbox')}</span>
					{license ? (
						<>
							<div className={styles.details}>
								<span className={styles.tableDetailsKey}>{t('blackboxType')}</span>
								<span className={styles.tableDetailsValue}>{generateBlackboxType(license?.blackboxBinding?.blackbox)}</span>
							</div>
							<div className={styles.details}>
								<span className={styles.tableDetailsKey}>{t('sn')}</span>
								<span className={styles.tableDetailsValue}>{license?.blackboxBinding?.blackbox?.friendlyName}</span>
							</div>
							{/* <div className={styles.details}>
						<span className={styles.tableDetailsKey}>{'MAC'}</span>
						<span className={styles.tableDetailsValue}></span>
					</div>
					<div className={styles.details}>
						<span className={styles.tableDetailsKey}>{'IP'}</span>
						<span className={styles.tableDetailsValue}></span>
					</div>
					<div className={styles.details}>
						<span className={styles.tableDetailsKey}>{'גרסה'}</span>
						<span className={styles.tableDetailsValue}></span>
					</div> */}
							<div className={styles.details}>
								<span className={styles.tableDetailsKey}>{t('licenseNumber')}</span>
								<span className={styles.tableDetailsValue}>{license?.id}</span>
							</div>
						</>
					) : (
						t('notAssociated')
					)}
				</div>
				{/* <div className={styles.testsParagraph}>
					<span className={styles.paragraphTitle}>{'בדיקות'}</span>
					<div className={styles.details}>
						<span className={styles.tableDetailsKey}>{'טסט אחרון'}</span>
						<span className={styles.tableDetailsValue}></span>
					</div>
					<div className={styles.details}>
						<span className={styles.tableDetailsKey}>{'טסט הבא'}</span>
						<span className={styles.tableDetailsValue}></span>
					</div>
				</div> */}
			</div>
			{showEditModal && <CraneEditDetailsModal crane={props.crane} show={showEditModal} showEditModalHandler={showEditModalHandler} />}
			{showRemoveModal && <CraneRemoveModal show={showRemoveModal} handleRemove={() => {}} showRemoveModalHandler={showRemoveModalHandler} />}
		</div>
	);
};

export default CraneDetailsTable;
