import { IAnalogInputValue, IDigitalInputValue } from './DeviceStatus';

export interface IActivtySnapshot {
	analogInputs: IAnalogInputValue[];
	digitalInputs: IDigitalInputValue[];
	time: string;
}

export class ActivitySnapshot implements IActivtySnapshot {
	analogInputs: IAnalogInputValue[];
	digitalInputs: IDigitalInputValue[];
	time: string;

	constructor(data: IActivtySnapshot) {
		this.analogInputs = data.analogInputs;
		this.digitalInputs = data.digitalInputs;
		this.time = data.time;
	}
}
