import React, { useRef, useState } from 'react';
import cn from 'classnames';
import styles from '../MobileCraneSettingsTable/MobileCraneSettingsTable.module.scss';
import useOutsideClick from '../../../../utils/useOutsideClick';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import { ReactComponent as FilterIcon } from '../../../../assets/icons/filter.svg';
import { ReactComponent as ChevronIcon } from '../../../../assets/icons/chevron.svg';
import { Hierarchy } from '../../../../DataTypes/Hierarchy';
import { SubscriptionCredit } from '../../../../DataTypes/SubscriptionCredit';
import { useTranslation } from 'react-i18next';
import { Popover } from 'antd';
import { MoreOutlined } from '@ant-design/icons';

type props = {
	hierarchy: Hierarchy;
	subscriptionCredit: SubscriptionCredit;
	licenseExpirationCalc: any;
	unassignModalHandler: any;
	assignModalHandler: any;
	setCurrentLicense: any;
	assignPopover: any;
	unassignPopover: any;
};

const MobileLicensesSettingsTable: React.FC<props> = props => {
	const controlsRef = useRef<HTMLDivElement>(null);
	const [openDistIds, setOpenDistIds] = useState<string[]>([]);
	const [openControls, setOpenControls] = useState<string | null>(null);
	const { t } = useTranslation();

	const toggleOnetDistId = index => {
		if (openDistIds.includes(index)) {
			setOpenDistIds(openDistIds.filter(_id => _id !== index));
		} else {
			setOpenDistIds([...openDistIds, index]);
		}
	};

	useOutsideClick(controlsRef, () => {
		if (openControls != null) setOpenControls(null);
	});

	return (
		<>
			<div className={styles.tableHeader} hidden={true}>
				<div>
					<FilterIcon />
					<p>{t('filter')}</p>
				</div>
				<div>
					<SearchIcon />
					<p>{t('search')}</p>
				</div>
			</div>
			<table className={cn(styles.mobileTable, 'table table-borderless fw-bold')}>
				<thead>
					<tr>
						<th scope='col'>{t('licenseType')}</th>
						<th scope='col'>{t('licenseNumber')}</th>
						<th scope='col'></th>
					</tr>
				</thead>
				<tbody>
					{props.subscriptionCredit?.licenses.map((license, index) => {
						return (
							<React.Fragment key={index}>
								<tr key={index} className={openDistIds.includes(license.id) ? styles.open : styles.close}>
									<td>{license.type === 'Full' ? 'SBC' : license.type === 'Lite' ? 'SBL' : '-'}</td>
									<td>{license.id}</td>
									<td className={styles.chevron} onClick={() => toggleOnetDistId(license.id)}>
										<ChevronIcon />
									</td>
								</tr>

								<tr className={cn(openDistIds.includes(license.id) ? styles.open : null, styles.tableExpand)}>
									<td colSpan={4}>
										<div>
											<span className={styles.distHeader}>{t('expirationInDays')}</span>
											<span>{license.blackboxBinding ? props.licenseExpirationCalc(license.durationInDays, license.blackboxBinding?.bindTime) : license.durationInDays}</span>
											<Popover
												placement='bottomRight'
												trigger='click'
												className={styles.moreIcon}
												content={license.blackboxBinding ? props.unassignPopover : props.assignPopover}>
												<MoreOutlined onClick={() => props.setCurrentLicense(license)} />
											</Popover>
										</div>
										<div>
											<span className={styles.distHeader}>{t('blackbox')}</span>
											<span>{license.blackboxBinding?.blackbox.friendlyName ?? t('notAssociated')}</span>
										</div>
										<div>
											<span className={styles.distHeader}>{t('crane')}</span>
											<span>
												{props.hierarchy?.cranes?.find(c => c.id === license.blackboxBinding?.craneBinding?.craneId)
													? `${t('crane')} ${props.hierarchy?.cranes?.find(c => c.id === license.blackboxBinding?.craneBinding?.craneId)?.number},  ${t('site')} ${
															props.hierarchy?.cranes?.find(c => c.id === license.blackboxBinding?.craneBinding?.craneId)?.site?.name
													  },  ${t('companyAdj')} ${props.hierarchy?.cranes?.find(c => c.id === license.blackboxBinding?.craneBinding?.craneId)?.site?.company?.name}`
													: t('notAssociated')}
											</span>
										</div>
									</td>
								</tr>
							</React.Fragment>
						);
					})}
				</tbody>
			</table>
		</>
	);
};

export default MobileLicensesSettingsTable;
