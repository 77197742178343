import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styles from './AddContactModal.module.scss';
import RTL from '../../RTL/RTL';
import { ITableItemData } from 'src/components/DistributionList/DistributionList';
import { useTranslation } from 'react-i18next';
import NewUserModal from '../NewUserModal/NewUserModal';
import axios from 'axios';
import { IUserData, User } from 'src/DataTypes/User';
import { Spin } from 'antd';
import { Hierarchy } from 'src/DataTypes/Hierarchy';

type props = {
	submitCallback: (values) => void;
	closeHandler: () => void;
	items: ITableItemData[];
	hierarchy: Hierarchy;
};

type inputs = {
	mail: string;
};
interface OptionType {
	inputValue?: string;
	mail: string;
}

const filter = createFilterOptions<OptionType>();

// const users = [
// 	{ mail: 'aaa', inputValue: '' },
// 	{ mail: 'bbb', inputValue: '' },
// 	{ mail: 'ccc', inputValue: '' },
// ];

const AddContactModal: React.FC<props> = props => {
	const [value, setValue] = useState<OptionType | null>(null);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [users, setUsers] = useState<OptionType[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const { t } = useTranslation();

	const closeHandler = () => {
		setIsOpen(false);
		props.closeHandler();
	};

	const PollUsers = async () => {
		const response = await axios
			.get('/User/All')
			.then(res => {
				setUsers(res.data.filter((x: IUserData) => x.mail !== undefined).map((u: IUserData) => new Object({ mail: u.mail, inputValue: '' })));
				setIsLoading(false);
			})
			.catch(err => {
				console.log(err);
			});
	};

	const validationSchema = yup.object({
		mail: yup
			.string()
			.email()
			.notOneOf(
				props.items.map(item => item.mail),
				t('userIsAlreadyRegisteredToDl')
			)
			.required(t('requiredField')),
	});

	const formik = useFormik({
		initialValues: {
			mail: '',
			canConfirmNotifications: false,
		},
		validationSchema: validationSchema,
		onSubmit: (values: inputs) => {
			props.submitCallback(values.mail);
		},
	});

	useEffect(() => {
		PollUsers();
	}, [isOpen, props.items]);

	return (
		<RTL>
			<Modal size='sm' className={styles.modal} aria-labelledby='contained-modal-title-vcenter' show centered onHide={props.closeHandler}>
				<Modal.Header style={{ border: 'none' }} closeButton />
				<div className={styles.title}>{t('addingUserToDistributionList')}</div>
				<Modal.Body className={styles.modalBody}>
					{isLoading ? (
						<Spin className={styles.spin} size='large' tip={t('loading')} />
					) : (
						<form className={styles.formi} onSubmit={formik.handleSubmit}>
							<Autocomplete
								value={value}
								onChange={(event, newValue) => {
									if (typeof newValue === 'string') {
										setValue({
											mail: newValue,
										});
										setIsOpen(true);
									} else if (newValue && newValue.inputValue) {
										// Create a new value from the user input
										setValue({
											mail: newValue.inputValue,
										});
										setIsOpen(true);
									} else {
										formik.values.mail = newValue?.mail!;
									}
								}}
								filterOptions={(options, params) => {
									const filtered = filter(options, params);

									const { inputValue } = params;
									// Suggest the creation of a new value
									const isExisting = options.some(option => inputValue === option.mail);
									if (inputValue !== '' && !isExisting) {
										filtered.push({
											inputValue,
											mail: t('addNewUser'),
										});
									}

									return filtered;
								}}
								selectOnFocus
								handleHomeEndKeys
								id='mail'
								options={users}
								getOptionLabel={option => {
									// Value selected with enter, right from the input
									if (typeof option === 'string') {
										return option;
									}
									// Add "xxx" option created dynamically
									if (option.mail) {
										return option.mail;
									}
									// Regular option
									return option.mail;
								}}
								renderOption={(props, option) => <li {...props}>{props.mail}</li>}
								freeSolo
								fullWidth
								renderInput={params => (
									<TextField
										label={t('mail')}
										helperText={formik.touched.mail ? formik.errors.mail : ''}
										error={formik.touched.mail && Boolean(formik.errors.mail)}
										margin='dense'
										onBlur={formik.handleBlur}
										variant='outlined'
										{...params}
									/>
								)}
							/>
							{/* <TextField
							id='mail'
							label={t('mail')}
							value={formik.values.mail}
							helperText={formik.touched.mail ? formik.errors.mail : ''}
							error={formik.touched.mail && Boolean(formik.errors.mail)}
							margin='dense'
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							variant='outlined'
							fullWidth
						/> */}
							<div className={styles.formButtons}>
								<Button type='submit' variant='outline-primary' className={styles.button}>
									{t('add')}
								</Button>
							</div>
						</form>
					)}
				</Modal.Body>
			</Modal>

			<NewUserModal show={isOpen} closeHandler={closeHandler} hierarchy={props.hierarchy} users={users.map(u => u.mail)} fromDl={true} submitCallback={props.submitCallback} />
		</RTL>
	);
};

export default AddContactModal;
