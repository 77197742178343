import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n.use(HttpApi)
	.use(initReactI18next) // passes i18n down to react-i18next
	.use(LanguageDetector)
	.init({
		backend: {
			loadPath: `${location.origin}/locales/{{lng}}.json`,
		},
		fallbackLng: 'en', // default language
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
		react: {
			// Turn off the use of React Suspense
			useSuspense: false,
		},
	});

export default i18n;
