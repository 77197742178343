import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Spin } from 'antd';
import styles from './Confirm.module.scss';
import { getWelcomeText } from '../../../services/services';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

const Confirm: React.FC = props => {
	const [result, setResult] = useState<boolean>();
	const [delay, setDelay] = useState(true);
	const { t } = useTranslation();

	useEffect(() => {
		let qs = new URL(window.location.toString()).searchParams;

		let params = {
			confirmId: qs.get('confirmId'),
			token: qs.get('token'),
		};

		axios.post('/NotificationConfirm', null, { params: params }).then(
			response => {
				setResult(response.data);
			},
			reason => {
				setResult(false);
			}
		);
	}, []);

	useEffect(() => {
		let id = setInterval(() => {
			setDelay(false);
		}, 2000);
		return () => clearInterval(id);
	}, [delay]);

	return (
		<div className={styles.wrapper}>
			<div className={styles.card}>
				<div className={styles.logo} />
				<div className={styles.user}>{getWelcomeText(t('user'))}</div>
				<div className={styles.alert}>
					{result === true && !delay && (
						<>
							<div className={styles.titleTop}>{t('notificationReadingConfirm')}</div>
							<div className={cn(styles.titleBottom, styles.success)}>{t('receivedSuccessfully')}</div>
						</>
					)}{' '}
					{result === false && !delay && (
						<>
							<div className={styles.titleTop}>{t('notificationReadingConfirm')}</div>
							<div className={cn(styles.titleBottom, styles.failed)}>{t('failed')}</div>
							<div>{t('pleaseContactSiteAdministrator')}</div>
						</>
					)}
					{result === null ||
						(delay && (
							<>
								<div className={styles.titleTop}>{t('sendingReadConfirmation')}</div>
								<Spin size='large' className={styles.spin} />
							</>
						))}
				</div>
			</div>
		</div>
	);
};

export default Confirm;
