import React, { useState, useContext } from 'react';
import { MyProfile } from '../../../DataTypes/MyProfile';
import Spinner from '../../UI/Spinner/Spinner';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { ReactComponent as Edit } from '../../../assets/icons/edit.svg';
import styles from '../CompanyDetailsTable/CompanyDetailsTable.module.scss';
import SiteDetailsUpdateModal from '../../Modals/SiteDetailsUpdateModal/SiteDetailsUpdateModal';
import { ISiteUpdateDetails, Site } from '../../../DataTypes/Site';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

type props = {
	site: Site;
};

const SiteDetailsTable: React.FC<props> = props => {
	const [showModal, setShowModal] = useState<boolean>(false);
	const [currentUser, setCurrentUser] = useState<MyProfile>(MyProfile.Current);
	const { ShowLoadingModal } = useContext(LoadingModalContext);
	const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
	const { t } = useTranslation();

	const handleShowUpdateModal = () => {
		setShowUpdateModal(show => !show);
	};

	const handleSubmit = (details: ISiteUpdateDetails) => {
		handleShowUpdateModal();
		ShowLoadingModal(props.site.UpdateDetails(details), 'SiteDetailsUpdate');
	};

	if (!props.site) {
		return <Spinner />;
	}
	return (
		<>
			{showUpdateModal && <SiteDetailsUpdateModal handleClose={handleShowUpdateModal} show={showUpdateModal} handleSubmit={handleSubmit} site={props.site} />}
			<div className={styles.card}>
				<span className={styles.cardTitle}>{t('siteDetails')}</span>
				<table className={cn('table', 'table-borderless', styles.mobileTable)}>
					<thead>
						<tr>
							<th scope='col'>{t('company')}</th>
							<th scope='col'>{t('name')}</th>
							<th scope='col'>{t('address')}</th>
							<th scope='col'>{t('contact')}</th>
							<th scope='col'>{t('phone')}</th>
							<th scope='col'></th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{props.site.company.name}</td>
							<td>{props.site.name}</td>
							<td>{props.site.address}</td>
							<td>{props.site.manager?.name}</td>
							<td>{props.site.manager?.phone}</td>
							<td hidden={!currentUser?.permissions.siteCRUD.update} className={styles.tcontrols}>
								<Edit onClick={handleShowUpdateModal} className={styles.icon} />
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</>
	);
};

export default SiteDetailsTable;
