import React, { useState, useEffect } from 'react';
import Spinner from '../UI/Spinner/Spinner';
import { Notification } from '../../DataTypes/Notification';
import ReadNotification from '../Notification/ReadNotification/ReadNotification';
import styles from './NotificationsPage.module.scss';
import { Hierarchy } from '../../DataTypes/Hierarchy';
import NotificationList from '../Notification/NotificationList/NotificationList';
import { NotificationManager } from '../../DataTypes/NotificationManager';
import { Button } from 'react-bootstrap';
import StickyHeader from '../StickyHeader/StickyHeader';
import { MyProfile } from 'src/DataTypes/MyProfile';
import { onlyUnique, onlyUniqueBy } from 'src/services/services';
import { useTranslation } from 'react-i18next';

type Props = {
	hierarchy: Hierarchy;
};

const NotificationsPage: React.FC<Props> = ({ hierarchy }) => {
	const [loadingMore, setLoadingMore] = useState<boolean>(false);
	const [notifications, setNotifications] = useState<Notification[]>([...(hierarchy.notificationManager?.notifications ?? [])]);
	const [skip, setSkip] = useState<number>(hierarchy.notificationManager?.notifications.length ?? 0);
	const { t } = useTranslation();

	useEffect(() => {
		if (hierarchy.notificationManager) {
			hierarchy.notificationManager.newNotificationsCount = 0;
			hierarchy.notificationManager.NotificationReceived.subscribe(NotificationReceived);
		}

		MyProfile.Current.updateLastSeenDate();

		return () => {
			if (hierarchy.notificationManager) {
				hierarchy.notificationManager.notifications = [];
				hierarchy.notificationManager.NotificationReceived.unsubscribe(NotificationReceived);
			}
		};
	}, []);

	const NotificationReceived = (s, n) => {
		notifications.push(n);
		setSkip(skip + 1);

		setTimeout(() => {
			if (hierarchy.notificationManager) hierarchy.notificationManager.newNotificationsCount = 0;
		}, 1000);
	};

	const loadOldNotifications = async () => {
		setLoadingMore(true);
		const amount = 10;

		const newNotifications = [...notifications];
		const fetched = await NotificationManager.FetchLast(hierarchy.cranes, amount, skip);

		fetched.forEach(n => {
			if (!notifications.find(x => x.id === n.id)) {
				newNotifications.push(n);
			}
		});

		setNotifications(newNotifications);
		setLoadingMore(false);
		setSkip(skip + amount);
		scroll({ top: 99999 });
	};

	return (
		<div className={styles.wrapper}>
			<h4>
				<StickyHeader>{t('notifications')}</StickyHeader>
			</h4>
			<div className={styles.notificationList}>
				{<NotificationList notifications={notifications} notificationToItemViewFunc={(n, index) => <ReadNotification key={index} notification={n} />} />}
				{loadingMore ? (
					<Spinner />
				) : (
					<Button variant='outline-primary' className={styles.button} onClick={loadOldNotifications}>
						{t('loadPreviousNotifications')}
					</Button>
				)}
			</div>
		</div>
	);
};

export default NotificationsPage;
