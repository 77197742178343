import React, { useState, useEffect, JSXElementConstructor } from 'react';
import styles from './Users.module.scss';
import Spinner from '../../UI/Spinner/Spinner';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import NewUserModal from '../../Modals/NewUserModal/NewUserModal';
import RemoveUserModal from '../../Modals/RemoveUserModal/RemoveUserModal';
import axios from 'axios';
import { User } from '../../../DataTypes/User';
import ErrorModal from '../../Modals/ErrorModal/ErrorModal';
import UsersSettingsTable from '../../Tables/UsersSettingsTable/UsersSettingsTable';
import { Hierarchy } from '../../../DataTypes/Hierarchy';
import MobileUsersSettingsTable from '../../Mobile/Tables/MobileUsersSettingsTable/MobileUsersSettingsTable';
import EditUserPermisionsModal from 'src/components/Modals/EditUserPermisionsModal/EditUserPermisionsModal';
import { useTranslation } from 'react-i18next';
import { MyProfile } from 'src/DataTypes/MyProfile';

type Props = {
	hierarchy: Hierarchy;
	myProfile: MyProfile;
};

const Users: React.FC<Props> = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [showNewUserModal, setShowNewUserModal] = useState(false);
	const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);
	const [showEditUserPermissionsModal, setShowEditUserPermissionsModal] = useState(false);
	const [currentUser, setCurrentUser] = useState<User>();
	const [users, setUsers] = useState<User[]>([]);
	const [apiError, setApiError] = useState<JSX.Element>();
	const { t } = useTranslation();

	let timeoutHandle: any = null;

	useEffect(() => {
		PollUsers();
		return () => clearTimeout(timeoutHandle);
	}, []);

	const PollUsers = async () => {
		const response = await axios.get('/User/All').catch(reason => {
			return { ...reason, isError: true };
		});

		if (response.isError) {
			setApiError(<ErrorModal statusCode={response.response.status} content={response.response.data} />);
			return clearTimeout(timeoutHandle);
		} else {
			setUsers(response.data.map(u => new User(u)));
			setIsLoading(false);
		}

		timeoutHandle = setTimeout(PollUsers, 3000);
	};

	const newUserModalHandler = () => {
		setShowNewUserModal(!showNewUserModal);
	};

	const editUserPermissionsModalHandler = () => {
		setShowEditUserPermissionsModal(!showEditUserPermissionsModal);
	};

	const removeUserModalHandler = () => {
		setShowRemoveUserModal(!showRemoveUserModal);
	};

	const pendingdPopoverContent = (
		<>
			<div role='button' className={styles.removeBb} onClick={removeUserModalHandler}>
				{t('remove')}
			</div>
			<hr />
			<div role='button' className={styles.removeBb} onClick={editUserPermissionsModalHandler}>
				{t('editDetails')}
			</div>
		</>
	);
	const registeredPopoverContent = (
		<>
			<div role='button' className={styles.removeBb} onClick={editUserPermissionsModalHandler}>
				{t('editDetails')}
			</div>
		</>
	);

	if (apiError) {
		return (
			<>
				{apiError} <Spinner />
			</>
		);
	} else if (isLoading) {
		return <Spinner />;
	} else {
		return (
			<div className={styles.wrapper}>
				{showNewUserModal && <NewUserModal hierarchy={props.hierarchy} show={showNewUserModal} closeHandler={newUserModalHandler} users={users.map(u => u.mail)} fromDl={false} />}
				{showRemoveUserModal && <RemoveUserModal show={showRemoveUserModal} closeHandler={removeUserModalHandler} user={currentUser!} />}
				{showEditUserPermissionsModal && (
					<EditUserPermisionsModal show={showEditUserPermissionsModal} closeHandler={editUserPermissionsModalHandler} user={currentUser!} myProfile={props.myProfile} />
				)}

				<div className={styles.card}>
					<div className={styles.cardHeader}>
						<div className={styles.cardTitle}>{t('users')}</div>
						<Button onClick={newUserModalHandler} type='primary' shape='circle' className='circular-add-btn' icon={<PlusOutlined />} />
					</div>
					<MobileUsersSettingsTable
						users={users}
						pendingPopoverContent={pendingdPopoverContent}
						registeredPopoverContent={registeredPopoverContent}
						setCurrentUser={setCurrentUser}
						removeUserModalHandler={removeUserModalHandler}
						newUserModalHandler={newUserModalHandler}
					/>
					<UsersSettingsTable dataSource={users} pendingPopoverContent={pendingdPopoverContent} registeredPopoverContent={registeredPopoverContent} setCurrentUser={setCurrentUser} />
				</div>
			</div>
		);
	}
};

export default Users;
