import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import styles from './MapView.module.scss';
import { Crane } from 'src/DataTypes/Crane';
import CraneMarker from './CraneMarker';
import i18n from 'i18next';
import { Config } from 'src/DataTypes/Config';

type props = {
	cranes: Crane[];
};

const MapView: React.FC<props> = props => {
	const [language, setLanguage] = useState<string>(i18n.language);
	const handleApiLoaded = (map, maps) => {};

	const defaultLocation = {
		center: {
			lat: 31.5461,
			lng: 34.8516,
		},
		zoom: 8,
	};

	useEffect(() => {
		setLanguage(i18n.language);
	}, [i18n.language]);

	return (
		<div className={styles.wrapper}>
			<GoogleMapReact
				key={language}
				bootstrapURLKeys={{
					key: `${Config.Current.googleMapsApiKey}&region=IL&language=${language}`,
				}}
				defaultCenter={defaultLocation.center}
				defaultZoom={defaultLocation.zoom}
				yesIWantToUseGoogleMapApiInternals
				onGoogleApiLoaded={({ map, maps }) =>
					handleApiLoaded(map, maps)
				}>
				{props.cranes.map(crane => (
					<CraneMarker
						key={`marker_${crane.id}`}
						crane={crane}
						lat={crane.deviceStatus?.location?.lat ?? 31.5461}
						lng={crane.deviceStatus?.location?.lng ?? 34.8516}
					/>
				))}
			</GoogleMapReact>
		</div>
	);
};

export default MapView;
