import React, { useContext } from 'react';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { Modal, Button } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styles from '../AddContactModal/AddContactModal.module.scss';
import RTL from '../../RTL/RTL';
import { Hierarchy } from '../../../DataTypes/Hierarchy';
import { License } from '../../../DataTypes/License';
import { useTranslation } from 'react-i18next';

type props = {
	hierarchy: Hierarchy;
	showModalHandler: any;
	show: boolean;
	license: License;
};

type inputs = {
	blackbox: string;
};

const LicenseAssignmentModal: React.FC<props> = props => {
	const { ShowLoadingModal } = useContext(LoadingModalContext);
	const { t } = useTranslation();

	const validationSchema = yup.object({
		blackbox: yup.string().required(t('requiredField')),
	});

	const formik = useFormik({
		initialValues: {
			blackbox: '',
		},
		validationSchema: validationSchema,
		onSubmit: (values: inputs) => {
			props.showModalHandler();
			ShowLoadingModal(props.license.BindBlackbox(values.blackbox), 'LicenseAssign');
		},
	});

	return (
		<RTL>
			<Modal size='sm' className={styles.modal} aria-labelledby='contained-modal-title-vcenter' show={props.show} centered onHide={props.showModalHandler}>
				<Modal.Header style={{ border: 'none' }} closeButton />
				<div className={styles.title}>{t('licenseAssign')}</div>
				<Modal.Body className={styles.modalBody}>
					<form className={styles.formi} onSubmit={formik.handleSubmit}>
						<TextField
							id='blackbox'
							name='blackbox'
							label={t('blackbox')}
							value={formik.values.blackbox}
							select
							SelectProps={{
								native: true,
							}}
							helperText={formik.touched.blackbox ? formik.errors.blackbox : ''}
							error={formik.touched.blackbox && Boolean(formik.errors.blackbox)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth>
							<option value='' disabled />
							{props.hierarchy.subscriptionCredit?.blackboxes
								.sort((a, b) => (a.friendlyName.toLowerCase() < b.friendlyName.toLowerCase() ? -1 : 1))
								.map(blackbox => (
									<option key={blackbox.id} id={blackbox.id} value={blackbox.id}>
										{blackbox.friendlyName}
									</option>
								))}
						</TextField>

						<div className={styles.formButtons}>
							<Button type='submit' variant='outline-primary' className={styles.button}>
								{t('update')}
							</Button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</RTL>
	);
};

export default LicenseAssignmentModal;
