import React, { useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styles from '../AddContactModal/AddContactModal.module.scss';
import RTL from '../../RTL/RTL';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { ERole, User, IUserPermisionsDetails } from 'src/DataTypes/User';
import { useTranslation } from 'react-i18next';
import { MyProfile } from 'src/DataTypes/MyProfile';

type props = {
	show: boolean;
	closeHandler: (result?: Promise<any>) => void;
	user: User;
	myProfile: MyProfile;
};

const EditUserPermisionsModal: React.FC<props> = props => {
	const { ShowLoadingModal } = useContext(LoadingModalContext);
	const { t } = useTranslation();

	const validationSchema = yup.object({
		role: yup.string().required(t('requiredField')),
		canConfirmNotifications: yup.boolean().required(t('requiredField')),
	});

	const formik = useFormik({
		initialValues: {
			fullName: props.user.fullName,
			mail: props.user.mail,
			phone: props.user.phone.number,
			role: props.user.role,
			canConfirmNotifications: props.user.notifications.canConfirmNotifications,
		},
		validationSchema: validationSchema,
		onSubmit: values => {
			const details: IUserPermisionsDetails = {
				role: values.role,
				notifications: {
					canConfirmNotifications: values.canConfirmNotifications,
				},
			};
			props.closeHandler(ShowLoadingModal(props.user.UpdateUserPermissions(details), 'UserUpdate'));
		},
	});

	return (
		<RTL>
			<Modal size='sm' className={styles.modal} aria-labelledby='contained-modal-title-vcenter' show centered onHide={props.closeHandler}>
				<Modal.Header style={{ border: 'none' }} closeButton />
				<div className={styles.title}>{t('updateUserDetails')}</div>
				<Modal.Body className={styles.modalBody}>
					<form className={styles.formi} onSubmit={formik.handleSubmit}>
						<TextField
							id='fullName'
							label={t('fullName')}
							value={formik.values.fullName}
							helperText={formik.touched.fullName ? formik.errors.fullName : ''}
							error={formik.touched.fullName && Boolean(formik.errors.fullName)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth
							disabled
						/>
						<TextField
							id='mail'
							label={t('mail')}
							value={formik.values.mail}
							helperText={formik.touched.mail ? formik.errors.mail : ''}
							error={formik.touched.mail && Boolean(formik.errors.mail)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth
							disabled
						/>
						<TextField
							id='phone'
							label={t('phone')}
							value={formik.values.phone}
							helperText={formik.touched.phone ? formik.errors.phone : ''}
							error={formik.touched.phone && Boolean(formik.errors.phone)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth
							disabled
						/>
						<TextField
							id='role'
							name='role'
							label={t('role')}
							value={formik.values.role}
							select
							SelectProps={{
								native: true,
							}}
							helperText={formik.touched.role ? formik.errors.role : ''}
							error={formik.touched.role && Boolean(formik.errors.role)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth>
							<option value='' disabled />
							{Object.values(ERole).map(value => (
								<option key={value} id={value} value={value}>
									{t(value)}
								</option>
							))}
						</TextField>
						<div className={styles.subtitle}>{t('notifications')}</div>
						<div className={styles.checkboxParagraph}>
							<FormControlLabel
								disabled={!props.myProfile.permissions.notificationsConfirm}
								control={<Checkbox checked={formik.values.canConfirmNotifications} onChange={formik.handleChange} name='canConfirmNotifications' color='primary' />}
								label={t('canConfirmNotifications')}
							/>
						</div>
						<div className={styles.formButtons}>
							<Button type='submit' variant='outline-primary' className={styles.button}>
								{t('update')}
							</Button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</RTL>
	);
};

export default EditUserPermisionsModal;
