import React, { useEffect, useContext } from 'react';
import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import styles from './LoadingModal.module.scss';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { useTranslation } from 'react-i18next';

export const successAnimation = (
	<svg className={styles.success_checkmark} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52'>
		<circle className={styles.success_checkmark_circle} cx='26' cy='26' r='25' fill='none' />
		<path className={styles.checkmark_check} fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8' />
	</svg>
);

export const errorAnimation = (
	<svg className={styles.error_checkmark} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52'>
		<circle className={styles.error_checkmark_circle} cx='26' cy='26' r='25' fill='none' />
		<path className={styles.checkmark_check} fill='none' d='M16 16 36 36 M36 16 16 36' />
	</svg>
);

const LoadingModal: React.FC = () => {
	const [type, setType] = useState<boolean | null>(null);
	const { show, actionTexts, response, toggleShow } = useContext(LoadingModalContext);
	const { t } = useTranslation();

	useEffect(() => {
		setType(response?.isError ?? null);
	}, [response]);

	const loadingAnimation = <div className={styles.loader} />;

	return (
		<Modal size='sm' show={show} centered>
			<div className={styles.modal}>
				<div className={styles.modalTitle}>{t(`${actionTexts}.Generic`)}</div>
				<div className={styles.animation}>{type === false ? successAnimation : type === true ? errorAnimation : loadingAnimation}</div>
				<div className={styles.modalFooterTitle}>{type === false ? t('success') : type === true ? t('fail') : ''}</div>
				<div className={styles.modalContent}>{typeof response?.value === 'string' ? response?.value : ''}</div>

				<Button variant='outline-primary' onClick={toggleShow} className={styles.button} disabled={typeof type !== 'boolean'}>
					{t('confirm')}
				</Button>
			</div>
		</Modal>
	);
};

export default LoadingModal;
