import React, { useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { TextField, ThemeProvider, createMuiTheme } from '@material-ui/core';
import RTL from '../../RTL/RTL';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { useFormik, Form } from 'formik';
import * as yup from 'yup';
import styles from '../AddContactModal/AddContactModal.module.scss';
import { MyProfile } from '../../../DataTypes/MyProfile';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { useTranslation } from 'react-i18next';

type props = {
	show: boolean;
	closeHandler: () => void;
	myProfile: MyProfile;
};

const ChangePasswordModal: React.FC<props> = props => {
	const { ShowLoadingModal } = useContext(LoadingModalContext);
	const { t } = useTranslation();

	const validationSchema = yup.object({
		password: yup
			.string()
			.required(t('requiredField'))
			.matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, t('passwordStrength')),
		rePassword: yup.string().oneOf([yup.ref('password'), ''], t('passwordMustMatch')),
	});

	const formik = useFormik({
		initialValues: {
			password: '',
			rePassword: '',
		},
		validationSchema: validationSchema,
		onSubmit: values => {
			props.closeHandler();
			ShowLoadingModal(props.myProfile.changePassword(values.password), 'UserPasswordChange');
		},
	});

	return (
		<RTL>
			<Modal size='sm' className={styles.modal} aria-labelledby='contained-modal-title-vcenter' show={props.show} centered onHide={props.closeHandler}>
				<Modal.Header style={{ border: 'none' }} closeButton />
				<div className={styles.title}>{t('changePassword')}</div>
				<Modal.Body className={styles.modalBody}>
					<form className={styles.formi} onSubmit={formik.handleSubmit}>
						<TextField
							id='password'
							label={t('password')}
							value={formik.values.password}
							helperText={formik.touched.password ? formik.errors.password : ''}
							error={formik.touched.password && Boolean(formik.errors.password)}
							margin='dense'
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							variant='outlined'
							type='password'
							fullWidth
						/>
						<TextField
							id='rePassword'
							label={t('rePassword')}
							value={formik.values.rePassword}
							helperText={formik.touched.rePassword ? formik.errors.rePassword : ''}
							error={formik.touched.rePassword && Boolean(formik.errors.rePassword)}
							margin='dense'
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							disabled={!formik.values.password}
							variant='outlined'
							type='password'
							fullWidth
						/>
						<div className={styles.formButtons}>
							<Button onClick={() => formik.resetForm()} variant='outline-danger' className={styles.button}>
								{t('reset')}
							</Button>
							<Button type='submit' variant='outline-primary' className={styles.button} disabled={!formik.values.password && !formik.values.rePassword}>
								{t('update')}
							</Button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</RTL>
	);
};

export default ChangePasswordModal;
