import React, { useContext, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styles from '../../Modals/AddContactModal/AddContactModal.module.scss';
import RTL from '../../RTL/RTL';
import { Hierarchy } from '../../../DataTypes/Hierarchy';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { useTranslation } from 'react-i18next';

type props = {
	show: boolean;
	handleClose: () => void;
	hierarchy: Hierarchy | any;
};

type inputs = {
	name: string;
	company: string;
	officeAddress: string;
	managerName: string;
	managerPhone: string;
};

const phoneRegExp: RegExp = /^05\d{8}$/;

const NewSiteModal: React.FC<props> = props => {
	const [companyId, setCompanyId] = useState<string>('');
	const { t } = useTranslation();

	const { ShowLoadingModal } = useContext(LoadingModalContext);

	const formik = useFormik({
		initialValues: {
			name: '',
			officeAddress: '',
			company: '',
			managerName: '',
			managerPhone: '',
		},
		validationSchema: yup.object({
			name: yup
				.string()
				.notOneOf(
					props.hierarchy.companies.find(company => (companyId ? company.id === companyId : 1))?.sites.map(site => site.name),
					t('existingSiteName')
				)
				.required(t('requiredField')),
			company: yup
				.string()
				.oneOf(props.hierarchy.companies.map(company => company.id))
				.required(t('requiredField')),
			officeAddress: yup.string().required(t('requiredField')),
			managerName: yup.string().required(t('requiredField')),
			managerPhone: yup.string().matches(phoneRegExp, t('enterValidValue')).required(t('requiredField')),
		}),
		onSubmit: (values: inputs) => {
			const details = {
				name: values.name,
				company: values.company,
				address: values.officeAddress,
				manager: { name: values.managerName, phone: values.managerPhone },
			};
			props.handleClose();
			ShowLoadingModal(props.hierarchy.CreateSite(details), 'SiteCreate');
		},
	});

	return (
		<RTL>
			<Modal size='sm' className={styles.modal} aria-labelledby='contained-modal-title-vcenter' show={props.show} centered onHide={props.handleClose}>
				<Modal.Header style={{ border: 'none' }} closeButton />
				<div className={styles.title}>{t('addingSite')}</div>
				<Modal.Body className={styles.modalBody}>
					<form className={styles.formi} onSubmit={formik.handleSubmit}>
						<TextField
							id='name'
							label={t('name')}
							value={formik.values.name}
							helperText={formik.touched.name ? formik.errors.name : ''}
							error={formik.touched.name && Boolean(formik.errors.name)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth
						/>
						<TextField
							id='company'
							name='company'
							label={t('company')}
							value={formik.values.company}
							select
							SelectProps={{
								native: true,
							}}
							helperText={formik.touched.company ? formik.errors.company : ''}
							error={formik.touched.company && Boolean(formik.errors.company)}
							onBlur={formik.handleBlur}
							onChange={e => {
								formik.handleChange(e);
								setCompanyId(e.target.value);
							}}
							margin='dense'
							variant='outlined'
							fullWidth>
							<option value='' disabled />
							{props.hierarchy.companies
								.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
								.map(company => (
									<option key={company.id} id={company.id} value={company.id}>
										{company.name}
									</option>
								))}
						</TextField>
						<TextField
							id='officeAddress'
							label={t('officeAddress')}
							value={formik.values.officeAddress}
							helperText={formik.touched.officeAddress ? formik.errors.officeAddress : ''}
							error={formik.touched.officeAddress && Boolean(formik.errors.officeAddress)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth
						/>
						<TextField
							id='managerName'
							label={t('contact')}
							value={formik.values.managerName}
							helperText={formik.touched.managerName ? formik.errors.managerName : ''}
							error={formik.touched.managerName && Boolean(formik.errors.managerName)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth
						/>
						<TextField
							id='managerPhone'
							label={t('phone')}
							value={formik.values.managerPhone}
							helperText={formik.touched.managerPhone ? formik.errors.managerPhone : ''}
							error={formik.touched.managerPhone && Boolean(formik.errors.managerPhone)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth
						/>
						<div className={styles.formButtons}>
							<Button type='submit' variant='outline-primary' className={styles.button}>
								{t('add')}
							</Button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</RTL>
	);
};

export default NewSiteModal;
