import React from 'react';
import { Route, Link, Switch, useLocation } from 'react-router-dom';
import { Menu, ConfigProvider } from 'antd';
import styles from './Settings.module.scss';
import Cranes from './Cranes/Cranes';
import Users from './Users/Users';
import Licenses from './Licenses/Licenses';
import Blackboxes from './Blackboxes/Blackboxes';
import { Hierarchy } from '../../DataTypes/Hierarchy';
import StickyHeader from '../StickyHeader/StickyHeader';
import { useTranslation } from 'react-i18next';
import { MyProfile } from 'src/DataTypes/MyProfile';
import Operators from './Operators/Operators';

type Props = {
	hierarchy: Hierarchy;
	myProfile: MyProfile;
};

const Settings: React.FC<Props> = ({ hierarchy, myProfile }) => {
	const location = useLocation();
	const { t } = useTranslation();

	const menuItems = [
		<Menu.Item key='cranes'>
			<Link to='/settings/'>{t('cranes')}</Link>
		</Menu.Item>,
		<Menu.Item key='blackbox'>
			<Link to='/settings/blackbox'>{t('blackboxes')}</Link>
		</Menu.Item>,
		<Menu.Item key='license'>
			<Link to='/settings/license'>{t('licenses')}</Link>
		</Menu.Item>,
		<Menu.Item key='users'>
			<Link to='/settings/users'>{t('users')}</Link>
		</Menu.Item>,
		<Menu.Item key='operators'>
			<Link to='/settings/operators'>{t('operators')}</Link>
		</Menu.Item>,
	];

	return (
		<div className={styles.wrapper}>
			<h4>
				<StickyHeader>{t('settings')}</StickyHeader>
			</h4>

			<Menu defaultSelectedKeys={[location.pathname.split('/')[2] ?? 'cranes']} mode='horizontal' style={{ background: 'none', border: 'none', marginRight: '0.5rem' }}>
				{menuItems}
			</Menu>
			<Switch>
				<Route exact path={'/settings/'} render={() => <Cranes hierarchy={hierarchy} />} />
				<Route path={'/settings/license'} render={() => <Licenses hierarchy={hierarchy} />} />
				<Route path={'/settings/blackbox'} render={() => <Blackboxes hierarchy={hierarchy} />} />
				<Route path={'/settings/users'} render={() => <Users hierarchy={hierarchy} myProfile={myProfile} />} />
				<Route path={'/settings/operators'} render={() => <Operators hierarchy={hierarchy} />} />
			</Switch>
		</div>
	);
};

export default Settings;
