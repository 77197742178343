import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from './SiteDetails.module.scss';
import SiteCranesTable from '../../Tables/SiteCranesTable/SiteCranesTable';
import SiteDetailsTable from '../../Tables/SiteDetailsTable/SiteDetailsTable';
import { Site } from '../../../DataTypes/Site';

type props = {
	item: Site | undefined;
};

const SiteDetails: React.FC<props> = props => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.table}>
				<Row>
					<Col md={10}>
						<SiteDetailsTable site={props.item!}></SiteDetailsTable>
					</Col>
				</Row>
			</div>
			<div className={styles.table}>
				<Row>
					<Col md={10}>
						<SiteCranesTable site={props.item!}></SiteCranesTable>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default SiteDetails;
