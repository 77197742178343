import React, { useState, Fragment } from 'react';
import styles from './MfaModal.module.scss';
import { Modal, Button } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import RTL from '../../RTL/RTL';
import { Spin } from 'antd';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { ReactComponent as CloseIcon } from '../../../assets/icons/x.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/chevron-rounded.svg';
import { successAnimation, errorAnimation } from '../../UI/LoadingModal/LoadingModal';
import { useTranslation } from 'react-i18next';

type props = {
	handleClose: () => void;
	userPhone: string;
	show: boolean;
	sendTokenFunc: () => Promise<any>;
	verifyTokenFunc: (token: string) => Promise<any>;
};

const MfaModal: React.FC<props> = props => {
	const [currentPage, setCurrentPage] = useState(1);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [loadingTitle, setLoadingTitle] = useState<string>();
	const [errorTitle, setErrorTitle] = useState<string>();
	const { t } = useTranslation();
	const [successTitle, setSuccessTitle] = useState<string>(t('theCodeHasBeenSuccessfullyVerified'));

	const validationCodeSchema = yup.object({
		validationCode: yup.string().length(7, t('EnterA7CharacterVerificationCode')).required(t('requiredField')),
	});

	const sendCodeHandler = async () => {
		setLoadingTitle(t('sendingTheVerificationCode'));
		setIsLoading(true);
		await props.sendTokenFunc().then(
			res => {
				setIsLoading(false);
				setCurrentPage(2);
			},
			err => {
				console.log(err);
				setErrorTitle(t('FailedToSendVerificationCode'));
				setIsLoading(false);
				setCurrentPage(3);
			}
		);
	};

	const validationCodeForm = useFormik({
		initialValues: {
			validationCode: '',
		},
		validationSchema: validationCodeSchema,
		onSubmit: ({ validationCode }) => {
			setLoadingTitle(t('verifyingTheVerificationCode'));
			setIsLoading(true);
			props.verifyTokenFunc(validationCode).then(
				res => {
					setIsLoading(false);
					setCurrentPage(4);
				},
				err => {
					setErrorTitle(t('incorrectVerificationCode'));
					setIsLoading(false);
					validationCodeForm.initialValues.validationCode = '';
					setCurrentPage(3);
				}
			);
		},
	});

	const handleResending = () => {
		validationCodeForm.initialValues.validationCode = '';
		sendCodeHandler();
	};

	const handleClose = () => {
		props.handleClose();
		setCurrentPage(1);
	};

	const page1 = (
		<div className={styles.modalContent}>
			<CloseIcon className={styles.closeButton} onClick={handleClose} />
			<div className={styles.title}>{t('thisActionRequiresUserAuthentication')}</div>
			<div className={styles.subtitle}>{t('smsWithCodeWillBeSendToYourPhone')}</div>
			<div className={styles.userPhone}>{props.userPhone}</div>
			<Button variant='outline-primary' className={styles.button} onClick={sendCodeHandler}>
				{t('getCode')}
			</Button>
		</div>
	);
	const page2 = (
		<div className={styles.modalContent}>
			<ArrowIcon role='button' className={styles.prevButton} onClick={() => setCurrentPage(1)} />
			<div className={styles.title}>{t('enterTheVerificationCode')} </div>
			<RTL>
				<form className={styles.form} onSubmit={validationCodeForm.handleSubmit}>
					<TextField
						id='validationCode'
						label={t('verificationCode')}
						value={validationCodeForm.values.validationCode}
						helperText={validationCodeForm.touched.validationCode ? validationCodeForm.errors.validationCode : ''}
						error={validationCodeForm.touched.validationCode && Boolean(validationCodeForm.errors.validationCode)}
						margin='dense'
						onBlur={validationCodeForm.handleBlur}
						onChange={validationCodeForm.handleChange}
						variant='outlined'
					/>
					<div className={styles.resending} onClick={handleResending}>
						{t('dontReceiveCodeClickToResending')}
					</div>
					<div className={styles.formButtons}>
						<Button type='submit' variant='outline-primary' className={styles.button}>
							{t('confirmCode')}
						</Button>
					</div>
				</form>
			</RTL>
		</div>
	);

	const errPage = (
		<div className={styles.modalContent}>
			<CloseIcon className={styles.closeButton} onClick={handleClose} />
			<div className={styles.title}>{errorTitle}</div>
			<div className={styles.iconWrapper}>{errorAnimation}</div>
			<Button variant='outline-primary' className={styles.button} onClick={sendCodeHandler}>
				{t('resendCode')}
			</Button>
		</div>
	);

	const successPage = (
		<div className={styles.modalContent}>
			<CloseIcon className={styles.closeButton} onClick={handleClose} />
			<div className={styles.title}>{successTitle}</div>
			<div className={styles.iconWrapper}>{successAnimation}</div>
			<Button variant='outline-primary' className={styles.button} onClick={handleClose}>
				{t('confirm')}
			</Button>
		</div>
	);

	return (
		<Modal size='sm' aria-labelledby='contained-modal-title-vcenter' show={props.show} onHide={props.handleClose} centered>
			{!isLoading && currentPage === 1 && page1}
			{!isLoading && currentPage === 2 && page2}
			{!isLoading && currentPage === 3 && errPage}
			{!isLoading && currentPage === 4 && successPage}
			{isLoading && (
				<div className={styles.LoadingContent}>
					<Spin tip={loadingTitle} />
				</div>
			)}
		</Modal>
	);
};

export default MfaModal;
