import { Company } from './Company';
import { Crane, ICraneData } from './Crane';
import { Manager } from './Manager';
import axios from 'axios';
import { Notification } from './Notification';
import { ReturnCranesDeviceIds } from '../services/services';
import { NotificationManager } from './NotificationManager';
import { OperationSummary } from './OperationSummary';

export interface ISiteUpdateDetails {
	name: string;
	address: string;
	manager: Manager;
}

export interface ISiteData {
	id: string;
	name: string;
	address: string;
	cranes: ICraneData[];
	manager: Manager;
}

export interface ISite extends ISiteData {
	company: any;
}

export class Site {
	id: string;
	name: string;
	address: string;
	cranes: Crane[];
	manager: Manager;
	company: Company;

	constructor(data: ISiteData, company: Company) {
		this.id = data.id;
		this.name = data.name;
		this.address = data.address;
		this.manager = data.manager;
		this.company = company;
		this.cranes = data.cranes.map(c => new Crane(c, this.company, this));
	}

	UpdateDetails(details: any) {
		return axios.put(`/Company/${this.company.id}/Site/${this.id}`, details);
	}

	/**@returns {Promise<Notification[]>} */
	GetNotifications(): Promise<Notification[]> {
		return NotificationManager.FetchLast(this.cranes);
	}

	// GetActivitySummary(startTime, endTime) {
	// 	return axios.post(
	// 		`/Summary/GetMultiple`,
	// 		ReturnCranesDeviceIds(this.cranes),
	// 		{ params: { startTime, endTime } }
	// 	);
	// }

	GetActivitySummary(startTime: Date, endTime: Date) {
		return OperationSummary.Query(startTime, endTime, ReturnCranesDeviceIds(this.cranes));
	}
}
