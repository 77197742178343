import React, { useRef, useState } from 'react';
import { Blackbox } from '../../../../DataTypes/Blackbox';
import { Popover, Tag } from 'antd';
import cn from 'classnames';
import styles from '../MobileCraneSettingsTable/MobileCraneSettingsTable.module.scss';
import useOutsideClick from '../../../../utils/useOutsideClick';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import { ReactComponent as FilterIcon } from '../../../../assets/icons/filter.svg';
import { ReactComponent as ChevronIcon } from '../../../../assets/icons/chevron.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';
import { useTranslation } from 'react-i18next';
import { MoreOutlined } from '@ant-design/icons';

type props = {
	blackboxes: any;
	craneSnFinder;
	assignModalHandler;
	unassignModalHandler;
	removeModalHandler;
	addBlackboxModalHandler;
	setCurrentBlackboxAndLicense;
	assignPopover;
	unassignPopover;
	removePopover;
};

const MobileBlackboxSettingsTable: React.FC<props> = props => {
	const controlsRef = useRef<HTMLDivElement>(null);
	const [openDistIds, setOpenDistIds] = useState<string[]>([]);
	const [openControls, setOpenControls] = useState<string | null>(null);
	const { t } = useTranslation();

	useOutsideClick(controlsRef, () => {
		if (openControls != null) setOpenControls(null);
	});

	const toggleOnetDistId = (index: string) => {
		if (openDistIds.includes(index)) {
			setOpenDistIds(openDistIds.filter(_id => _id !== index));
		} else {
			setOpenDistIds([...openDistIds, index]);
		}
	};

	return (
		<>
			<div className={styles.tableHeader} hidden={true}>
				<div>
					<FilterIcon />
					<p>{t('filter')}</p>
				</div>
				<div>
					<SearchIcon />
					<p>{t('search')}</p>
				</div>
				<div onClick={props.addBlackboxModalHandler}>
					<PlusIcon />
					<p>{t('add')}</p>
				</div>
			</div>
			<table className={cn(styles.mobileTable, 'table table-borderless fw-bold')}>
				<thead>
					<tr>
						<th scope='col'>{t('blackboxType')}</th>
						<th scope='col'>{t('sn')}</th>
						<th scope='col'>{t('associated')}</th>
						<th scope='col'></th>
					</tr>
				</thead>
				<tbody>
					{props.blackboxes.map((blackbox, index) => {
						return (
							<React.Fragment key={index}>
								<tr key={index} className={openDistIds.includes(index) ? styles.open : styles.close}>
									<td>{blackbox.type === 'Full' ? 'SBC' : blackbox.type === 'Lite' ? 'SBL' : blackbox.type === 'Feeder' ? 'SBF' : '-'}</td>
									<td>{blackbox.friendlyName}</td>
									<td className={styles.biding}>
										{blackbox.license?.blackboxBinding?.craneBinding ? <Tag color='blue'>{t('associated')}</Tag> : <Tag color='green'>{t('notAssociated')}</Tag>}
									</td>
									<td onClick={() => toggleOnetDistId(index)}>
										<ChevronIcon className={styles.chevron} />
									</td>
								</tr>

								<tr className={cn(openDistIds.includes(index) ? styles.open : null, styles.tableExpand)}>
									<td colSpan={4}>
										<div>
											<span className={styles.distHeader}>{t('version')}</span>
											<span>{blackbox.version ?? '-'}</span>
											<Popover
												placement='bottomRight'
												trigger='click'
												className={styles.moreIcon}
												content={
													blackbox.license?.id && !blackbox.license?.blackboxBinding?.craneBinding
														? props.assignPopover
														: blackbox.license?.id && blackbox.license?.blackboxBinding?.craneBinding
														? props.unassignPopover
														: props.removePopover
												}>
												<MoreOutlined onClick={() => props.setCurrentBlackboxAndLicense(blackbox)} />
											</Popover>
										</div>
										<div>
											<span className={styles.distHeader}>{t('crane')}</span>
											<span>{props.craneSnFinder(blackbox.license?.blackboxBinding?.craneBinding?.craneId) ?? '-'}</span>
										</div>
										<div>
											<span className={styles.distHeader}>{t('license')}</span>
											<span>{blackbox.license?.id ?? '-'}</span>
										</div>
									</td>
								</tr>
							</React.Fragment>
						);
					})}
				</tbody>
			</table>
		</>
	);
};

export default MobileBlackboxSettingsTable;
