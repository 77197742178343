import React, { useState } from 'react';
import { Menu, ConfigProvider, Spin } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Hierarchy } from '../../../DataTypes/Hierarchy';
import { Company } from '../../../DataTypes/Company';
import { Site } from '../../../DataTypes/Site';
import * as _ from 'lodash';
import styles from './OperationAverage.module.scss';
import OperationAverageChart from './OperationAverageChart';
import { testData } from './testData.js';
import { IQueryOperationSummary, OperationSummary } from '../../../DataTypes/OperationSummary';
import { Crane } from 'src/DataTypes/Crane';

type props = {
	item: IQueryOperationSummary;
};

enum Segment {
	Daily,
	Monthly,
	Yearly,
}

class DataQueryParams {
	startTime: Date | null = null;
	endTime: Date | null = null;
	segment: Segment | null = null;
	labels: string[] | null = null;
	data: number[] | null = null;
}

const getQueryTimes = (option: string): { startTime: Date; endTime: Date } => {
	let now: moment.Moment = moment();
	let today: moment.Moment = now.startOf('day');
	let startTime: string = '';
	let endTime: string = moment.utc(new Date()).format();

	switch (option) {
		case 'week':
			startTime = today.subtract(6, 'days').format();
			break;

		case 'month':
			startTime = today.subtract(1, 'M').format();
			break;

		case 'threeMonths':
			startTime = today.subtract(3, 'M').format();
			break;

		case 'year':
			startTime = today.subtract(1, 'years').format();
			break;

		case 'total':
			//TODO
			break;
	}

	return { startTime: new Date(startTime), endTime: new Date(endTime) };
};

const buildOperationSummaryData = (option: string, data: OperationSummary[], { startTime, endTime }): DataQueryParams => {
	let dqp = new DataQueryParams();
	let delta: number;
	let summaryHours: number;

	let labelsArr: string[] = [];
	let dataArr: number[] = [];

	switch (option) {
		case 'week':
			dqp.segment = Segment.Daily;

			delta = moment(endTime).diff(startTime, 'days');
			for (let i = 0; i <= delta; i++) {
				labelsArr.unshift(moment().subtract(i, 'days').format('D/M'));

				summaryHours = data.find(operation => moment(operation?.date).format('DD-MM-YY') === moment().subtract(i, 'days').format('DD-MM-YY'))?.secondsOfOperation! / 3600;
				if (summaryHours > 0 && summaryHours <= 1) {
					dataArr.unshift(Math.ceil(summaryHours));
				} else if (summaryHours > 1) {
					dataArr.unshift(Math.round(summaryHours));
				} else {
					dataArr.unshift(0);
				}

				summaryHours = 0;
			}
			break;

		case 'month':
			dqp.segment = Segment.Daily;

			delta = moment(endTime).diff(startTime, 'days');
			for (let i = 0; i <= delta; i++) {
				labelsArr.unshift(moment().subtract(i, 'days').format('D/M'));

				summaryHours = data.find(operation => moment(operation.date).format('DD-MM-YY') === moment().subtract(i, 'days').format('DD-MM-YY'))?.secondsOfOperation! / 3600;
				if (summaryHours > 0 && summaryHours <= 1) {
					dataArr.unshift(Math.ceil(summaryHours));
				} else if (summaryHours > 1) {
					dataArr.unshift(Math.round(summaryHours));
				} else {
					dataArr.unshift(0);
				}

				summaryHours = 0;
			}
			break;

		case 'threeMonths':
			dqp.segment = Segment.Daily;

			delta = moment().diff(startTime, 'days');
			for (let i = 0; i <= delta; i++) {
				labelsArr.unshift(moment().subtract(i, 'days').format('D/M'));

				summaryHours = data.find(operation => moment(operation.date).format('DD-MM-YY') === moment().subtract(i, 'days').format('DD-MM-YY'))?.secondsOfOperation! / 3600;
				if (summaryHours > 0 && summaryHours <= 1) {
					dataArr.unshift(Math.ceil(summaryHours));
				} else if (summaryHours > 1) {
					dataArr.unshift(Math.round(summaryHours));
				} else {
					dataArr.unshift(0);
				}

				summaryHours = 0;
			}
			break;

		case 'year':
			dqp.segment = Segment.Monthly;

			delta = moment(endTime).diff(startTime, 'months');
			for (let i = 0; i <= delta; i++) {
				labelsArr.unshift(moment().subtract(i, 'months').format('M/YY'));

				summaryHours =
					data.filter(operation => moment(operation.date).format('MM-YY') === moment().subtract(i, 'months').format('MM-YY'))?.reduce((a, b) => a + b.secondsOfOperation, 0) / 3600;
				if (summaryHours > 0 && summaryHours <= 1) {
					dataArr.unshift(Math.ceil(summaryHours));
				} else if (summaryHours > 1) {
					dataArr.unshift(Math.round(summaryHours));
				} else {
					dataArr.unshift(0);
				}
			}
			break;

		case 'total':
			//TODO
			break;
	}

	dqp.startTime = new Date(startTime);
	dqp.endTime = new Date(endTime);
	dqp.labels = labelsArr;
	dqp.data = dataArr;

	return dqp;
};

const OperationAverage: React.FC<props> = props => {
	const [displayOption, setDisplayOption] = useState('week');
	const [dataArr, setDataArr] = useState<DataQueryParams | null>(null);
	const { t } = useTranslation();

	const menuItems = [
		<Menu.Item key='week'>{t('week')}</Menu.Item>,
		<Menu.Item key='month'>{t('month')}</Menu.Item>,
		<Menu.Item key='threeMonths'>{t('threeMonths')}</Menu.Item>,
		<Menu.Item key='year'>{t('year')}</Menu.Item>,
		// <Menu.Item key='total'>{t("total")}</Menu.Item>,
	];
	const handleSegmentationChange = ({ key }) => {
		setDisplayOption(key);
	};

	useEffect(() => {
		setDataArr(null);

		const queryTimes = getQueryTimes(displayOption);
		let promise = props.item.GetActivitySummary(queryTimes.startTime, queryTimes.endTime);

		promise
			.then(result => {
				const data = buildOperationSummaryData(displayOption, result.data, queryTimes);
				setDataArr(data);
			})
			.catch(err => {
				setDataArr(null);
			});
	}, [displayOption]);

	return (
		<div className={styles.operationWrapper}>
			<Menu defaultSelectedKeys={['week']} mode='horizontal' style={{ background: 'none', border: 'none', marginBottom: '1rem' }} onClick={handleSegmentationChange}>
				{menuItems}
			</Menu>
			{dataArr ? <OperationAverageChart data={dataArr?.data!} labels={dataArr?.labels!} /> : <Spin className={styles.spin} size='large' tip={t('loadingGraph')} />}
		</div>
	);
};

export default OperationAverage;
