import React, { useState } from 'react';
import { Table, ConfigProvider, Tag, Button, Popover, Input, Space } from 'antd';
import { MoreOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import he_IL from 'antd/es/locale/he_IL';
import { ERole, User } from '../../../DataTypes/User';
import styles from '../DesktopTable.module.scss';
import { showRangeAndTotal } from 'src/services/services';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

type Props = {
	dataSource: User[];
	registeredPopoverContent: any;
	pendingPopoverContent: any;
	setCurrentUser: (user: User) => void;
};

const UsersSettingsTable = (props: Props) => {
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const { t } = useTranslation();

	const getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					placeholder={`${t('search')} ${t(dataIndex)}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button type='primary' onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size='small' style={{ width: 90 }}>
						{t('search')}
					</Button>
					<Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
						{t('reset')}
					</Button>
					{/* <Button
						type='link'
						size='small'
						onClick={() => {
							confirm({ closeDropdown: false });
							setSearchText(selectedKeys[0]);
							setSearchedColumn(dataIndex);
						}}>
						Filter
					</Button> */}
				</Space>
			</div>
		),
		filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, marginRight: '1rem', fontSize: '18px' }} />,
		onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),
		render: text =>
			searchedColumn === dataIndex ? (
				<Highlighter highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }} searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''} />
			) : (
				text
			),
	});

	const columns: any = [
		{
			title: `${t('fullName')}`,
			dataIndex: 'fullName',
			key: 'type',
			defaultSortOrder: 'ascend',
			width: '15%',
			isUseDefaultSearch: true,
			sorter: (a, b) => sortFunction(a.fullName, b.fullName),
			sortDirections: ['descend', 'ascend'],
			...getColumnSearchProps('fullName'),
		},
		{
			title: `${t('company')}`,
			dataIndex: 'company',
			width: '15%',
			isUseDefaultSearch: true,
			sorter: (a, b) => sortFunction(a.company, b.company),
			sortDirections: ['descend', 'ascend'],
			...getColumnSearchProps('company'),
		},
		{
			title: `${t('role')}`,
			dataIndex: 'role',
			width: '15%',
			isUseDefaultSearch: true,
			sorter: (a, b) => sortFunction(a.role, b.role),
			sortDirections: ['descend', 'ascend'],
			render: (role: ERole) => t(role),
		},
		{
			title: t('phone'),
			dataIndex: ['phone', 'number'],
			width: '15%',
		},
		{
			title: t('mail'),
			dataIndex: 'mail',
			width: '20%',
			sorter: (a, b) => sortFunction(a.mail, b.mail),
			sortDirections: ['descend', 'ascend'],
			...getColumnSearchProps('mail'),
		},
		{
			title: t('status'),
			dataIndex: 'isPending',
			render: isPending => (isPending ? <Tag color='geekblue'>{t('invitationSent')}</Tag> : <Tag color='green'>{t('registered')}</Tag>),
			width: '20%',
			sorter: (a, b) => sortFunction(a.isPending, b.isPending),
			sortDirections: ['descend', 'ascend'],
		},

		{
			render: user => (
				<Popover placement='right' trigger='click' content={user.isPending ? props.pendingPopoverContent : props.registeredPopoverContent}>
					<MoreOutlined onClick={() => props.setCurrentUser(user)} />
				</Popover>
			),
		},
	];

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = clearFilters => {
		clearFilters();
		setSearchText('');
	};

	const sortFunction = (a, b) => {
		let sortA;
		let sortB;

		switch (typeof a) {
			case 'string':
				sortA = t(a?.toLowerCase());
				sortB = t(b?.toLowerCase());
				break;

			default:
				sortA = a;
				sortB = b;
				break;
		}

		if (sortA < sortB) {
			return -1;
		}
		if (sortA > sortB) {
			return 1;
		}

		return 0;
	};

	return (
		<ConfigProvider direction={i18n.language == 'he' ? 'rtl' : 'ltr'} locale={i18n.language == 'he' ? he_IL : undefined}>
			<Table className={styles.desktopTable} dataSource={props.dataSource} columns={columns} pagination={{ pageSize: 10, showTotal: showRangeAndTotal }} />
		</ConfigProvider>
	);
};

export default UsersSettingsTable;
