import React, { useState } from 'react';
import styles from './MobileDistributionListTable.module.scss';
import { User } from '../../../../DataTypes/User';
import cn from 'classnames';
import { buildIcons } from '../../../Tables/DistributionListTable/DistributionListTable';

import { ReactComponent as ChevronIcon } from '../../../../assets/icons/chevron-rounded.svg';
import { ReactComponent as RemoveIcon } from '../../../../assets/icons/trash.svg';
import { ReactComponent as AlertIcon } from '../../../../assets/icons/alert-circle.svg';
import { ReactComponent as SmsIcon } from '../../../../assets/icons/sms.svg';
import { ReactComponent as MailIcon } from '../../../../assets/icons/email.svg';
import { ReactComponent as ConfirmIcon } from '../../../../assets/icons/confirm.svg';
import { Empty } from 'antd';
import { ITableItemData } from 'src/components/DistributionList/DistributionList';
import { useTranslation } from 'react-i18next';

type props = {
	items: ITableItemData[];
	setUserIdToDelete: (userId: string) => void;
};

const MobileDistributionListTable: React.FC<props> = props => {
	const [openDistIds, setOpenDistIds] = useState<string[]>([]);
	const { t } = useTranslation();

	const toggleOnetDistId = id => {
		if (window.innerWidth >= 1200) return;

		if (openDistIds.includes(id)) {
			setOpenDistIds(openDistIds.filter(_id => _id !== id));
		} else {
			setOpenDistIds([...openDistIds, id]);
		}
	};

	return (
		<table className={cn('table', 'table-borderless', styles.mobileTable)}>
			<tbody>
				{props.items.length <= 0 ? (
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('distributionListIsEmpty')} />
				) : (
					props.items.map(item => {
						return (
							<tr key={item.id} className={cn(styles.distributor, openDistIds.includes(item.id) && styles.open)}>
								<td className={cn(styles.rowHead)} onClick={() => toggleOnetDistId(item.id)}>
									<ChevronIcon />
									<span>{item.displayName}</span>
								</td>
								<td>
									<span className={styles.distHeader}>{t('role')}</span>
									<span>{t(item.role)}</span>
								</td>
								<td>
									<span className={styles.distHeader}>{t('phone')}</span>
									<span>{item.phone.number}</span>
								</td>
								{item.isDeletable && (
									<td className={cn(styles.button, styles.delete)}>
										<RemoveIcon className={styles.icon} onClick={() => props.setUserIdToDelete(item.id)} />
									</td>
								)}

								<td>
									<span className={styles.distHeader}>{t('notifications')}</span>
									{buildIcons(item, t)}
								</td>
							</tr>
						);
					})
				)}
			</tbody>
		</table>
	);
};

export default MobileDistributionListTable;
