import React from 'react';
import styles from './CraneRemoveModal.module.scss';
import { Button, Modal, ModalBody } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type props = {
	showRemoveModalHandler: () => void;
	show: boolean;
	handleRemove?: () => void;
};

const CraneRemoveModal: React.FC<props> = props => {
	const { t } = useTranslation();

	return (
		<Modal size='sm' aria-labelledby='contained-modal-title-vcenter' show={props.show} onHide={props.showRemoveModalHandler} centered>
			<Modal.Header style={{ border: 'none' }} closeButton />

			<ModalBody>
				<p className={styles.title}>{t('removeCraneConfirmation')}</p>
				<div className={styles.buttons}>
					<Button variant='outline-danger' className={styles.button} onClick={props.handleRemove}>
						{t('yes&sure')}
					</Button>
					<Button variant='outline-primary' className={styles.button} onClick={props.showRemoveModalHandler}>
						{t('no&cancel')}
					</Button>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default CraneRemoveModal;
