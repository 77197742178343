import axios from 'axios';
import { Phone } from './Phone';

export enum ERole {
	SuperAdmin = 'SuperAdmin',
	ServiceManager = 'ServiceManager',
	ServiceManager2nd = 'ServiceManager2nd',
	SafetyManager = 'SafetyManager',
	OperationManager = 'OperationManager',
	CranesManager = 'CranesManager',
	SiteManager = 'SiteManager',
}

export interface IUserCreateDetails {
	mail: string;
	company: string;
	role: ERole;
	notifications: {
		canConfirmNotifications: boolean;
	};
}
export interface IUserPermisionsDetails {
	role: ERole;
	notifications: {
		canConfirmNotifications: boolean;
	};
}

export interface IUserData {
	id: string;
	fullName: string;
	mail: string;
	phone: Phone;
	role: ERole;
	company: string;
	isPending: boolean;
	notifications: IUserNotifications;
}

export interface IUserNotifications {
	canConfirmNotifications: boolean;
	isSendMail: boolean;
	isSendSms: boolean;
	lastSeenDate: Date;
}

export interface IUser extends IUserData {}

export class User implements IUser {
	id: string;
	fullName: string;
	mail: string;
	phone: Phone;
	role: ERole;
	company: string;
	isPending: boolean;
	notifications: IUserNotifications;

	constructor(data: IUserData) {
		this.id = data?.id;
		this.fullName = data?.fullName;
		this.mail = data?.mail;
		this.phone = new Phone(data?.phone);
		this.role = data?.role;
		this.company = data?.company;
		this.isPending = data?.isPending;
		this.notifications = data?.notifications;
	}

	DeleteUser() {
		return axios.delete(`/User/${this.id}`);
	}

	static CreateNewUser(details: IUserCreateDetails) {
		return axios.post('/User/Invite', details);
	}

	UpdateUserPermissions(details: IUserPermisionsDetails) {
		return axios.put(`/User/${this.id}`, details);
	}
}
