import React, { useState, useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styles from '../../Modals/AddContactModal/AddContactModal.module.scss';
import { ECraneType, ECraneStatus, ICraneCreateDetails } from '../../../DataTypes/Crane';
import RTL from '../../RTL/RTL';
import { Hierarchy } from '../../../DataTypes/Hierarchy';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { Company } from 'src/DataTypes/Company';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/chevron.svg';

type props = {
	show: boolean;
	handleClose: () => void;
	hierarchy: Hierarchy;
};

type inputs = {
	number?: string;
	model?: string;
	type: ECraneType;
	status: ECraneStatus;
	sn?: string;
	manufacturer?: string;
	height: number;
	length: number;
};

export const generateCranesNumberOption = (companies: Company[], compId: string, siteId: string): JSX.Element[] => {
	const company = companies.find(comp => comp.id === compId);
	const site = company?.sites.find(site => site.id == siteId);
	const cranesNumbersArr: string[] = [];
	const options: JSX.Element[] = [];
	const { t } = useTranslation();

	site?.cranes.forEach(crane => {
		cranesNumbersArr.push(crane.number);
	});

	for (let i = 1; i < 21; i++) {
		if (cranesNumbersArr.includes(`${i}`)) {
			options.push(
				<option key={i} value={i} disabled>
					{`${i} ${t('inUse')}`}
				</option>
			);
		} else {
			options.push(
				<option key={i} value={i}>
					{i}
				</option>
			);
		}
	}
	return options;
};

const NewCraneModal: React.FC<props> = props => {
	const [currentModal, setCurrentModal] = useState(1);
	const { ShowLoadingModal } = useContext(LoadingModalContext);
	const { t } = useTranslation();

	const validationSchema = yup.object({
		number: yup.string().required(t('requiredField')),
		model: yup.string(),
		type: yup.string().oneOf(Object.values(ECraneType)).required(t('requiredField')),
		status: yup.string().oneOf(Object.values(ECraneStatus)).required(t('requiredField')),
		sn: yup.string().required(t('requiredField')),
		manufacturer: yup.string(),
		height: yup.number().typeError(t('enterNumberInMeters')).min(0, t('enterNumberEqualOrGreaterThanZero')).required(t('requiredField')),
		length: yup.number().typeError(t('enterNumberInMeters')).min(0, t('enterNumberEqualOrGreaterThanZero')).required(t('requiredField')),
		company: yup.string().required(t('requiredField')),
		site: yup.string().required(t('requiredField')),
	});

	const formik = useFormik({
		initialValues: {
			number: '',
			model: '',
			type: ECraneType.Tower,
			status: ECraneStatus.Active,
			sn: '',
			manufacturer: '',
			height: 0,
			length: 0,
			company: '',
			site: '',
		},
		validationSchema: validationSchema,
		onSubmit: (values: ICraneCreateDetails) => {
			props.handleClose();
			ShowLoadingModal(props.hierarchy.CreateCrane(values), 'CraneCreate');
		},
	});

	const form = (
		<form className={styles.formi} onSubmit={formik.handleSubmit}>
			<TextField
				id='sn'
				label={t('licenseNumber')}
				value={formik.values.sn}
				helperText={formik.touched.sn ? formik.errors.sn : ''}
				error={formik.touched.sn && Boolean(formik.errors.sn)}
				onBlur={formik.handleBlur}
				onChange={formik.handleChange}
				margin='dense'
				variant='outlined'
				fullWidth
			/>
			<TextField
				id='model'
				label={t('model')}
				value={formik.values.model}
				helperText={formik.touched.model ? formik.errors.model : ''}
				error={formik.touched.model && Boolean(formik.errors.model)}
				onBlur={formik.handleBlur}
				onChange={formik.handleChange}
				margin='dense'
				variant='outlined'
				fullWidth
			/>
			<TextField
				id='type'
				name='type'
				label={t('type')}
				value={formik.values.type}
				select
				SelectProps={{
					native: true,
				}}
				helperText={formik.touched.type ? formik.errors.type : ''}
				error={formik.touched.type && Boolean(formik.errors.type)}
				onBlur={formik.handleBlur}
				onChange={formik.handleChange}
				margin='dense'
				variant='outlined'
				fullWidth>
				<option value='' disabled />
				{Object.values(ECraneType).map(value => (
					<option id={value} key={value} value={value}>
						{t(value.toLowerCase())}
					</option>
				))}
			</TextField>
			<TextField
				id='manufacturer'
				label={t('manufacturer')}
				value={formik.values.manufacturer}
				helperText={formik.touched.manufacturer ? formik.errors.manufacturer : ''}
				error={formik.touched.manufacturer && Boolean(formik.errors.manufacturer)}
				onBlur={formik.handleBlur}
				onChange={formik.handleChange}
				margin='dense'
				variant='outlined'
				fullWidth
			/>
			<TextField
				id='height'
				label={t('height')}
				value={formik.values.height}
				helperText={formik.touched.height ? formik.errors.height : ''}
				error={formik.touched.height && Boolean(formik.errors.height)}
				onBlur={formik.handleBlur}
				onChange={formik.handleChange}
				margin='dense'
				variant='outlined'
				fullWidth
			/>
			<TextField
				id='length'
				label={t('length')}
				value={formik.values.length}
				helperText={formik.touched.length ? formik.errors.length : ''}
				error={formik.touched.length && Boolean(formik.errors.length)}
				onBlur={formik.handleBlur}
				onChange={formik.handleChange}
				margin='dense'
				variant='outlined'
				fullWidth
			/>
			<TextField
				id='status'
				name='status'
				label={t('status')}
				value={formik.values.status}
				select
				SelectProps={{
					native: true,
				}}
				helperText={formik.touched.status ? formik.errors.status : ''}
				error={formik.touched.status && Boolean(formik.errors.status)}
				onBlur={formik.handleBlur}
				onChange={formik.handleChange}
				margin='dense'
				variant='outlined'
				fullWidth>
				<option value='' disabled />
				{Object.values(ECraneStatus).map(value => (
					<option id={value} key={value} value={value}>
						{t(value.toLowerCase())}
					</option>
				))}
			</TextField>
			<TextField
				id='company'
				name='company'
				label={t('company')}
				value={formik.values.company}
				select
				SelectProps={{
					native: true,
				}}
				helperText={formik.touched.company ? formik.errors.company : ''}
				error={formik.touched.company && Boolean(formik.errors.company)}
				onBlur={formik.handleBlur}
				onChange={formik.handleChange}
				margin='dense'
				variant='outlined'
				fullWidth>
				<option value='' disabled />
				{props.hierarchy.companies
					.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
					.map(company => (
						<option id={company.id} key={company.id} value={company.id}>
							{company.name}
						</option>
					))}
			</TextField>
			<TextField
				id='site'
				name='site'
				label={t('site')}
				value={formik.values.site}
				select
				SelectProps={{
					native: true,
				}}
				helperText={formik.touched.site ? formik.errors.site : ''}
				error={formik.touched.site && Boolean(formik.errors.site)}
				onBlur={formik.handleBlur}
				onChange={formik.handleChange}
				margin='dense'
				variant='outlined'
				fullWidth
				disabled={formik.values.company === '' || props.hierarchy.companies.find(company => company.id === formik.values.company)?.sites.length === 0}>
				<option value='' disabled />
				{formik.values.company !== '' ? (
					props.hierarchy.companies
						.find(company => company.id === formik.values.company)
						?.sites.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
						.map(site => (
							<option key={site.id} id={site.id} value={site.id}>
								{site.name}
							</option>
						))
				) : (
					<option id={'none'} value={'none'} disabled>
						{'none'}
					</option>
				)}
			</TextField>
			<TextField
				id='number'
				name='number'
				label={t('number')}
				value={formik.values.number}
				select
				SelectProps={{
					native: true,
				}}
				helperText={formik.touched.number ? formik.errors.number : ''}
				error={formik.touched.number && Boolean(formik.errors.number)}
				onBlur={formik.handleBlur}
				onChange={formik.handleChange}
				margin='dense'
				variant='outlined'
				fullWidth
				disabled={!formik.values.company || !formik.values.site}>
				<option value='' disabled />
				{generateCranesNumberOption(props.hierarchy.companies, formik.values.company, formik.values.site)}
			</TextField>
			<div className={styles.formButtons}>
				<Button type='submit' variant='outline-primary' className={styles.button}>
					{t('add')}
				</Button>
			</div>
		</form>
	);

	return (
		<RTL>
			<Modal size='sm' className={styles.modal} aria-labelledby='contained-modal-title-vcenter' show={props.show} centered onHide={props.handleClose}>
				<Modal.Header style={{ border: 'none' }} closeButton />
				<div className={styles.title}>{t('addingCrane')}</div>
				<Modal.Body className={styles.modalBody}>{form}</Modal.Body>
			</Modal>
		</RTL>
	);
};

export default NewCraneModal;
