import React, { useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { useFormik, Form } from 'formik';
import * as yup from 'yup';
import styles from '../AddContactModal/AddContactModal.module.scss';
import { MyProfile } from '../../../DataTypes/MyProfile';
import { Company } from '../../../DataTypes/Company';
import RTL from '../../RTL/RTL';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { useTranslation } from 'react-i18next';

type props = {
	closeHandler: (result?: Promise<any>) => void;
	myProfile: MyProfile;
	company: Company;
};

type inputs = {
	fullName: string;
	company?: string;
	role?: string;
	phone?: string;
	notifications: {};
};

const phoneRegExp: RegExp = /^05\d{8}$/;

const UserDetailsUpdateModal: React.FC<props> = props => {
	const { ShowLoadingModal } = useContext(LoadingModalContext);
	const { t } = useTranslation();

	const validationSchema = yup.object({
		fullName: yup.string().required(t('requiredField')),
		company: yup.string().required(t('requiredField')),
		role: yup.string().required(t('requiredField')),
		phone: yup.string().matches(phoneRegExp, t('enterValidValue')).required(t('requiredField')),
	});

	const formik = useFormik({
		initialValues: {
			fullName: props.myProfile.fullName,
			mail: props.myProfile.mail,
			company: props.company.name,
			role: props.myProfile.role,
			phone: props.myProfile.phone.number,
			canConfirmNotifications: props.myProfile.notifications.canConfirmNotifications,
			isSendMail: props.myProfile.notifications.isSendMail,
			isSendSms: props.myProfile.notifications.isSendSms,
		},
		validationSchema: validationSchema,
		onSubmit: values => {
			const details: inputs = {
				fullName: values.fullName,
				notifications: {
					canConfirmNotifications: values.canConfirmNotifications,
					isSendMail: values.isSendMail,
					isSendSms: values.isSendSms,
				},
			};
			props.closeHandler(ShowLoadingModal(props.myProfile.updateUserDetails(details), 'UserUpdate'));
		},
	});

	return (
		<RTL>
			<Modal size='sm' className={styles.modal} aria-labelledby='contained-modal-title-vcenter' show centered onHide={props.closeHandler}>
				<Modal.Header style={{ border: 'none' }} closeButton />
				<div className={styles.title}>{t('updateUserDetails')}</div>
				<Modal.Body className={styles.modalBody}>
					<form className={styles.formi} onSubmit={formik.handleSubmit}>
						<TextField
							id='fullName'
							label={t('fullName')}
							value={formik.values.fullName}
							helperText={formik.touched.fullName ? formik.errors.fullName : ''}
							error={formik.touched.fullName && Boolean(formik.errors.fullName)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth
						/>
						{/* <TextField
							id='mail'
							label={dict.texts.mail}
							value={formik.values.mail}
							helperText={formik.touched.mail ? formik.errors.mail : ''}
							error={formik.touched.mail && Boolean(formik.errors.mail)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							disabled
							margin='dense'
							variant='outlined'
							fullWidth
						/>
						<TextField
							id='company'
							label={dict.texts.company}
							value={formik.values.company}
							helperText={formik.touched.company ? formik.errors.company : ''}
							error={formik.touched.company && Boolean(formik.errors.company)}
							onBlur={formik.handleBlur}
							disabled
							margin='dense'
							variant='outlined'
							fullWidth
						/>
						<TextField
							id='role'
							label={dict.texts.role}
							value={formik.values.role}
							helperText={formik.touched.role ? formik.errors.role : ''}
							error={formik.touched.role && Boolean(formik.errors.role)}
							onBlur={formik.handleBlur}
							disabled
							margin='dense'
							variant='outlined'
							fullWidth
						/>
						<TextField
							id='phone'
							label={dict.texts.phone}
							value={formik.values.phone}
							helperText={formik.touched.phone ? formik.errors.phone : ''}
							error={formik.touched.phone && Boolean(formik.errors.phone)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							disabled
							margin='dense'
							variant='outlined'
							fullWidth
						/> */}
						<div className={styles.subtitle}>{t('notifications')}</div>
						<div className={styles.checkboxParagraph}>
							<FormControlLabel
								control={
									<Checkbox
										checked={formik.values.canConfirmNotifications}
										disabled={!props.myProfile.permissions.notificationsConfirm}
										onChange={formik.handleChange}
										name='canConfirmNotifications'
										color='primary'
									/>
								}
								label={t('authorizedToConfirmNotifications')}
							/>
							<FormControlLabel
								control={<Checkbox checked={formik.values.isSendMail} onChange={formik.handleChange} name='isSendMail' color='primary' />}
								label={t('receiveEmailNotifications')}
							/>
							<FormControlLabel
								control={<Checkbox checked={formik.values.isSendSms} onChange={formik.handleChange} name='isSendSms' color='primary' />}
								label={t('receiveSmsNotifications')}
							/>
						</div>
						<div className={styles.formButtons}>
							<Button type='submit' variant='outline-primary' className={styles.button}>
								{t('update')}
							</Button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</RTL>
	);
};

export default UserDetailsUpdateModal;
