import React, { useState } from 'react';
import { OperationSummary } from 'src/DataTypes/OperationSummary';
import styles from './MobileCraneActivityTable.module.scss';
import cn from 'classnames';
import moment from 'moment';
import { ReactComponent as ChevronIcon } from '../../../../assets/icons/chevron.svg';
import { useTranslation } from 'react-i18next';

type Props = {
	operationSummaryArr: OperationSummary[];
};

const MobileCraneActivityTable: React.FC<Props> = props => {
	const [openActivityIds, setOpenActivity] = useState<string[]>([]);
	const { t } = useTranslation();

	const toggleOpenActivityId = (id: string) => {
		if (window.innerWidth >= 1200) return;

		if (openActivityIds.includes(id)) {
			setOpenActivity(openActivityIds.filter(_id => _id !== id));
		} else {
			setOpenActivity([...openActivityIds, id]);
		}
	};

	const dailyWorkingHours: number = 12;

	return (
		<table className={cn('table', 'table-borderless', styles.mobileTable)}>
			<thead>
				<tr>
					<th scope='col'>{t('date')}</th>
					<th scope='col'>{t('totalHours')}</th>
					<th scope='col'>{t('dailyActivityPercentage')}</th>
					<th scope='col'></th>
				</tr>
			</thead>
			<tbody>
				{props.operationSummaryArr
					.sort((a, b) => (a.date > b.date ? -1 : 1))
					.map((x, xIndex) => (
						<React.Fragment key={xIndex}>
							<tr onClick={() => toggleOpenActivityId('' + xIndex)}>
								<td scope='col'>{moment(x.date).format('DD/MM/YY')}</td>
								<td scope='col'>{new Date(x.secondsOfOperation * 1000).toISOString().substr(11, 8)}</td>
								<td scope='col'>{`${((x.secondsOfOperation / 3600 / dailyWorkingHours) * 100).toFixed(2)}%`}</td>
								<td>
									<ChevronIcon className={cn(openActivityIds.includes('' + xIndex) ? styles.open : styles.close)} />
								</td>
							</tr>
							<table className={cn(styles.innerTable)} hidden={!openActivityIds.includes(xIndex.toString())}>
								<thead>
									<tr>
										<th>{t('turnOn')}</th>
										<th>{t('turnOff')}</th>
										<th>{t('total')}</th>
										<th>{/* <th scope='col'>operator</th> */}</th>
									</tr>
								</thead>
								<tbody>
									{x.intervals?.map((i, indexI) => (
										<tr key={indexI}>
											<td>{moment(i.start).format('HH:mm:ss')}</td>
											<td>{moment(i.end).format('HH:mm:ss')}</td>
											<td>{moment.utc(moment(i.end).diff(moment(i.start))).format('HH:mm:ss')}</td>
											{/* <td scope='col'>לא ידוע</td> */}
										</tr>
									))}
								</tbody>
							</table>
						</React.Fragment>
					))}
			</tbody>
		</table>
	);
};

export default MobileCraneActivityTable;
