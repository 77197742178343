import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './CranesStatus.module.scss';
import { Company } from '../../DataTypes/Company';
import { Site } from '../../DataTypes/Site';
import MiniCraneControl from '../Notification/MiniCraneControl/MiniCraneControl';
import { Hierarchy } from '../../DataTypes/Hierarchy';
import Spinner from '../UI/Spinner/Spinner';
import { useTranslation } from 'react-i18next';

type props = {
	item: Hierarchy | Company | Site;
};

const OrganizationCranesStatus: React.FC<{ item: Hierarchy }> = props => {
	return (
		<div className={styles.cranesCards}>
			{props.item.companies
				.filter(company => company.cranes.some(crane => crane.deviceStatus != undefined))
				.map(company => (
					<CompanyCranesStatus key={company.id} item={company} />
				))}
		</div>
	);
};

const CompanyCranesStatus: React.FC<{ item: Company }> = props => {
	const { t } = useTranslation();

	const sites = (
		<div className={styles.wrapperCard}>
			<div className={styles.companyName}>{`${t('companyAdj')} ${props.item.name}`}</div>
			<div className={styles.cranesCards}>
				{props.item.sites
					.filter(site => site.cranes.some(crane => crane.deviceStatus != undefined))
					.map(site => (
						<SiteCranesStatus key={site.id} item={site} />
					))}
			</div>
		</div>
	);

	return props.item.cranes.length != 0 ? sites : null;
};

const SiteCranesStatus: React.FC<{ item: Site }> = props => {
	const { t } = useTranslation();

	const cranes = (
		<div className={styles.siteCard}>
			<div className={styles.siteName}>{`${t('site')} ${props.item.name}`}</div>
			<div className={styles.wrapper}>
				{props.item?.cranes
					.filter(crane => crane.deviceStatus != undefined)
					.map(crane => (
						<MiniCraneControl key={crane.id} crane={crane} />
					))}
			</div>
		</div>
	);
	return <>{props.item?.cranes.length != 0 ? cranes : null}</>;
};

const CranesStatus: React.FC<props> = props => {
	const filterAssociatedCranes = () => {
		return props.item.cranes.filter(crane => crane.deviceStatus != undefined);
	};

	if (!props.item) {
		return <Spinner />;
	} else if (props.item instanceof Hierarchy && props.item.cranes.length != 0) return <OrganizationCranesStatus item={props.item} />;
	else if (props.item instanceof Company && props.item.cranes.length != 0) return <CompanyCranesStatus item={props.item} />;
	else if (props.item instanceof Site && props.item.cranes.length != 0) return <SiteCranesStatus item={props.item} />;
	else {
		return null;
	}
};

export default CranesStatus;
