import { Blackbox, EBlackboxType } from './Blackbox';
import axios from 'axios';

export interface ILicenseData {
	deviceId: string;
	type: EBlackboxType;
	id: string;
	durationInDays: number;
	createdTime: string;
	blackboxBinding: BlackboxBinding;
}

export interface ILicense extends ILicenseData {}

export class License implements ILicense {
	deviceId: string;
	type: EBlackboxType;
	id: string;
	durationInDays: number;
	createdTime: string;
	blackboxBinding: BlackboxBinding;

	constructor(data: ILicenseData) {
		this.deviceId = data.deviceId;
		this.type = data.type;
		this.id = data.id;
		this.durationInDays = data.durationInDays;
		this.createdTime = data.createdTime;
		this.blackboxBinding = data.blackboxBinding;
	}

	BindBlackbox(blackboxId: string) {
		return axios.post(`/License/${this.id}/Blackbox/${blackboxId}/BindBlackbox`);
	}

	UnbindBlackbox() {
		return axios.post(`/License/${this.id}/Blackbox/${this.blackboxBinding.blackbox.id}/UnbindBlackbox`);
	}

	BindCrane(blackboxId: string, companyId: string, siteId: string, craneId: string) {
		return axios.post(`/License/${this.id}/Blackbox/${blackboxId}/BindCrane/${companyId}/${siteId}/${craneId}`);
	}

	UnbindCrane(blackboxId: string, companyId: string, siteId: string, craneId: string) {
		return axios.post(`/License/${this.id}/Blackbox/${blackboxId}/unbindCrane/${companyId}/${siteId}/${craneId}`);
	}
}

export type CraneBinding = {
	craneId: string;
	bindTime: string;
};

export type BlackboxBinding = {
	blackbox: Blackbox;
	bindTime: string;
	craneBinding: CraneBinding;
};
