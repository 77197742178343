import react from 'react';
import styles from './Breadcrumb.module.scss';
import i18n from 'i18next';
import { Crane } from 'src/DataTypes/Crane';
import React from 'react';
import { Breadcrumb } from 'antd';
import { seperatorDir } from 'src/components/utils/utils';

type Props = {
	crane?: Crane;
};

const CranePathBreadcrumb: React.FC<Props> = ({ crane }) => {
	if (crane === undefined) return null;
	const pathArr = [crane.company.name, crane.site.name, crane.number];

	return (
		<Breadcrumb className={styles.cranePath} separator={seperatorDir(i18n.dir())}>
			{pathArr.map(item => (
				<Breadcrumb.Item className={styles.item}>{item}</Breadcrumb.Item>
			))}
		</Breadcrumb>
	);
};

export default CranePathBreadcrumb;
