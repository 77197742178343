import React, { useState, useEffect } from 'react';
import styles from './Licenses.module.scss';
import Spinner from '../../UI/Spinner/Spinner';
import LicenseAssignmentModal from '../../Modals/LicenseAssignmentModal/LicenseAssignmentModal';
import LicenseUnassignmentModal from '../../Modals/LicenseUnassignmentModal/LicenseUnassignmentModal';
import LicenseSettingsTable from '../../Tables/LicenesSettingsTable/LicenseSettingsTable';
import { Hierarchy } from '../../../DataTypes/Hierarchy';
import MobileLicenesSettingsTable from '../../Mobile/Tables/MobileLicensesSettingsTable/MobileLicensesSettingsTable';
import { License } from 'src/DataTypes/License';
import { useTranslation } from 'react-i18next';

type Props = {
	hierarchy: Hierarchy;
};

const Licenses: React.FC<Props> = props => {
	const [subscriptionCredit, setSubscriptionCredit] = useState<Hierarchy['subscriptionCredit']>();
	const [isLoading, setIsLoading] = useState(true);
	const [currentLicense, setCurrentLicense] = useState<License>();
	const [showAssignModal, setShowAssignModal] = useState(false);
	const [showUnassignModal, setShowUnassignModal] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		loadData();
		setIsLoading(false);
	}, [props.hierarchy.subscriptionCredit]);

	const loadData = () => {
		if (props.hierarchy.subscriptionCredit) {
			setSubscriptionCredit(props.hierarchy.subscriptionCredit);
		}
	};

	const assignModalHandler = () => {
		const show = showAssignModal;
		setShowAssignModal(!show);
	};

	const unassignModalHandler = () => {
		const show = showUnassignModal;
		setShowUnassignModal(!show);
	};

	const licenseExpirationCalc = (duration, bindTime) => {
		let now = Date.now();
		let bindingTime = new Date(bindTime).getTime();
		return (duration - (now - bindingTime) / (1000 * 60 * 60 * 24)).toFixed(0);
	};

	const assignPopover = (
		<div role='button' className={styles.assign} onClick={assignModalHandler}>
			<span className={styles.popoverText}>{t('associateToBlackbox')}</span>
		</div>
	);
	const unassignPopover = (
		<div role='button' className={styles.unassign} onClick={unassignModalHandler}>
			<span className={styles.popoverText}>{t('unassociate')}</span>
		</div>
	);

	if (isLoading) {
		return <Spinner />;
	}
	return (
		<div className={styles.wrapper}>
			{showAssignModal && <LicenseAssignmentModal license={currentLicense!} show={showAssignModal} showModalHandler={assignModalHandler} hierarchy={props.hierarchy} />}
			{showUnassignModal && <LicenseUnassignmentModal license={currentLicense!} show={showUnassignModal} unassignModalHandler={unassignModalHandler} />}
			<div className={styles.card}>
				<div className={styles.cardHeader}>
					<div className={styles.cardTitle}>{t('licenses')}</div>
				</div>
				<MobileLicenesSettingsTable
					assignModalHandler={assignModalHandler}
					hierarchy={props.hierarchy}
					unassignModalHandler={unassignModalHandler}
					licenseExpirationCalc={licenseExpirationCalc}
					subscriptionCredit={subscriptionCredit!}
					setCurrentLicense={setCurrentLicense}
					assignPopover={assignPopover}
					unassignPopover={unassignPopover}
				/>
				<LicenseSettingsTable
					assignPopover={assignPopover}
					unassignPopover={unassignPopover}
					dataSource={subscriptionCredit?.licenses!}
					hierarchy={props.hierarchy}
					licenseExpirationCalc={licenseExpirationCalc}
					setCurrentLicense={setCurrentLicense}
				/>
			</div>
			<div className={styles.licenseAmountCard}>
				<div className={styles.licenseAmountCardTitle}>{t('licensesAmount')}</div>
				<div className={styles.licenseAmountCardBody}>
					<div className={styles.licenseAmount}>
						<span className={styles.licenseAmountKey}>SBC :</span>
						<span className={styles.licenseAmountValue}>{subscriptionCredit?.licenses.filter(license => license.type === 'Full').length}</span>
					</div>
					<div className={styles.licenseAmount}>
						<span className={styles.licenseAmountKey}>SBL :</span>
						<span className={styles.licenseAmountValue}>{subscriptionCredit?.licenses.filter(license => license.type === 'Lite').length}</span>
					</div>
				</div>
				<div className={styles.licenseAmountCardFooter}>
					<span>{`${t('total')} ${subscriptionCredit?.licenses.length} ${t('licenses')} `}</span>
				</div>
			</div>
		</div>
	);
};

export default Licenses;
