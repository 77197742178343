import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Company } from 'src/DataTypes/Company';
import { Crane } from 'src/DataTypes/Crane';
import { Hierarchy } from 'src/DataTypes/Hierarchy';
import { Site } from 'src/DataTypes/Site';
import styles from './Breadcrumb.module.scss';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { Breadcrumb } from 'antd';
import { seperatorDir } from 'src/components/utils/utils';

type props = {
	hierarchy: Hierarchy;
};

const BreadcrumbComponent: React.FC<props> = props => {
	const location = useLocation();
	const [breadcrumb, setBreadcrumb] = useState<string[]>([]);
	const { t } = useTranslation();

	const generateBreadcrumbEntities = (path: string) => {
		let entity: Company | Site | Crane | undefined = undefined;
		let breadcrumbArr: string[] = [];
		let entityId = '';

		if (path.includes('site')) {
			entityId = path.split('/')[2];
			entity = props.hierarchy.sites.find(site => site.id === entityId);
			breadcrumbArr.push(`${entity?.company.name}`);
			breadcrumbArr.push(`${entity?.name}`);

			if (path.includes('cranes-status')) {
				breadcrumbArr.push(t('cranesStatus'));
			} else if (path.includes('details')) {
				breadcrumbArr.push(t('details'));
			} else if (path.includes('distribution-list')) {
				breadcrumbArr.push(t('distributionList'));
			} else {
				breadcrumbArr.push(t('general'));
			}
		} else if (path.includes('comp')) {
			entityId = path.split('/')[2];
			entity = props.hierarchy.companies.find(
				comp => comp.id === entityId
			);
			breadcrumbArr.push(`${entity?.name}`);

			if (path.includes('cranes-status')) {
				breadcrumbArr.push(t('cranesStatus'));
			} else if (path.includes('details')) {
				breadcrumbArr.push(t('details'));
			} else if (path.includes('distribution-list')) {
				breadcrumbArr.push(t('distributionList'));
			} else {
				breadcrumbArr.push(t('general'));
			}
		} else if (path.includes('crane/')) {
			entityId = path.split('/')[2];
			entity = props.hierarchy.cranes.find(
				crane => crane.id === entityId
			);
			breadcrumbArr.push(`${entity?.company.name}`);
			breadcrumbArr.push(`${entity?.site.name}`);
			breadcrumbArr.push(`${t('crane')} ${entity?.number}`);

			if (path.includes('crane-activity')) {
				breadcrumbArr.push(t('activity'));
			} else if (path.includes('crane-details')) {
				breadcrumbArr.push(t('details'));
			} else if (path.includes('crane-distribution-list')) {
				breadcrumbArr.push(t('distributionList'));
			} else if (path.includes('crane-snapshots')) {
				breadcrumbArr.push(t('snapshots'));
			} else {
				breadcrumbArr.push(t('general'));
			}
		} else if (path.includes('notifications')) {
			breadcrumbArr.push(t('notifications'));
		} else if (path.includes('settings')) {
			breadcrumbArr.push(t('settings'));
			if (path.includes('blackbox')) {
				breadcrumbArr.push(t('blackboxes'));
			} else if (path.includes('license')) {
				breadcrumbArr.push(t('licenses'));
			} else if (path.includes('users')) {
				breadcrumbArr.push(t('users'));
			} else if (path.includes('operators')) {
				breadcrumbArr.push(t('operators'));
			} else {
				breadcrumbArr.push(t('cranes'));
			}
		} else if (path.includes('userProfile')) {
			breadcrumbArr.push(t('userProfile'));
		} else {
			breadcrumbArr.push(t('mainView'));
			if (path.includes('cranes-status')) {
				breadcrumbArr.push(t('cranesStatus'));
			} else {
				breadcrumbArr.push(t('general'));
			}
		}
		return breadcrumbArr;
	};

	useEffect(() => {
		setBreadcrumb(generateBreadcrumbEntities(location.pathname));
	}, [location.pathname, i18n.language]);

	return (
		<Breadcrumb
			className={styles.wrapper}
			separator={seperatorDir(i18n.dir())}>
			{breadcrumb.map((item, index) => (
				<Breadcrumb.Item key={index} className={styles.item}>
					{item}
				</Breadcrumb.Item>
			))}
		</Breadcrumb>
	);
};

export default BreadcrumbComponent;
