import React, { useContext } from 'react';
import styles from '../RemoveUserModal/RemoveUserModal.module.scss';
import { Button, Modal, ModalBody } from 'react-bootstrap';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { useTranslation } from 'react-i18next';

type props = {
	blackbox: any;
	show: boolean;
	handleClose: () => void;
};
const BBRemoveModal: React.FC<props> = props => {
	const { ShowLoadingModal } = useContext(LoadingModalContext);
	const { t } = useTranslation();

	const handleRemove = () => {
		props.handleClose();
		ShowLoadingModal(props.blackbox.RemoveBlackbox(), 'BBRemove');
	};
	return (
		<Modal size='sm' aria-labelledby='contained-modal-title-vcenter' show={props.show} onHide={props.handleClose} centered>
			<Modal.Header style={{ border: 'none' }} closeButton />
			<div className={styles.title}>{t('removeBlackboxConfirmation')}</div>
			<ModalBody>
				<div className={styles.buttons}>
					<Button variant='outline-danger' className={styles.button} onClick={handleRemove}>
						{t('yes&sure')}
					</Button>
					<Button variant='outline-primary' className={styles.button} onClick={props.handleClose}>
						{t('no&cancel')}
					</Button>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default BBRemoveModal;
