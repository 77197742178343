import React, { useState, useEffect } from 'react';
import styles from './Blackboxes.module.scss';
import Spinner from '../../UI/Spinner/Spinner';
import BBAssignmentModal from '../../Modals/BBAssignmentModal/BBAssignmentModal';
import BBUnassignmentModal from '../../Modals/BBUnassignmentModal/BBUnassignmentModal';
import NewBlackboxModal from '../../Modals/NewBlackboxModal/NewBlackboxModal';
import BBRemoveModal from '../../Modals/BBRemoveModal/BBRemoveModal';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import BlackboxSettingsTable from '../../Tables/BlackboxSettingsTable/BlackboxSettingsTable';
import { EBlackboxType } from '../../../DataTypes/Blackbox';
import { Hierarchy } from '../../../DataTypes/Hierarchy';
import MobileBlackboxSettingsTable from '../../Mobile/Tables/MobileBlackboxSettingsTable/MobileBlackboxSettingsTable';
import { useTranslation } from 'react-i18next';

type props = {
	hierarchy: Hierarchy;
};

const Blackboxes: React.FC<props> = props => {
	const [blackboxes, setBlackboxes] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [companyId, setCompanyId] = useState<string | undefined>();
	const [siteId, setSiteId] = useState<string | undefined>();
	const [craneId, setCraneId] = useState<string | undefined>();
	const [currentBlackbox, setCurrentBlackbox] = useState(null);
	const [currentLicense, setCurrentLicense] = useState(null);
	const [showAssignModal, setShowAssignModal] = useState(false);
	const [showUnassignModal, setShowUnassignModal] = useState(false);
	const [showAddBlackboxModal, setShowAddBlackboxModal] = useState(false);
	const [showRemoveBbModal, setShowRemoveBbModal] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		loadData();
		setIsLoading(false);
	}, [props.hierarchy.subscriptionCredit]);

	const loadData = () => {
		if (props.hierarchy.subscriptionCredit != null) {
			let sc = props.hierarchy.subscriptionCredit;
			let bb = sc.licenses
				.filter(x => x.blackboxBinding != null)
				.map(x => {
					x.blackboxBinding.blackbox.license = x;
					return x.blackboxBinding.blackbox;
				});

			bb = bb.concat(sc.blackboxes);

			setBlackboxes(bb);
		}
	};

	const setCurrentBlackboxAndLicense = blackbox => {
		setCurrentBlackbox(blackbox);
		setCurrentLicense(blackbox.license);

		const crane = props.hierarchy.cranes.find(c => c.id === blackbox.license?.blackboxBinding?.craneBinding?.craneId);

		setCompanyId(crane?.company.id);
		setSiteId(crane?.site.id);
		setCraneId(crane?.id);
	};

	const craneSnFinder = craneId => {
		const crane = props.hierarchy.cranes.find(c => c.id === craneId);
		if (crane) {
			return crane.sn;
		} else {
			return null;
		}
	};

	const assignPopover = (
		<div role='button' className={styles.action} onClick={() => setShowAssignModal(show => !show)}>
			{t('associateToCrane')}
		</div>
	);
	const unassignPopover = (
		<div role='button' className={styles.action} onClick={() => setShowUnassignModal(show => !show)}>
			{t('unassociate')}
		</div>
	);
	const removePopover = (
		<div role='button' className={styles.removeBb} onClick={() => setShowRemoveBbModal(show => !show)}>
			{t('remove')}
		</div>
	);

	if (isLoading) {
		return <Spinner />;
	}
	return (
		<div className={styles.wrapper}>
			{showAssignModal && (
				<BBAssignmentModal show={showAssignModal} handleClose={() => setShowAssignModal(show => !show)} license={currentLicense!} blackbox={currentBlackbox} hierarchy={props.hierarchy} />
			)}
			{showUnassignModal && (
				<BBUnassignmentModal
					show={showUnassignModal}
					handleClose={() => setShowUnassignModal(show => !show)}
					license={currentLicense!}
					blackbox={currentBlackbox}
					companyId={companyId!}
					siteId={siteId!}
					craneId={craneId!}
					craneSn={craneSnFinder(craneId)!}
				/>
			)}
			{showAddBlackboxModal && (
				<NewBlackboxModal show={showAddBlackboxModal} handleClose={() => setShowAddBlackboxModal(show => !show)} subscriptionCredit={props.hierarchy.subscriptionCredit!} />
			)}
			{showRemoveBbModal && <BBRemoveModal show={showRemoveBbModal} handleClose={() => setShowRemoveBbModal(show => !show)} blackbox={currentBlackbox} />}
			<div className={styles.card}>
				<div className={styles.cardHeader}>
					<div className={styles.cardTitle}>{t('blackboxes')}</div>
					<Button onClick={() => setShowAddBlackboxModal(show => !show)} type='primary' shape='circle' className='circular-add-btn' icon={<PlusOutlined />} />
				</div>
				<MobileBlackboxSettingsTable
					addBlackboxModalHandler={() => setShowAddBlackboxModal(show => !show)}
					assignModalHandler={() => setShowAssignModal(show => !show)}
					blackboxes={blackboxes}
					craneSnFinder={craneSnFinder}
					removeModalHandler={() => setShowRemoveBbModal(show => !show)}
					unassignModalHandler={() => setShowUnassignModal(show => !show)}
					assignPopover={assignPopover}
					unassignPopover={unassignPopover}
					removePopover={removePopover}
					setCurrentBlackboxAndLicense={setCurrentBlackboxAndLicense}
				/>
				<BlackboxSettingsTable
					dataSource={blackboxes}
					assignPopover={assignPopover}
					unassignPopover={unassignPopover}
					removePopover={removePopover}
					setCurrentBlackboxAndLicense={setCurrentBlackboxAndLicense}
					hierarchy={props.hierarchy}
				/>
			</div>
			<div className={styles.licenseAmountCard}>
				<div className={styles.licenseAmountCardTitle}>{t('blackboxesAmount')}</div>
				<div className={styles.licenseAmountCardBody}>
					<div className={styles.licenseAmount}>
						<span className={styles.licenseAmountKey}>SBC :</span>
						<span className={styles.licenseAmountValue}>{blackboxes?.filter(blackbox => blackbox.type === EBlackboxType.Full).length}</span>
					</div>
					<div className={styles.licenseAmount}>
						<span className={styles.licenseAmountKey}>SBL :</span>
						<span className={styles.licenseAmountValue}>{blackboxes?.filter(blackbox => blackbox.type === EBlackboxType.Lite).length}</span>
					</div>
					<div className={styles.licenseAmount}>
						<span className={styles.licenseAmountKey}>SBF :</span>
						<span className={styles.licenseAmountValue}>{blackboxes?.filter(blackbox => blackbox.type === EBlackboxType.Feeder).length}</span>
					</div>
				</div>
				<div className={styles.licenseAmountCardFooter}>
					<span>{`${t('inUse')} ${props.hierarchy.subscriptionCredit?.licenses.filter(license => license?.blackboxBinding?.craneBinding != null).length}/${blackboxes.length}`}</span>
				</div>
			</div>
		</div>
	);
};

export default Blackboxes;
