import React, { useContext, useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Operator } from 'src/DataTypes/Operator';
import { useTranslation } from 'react-i18next';

import styles from './Operators.module.scss';
import OperatorsSettingsTable from 'src/components/Tables/OperatorsSettingsTable/OperatorsSettingsTable';
import NewOperatorModal from 'src/components/Modals/NewOperatorModal/NewOperatorModal';
import RemoveOperatorModal from 'src/components/Modals/RemoveOperatorModal/RemoveOperatorModal';
import EditOperatorModal from 'src/components/Modals/EditOperatorModal/EditOperatorModal';
import UploadOperatorImage from 'src/components/Modals/UploadOperatorImage/UploadOperatorImage';
import MobileOperatorsSettingsTable from 'src/components/Mobile/Tables/MobileOperatorsSettingsTable/MobileOperatorsSettingsTable';
import RemoveOperatorImagesModal from 'src/components/Modals/RemoveOperatorImagesModal/RemoveOperatorImagesModal';
import Spinner from 'src/components/UI/Spinner/Spinner';
import { Hierarchy } from 'src/DataTypes/Hierarchy';
import ReportGeneratorModal, {
	Dates,
} from 'src/components/Modals/ReportGeneratorModal/ReportGeneratorModal';
import { OperationSummary } from 'src/DataTypes/OperationSummary';
import OperatorActivityReport from 'src/components/PdfDocuments/OperatorActivityReport';
import RemoveModal from 'src/components/Modals/RemoveModal/RemoveModal';
import { LoadingModalContext } from 'src/contexts/LoadingModalProvider';

type props = {
	hierarchy: Hierarchy;
};

const Operators: React.FC<props> = props => {
	const { t } = useTranslation();
	const [currentOperator, setCurrentOperator] = useState<Operator>();
	const [isLoading, setIsLoading] = useState(true);
	const [isChanged, setIsChanged] = useState(false);
	const [showNewOperatorModal, setShowNewOperatorModal] = useState(false);
	const [showRemoveOperatorModal, setShowRemoveOperatorModal] =
		useState(false);
	const [showRemoveOperatorImagesModal, setShowRemoveOperatorImagesModal] =
		useState(false);
	const [showReportGeneratorModal, setShowReportGeneratorModal] =
		useState(false);
	const [showEditOperatorModal, setShowEditOperatorModal] = useState(false);
	const [showUploadOperatorImage, setShowUploadOperatorImage] =
		useState(false);
	const [operators, setOperators] = useState<Operator[]>([]);
	const { ShowLoadingModal } = useContext(LoadingModalContext);

	useEffect(() => {
		Operator.GetAllOperators().then(
			res => {
				setOperators(res);
				setIsLoading(false);
			},
			rej => {
				console.log(rej);
			}
		);
	}, [isChanged]);

	const popoverContent = (
		<>
			<div
				role='button'
				className={styles.removeBb}
				onClick={() => setShowRemoveOperatorModal(true)}>
				{t('remove')}
			</div>
			<hr />
			<div
				role='button'
				className={styles.removeBb}
				onClick={() => setShowEditOperatorModal(true)}>
				{t('editDetails')}
			</div>
			<hr />
			<div
				role='button'
				className={styles.removeBb}
				onClick={() => setShowUploadOperatorImage(true)}>
				{t('takeNewImage')}
			</div>
			<hr />
			<div
				role='button'
				className={styles.removeBb}
				onClick={() => setShowRemoveOperatorImagesModal(true)}>
				{t('removeOperatorImages')}
			</div>
			<hr />
			<div
				role='button'
				className={styles.removeBb}
				onClick={() => setShowReportGeneratorModal(true)}>
				{t('generateActivityHoursReport')}
			</div>
		</>
	);

	const generateDocFunc = async (dates: Dates): Promise<JSX.Element> => {
		let operatorSummaryArr = await currentOperator!.GetSummary(
			dates.startTime,
			dates.endTime,
			props.hierarchy.cranes
				.filter(x => x != undefined)
				.map(c => c.deviceId!)
		);
		return (
			<OperatorActivityReport
				operationSummaryArr={operatorSummaryArr}
				dailyWorkingHours={12}
				operator={currentOperator!}
				dates={dates}
				hierarchy={props.hierarchy}
			/>
		);
	};

	if (isLoading) {
		return <Spinner />;
	}
	return (
		<div className={styles.wrapper}>
			<div className={styles.header}>
				<div className={styles.title}>{t('operators')}</div>
				<Button
					onClick={() => setShowNewOperatorModal(true)}
					type='primary'
					shape='circle'
					className='circular-add-btn'
					icon={<PlusOutlined />}
				/>
			</div>
			{showNewOperatorModal && (
				<NewOperatorModal
					submitCallback={() => setIsChanged(current => !current)}
					closeHandler={() => setShowNewOperatorModal(false)}
					operators={operators}
				/>
			)}
			{showEditOperatorModal && (
				<EditOperatorModal
					submitCallback={() => setIsChanged(current => !current)}
					closeHandler={() => setShowEditOperatorModal(false)}
					operators={operators}
					operator={currentOperator!}
				/>
			)}
			{showRemoveOperatorModal && (
				<RemoveOperatorModal
					submitCallback={() => setIsChanged(current => !current)}
					closeHandler={() => setShowRemoveOperatorModal(false)}
					operator={currentOperator!}
				/>
				// <RemoveModal
				// 	handleClose={() => setShowRemoveOperatorModal(false)}
				// 	handleRemove={() => {
				// 		setShowRemoveOperatorModal(false);
				// 		ShowLoadingModal(
				// 			currentOperator!.Delete(),
				// 			'OperatorRemove'
				// 		).then(res => {
				// 			if (!res.isError) {
				// 				setIsChanged(current => !current);
				// 			}
				// 		});
				// 	}}
				// 	title={t('OperatorRemove.Generic')}
				// 	content=''
				// />
			)}
			{showRemoveOperatorImagesModal && (
				<RemoveOperatorImagesModal
					submitCallback={() => setIsChanged(current => !current)}
					closeHandler={() => setShowRemoveOperatorImagesModal(false)}
					operator={currentOperator!}
				/>
			)}
			{showUploadOperatorImage && (
				<UploadOperatorImage
					submitCallback={() => setIsChanged(current => !current)}
					closeHandler={() => setShowUploadOperatorImage(false)}
					operator={currentOperator!}
				/>
			)}
			{showReportGeneratorModal && (
				<ReportGeneratorModal
					generateDocFunc={generateDocFunc}
					dailyWorkingHours={12}
					closeHandler={() => setShowReportGeneratorModal(false)}
				/>
			)}
			<OperatorsSettingsTable
				dataSource={operators}
				setCurrentOperator={setCurrentOperator}
				popoverContent={popoverContent}
			/>
			<MobileOperatorsSettingsTable
				setCurrentOperator={setCurrentOperator}
				popoverContent={popoverContent}
				operators={operators}
				newOperatorModalHandler
				removeOperatorModalHandler
			/>
		</div>
	);
};

export default Operators;
