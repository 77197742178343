import React, { Fragment, useEffect, useState } from 'react';
import { NavLink as Link, useHistory, useLocation } from 'react-router-dom';
import { Menu, ConfigProvider, Popover } from 'antd';
import { AppstoreOutlined } from '@ant-design/icons';
import { Collapse } from 'react-collapse';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import styles from './Sidebar.module.scss';

import { ReactComponent as AddIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as HomeIcon } from '../../assets/icons/Home.svg';

import { ReactComponent as CraneIcon } from '../../assets/icons/crane.svg';
import { ReactComponent as LufferIcon } from '../../assets/icons/luffer.svg';
import { ReactComponent as Circle } from '../../assets/icons/circle.svg';

import { ReactComponent as MenuIcon } from '../../assets/icons/menu.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/sidebar-plus.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/sidebar-close.svg';
import { ReactComponent as ProfileIcon } from '../../assets/icons/profile.svg';
import { ReactComponent as Arrow } from '../../assets/icons/arrow.svg';

import { ReactComponent as IL } from '../../assets/icons/il.svg';
import { ReactComponent as US } from '../../assets/icons/us.svg';
import { ReactComponent as GlobeIcon } from '../../assets/icons/globe.svg';

import cn from 'classnames';
import { Company } from 'src/DataTypes/Company';
import { Site } from 'src/DataTypes/Site';
import { Logout } from 'src/auth';
import { Crane } from 'src/DataTypes/Crane';

const { SubMenu } = Menu;

const Sidebar = props => {
	const history = useHistory();
	const [current, setCurrent] = useState('1');
	const location = useLocation();
	const [visibleLangPopover, setVisibleLangPopover] = useState(false);
	const { t } = useTranslation();

	const handleClick = e => {
		switch (e.key) {
			case 'mainView':
				history.push('/');
				break;
			case 'addNewItem':
				setCurrent('1');
				props.openHandler();
				break;
			default:
				setCurrent(e.key);
				history.push(e.key);
				break;
		}
	};

	const [activeCompanyIndex, setActiveCompanyIndex] = useState(null);
	const [activeSiteIndex, setActiveSiteIndex] = useState(null);
	const [siteChildrenActive, setSiteChildrenActive] = useState(null);
	const [companyChildrenActive, setCompanyChildrenActive] = useState(null);
	const [sidebarOpen, setSidebarOpen] = useState(false);

	const [entity, setEntity] = useState<{ type: string; object: { name?: string; company?: Company; site?: Site; number?: string } } | null>(null);

	function goToMain() {
		setActiveCompanyIndex(null);
		setActiveSiteIndex(null);
		setSiteChildrenActive(null);
		setCompanyChildrenActive(null);
	}

	function toggleComp(compId) {
		setActiveCompanyIndex(activeCompanyIndex === compId ? null : compId);
		setCompanyChildrenActive(null);
		setSiteChildrenActive(null);
	}

	function toggleSite(siteId, companyId) {
		setCompanyChildrenActive(companyId);
		setActiveCompanyIndex(companyId);
		setActiveSiteIndex(activeSiteIndex === siteId ? null : siteId);
		setSiteChildrenActive(null);
	}

	function getCompanyClass(company) {
		return cn(
			styles.navLink,
			activeCompanyIndex === company.id ? styles.activeNav : null,
			(activeCompanyIndex === company.id && activeSiteIndex != null && (() => company.sities.find(site => site.id === activeSiteIndex))) ||
				(activeCompanyIndex === company.id && companyChildrenActive != null && (() => (company.id = companyChildrenActive)))
				? styles.companyChildrenActive
				: null
		);
	}

	function getSiteClass(id) {
		return cn(
			styles.navLink,
			activeSiteIndex === id ? styles.activeNav : null,
			activeSiteIndex === id && siteChildrenActive != null && id === siteChildrenActive ? styles.siteChildrenActive : null
		);
	}

	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng);
		setVisibleLangPopover(false);
		props.currentUser.changeLocaleSettings({ language: lng });
	};

	useEffect(() => {
		setSidebarOpen(false);

		const isCrane = location.pathname.includes('/crane/');
		const isSite = location.pathname.includes('/site/');
		const isCompany = location.pathname.includes('/comp/');

		const pathnameParts = location.pathname.split('/');
		const entityId = pathnameParts[2];

		if (isCompany) {
			setEntity({ type: 'company', object: props.hierarchy.companies.find(c => c.id === entityId) });
		} else if (isSite) {
			setEntity({ type: 'site', object: props.hierarchy.sites.find(s => s.id === entityId) });
		} else if (isCrane) {
			setEntity({ type: 'crane', object: props.hierarchy.cranes.find(c => c.id === entityId) });
		} else {
			setEntity(null);
		}
	}, [location.pathname]);

	const languagePopoverContent = (
		<div className={styles.popoverContent}>
			{i18n.language === 'he' ? (
				<div className={styles.popoverItem} onClick={() => changeLanguage('en')}>
					<US className={styles.flag} />
					English
				</div>
			) : (
				<div className={styles.popoverItem} onClick={() => changeLanguage('he')}>
					<IL className={styles.flag} />
					עברית
				</div>
			)}
		</div>
	);

	return (
		<>
			<div className={styles.mobileHeader}>
				<Link to='' className={cn(styles.mobileLng)}>
					<Popover content={languagePopoverContent} placement='bottomLeft' trigger='click' visible={visibleLangPopover} onVisibleChange={() => setVisibleLangPopover(current => !current)}>
						<GlobeIcon />
					</Popover>
				</Link>
				{entity && entity.type === 'company' && (
					<span className={styles.title}>
						<span>{entity.object.name}</span>
					</span>
				)}
				{entity && entity.type === 'site' && (
					<span className={styles.title}>
						<span>{entity?.object?.company?.name}</span>
						<span>{entity.object?.name}</span>
					</span>
				)}
				{entity && entity.type === 'crane' && (
					<span className={styles.title}>
						<span>{entity.object.company?.name}</span>
						<span>{entity.object.site?.name}</span>
						<span>{`${t('crane')} ${entity.object.number}`}</span>
					</span>
				)}
				{!entity && (
					<span className={styles.title}>
						<span>{t('mainView')}</span>
					</span>
				)}
				<button className={styles.menuBtn} onClick={() => setSidebarOpen(true)}>
					<MenuIcon className={styles.menuIcon} />
				</button>
			</div>
			<div className={cn(styles.sidebar, sidebarOpen && styles.open)}>
				<span className={styles.header}>
					<button className={styles.closeBtn} onClick={() => setSidebarOpen(false)}>
						<CloseIcon className={styles.closeIcon} />
					</button>

					<div className={styles.profile} onClick={() => history.push('/userProfile')}>
						<span className={styles.logout} onClick={Logout}>
							{`, ${t('logout')} `}
						</span>
						<span className={styles.name}>{props.currentUser.fullName}</span>
						<ProfileIcon className={styles.icon} />
					</div>
				</span>

				<span className={styles.mobileScroll}>
					<div className={styles.org} onClick={goToMain}>
						<Link to='/' activeClassName={styles.activeHome} exact>
							<img src={require('../../assets/icons/Home.svg')} />
							{t('mainView')}
						</Link>
					</div>
					<div className={styles.nestedList}>
						{props.hierarchy.companies
							.sort((a, b) => (a.name < b.name ? -1 : 1))
							.map((company, index) => {
								return (
									<Fragment key={index}>
										<div className={styles.company} onClick={() => toggleComp(company.id)}>
											<Link
												to={{
													pathname: `/comp/${company.id}`,
												}}
												className={getCompanyClass(company)}
												activeClassName={styles.companyActive}>
												{company.sites.length != 0 ? (
													<Arrow className={styles.arrow} onClick={e => e.preventDefault()} style={{ transform: i18n.dir() === 'rtl' ? 'rotate(0deg)' : 'rotate(180deg)' }} />
												) : null}
												{company.name}
											</Link>
										</div>
										<Collapse isOpened={activeCompanyIndex === company.id}>
											{company.sites.map((site, index) => {
												return (
													<Fragment key={index}>
														<div className={styles.site} onClick={() => toggleSite(site.id, company.id)}>
															<Link
																to={{
																	pathname: `/site/${site.id}`,
																}}
																className={getSiteClass(site.id)}
																activeClassName={styles.siteActive}>
																{site.cranes.length != 0 ? (
																	<Arrow
																		onClick={e => e.preventDefault()}
																		className={styles.arrow}
																		style={{ transform: i18n.dir() === 'rtl' ? 'rotate(0deg)' : 'rotate(180deg)' }}
																	/>
																) : null}
																{site.name}
															</Link>
														</div>
														<Collapse isOpened={activeSiteIndex === site.id}>
															{site.cranes.map((crane, index) => {
																return (
																	<div className={styles.crane} key={index} onClick={() => setSiteChildrenActive(site.id)}>
																		<Link
																			to={{
																				pathname: `/crane/${crane.id}`,
																			}}
																			className={styles.navLink}
																			activeClassName={styles.craneActive}>
																			{`${t('crane')} ${crane.number}`}
																		</Link>
																	</div>
																);
															})}
														</Collapse>
													</Fragment>
												);
											})}
										</Collapse>
									</Fragment>
								);
							})}
					</div>
					<div
						className={styles.footerSidebar}
						hidden={!props.currentUser.permissions.craneCRUD.create && !props.currentUser.permissions.siteCRUD.create && !props.currentUser.permissions.companyCRUD.create}>
						<PlusIcon className={styles.plusIcon} />
						<div className={styles.addNewItem} role='button' onClick={props.openHandler}>
							{t('addNewItem')}
						</div>
					</div>
				</span>
			</div>

			<ConfigProvider direction={i18n.language === 'he' ? 'rtl' : 'ltr'}>
				<div className={styles.sidebarHeader}>
					<div className={styles.orgLogo}>
						<img src={require('../../assets/img/skylinelogo.png')} style={{ width: '40px', height: '40px', cursor: 'pointer' }} onClick={() => history.push('/')} />
					</div>
				</div>
				<Menu
					theme='dark'
					onClick={handleClick}
					style={{ width: 256, backgroundColor: '#242530' }}
					defaultOpenKeys={['sub1']}
					selectedKeys={[current]}
					mode='inline'
					className={styles.sidebar}>
					<Menu.Item key='mainView'>
						<HomeIcon className={styles.homeIcon} />
						{t('mainView')}
					</Menu.Item>
					{props.hierarchy.companies
						.sort((a, b) => (a.name < b.name ? -1 : 1))
						.map(company =>
							company.sites?.length !== 0 ? (
								<SubMenu key={company.id} title={company.name} onTitleClick={() => history.push(`/comp/${company.id}`)} className={styles.nestedList}>
									{company.sites.map(site =>
										site.cranes?.length !== 0 ? (
											<SubMenu key={site.id} title={site.name} onTitleClick={() => history.push(`/site/${site.id}`)} icon={<Circle className={styles.icon} />}>
												{site.cranes.map((crane: Crane) => (
													<Menu.Item
														key={`/crane/${crane.id}`}
														icon={crane.type === 'Luffing' ? <LufferIcon className={styles.lufferIcon} /> : <CraneIcon className={styles.craneIcon} />}>{`${t('crane')} ${
														crane.number
													}`}</Menu.Item>
												))}
											</SubMenu>
										) : (
											<Menu.Item key={`/site/${site.id}`} icon={<Circle className={styles.icon} />}>
												{site.name}
											</Menu.Item>
										)
									)}
								</SubMenu>
							) : (
								<Menu.Item key={`/comp/${company.id}`}>{company.name}</Menu.Item>
							)
						)}
				</Menu>
				<Menu
					theme='dark'
					onClick={handleClick}
					style={{ width: 256, backgroundColor: '#242530' }}
					defaultOpenKeys={['sub1']}
					selectedKeys={[current]}
					mode='inline'
					className={styles.sidebarFooter}>
					<Menu.Item
						key='addNewItem'
						className={styles.addItem}
						style={{ position: 'fixed', bottom: '0', marginRight: '3rem' }}
						hidden={!props.currentUser.permissions.craneCRUD.create && !props.currentUser.permissions.siteCRUD.create && !props.currentUser.permissions.companyCRUD.create}>
						<AddIcon className={styles.addIcon} />
						{t('addNewItem')}
					</Menu.Item>
				</Menu>
			</ConfigProvider>
		</>
	);
};

export default Sidebar;
