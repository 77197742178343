import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, ConfigProvider, Tag, Button, Popover, Input, Space } from 'antd';
import { MoreOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import he_IL from 'antd/es/locale/he_IL';
import { Crane } from '../../../DataTypes/Crane';
import styles from '../DesktopTable.module.scss';
import { showRangeAndTotal } from 'src/services/services';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

type Props = {
	dataSource: Crane[];
	popover: any;
	setCurrentCrane: (crane: Crane) => void;
	CraneDetailModalHandler: any;
};

const CraneSettingsTable = (props: Props) => {
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const history = useHistory();
	const { t } = useTranslation();

	const getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					placeholder={`${t('search')} ${t(dataIndex)}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button type='primary' onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size='small' style={{ width: 90 }}>
						{t('search')}
					</Button>
					<Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
						{t('reset')}
					</Button>
					{/* <Button
						type='link'
						size='small'
						onClick={() => {
							confirm({ closeDropdown: false });
							setSearchText(selectedKeys[0]);
							setSearchedColumn(dataIndex);
						}}>
						Filter
					</Button> */}
				</Space>
			</div>
		),
		filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, marginRight: '1rem', fontSize: '18px' }} />,
		onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),
		render: text =>
			searchedColumn === dataIndex ? (
				<Highlighter highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }} searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''} />
			) : (
				text
			),
	});

	const columns: any = [
		{
			title: t('sn'),
			dataIndex: 'sn',
			key: 'sn',
			defaultSortOrder: 'ascend',
			width: '15%',
			isUseDefaultSearch: true,
			sorter: (a, b) => sortFunction(a.sn, b.sn),
			sortDirections: ['descend', 'ascend'],
			...getColumnSearchProps('sn'),
		},
		{
			title: t('model'),
			dataIndex: 'model',
			key: 'model',
			width: '15%',
			isUseDefaultSearch: true,
			sorter: (a, b) => sortFunction(a.model, b.model),
			sortDirections: ['descend', 'ascend'],
			...getColumnSearchProps('model'),
		},
		{
			title: t('status'),
			dataIndex: 'status',
			key: 'status',
			width: '10%',
			render: status => {
				switch (status) {
					case 'Fault':
						return <Tag color='red'>{t('fault')}</Tag>;
					case 'Active':
						return <Tag color='green'>{t('active')}</Tag>;
					case 'Inactive':
						return <Tag color='blue'>{t('inactive')}</Tag>;
					case 'InConstruction':
						return <Tag color='yellow'>{t('inAssembly')}</Tag>;
					default:
						return <Tag color='blue'>{t('inactive')}</Tag>;
				}
			},
		},
		{
			title: t('company'),
			render: crane => (
				<Button type='link' size='small' onClick={() => history.push(`/comp/${crane.company.id}`)}>
					{crane.company.name}
				</Button>
			),
			sorter: (a, b) => sortFunction(a.company.name, b.company.name),
			width: '15%',
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: t('site'),
			render: crane => (
				<Button type='link' size='small' onClick={() => history.push(`/site/${crane.site.id}`)}>
					{crane.site.name}
				</Button>
			),
			sorter: (a, b) => sortFunction(a.site.name, b.site.name),
			width: '15%',
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: t('craneNumber'),
			render: crane => (
				<Button type='link' size='small' onClick={() => history.push(`/crane/${crane.id}`)}>
					{crane.number}
				</Button>
			),
			sorter: (a, b) => sortFunction(a.number, b.number),
			width: '30%',
			sortDirections: ['descend', 'ascend'],
		},
		{
			render: crane => (
				<Popover placement='right' trigger='click' content={props.popover}>
					<MoreOutlined onClick={() => props.setCurrentCrane(crane)} />
				</Popover>
			),
		},
	];

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = clearFilters => {
		clearFilters();
		setSearchText('');
	};

	const sortFunction = (a, b) => {
		let sortA;
		let sortB;

		switch (typeof a) {
			case 'string':
				sortA = a?.toLowerCase();
				sortB = b?.toLowerCase();
				break;

			default:
				sortA = a;
				sortB = b;
				break;
		}

		if (sortA < sortB) {
			return -1;
		}
		if (sortA > sortB) {
			return 1;
		}

		return 0;
	};

	return (
		<ConfigProvider direction={i18n.language == 'he' ? 'rtl' : 'ltr'} locale={i18n.language == 'he' ? he_IL : undefined}>
			<Table className={styles.desktopTable} dataSource={props.dataSource} columns={columns} pagination={{ pageSize: 10, showTotal: showRangeAndTotal }} />
		</ConfigProvider>
	);
};

export default CraneSettingsTable;
