import React, { useEffect, useState } from 'react';
import styles from './StickyHeader.module.scss';

const StickyHeader = props => {
	const [offset, setOffset] = useState<number>(0);

	useEffect(() => {
		window.onscroll = () => {
			setOffset(window.pageYOffset);
		};
	}, []);

	return <div className={offset > 100 ? styles.stickyHeader : undefined}>{props.children}</div>;
};

export default StickyHeader;
