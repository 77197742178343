import React, { useContext } from 'react';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { Modal, Button } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styles from '../AddContactModal/AddContactModal.module.scss';
import { Crane } from '../../../DataTypes/Crane';
import RTL from '../../RTL/RTL';
import { Hierarchy } from '../../../DataTypes/Hierarchy';
import { License } from '../../../DataTypes/License';
import { useTranslation } from 'react-i18next';

type props = {
	hierarchy: Hierarchy;
	handleClose: any;
	show: boolean;
	blackbox: any;
	license: License;
};

type inputs = {
	company: string;
	site: string;
	crane: string;
};

const BBAssignmentModal: React.FC<props> = props => {
	const { ShowLoadingModal } = useContext(LoadingModalContext);
	const { t } = useTranslation();

	const validationSchema = yup.object({
		company: yup.string().required(t('requiredField')),
		site: yup.string().required(t('requiredField')),
		crane: yup.string().required(t('requiredField')),
	});
	const formik = useFormik({
		initialValues: {
			company: '',
			site: '',
			crane: '',
		},
		validationSchema: validationSchema,
		onSubmit: (values: inputs) => {
			props.handleClose();
			ShowLoadingModal(props.license.BindCrane(props.blackbox.id, values.company, values.site, values.crane), 'BBAssign');
		},
	});

	return (
		<RTL>
			<Modal size='sm' className={styles.modal} aria-labelledby='contained-modal-title-vcenter' show={props.show} centered onHide={props.handleClose}>
				<Modal.Header style={{ border: 'none' }} closeButton />
				<div className={styles.title}>{t('blackboxAssign')}</div>
				<Modal.Body className={styles.modalBody}>
					<form className={styles.formi} onSubmit={formik.handleSubmit}>
						<TextField
							id='company'
							name='company'
							label={t('company')}
							value={formik.values.company}
							select
							SelectProps={{
								native: true,
							}}
							helperText={formik.touched.company ? formik.errors.company : ''}
							error={formik.touched.company && Boolean(formik.errors.company)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth>
							<option value='' disabled />
							{props.hierarchy.companies
								.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
								.map(company => (
									<option id={company.id} value={company.id}>
										{company.name}
									</option>
								))}
						</TextField>
						<TextField
							id='site'
							name='site'
							label={t('site')}
							value={formik.values.site}
							select
							SelectProps={{
								native: true,
							}}
							helperText={formik.touched.site ? formik.errors.site : ''}
							error={formik.touched.site && Boolean(formik.errors.site)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth
							disabled={formik.values.company === '' || props.hierarchy.companies.find(company => company.id === formik.values.company)?.sites.length === 0}>
							<option value='' disabled />
							{formik.values.company !== '' ? (
								props.hierarchy.companies
									.find(company => company.id === formik.values.company)
									?.sites.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
									.map(site => (
										<option key={site.id} id={site.id} value={site.id}>
											{site.name}
										</option>
									))
							) : (
								<option id={'none'} value={'none'} disabled>
									{'none'}
								</option>
							)}
						</TextField>
						<TextField
							id='crane'
							name='crane'
							label={t('crane')}
							value={formik.values.crane}
							select
							SelectProps={{
								native: true,
							}}
							helperText={formik.touched.crane ? formik.errors.crane : ''}
							error={formik.touched.crane && Boolean(formik.errors.crane)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth
							disabled={formik.values.site === '' || props.hierarchy.sites.find(site => site.id === formik.values.site)?.cranes.length === 0}>
							<option value='' disabled />
							{formik.values.site !== '' ? (
								props.hierarchy.sites
									.find(site => site.id === formik.values.site)
									?.cranes.sort((a, b) => (a.number.toLowerCase() < b.number.toLowerCase() ? -1 : 1))
									.map(crane => (
										<option key={crane.id} id={crane.id} value={crane.id}>
											{crane.number}
										</option>
									))
							) : (
								<option id={'none'} value={'none'} disabled>
									{'none'}
								</option>
							)}
						</TextField>
						<div className={styles.formButtons}>
							<Button type='submit' variant='outline-primary' className={styles.button}>
								{t('update')}
							</Button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</RTL>
	);
};

export default BBAssignmentModal;
