import React, { useRef, useState } from 'react';
import cn from 'classnames';
import styles from './MobileCraneSettingsTable.module.scss';
import { Crane } from '../../../../DataTypes/Crane';
import useOutsideClick from '../../../../utils/useOutsideClick';
import { Popover, Tag } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ChevronIcon } from '../../../../assets/icons/chevron.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/x.svg';
import { MoreOutlined } from '@ant-design/icons';

type props = {
	cranes: Crane[];
	CraneDetailModalHandler: any;
	CraneEditModalHandler: any;
	CranePositionModalHandler: any;
	setCurrentCrane: any;
	popover: any;
};

const MobileCraneSettingsTable: React.FC<props> = props => {
	const controlsRef = useRef<HTMLDivElement>(null);
	const [openDistIds, setOpenDistIds] = useState<string[]>([]);
	const [openControls, setOpenControls] = useState<string | null>(null);
	const { t } = useTranslation();

	useOutsideClick(controlsRef, () => {
		if (openControls != null) setOpenControls(null);
	});

	const toggleOnetDistId = (index: string) => {
		if (openDistIds.includes(index)) {
			setOpenDistIds(openDistIds.filter(_id => _id !== index));
		} else {
			setOpenDistIds([...openDistIds, index]);
		}
	};

	const getStatusTag = status => {
		switch (status) {
			case 'Fault':
				return <Tag color='red'>{t('fault')}</Tag>;
			case 'Active':
				return <Tag color='green'>{t('active')}</Tag>;
			case 'Inactive':
				return <Tag color='blue'>{t('inactive')}</Tag>;
			case 'InConstruction':
				return <Tag color='yellow'>{t('inAssembly')}</Tag>;

			default:
				return <Tag color='blue'>{t('inactive')}</Tag>;
		}
	};

	return (
		<table className={cn(styles.mobileTable, 'table table-borderless fw-bold')}>
			<thead>
				<tr>
					<th scope='col'>{t('sn')}</th>
					<th scope='col'>{t('model')}</th>
					<th scope='col'>{t('status')}</th>
					<th scope='col'></th>
				</tr>
			</thead>
			<tbody>
				{props.cranes.map((crane, index) => {
					return (
						<React.Fragment key={crane.id}>
							<tr key={index} className={openDistIds.includes(crane.id) ? styles.open : styles.close}>
								<td>{crane.sn}</td>
								<td>{crane.model}</td>
								<td>{getStatusTag(crane.status)}</td>
								<td onClick={() => toggleOnetDistId(crane.id)}>
									<ChevronIcon className={styles.chevron} />
								</td>
							</tr>

							<tr className={cn(openDistIds.includes(crane.id) ? styles.open : null, styles.tableExpand)}>
								<td colSpan={4}>
									<div>
										<span className={styles.distHeader}>{t('company')}</span>
										<span>{crane.company.name}</span>
										<Popover placement='bottomRight' trigger='click' className={styles.moreIcon} content={props.popover}>
											<MoreOutlined onClick={() => props.setCurrentCrane(crane)} />
										</Popover>
									</div>
									<div>
										<span className={styles.distHeader}>{t('site')}</span>
										<span>{crane.site.name}</span>
									</div>
									<div>
										<span className={styles.distHeader}>{t('craneNumber')}</span>
										<span>{crane.number}</span>
									</div>
								</td>
							</tr>
						</React.Fragment>
					);
				})}
			</tbody>
		</table>
	);
};

export default MobileCraneSettingsTable;
