import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import styles from './ErrorModal.module.scss';

type props = {
	statusCode: number;
	content: string;
};

//TODO rebuild
const ErrorModal: React.FC<props> = props => {
	const [show, setShow] = useState(true);
	const handleClose = () => setShow(false);
	const [showErrorDescription, setShowErrorDescription] = useState(false);

	return (
		<Modal className={styles.modal} show={show} onHide={handleClose} backdrop='static' keyboard={false}>
			<Modal.Header className={styles.modalHeader} closeButton>
				<Modal.Title>Error {`( status code: ${props.statusCode} )`}</Modal.Title>
			</Modal.Header>
			<Modal.Body className={styles.modalBody}>
				<p className={styles.errorDescription}>{showErrorDescription ? props.content : null}</p>
				<Button className={styles.errorDescriptionButton} onClick={() => setShowErrorDescription(!showErrorDescription)}>
					{showErrorDescription ? 'Close Error Description' : 'Show Error Description'}
				</Button>
			</Modal.Body>
		</Modal>
	);
};

export default ErrorModal;
