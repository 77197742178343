import React from 'react';
import styles from './Spinner.module.scss';

const Spinner: React.FC = () => {
	return (
		<div className={styles.ldsRoller}>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	);
};

export default Spinner;
