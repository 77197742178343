import { OperatorDetails } from './Operator';

export interface IDeviceStatusData {
	deviceId: string;
	isConnected: boolean;
	isCrevisConnect: boolean;
	isPowered: boolean;
	isReleased: boolean;
	isLocked: boolean;
	config: any;
	location: ILocation;
	recognition?: IRecognitionData;
	lastOperated: Date;
	lastMessageTime: Date;
	analogInputValues: AnalogInputValue[];
	digitalInputValues: DigitalInputValue[];
	digitalFeedbacks: DigitalFeedback[];
	averageInputValues: AverageInputValues[];
}

export enum ERecognitionStatus {
	NotEnabled,
	NoFaceDetected,
	OnlineNotRecognized,
	OfflineNotRecognized,
	OnlineRecognized,
	OfflineRecognized,
}

export interface ILocation {
	lat: number;
	lng: number;
}
export interface IDeviceStatus extends IDeviceStatusData {}

export class DeviceStatus implements IDeviceStatus {
	deviceId: string;

	isConnected: boolean;

	isCrevisConnect: boolean;

	isPowered: boolean;

	config: any;

	location: ILocation;

	recognition?: Recognition;

	isReleased: boolean;

	isLocked: boolean;

	lastOperated: Date;

	lastMessageTime: Date;

	analogInputValues: AnalogInputValue[];

	digitalInputValues: DigitalInputValue[];

	digitalFeedbacks: DigitalFeedback[];

	averageInputValues: AverageInputValues[];

	constructor(data: IDeviceStatusData) {
		this.deviceId = data.deviceId;
		this.isConnected = data.isConnected;
		this.isCrevisConnect = data.isCrevisConnect;
		this.isPowered = data.isPowered;
		this.isReleased = data.isReleased;
		this.isLocked = data.isLocked;
		this.config = data.config;
		this.location = data.location;
		this.recognition = data.recognition
			? new Recognition(data.recognition)
			: undefined;
		this.lastOperated = new Date(data.lastOperated);
		this.lastMessageTime = new Date(data.lastMessageTime);

		this.analogInputValues = data.analogInputValues.map(
			i => new AnalogInputValue(i)
		);
		this.digitalInputValues = data.digitalInputValues.map(
			i => new DigitalInputValue(i)
		);
		this.digitalFeedbacks = data.digitalFeedbacks.map(
			i => new DigitalFeedback(i)
		);
		this.averageInputValues = data.averageInputValues.map(
			i => new AverageInputValues(i)
		);
	}

	/**
	 * Finds input value by name
	 * @param name Input name
	 * @returns {number} The value in units for the InputValue
	 */
	GetAnalogInputValueInUnits(name: string): number | null {
		let found = this.analogInputValues.find(x => x.name == name);
		if (found == null) {
			return null;
		}

		return found.valueInUnits;
	}

	GetAverageInputValueInUnits(name: string): number | null {
		let found = this.averageInputValues.find(x => x.name == name);
		if (found == null) {
			return null;
		}

		return found.average;
	}
}

export interface IWind {
	speed: number;
	direction: number;
}

class Wind implements IWind {
	speed: number;
	averageSpeed: number;
	direction: number;

	constructor(speed: number, averageSpeed: number, direction: number) {
		this.speed = speed;
		this.averageSpeed = averageSpeed;
		this.direction = direction;
	}
}

export interface IAnalogInputValue {
	name: string;
	value01: number;
	valueInUnits: number;
}

class AnalogInputValue implements IAnalogInputValue {
	name: string;

	value01: number;

	valueInUnits: number;

	constructor(data: IAnalogInputValue) {
		this.name = data.name;
		this.value01 = data.value01;
		this.valueInUnits = data.valueInUnits;
	}
}

export interface IDigitalInputValue {
	name: string;
	value: boolean;
}

class DigitalInputValue implements IDigitalInputValue {
	name: string;

	value: boolean = false;

	constructor(data: IDigitalInputValue) {
		this.name = data.name;
		this.value = data.value;
	}
}

export interface IDigitalFeedback {
	name: string;
	speed: number;
}

class DigitalFeedback implements IDigitalFeedback {
	name: string;

	speed: number;

	constructor(data: IDigitalFeedback) {
		this.name = data.name;
		this.speed = data.speed;
	}
}

export interface IRecognitionData {
	status: ERecognitionStatus;
	operatorId?: string;
}

export class Recognition implements IRecognitionData {
	status: ERecognitionStatus;
	operatorId?: string;

	constructor(data: IRecognitionData) {
		this.status = data.status;
		this.operatorId = data.operatorId;
	}

	isStatusOk(): boolean {
		if (!this.operatorId) {
			return false;
		}
		return (
			this.status == ERecognitionStatus.OfflineRecognized ||
			this.status == ERecognitionStatus.OnlineRecognized
		);
	}
}

export interface IAverageInputValuesData {
	name: string;
	average: number;
}

export class AverageInputValues implements IAverageInputValuesData {
	name: string;
	average: number;

	constructor(data: IAverageInputValuesData) {
		this.name = data.name;
		this.average = data.average;
	}
}
