import React, { useRef, useState } from 'react';
import cn from 'classnames';
import styles from '../MobileUsersSettingsTable/MobileUsersSettingsTable.module.scss';
import useOutsideClick from '../../../../utils/useOutsideClick';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import { ReactComponent as FilterIcon } from '../../../../assets/icons/filter.svg';
import { ReactComponent as ChevronIcon } from '../../../../assets/icons/chevron.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';
import { Operator } from 'src/DataTypes/Operator';
import { useTranslation } from 'react-i18next';
import { Popover } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import moment from 'moment';
import { expDateTag, imageCountTag } from 'src/components/Tables/OperatorsSettingsTable/OperatorsSettingsTable';

type props = {
	operators: Operator[];
	setCurrentOperator: any;
	newOperatorModalHandler: any;
	removeOperatorModalHandler: any;
	popoverContent;
};

const MobileOperatorsSettingsTable: React.FC<props> = props => {
	const controlsRef = useRef<HTMLDivElement>(null);
	const [openDistIds, setOpenDistIds] = useState<string[]>([]);
	const [openControls, setOpenControls] = useState<string | null>(null);
	const { t } = useTranslation();

	useOutsideClick(controlsRef, () => {
		if (openControls != null) setOpenControls(null);
	});

	const toggleOnetDistId = index => {
		if (openDistIds.includes(index)) {
			setOpenDistIds(openDistIds.filter(_id => _id !== index));
		} else {
			setOpenDistIds([...openDistIds, index]);
		}
	};

	return (
		<>
			<div className={styles.tableHeader} hidden={true}>
				<div>
					<FilterIcon />
					<p>{t('filter')}</p>
				</div>
				<div>
					<SearchIcon />
					<p>{t('search')}</p>
				</div>
				<div>
					<PlusIcon />
					<p>{t('add')}</p>
				</div>
			</div>
			<table className={cn(styles.mobileTable, 'table table-borderless fw-bold')}>
				<tbody>
					{props.operators?.map((operator, index) => {
						return (
							<tr key={index} className={openDistIds.includes(operator.id) ? styles.open : styles.close}>
								<td>
									<span>{operator.fullName}</span>
									<span onClick={() => toggleOnetDistId(operator.id)}>
										<ChevronIcon />
									</span>
								</td>
								<td>
									<span>{t('id')}</span>
									<span>{operator.operatorId}</span>
									<Popover placement='bottomRight' trigger='click' className={styles.moreIcon} content={props.popoverContent}>
										<MoreOutlined onClick={() => props.setCurrentOperator(operator)} />
									</Popover>
								</td>
								<td>
									<span>{t('licenseId')}</span>
									<span>{operator.licenseId}</span>
								</td>
								<td>
									<span>{t('fullName')}</span>
									<span>{operator.fullName}</span>
								</td>
								<td>
									<span>{t('birthday')}</span>
									<span>{moment(operator.birthday).format('DD/MM/YYYY')}</span>
								</td>
								<td>
									<span>{t('licenseExpirationDate')}</span>
									<span>{expDateTag(operator.licenseExpirationDate)}</span>
								</td>
								<td>
									<span>{t('type')}</span>
									<span>{t(operator.type)}</span>
								</td>
								<td>
									<span>{t('imageCount')}</span>
									<span>{imageCountTag(operator.imageCount)}</span>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</>
	);
};

export default MobileOperatorsSettingsTable;
