import axios from 'axios';
import { Blackbox, IBlackboxAddDetails } from './Blackbox';
import { License } from './License';

export interface ISubscriptionCreditData {
	licenses: License[];
	blackboxes: Blackbox[];
	deviceIds: string[];
}

export interface ISubscriptionCredit extends ISubscriptionCreditData {}

export class SubscriptionCredit {
	licenses: License[] = [];
	blackboxes: Blackbox[] = [];
	deviceIds: string[] = [];

	constructor(data: ISubscriptionCreditData) {
		//TODO
		this.licenses = data.licenses.map(l => new License(l));
		this.blackboxes = data.blackboxes.map(b => new Blackbox(b));
		this.deviceIds = this.licenses.map(x => x.deviceId);
	}

	AddBlackbox(details: IBlackboxAddDetails) {
		return axios.post('/License/AddBlackbox', details);
	}
}
