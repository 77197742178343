import axios from 'axios';
import { Phone } from './Phone';
import { ERole } from './User';

export interface IRegistrationUserData {
	userId: string;
	mail: string;
	role: ERole;
	company: string;
}

export interface IRegistrationUser extends IRegistrationUserData {
	fullName?: string;
	phone?: Phone;
	password?: string;
}
export interface IRegistrationUserDetails {
	fullName?: string;
	phone?: Phone;
	password?: string;
	notifications: {
		isSendMail: boolean;
		isSendSms: boolean;
	};
}

export class RegistrationUser implements IRegistrationUser {
	userId: string;
	mail: string;
	role: ERole;
	company: string;
	fullName?: string;
	phone?: Phone;
	password?: string;
	notifications = {
		isSendMail: false,
		isSendSms: false,
	};

	constructor(data: IRegistrationUserData) {
		this.userId = data.userId;
		this.mail = data.mail;
		this.role = data.role;
		this.company = data.company;
	}

	editDetails(data: IRegistrationUserDetails) {
		this.fullName = data?.fullName;
		this.phone = data.phone;
		this.password = data?.password;
		this.notifications.isSendMail = data.notifications.isSendMail;
		this.notifications.isSendSms = data.notifications.isSendSms;
	}

	getPassword() {
		if (this.password === null) {
			return false;
		} else {
			return this.password;
		}
	}

	static getInviteDetails(token: string) {
		return axios.get(`/User/InviteDetails`, {
			params: {
				token: token,
			},
		});
	}

	register() {
		return axios.post('/User/Register', {
			userId: this.userId,
			fullName: this.fullName,
			phoneNumber: this.phone?.number,
			countryCode: this.phone?.countryCode,
			password: this.password,
			notifications: this.notifications,
		});
	}

	registerFinish(validationCode: string) {
		return axios.post('/User/RegisterFinish', null, {
			params: {
				userId: this.userId,
				token: validationCode,
			},
		});
	}
}
