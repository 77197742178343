import { ISnapshotsBulk } from './Snapshot';
import { Company } from './Company';
import { Site } from './Site';
import { DeviceStatus, IDeviceStatusData } from './DeviceStatus';
import axios from 'axios';
import { OperationSummary } from './OperationSummary';
import moment from 'moment';

export enum ECraneType {
	Tower = 'Tower',
	Luffer = 'Luffing',
	Feeder = 'Feeder',
	Other = 'Other',
}

export enum ECraneStatus {
	Active = 'Active',
	Inactive = 'Inactive',
	Fault = 'Fault',
	InConstruction = 'InConstruction',
}

export enum EDeviceStatus {
	Connected = 'Connected',
	Started = 'Started',
	Disconnected = 'Disconnected',
}

export interface ICraneUpdateDetails {
	number: string;
	model: string;
	type: ECraneType;
	status: ECraneStatus;
	manufacturer: string;
	height: number;
	length: number;
}

export interface ICraneMoveDetails {
	companyId: Company['id'];
	siteId: Site['id'];
	craneNumber: string;
}

export interface ICraneCreateDetails {
	number?: string;
	model?: string;
	type: ECraneType | undefined;
	status: ECraneStatus | undefined;
	sn?: string;
	manufacturer?: string;
	height: number | string;
	length: number | string;
	company: Company['id'];
	site: Site['id'];
}

export interface ICraneData {
	id: string;
	number: string;
	model: string;
	type: ECraneType;
	status: ECraneStatus;
	sn: string;
	manufacturer: string;
	blackboxId: string;
	blackboxVersion: string;
	height: number;
	length: number;
}

export interface ICrane extends ICraneData {
	site: any;
	company: any;
}

export class Crane implements ICrane {
	id: string;
	number: string;
	model: string;
	type: ECraneType;
	status: ECraneStatus;
	sn: string;
	manufacturer: string;
	blackboxId: string;
	blackboxVersion: string;
	height: number;
	length: number;
	site: Site;
	company: Company;
	deviceId?: string;
	deviceStatus?: DeviceStatus;

	get craneStatus(): EDeviceStatus {
		return !this.deviceStatus?.isConnected ? EDeviceStatus.Disconnected : this.deviceStatus?.isPowered ? EDeviceStatus.Started : EDeviceStatus.Connected;
	}

	/**
	 * Creates a new instance of Crane
	 */
	constructor(data: ICraneData, company: Company, site: Site) {
		this.id = data.id;
		this.number = data.number;
		this.model = data.model;
		this.type = data.type;
		this.status = data.status;
		this.sn = data.sn;
		this.manufacturer = data.manufacturer;
		this.blackboxId = data.blackboxId;
		this.blackboxVersion = data.blackboxVersion;
		this.height = data.height;
		this.length = data.length;
		this.site = site;
		this.company = company;
	}

	GetStatus(): ECraneStatus | any {
		return this.status.toLowerCase();
	}

	UpdateDeviceStatus(data: IDeviceStatusData) {
		this.deviceStatus = new DeviceStatus(data);
	}

	UpdateDetails(details: ICraneUpdateDetails) {
		return axios.put(`/Company/${this.company.id}/Site/${this.site.id}/Crane/${this.id}`, details);
	}

	Move(details: ICraneMoveDetails) {
		return axios.post(`/Company/${this.company.id}/Site/${this.site.id}/Crane/${this.id}/Move`, details);
	}

	SetPower(action: boolean) {
		if (this.deviceId === null) {
			alert('Device Id not found');
			return Promise.reject('Device Id not found');
		}

		return axios.put(`/Device/${this.deviceId}/SetPower?power=${action}`);
	}

	SetPowerVerify(token: string) {
		return axios.put(`/Device/${this.deviceId}/SetPowerVerify?token=${token}`);
	}

	// GetActivitySummary(startTime, endTime) {
	// 	return axios.post(`/Summary/${this.deviceId}`, null, { params: { startTime, endTime } });
	// }

	GetActivitySummary(startTime: Date, endTime: Date) {
		return OperationSummary.Query(startTime, endTime, this.deviceId ? [this.deviceId] : undefined);
	}

	GetOperatorImage() {
		return axios.put(`/Device/${this.deviceId}/GetOperatorImage`);
	}

	async GetSnapshotImages(): Promise<ISnapshotsBulk[] | null> {
		if (this.deviceId == null) return null;
		const response = await axios.post(`/Device/Snapshots`, {
			startTime: moment(new Date()).subtract(1, 'month').format(),
			endTime: moment(new Date()).format(),
			snapshotsBinding: {
				craneId: this.id,
			},
		});
		return response.data;
	}

	getBorderColor(): string {
		switch (this.craneStatus) {
			case 'Disconnected':
				return 'disconnected';
			case 'Connected':
				return 'connected';
			case 'Started':
				return 'isPowered';

			default:
				return 'disconnected';
		}
	}

	getLastOperated() {
		if (!this.deviceStatus) {
			return false;
		}

		if (!this.deviceStatus.isConnected || !this.deviceStatus.isPowered) {
			return this.deviceStatus.lastOperated;
		}

		if (this.deviceStatus.isConnected) {
			return true;
		}

		{
			return false;
		}
	}

	getActivitySummary(date: string | undefined) {
		return axios.get(`/Summary/${this.id}/Activity`, { params: { date } });
	}

	static typeDropdown = [
		{ label: 'בחר', value: '', disabled: true },
		{ label: 'צריח', value: 'Tower' },
		{ label: 'פידר', value: 'Feeder' },
		{ label: 'אחר', value: 'Other' },
	];

	static statusDropdown = [
		{ label: 'בחר', value: '', disabled: true },
		{ label: 'פעיל', value: 'Active' },
		{ label: 'לא פעיל', value: 'Inactive' },
		{ label: 'תקול', value: 'Fault' },
	];
}
