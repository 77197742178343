import React, { useRef, useState } from 'react';
import cn from 'classnames';
import styles from './MobileUsersSettingsTable.module.scss';
import useOutsideClick from '../../../../utils/useOutsideClick';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import { ReactComponent as FilterIcon } from '../../../../assets/icons/filter.svg';
import { ReactComponent as ChevronIcon } from '../../../../assets/icons/chevron.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';
import { User } from '../../../../DataTypes/User';
import { useTranslation } from 'react-i18next';
import { Popover } from 'antd';
import { MoreOutlined } from '@ant-design/icons';

type props = {
	users: User[];
	setCurrentUser: any;
	newUserModalHandler: any;
	removeUserModalHandler: any;
	pendingPopoverContent;
	registeredPopoverContent;
};

const MobileUsersSettingsTable: React.FC<props> = props => {
	const controlsRef = useRef<HTMLDivElement>(null);
	const [openDistIds, setOpenDistIds] = useState<string[]>([]);
	const [openControls, setOpenControls] = useState<string | null>(null);
	const { t } = useTranslation();

	useOutsideClick(controlsRef, () => {
		if (openControls != null) setOpenControls(null);
	});

	const toggleOnetDistId = index => {
		if (openDistIds.includes(index)) {
			setOpenDistIds(openDistIds.filter(_id => _id !== index));
		} else {
			setOpenDistIds([...openDistIds, index]);
		}
	};

	return (
		<>
			<div className={styles.tableHeader} hidden={true}>
				<div>
					<FilterIcon />
					<p>{t('filter')}</p>
				</div>
				<div>
					<SearchIcon />
					<p>{t('search')}</p>
				</div>
				<div onClick={props.newUserModalHandler}>
					<PlusIcon />
					<p>{t('add')}</p>
				</div>
			</div>
			<table className={cn(styles.mobileTable, 'table table-borderless fw-bold')}>
				<tbody>
					{props.users?.map((user, index) => {
						return (
							<tr key={index} className={openDistIds.includes(user.id) ? styles.open : styles.close}>
								<td>
									<span>{user.fullName}</span>
									<span onClick={() => toggleOnetDistId(user.id)}>
										<ChevronIcon />
									</span>
								</td>
								<td>
									<span>{t('company')}</span>
									<span>{user.company}</span>
									<Popover
										placement='bottomRight'
										trigger='click'
										className={styles.moreIcon}
										content={user.isPending ? props.pendingPopoverContent : props.registeredPopoverContent}>
										<MoreOutlined onClick={() => props.setCurrentUser(user)} />
									</Popover>
								</td>
								<td>
									<span>{t('role')}</span>
									<span>{t(user.role)}</span>
								</td>
								<td>
									<span>{t('phone')}</span>
									<span>{user.phone.number}</span>
								</td>
								<td>
									<span>{t('mail')}</span>
									<span>{user.mail}</span>
								</td>
								<td>
									<span>{t('status')}</span>
									<span>{user.isPending ? t('invitationSent') : t('registered')}</span>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</>
	);
};

export default MobileUsersSettingsTable;
