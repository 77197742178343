import React from 'react';
import {
	Document,
	Page,
	Text,
	View,
	StyleSheet,
	Image,
	Font,
} from '@react-pdf/renderer';
import moment from 'moment';
import { OperationSummary } from 'src/DataTypes/OperationSummary';
import font from 'src/assets/fonts/Rubik-Regular.ttf';
import { Crane } from 'src/DataTypes/Crane';
import { MyProfile } from 'src/DataTypes/MyProfile';
import i18n from 'src/i18n/i18n';
import { Dates } from '../Modals/ReportGeneratorModal/ReportGeneratorModal';

type props = {
	operationSummaryArr: OperationSummary[];
	dailyWorkingHours: number;
	crane: Crane;
	dates: Dates;
};
Font.register({
	family: 'Roboto',
	// src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
	src: font,
});

// Create styles
const styles = StyleSheet.create({
	page: {
		position: 'relative',
		backgroundColor: 'white',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		fontFamily: 'Roboto',
		paddingVertical: 30,
	},
	image: {
		position: 'absolute',
		top: 15,
		right: 15,
		width: '60px',
		height: '60px',
	},
	currentDate: {
		position: 'absolute',
		top: 10,
		left: 10,
		fontSize: '8px',
	},
	header: {
		margin: '50 0 25 0',
		fontWeight: 'bold',
		fontSize: '14px',
		textDecoration: 'underline',
	},
	body: {
		width: '70%',
		display: 'flex',
		flexDirection: 'column',
	},
	description: {
		display: 'flex',
		flexDirection: 'row',
	},
	craneHierarchy: {
		display: 'flex',
		flexDirection: 'column',
		// textAlign: 'left',
		fontSize: '10px',
		color: 'royalblue',
	},
	sortedDates: {
		fontSize: '10px',
		marginLeft: 'auto',
	},
	table: {
		marginVertical: 10,
		flexGrow: 1,
		fontSize: '10px',
		borderRight: '0.5px solid black',
	},
	th: {
		display: 'flex',
		flexDirection: 'row',
		backgroundColor: '#69a2ff',
	},
	tr: { display: 'flex', flexDirection: 'row' },
	td: {
		border: '0.5px solid black',
		borderBottom: 'none',
		borderRight: 'none',
		flexBasis: '20%',
		padding: 2,
		margin: 0,
		fontSize: '10px',
	},
	totalHours: {
		display: 'flex',
		flexDirection: 'row',
		backgroundColor: 'lightgray',
		border: '0.5px solid black',
		fontWeight: 'bold',
		padding: 5,
	},

	dailyActivity: {
		marginTop: 20,
		fontSize: '10px',
	},
});

// Create Document Component
const CraneActivityReport: React.FC<props> = props => {
	return (
		<Document language={i18n.language} title='Crane Activity Report'>
			<Page size='A4' style={styles.page} wrap>
				<View style={styles.currentDate}>
					<Text>{`Generated At: ${moment().format(
						'DD/MM/YYYY HH:mm'
					)}`}</Text>
					<Text>{`Generated By: ${MyProfile.Current.fullName}`}</Text>
				</View>
				<Image
					src='https://i.ibb.co/CK0ytrB/skylinelogo.png'
					style={styles.image}
				/>
				<View style={styles.header}>
					<Text>Crane Activity Report</Text>
				</View>
				<View style={styles.body}>
					<View style={styles.description}>
						<View style={styles.craneHierarchy}>
							<Text>{`Company: ${props.crane.company.name}`}</Text>
							<Text>{`Site: ${props.crane.site.name}`}</Text>
							<Text>{`Crane: ${props.crane.number}`}</Text>
						</View>
						<View style={styles.sortedDates}>
							<Text>{`Dates: ${moment(
								props.dates.startTime
							).format('DD/MM/YYYY')} - ${moment(
								props.dates.endTime
							).format('DD/MM/YYYY')}`}</Text>
						</View>
					</View>

					<View style={styles.table}>
						<View style={styles.th}>
							<Text style={styles.td}>#</Text>
							<Text style={styles.td}>Date</Text>
							<Text style={styles.td}>Total Time</Text>
							<Text style={styles.td}>Daily Activity</Text>
							<Text style={styles.td}>Operator</Text>
						</View>
						{props.operationSummaryArr.length != 0 ? (
							props.operationSummaryArr.map((o, index) => {
								const operatorsNames = [
									...new Set(
										o.intervals.map(
											i => i.operator?.fullName
										)
									),
								];
								return (
									<View style={styles.tr} key={o.id}>
										<Text style={styles.td}>
											{index + 1}
										</Text>
										<Text style={styles.td}>
											{moment(o.date).format('DD/MM/YY')}
										</Text>
										<Text style={styles.td}>
											{o.CalcTotalDailyHours()}
										</Text>
										<Text style={styles.td}>
											{o.CalcDailyPercent(
												props.dailyWorkingHours
											)}
										</Text>
										<Text style={styles.td}>
											{operatorsNames.join(', ')}
										</Text>
									</View>
								);
							})
						) : (
							<View style={styles.tr}>
								<Text>No Data</Text>
							</View>
						)}
						<View style={styles.totalHours}>
							<Text>Total Hours: </Text>
							<Text>
								{OperationSummary.CalcTotalHours(
									props.operationSummaryArr
								)}
							</Text>
						</View>
					</View>
					<View style={styles.dailyActivity}>
						<Text>* Daily Activity: 06:00 - 18:00</Text>
					</View>
				</View>
			</Page>
		</Document>
	);
};

export default CraneActivityReport;
