import React, { useEffect, useState } from 'react';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { Menu, ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';

import { Hierarchy } from '../../DataTypes/Hierarchy';
import { Company } from '../../DataTypes/Company';
import { Site } from '../../DataTypes/Site';

import CompanyDetails from '../Company/CompanyDetails/CompanyDetails';
import DistributionList from '../DistributionList/DistributionList';
import SiteDetails from '../Site/SiteDetails/SiteDetails';
import General from '../General/General';
import CranesStatus from '../CranesStatus/CranesStatus';
import i18n from 'i18next';

type props = {
	item?: Company | Site;
	hierarchy: Hierarchy;
	location: any;
	match: any;
};

const Wrapper: React.FC<props> = props => {
	const [menuItems, setMenuItems] = useState<JSX.Element[]>();
	const [routes, setRoutes] = useState<JSX.Element[]>();
	const { t } = useTranslation();

	const associatedCranesFilter = (item: Company | Site | undefined) => {
		if (item) {
			return item?.cranes.filter(crane => crane.deviceStatus != undefined).length > 0 ? true : false;
		}
		return false;
	};

	const organization = {
		links: [
			<Menu.Item key='general'>
				<Link to='/'>{t('general')}</Link>
			</Menu.Item>,
			<Menu.Item key='cranesStatus'>
				<Link to='/cranes-status'>{t('cranesStatus')}</Link>
			</Menu.Item>,
		],
		routes: [
			<Route exact key='general' path='/' render={() => <General item={props.hierarchy} hierarchy={props.hierarchy} />} />,
			<Route key='cranesStatus' path='/cranes-status' render={() => <CranesStatus item={props.hierarchy} />} />,
		],
	};

	const company = {
		links: [
			<Menu.Item key='general' disabled={!associatedCranesFilter(props.item)}>
				<Link to={`/comp/${props.match.params.id}`}>{t('general')}</Link>
			</Menu.Item>,
			<Menu.Item key='cranesStatus' disabled={props.item?.cranes.length! <= 0 || !associatedCranesFilter(props.item)}>
				<Link to={`${props.match.url}/cranes-status`}>{t('cranesStatus')}</Link>
			</Menu.Item>,
			<Menu.Item key='details'>
				<Link to={`${props.match.url}/details`}>{t('companyDetails')}</Link>
			</Menu.Item>,
			<Menu.Item key='distributionList'>
				<Link to={`${props.match.url}/distribution-list`}>{t('distributionList')}</Link>
			</Menu.Item>,
		],
		routes: [
			<Route exact key='general' path={`/comp/${props.match.params.id}`} render={() => <General hierarchy={props.hierarchy} item={props.item!} />}>
				{!associatedCranesFilter(props.item) && <Redirect to={`${props.match.url}/details`} />}
			</Route>,
			<Route key='cranes-status' path={`${props.match.url}/cranes-status`} render={() => <CranesStatus item={props.item!} />} />,
			<Route key='details' path={`${props.match.url}/details`} render={() => <CompanyDetails item={props.item instanceof Company ? props.item! : undefined} />} />,
			<Route key='distribution-list' path={`${props.match.url}/distribution-list`} render={() => <DistributionList item={props.item!} hierarchy={props.hierarchy} />} />,
		],
	};

	const site = {
		links: [
			<Menu.Item key='general' disabled={!associatedCranesFilter(props.item)}>
				<Link to={`/site/${props.match.params.id}`}>{t('general')}</Link>
			</Menu.Item>,
			<Menu.Item key='cranesStatus' disabled={props.item?.cranes?.length! <= 0 || !associatedCranesFilter(props.item)}>
				<Link to={`${props.match.url}/cranes-status`}>{t('cranesStatus')}</Link>
			</Menu.Item>,
			<Menu.Item key='details'>
				<Link to={`${props.match.url}/details`}>{t('siteDetails')}</Link>
			</Menu.Item>,
			<Menu.Item key='distributionList'>
				<Link to={`${props.match.url}/distribution-list`}>{t('distributionList')}</Link>
			</Menu.Item>,
		],
		routes: [
			<Route exact key='general' path={`/site/${props.match.params.id}`} render={() => <General hierarchy={props.hierarchy} item={props.item!} />}>
				{!associatedCranesFilter(props.item) && <Redirect to={`${props.match.url}/details`} />}
			</Route>,
			<Route key='cranes-status' path={`${props.match.url}/cranes-status`} render={() => <CranesStatus item={props.item!} />} />,
			<Route key='details' path={`${props.match.url}/details`} render={() => <SiteDetails item={props.item instanceof Site ? props.item! : undefined} />} />,
			<Route key='distribution-list' path={`${props.match.url}/distribution-list`} render={() => <DistributionList item={props.item!} hierarchy={props.hierarchy} />} />,
		],
	};

	const checkSelectedKey = pathname => {
		let key = 'general';
		if (pathname.includes('cranes-status')) {
			key = 'cranesStatus';
		} else if (pathname.includes('details')) {
			key = 'details';
		} else if (pathname.includes('distribution-list')) {
			key = 'distributionList';
		}
		return [key];
	};

	useEffect(() => {
		if (props.item == undefined) {
			setMenuItems(organization.links);
			setRoutes(organization.routes);
		} else if (props.item instanceof Company) {
			setMenuItems(company.links);
			setRoutes(company.routes);
		} else {
			setMenuItems(site.links);
			setRoutes(site.routes);
		}
	}, [props.item, props.hierarchy, i18n.language]);

	return (
		<div className='wrapper'>
			{/* <ConfigProvider> */}
			<Menu selectedKeys={checkSelectedKey(props.location.pathname)} mode='horizontal' style={{ background: 'none', border: 'none', marginRight: '0.5rem' }}>
				{menuItems}
			</Menu>
			{/* </ConfigProvider> */}
			<Switch>{routes}</Switch>
		</div>
	);
};

export default Wrapper;
