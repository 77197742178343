import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, ConfigProvider, Tag, Button, Popover, Input, Space } from 'antd';
import { MoreOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import he_IL from 'antd/es/locale/he_IL';
import { Blackbox } from '../../../DataTypes/Blackbox';
import styles from '../DesktopTable.module.scss';
import { showRangeAndTotal } from 'src/services/services';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Hierarchy } from 'src/DataTypes/Hierarchy';
import CranePathBreadcrumb from 'src/components/UI/Breadcrumb/CranePathBreadcrumb';
import { Crane } from 'src/DataTypes/Crane';

type Props = {
	dataSource: Blackbox[];
	assignPopover: any;
	unassignPopover: any;
	removePopover: any;
	hierarchy: Hierarchy;
	setCurrentBlackboxAndLicense: (Blackbox: Blackbox) => void;
};

const BlackboxSettingsTable = (props: Props) => {
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const history = useHistory();
	const { t } = useTranslation();

	const getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					placeholder={`${t('search')}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button type='primary' onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size='small' style={{ width: 90 }}>
						{t('search')}
					</Button>
					<Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
						{t('reset')}
					</Button>
					{/* <Button
						type='link'
						size='small'
						onClick={() => {
							confirm({ closeDropdown: false });
							setSearchText(selectedKeys[0]);
							setSearchedColumn(dataIndex);
						}}>
						Filter
					</Button> */}
				</Space>
			</div>
		),
		filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, marginRight: '1rem', fontSize: '18px' }} />,
		onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),
		render: text =>
			searchedColumn === dataIndex ? (
				<Highlighter highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }} searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''} />
			) : (
				text
			),
	});

	const columns: any = [
		{
			title: t('sn'),
			dataIndex: 'friendlyName',
			key: 'friendlyName',
			width: '15%',
			isUseDefaultSearch: true,
			defaultSortOrder: 'ascend',
			sorter: (a, b) => sortFunction(a.friendlyName, b.friendlyName),
			sortDirections: ['descend', 'ascend'],
			...getColumnSearchProps('friendlyName'),
		},
		{
			title: t('blackboxType'),
			dataIndex: 'type',
			key: 'type',
			width: '15%',
			isUseDefaultSearch: true,
			render: type => (type === 'Full' ? 'SBC' : type === 'Lite' ? 'SBL' : type === 'Feeder' ? 'SBF' : '-'),
			sorter: (a, b) => sortFunction(a.type, b.type),
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: t('version'),
			dataIndex: 'version',
			key: 'version',
			width: '10%',
			render: version => version ?? '-',
			sorter: (a, b) => sortFunction(a.version, b.version),
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: t('associated'),
			dataIndex: ['license', 'blackboxBinding', 'craneBinding'],
			width: '10%',
			render: assigned => (assigned ? <Tag color='blue'>{t('associated')}</Tag> : <Tag color='green'>{t('notAssociated')}</Tag>),
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: t('crane'),
			dataIndex: ['license', 'blackboxBinding', 'craneBinding', 'craneId'],
			width: '20%',
			render: (craneId: string) =>
				craneId ? (
					<Button type='link' size='small' onClick={() => history.push(`/crane/${craneId}`)}>
						<CranePathBreadcrumb crane={props.hierarchy.cranes?.find(c => c.id === craneId)} />
					</Button>
				) : (
					'-'
				),
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: t('license'),
			// dataIndex: ['license', 'id'],
			key: 'license',
			width: '30%',
			sortDirections: ['descend', 'ascend'],
			// render: crane => <div style={{ userSelect: 'text' }}>{crane.license.id}</div>,
			render: blackbox => (
				<CopyToClipboard text={blackbox.license?.id}>
					<div style={{ userSelect: 'text', cursor: 'pointer' }}>{blackbox.license?.id}</div>
				</CopyToClipboard>
			),
		},
		{
			render: blackbox => (
				<Popover
					placement='right'
					trigger='click'
					content={
						blackbox.license?.id && !blackbox.license?.blackboxBinding?.craneBinding
							? props.assignPopover
							: blackbox.license?.id && blackbox.license?.blackboxBinding?.craneBinding
							? props.unassignPopover
							: props.removePopover
					}>
					<MoreOutlined onClick={() => props.setCurrentBlackboxAndLicense(blackbox)} />
				</Popover>
			),
		},
	];

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = clearFilters => {
		clearFilters();
		setSearchText('');
	};

	const sortFunction = (a, b) => {
		let sortA;
		let sortB;

		switch (typeof a) {
			case 'string':
				sortA = a?.toLowerCase();
				sortB = b?.toLowerCase();
				break;

			default:
				sortA = a;
				sortB = b;
				break;
		}

		if (sortA < sortB) {
			return -1;
		}
		if (sortA > sortB) {
			return 1;
		}

		return 0;
	};

	return (
		<ConfigProvider direction={i18n.language == 'he' ? 'rtl' : 'ltr'} locale={i18n.language == 'he' ? he_IL : undefined}>
			<Table className={styles.desktopTable} dataSource={props.dataSource} columns={columns} pagination={{ pageSize: 10, showTotal: showRangeAndTotal }} />
		</ConfigProvider>
	);
};

export default BlackboxSettingsTable;
