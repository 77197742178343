import React, { useState, useCallback, createContext } from 'react';

interface ILoadingModalResponse {
	value: string | null;
	isError: boolean;
}

interface ILoadingModalContent {
	response: ILoadingModalResponse | null;
	show?: boolean;
	toggleShow?: () => void;
	ShowLoadingModal: (promise: Promise<any>, actionTexts: string) => Promise<ILoadingModalResponse>;
	actionTexts?: string;
}

const defaultContext: ILoadingModalContent = {
	response: null,
	ShowLoadingModal: async (p, a) => {
		return { value: '', isError: true };
	},
};

export const LoadingModalContext = createContext<ILoadingModalContent>(defaultContext);

export default function LoadingModalProvider({ children }: { children: any }) {
	const [show, setShow] = useState<boolean>(false);
	const [response, setResponse] = useState<ILoadingModalResponse | null>(null);
	const [action, setAction] = useState<string>();

	const promiseHandler = async (promise: Promise<any>, actionTexts: string) => {
		setResponse(null);
		setAction(actionTexts);
		setShow(true);

		const response: ILoadingModalResponse = {
			value: null,
			isError: false,
		};

		response.value = await promise.catch(reason => {
			response.isError = true;
			return reason;
		});

		if (response.value == null) {
			response.isError = true;
		}

		setResponse(response);
		return response;
	};

	const contextValue: ILoadingModalContent = {
		response: response,
		show: show,
		toggleShow: () => {
			setShow(!show);
		},
		ShowLoadingModal: (promise: Promise<any>, action: string) => promiseHandler(promise, action),
		actionTexts: action,
	};

	return <LoadingModalContext.Provider value={contextValue}>{children}</LoadingModalContext.Provider>;
}
