import React, { useState, useEffect } from 'react';
import styles from './General.module.scss';
import CranesStatusChart from '../Charts/CranesStatusChart/CranesStatusChart';
import CranesWithNotificationsChart from '../Charts/CranesWithNotificationsChart/CranesWithNotificationsChart';
import ControlNotification from '../Notification/ControlNotification/ControlNotification';
import { Container, Row, Col } from 'react-bootstrap';
import { Company } from '../../DataTypes/Company';
import { Hierarchy } from '../../DataTypes/Hierarchy';
import { Site } from '../../DataTypes/Site';
import OperationAverage from '../Charts/OperationAverage/OperationAverage';
import { useTranslation } from 'react-i18next';
import { Crane, ECraneStatus } from '../../DataTypes/Crane';
import CraneNotifications from '../Notification/CraneNotifications/CraneNotifications';
import MapView from '../MapView/MapView';
import cn from 'classnames';

type props = {
	item: Company | Site | Hierarchy;
	hierarchy: Hierarchy;
};

const General: React.FC<props> = props => {
	const { t } = useTranslation();

	/**
	 * Sorts the cranes array according to their status
	 * For CranesStatusChart
	 * @param cranes
	 * @returns number[]
	 */
	const cranesStatusSorter = (cranes: Crane[]): number[] => {
		let active = 0;
		let inactive = 0;
		let fault = 0;
		let inConstruction = 0;
		cranes.forEach(crane => {
			switch (crane.status) {
				case ECraneStatus.Fault:
					fault++;
					break;
				case ECraneStatus.Active:
					active++;
					break;
				case ECraneStatus.Inactive:
					inactive++;
					break;
				case ECraneStatus.InConstruction:
					inConstruction++;
					break;
			}
		});

		let cranesStatusTmpArr = [fault, inactive, active, inConstruction];
		return cranesStatusTmpArr;
	};

	/**
	 * Counts the qty of: total cranes, cranes with notifications
	 * For CranesWithNotificationsChart
	 */
	const countNotifications = (): {
		cranesWithNotifications: number;
		total: number;
	} => {
		//Filter notifications from relevant cranes
		const relevantNotifications =
			props.hierarchy.notificationManager?.notifications.filter(
				n => props.item.cranes.find(c => c.id == n.crane.id) != null
			);
		//Set.size - ensures only unique id values would be counted
		const uniqueCranesWithNotifications = new Set(
			relevantNotifications?.map(n => n.crane.id)
		).size;
		let data = {
			cranesWithNotifications: uniqueCranesWithNotifications,
			total: props.item.cranes.length,
		};

		return data;
	};

	return (
		<div className={styles.wrapperOrg}>
			<Container fluid>
				<Row>
					<Col md={4}>
						<div className={styles.card}>
							<div className={styles.title}>
								{t('cranesNotifications')}
							</div>
							<div className={styles.cardBody}>
								<CranesWithNotificationsChart
									data={countNotifications()}
								/>
							</div>
						</div>
					</Col>
					<Col md={4}>
						<div className={styles.card}>
							<div className={styles.title}>
								{t('activityHours')}
							</div>
							<div className={styles.cardBody}>
								<OperationAverage item={props.item} />
							</div>
						</div>
					</Col>
					<Col md={4}>
						<div className={styles.card}>
							<div className={styles.title}>
								{t('cranesLocations')}
							</div>
							<MapView
								cranes={props.item.cranes.filter(
									c => c.deviceStatus
								)}
							/>
						</div>
					</Col>
					{/* <Col md={4}>
						<div className={styles.card}>
							<div className={styles.title}>{t('cranesStatus')}</div>
							<div className={styles.cardBody}>
								<CranesStatusChart data={cranesStatusSorter(props.item.cranes)} />
							</div>
						</div>
					</Col> */}
				</Row>
				<Row>
					<Col md={12}>
						<div className={styles.card}>
							<div className={styles.notificationsTitle}>
								{t('latestNotifications')}
							</div>

							<CraneNotifications
								pollingInterval={2000}
								cranes={props.item.cranes}
								notificationToItemViewFunc={n => (
									<ControlNotification
										key={n?.id}
										notification={n}
									/>
								)}
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default General;
