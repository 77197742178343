import React, { useState, Fragment, useEffect } from 'react';
import Switch from 'react-ios-switch';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './MiniCraneControl.module.scss';
import MfaModal from '../../Modals/MfaModal/MfaModal';
import { MyProfile } from '../../../DataTypes/MyProfile';
import { ReactComponent as OperatorIcon } from '../../../assets/icons/operator.svg';
import { ReactComponent as WindIcon } from '../../../assets/icons/air.svg';
import { ReactComponent as PowerIcon } from '../../../assets/icons/power.svg';
import { ReactComponent as NightIcon } from '../../../assets/icons/night.svg';
import { ReactComponent as LockIcon } from '../../../assets/icons/lock.svg';
import { Crane } from '../../../DataTypes/Crane';
import CheckButton, { Size } from 'src/components/UI/CheckButton/Checkbutton';

type props = {
	crane: Crane;
};

const MiniCraneControl: React.FC<props> = props => {
	const [showPowerMfa, setShowPowerMfa] = useState<boolean>(false);
	const [showPowerMfaValue, setShowPowerMfaValue] = useState<boolean>(false);
	const [currentUser, setCurrent] = useState<MyProfile>(MyProfile.Current);
	const { t } = useTranslation();

	const SetPower = checked => {
		setShowPowerMfa(true);
		setShowPowerMfaValue(checked);
	};

	const sendTokenFuncPower = async () => {
		const result = await props.crane.SetPower(showPowerMfaValue);
		return result;
	};

	const verifyTokenFuncPower = async token => {
		const result = await props.crane.SetPowerVerify(token);
		return result;
	};

	const handleClose = () => {
		setShowPowerMfa(false);
	};

	return (
		<>
			<MfaModal
				userPhone={currentUser.phone?.number}
				show={showPowerMfa}
				handleClose={handleClose}
				sendTokenFunc={() => sendTokenFuncPower()}
				verifyTokenFunc={t => verifyTokenFuncPower(t)}
			/>
			<div
				className={cn(
					styles.notificationCard,
					!props.crane.deviceStatus?.isConnected
						? styles.disconnected
						: props.crane.deviceStatus?.isPowered
						? styles.isPowered
						: styles.connected
				)}>
				<div className={styles.rightSectionControl}>
					<div className={styles.crane}>{`${t('crane')} ${
						props.crane.number
					}`}</div>
					<div className={styles.sensor}>
						<OperatorIcon
							className={cn(
								styles.icons,
								!props.crane.deviceStatus?.recognition
									?.status && styles.inactive
							)}
						/>
						<span>
							{props.crane.deviceStatus?.recognition?.isStatusOk()
								? t('operatorInCabin')
								: t('noOperatorInCabin')}
						</span>
					</div>
					<div className={styles.sensor}>
						<WindIcon className={styles.icons} />
						<span>{`${
							props.crane.deviceStatus
								?.GetAnalogInputValueInUnits('Wind')
								?.toFixed(0) ?? t('unknown')
						} ${t('km/h')}`}</span>
					</div>
				</div>

				<div className={styles.leftSectionControl}>
					<div className={styles.notificationSwitchButton}>
						<CheckButton
							icon={PowerIcon}
							checked={
								props.crane.deviceStatus?.isPowered! &&
								props.crane.deviceStatus.isConnected
							}
							disabled={
								!currentUser?.permissions.cranePowerCRUD
									.update ||
								props.crane.deviceId === null ||
								!props.crane.deviceStatus?.isConnected
							}
							size={Size.sm}
							onClick={() =>
								SetPower(!props.crane.deviceStatus?.isPowered)
							}
						/>
					</div>
					<div className={styles.notificationSwitchButton}>
						<CheckButton
							icon={NightIcon}
							checked={
								props.crane.deviceStatus?.isReleased! &&
								props.crane.deviceStatus.isConnected
							}
							disabled={
								props.crane.deviceStatus?.isReleased ||
								!currentUser?.permissions.nightReleaseCRUD
									.update ||
								props.crane.deviceId === null ||
								!props.crane.deviceStatus?.isConnected
							}
							size={Size.sm}
						/>
					</div>
					<div
						className={styles.notificationSwitchButton}
						hidden={!currentUser?.permissions.craneLockCRUD.read}>
						<CheckButton
							icon={LockIcon}
							checked={props.crane.deviceStatus?.isLocked!}
							disabled={
								!currentUser?.permissions.craneLockCRUD
									.update ||
								props.crane.deviceId === null ||
								!props.crane.deviceStatus?.isConnected
							}
							size={Size.sm}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default MiniCraneControl;
