import React, { useContext } from 'react';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { Modal, Button } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styles from '../AddContactModal/AddContactModal.module.scss';
import { Crane, ECraneType, ECraneStatus, ICraneUpdateDetails } from '../../../DataTypes/Crane';
import RTL from '../../RTL/RTL';
import { useTranslation } from 'react-i18next';

type props = {
	crane: Crane;
	showEditModalHandler: any;
	show: boolean;
};

type inputs = {
	number: string;
	model: string;
	type: ECraneType;
	status: ECraneStatus;
	manufacturer: string;
	height: number;
	length: number;
};

const CraneEditDetailsModal: React.FC<props> = props => {
	const { ShowLoadingModal } = useContext(LoadingModalContext);
	const { t } = useTranslation();

	const validationSchema = yup.object({
		number: yup.string(),
		model: yup.string(),
		type: yup.string().oneOf(Object.values(ECraneType)).required(t('requiredField')),
		status: yup.string().oneOf(Object.values(ECraneStatus)).required(t('requiredField')),
		manufacturer: yup.string().required(t('requiredField')),
		height: yup.number().typeError(t('enterNumberInMeters')).min(0, t('enterNumberEqualOrGreaterThanZero')).required(t('requiredField')),
		length: yup.number().typeError(t('enterNumberInMeters')).min(0, t('enterNumberEqualOrGreaterThanZero')).required(t('requiredField')),
	});

	const formik = useFormik({
		initialValues: {
			number: props.crane.number,
			model: props.crane.model,
			type: props.crane.type,
			status: props.crane.status,
			manufacturer: props.crane.manufacturer,
			height: props.crane.height,
			length: props.crane.length,
		},
		validationSchema: validationSchema,
		onSubmit: values => {
			const details: ICraneUpdateDetails = {
				number: values.number,
				model: values.model,
				type: values.type,
				status: values.status,
				manufacturer: values.manufacturer,
				height: values.height,
				length: values.length,
			};

			handleSubmit(details);
		},
	});

	const handleSubmit = (details: ICraneUpdateDetails) => {
		props.showEditModalHandler();
		ShowLoadingModal(props.crane.UpdateDetails(details), 'CraneUpdate');
	};

	return (
		<RTL>
			<Modal size='sm' className={styles.modal} aria-labelledby='contained-modal-title-vcenter' show={props.show} centered onHide={props.showEditModalHandler}>
				<Modal.Header style={{ border: 'none' }} closeButton />
				<div className={styles.title}>{t('updateCraneDetails')}</div>
				<Modal.Body className={styles.modalBody}>
					<form className={styles.formi} onSubmit={formik.handleSubmit}>
						{/* <TextField
							id='number'
							label={dict.texts.craneNumber}
							value={formik.values.number}
							helperText={formik.touched.number ? formik.errors.number : ''}
							error={formik.touched.number && Boolean(formik.errors.number)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth
						/> */}
						<TextField
							id='model'
							label={t('model')}
							value={formik.values.model}
							helperText={formik.touched.model ? formik.errors.model : ''}
							error={formik.touched.model && Boolean(formik.errors.model)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth
						/>
						<TextField
							id='type'
							label={t('type')}
							value={formik.values.type}
							select
							SelectProps={{
								native: true,
							}}
							helperText={formik.touched.type ? formik.errors.type : ''}
							error={formik.touched.type && Boolean(formik.errors.type)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth>
							<option value='' disabled />
							{Object.values(ECraneType).map(value => (
								<option id={value} key={value} value={value}>
									{t(value.toLowerCase())}
								</option>
							))}
						</TextField>
						<TextField
							id='status'
							label={t('status')}
							value={formik.values.status}
							select
							SelectProps={{
								native: true,
							}}
							helperText={formik.touched.status ? formik.errors.status : ''}
							error={formik.touched.status && Boolean(formik.errors.status)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth>
							<option value='' disabled />
							{Object.values(ECraneStatus).map(value => (
								<option id={value} key={value} value={value}>
									{t(value.toLowerCase())}
								</option>
							))}
						</TextField>
						<TextField
							id='manufacturer'
							label={t('manufacturer')}
							value={formik.values.manufacturer}
							helperText={formik.touched.manufacturer ? formik.errors.manufacturer : ''}
							error={formik.touched.manufacturer && Boolean(formik.errors.manufacturer)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth
						/>
						<TextField
							id='height'
							label={t('height')}
							value={formik.values.height}
							helperText={formik.touched.height ? formik.errors.height : ''}
							error={formik.touched.height && Boolean(formik.errors.height)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth
						/>
						<TextField
							id='length'
							label={t('length')}
							value={formik.values.length}
							helperText={formik.touched.length ? formik.errors.length : ''}
							error={formik.touched.length && Boolean(formik.errors.length)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth
						/>
						<div className={styles.formButtons}>
							<Button type='submit' variant='outline-primary' className={styles.button}>
								{t('update')}
							</Button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</RTL>
	);
};

export default CraneEditDetailsModal;
