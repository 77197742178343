import React, { Suspense, useEffect, useState } from 'react';
import { Canvas, useLoader } from '@react-three/fiber';
import {
	Environment,
	OrbitControls,
	Html,
	useProgress,
} from '@react-three/drei';
import styles from './Activity3d.module.scss';
import Model from './Model';
import { Slider, Progress, DatePicker, Empty, Spin, TimePicker } from 'antd';
import { Crane } from 'src/DataTypes/Crane';
import Counter from 'src/components/UI/Counter/Counter';
import { useTranslation } from 'react-i18next';
import { ActivitySnapshot } from 'src/DataTypes/ActivitySnapshot';
import moment from 'moment';
import Spinner from 'src/components/UI/Spinner/Spinner';
import { SliderMarks } from 'antd/lib/slider';
import { LoadingOutlined } from '@ant-design/icons';

const SEGMENT_HEIGHT: number = 6; //In meters

type props = {
	crane: Crane;
};

const Activity3d: React.FC<props> = props => {
	const [turnInputValue, setTurnInputValue] = useState(0);
	const [cartInputValue, setCartInputValue] = useState(0);
	const [hookInputValue, setHookInputValue] = useState(0);
	const [maxHook, setMaxHook] = useState(0);
	const [date, setDate] = useState<moment.Moment | null>();
	const [startTime, setStartTime] = useState<moment.Moment | null>();
	const [endTime, setEndTime] = useState<moment.Moment | null>();
	const [fourCables, setCablesAmount] = useState<boolean>(true);
	const [offset, setOffset] = useState({ x: 0, y: 0, z: 0 });
	const { t } = useTranslation();
	const [data, setData] = useState<ActivitySnapshot[]>([]);
	const [hours, setHours] = useState<string[]>([]);
	const [marks, setMarks] = useState<SliderMarks>({});
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [currentValue, setCurrentValue] = useState<number>(0);

	//Count tower segments
	const towerSegmentsCounter = () => {
		const segmentHeight: number = 6;
		const count = Math.round(
			(props.crane.height / props.crane.length) * SEGMENT_HEIGHT
		);

		//TODO
		setMaxHook(count * 210.81);

		return count;
	};

	const formatter = value => {
		return hours[value];
	};

	const Loader = () => {
		const { progress } = useProgress();
		return (
			<Html center>
				<Progress type='circle' percent={Math.round(progress)} />
			</Html>
		);
	};

	const buildData = (data: ActivitySnapshot[]) => {
		setData(data.map(s => new ActivitySnapshot(s)));

		let marksArr: string[] = [];
		for (let i = 0; i < data.length; i++) {
			if (i > 0 && i < data.length - 1) {
				marksArr[i] = '';
			} else {
				marksArr[i] = moment(data[i].time).format('HH:mm:ss');
			}
			// marks[i] = moment(data[i].time).format('HH:mm:ss');
		}
		setMarks(marksArr);

		setHours(data.map(i => moment(i.time).format('HH:mm:ss')));
	};

	const filterAndSortData = (data: ActivitySnapshot[]) => {
		// let filteredData = data.filter(a => {
		// 	const activitySnapshotDate = moment(a.time);
		// 	return (
		// 		activitySnapshotDate.isAfter(startTime) &&
		// 		activitySnapshotDate.isBefore(endTime)
		// 	);
		// });
		// buildData(filteredData);
		const sortedData = data.sort((a, b) =>
			moment(a.time).isBefore(moment(b.time)) ? -1 : 1
		);
		buildData(sortedData);
	};

	useEffect(() => {
		async function fetchActivity() {
			setIsLoading(true);
			if (!date) {
				setData([]);
			} else {
				await props.crane.getActivitySummary(date?.toISOString()).then(
					res => {
						filterAndSortData(res.data);
					},
					rej => {
						setData([]);
					}
				);
			}
			setIsLoading(false);
		}

		fetchActivity();
	}, [date]);

	const slideHandler = value => {
		setCurrentValue(value);
		setTurnInputValue(
			data[value]?.analogInputs.find(a => a.name == 'Turn')
				?.valueInUnits! / 360 ?? 0
		);
		setCartInputValue(
			data[value]?.analogInputs.find(a => a.name == 'Cart')
				?.valueInUnits! / props.crane.length ?? 0
		);
		setHookInputValue(
			data[value]?.analogInputs.find(a => a.name == 'Lift')
				?.valueInUnits! / props.crane.height ?? 0
		);
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.date}>
				<div className={styles.title}>Select Date:</div>
				<DatePicker onChange={date => setDate(date)} />
				{/* {date && (
					<TimePicker.RangePicker
						value={[
							startTime ?? date.startOf('day'),
							endTime ?? date.startOf('day'),
						]}
						showSecond={false}
						onChange={(values: any) => {
							console.log(values);
							//FIXME
							setStartTime(values[0]);
							setEndTime(values[1]);
						}}
					/>
				)} */}
			</div>
			{isLoading ? (
				<Spin
					indicator={
						<LoadingOutlined style={{ fontSize: 48 }} spin />
					}
					spinning
					tip='Loading 3D View...'
				/>
			) : data.length > 0 ? (
				<>
					<Slider
						className={styles.slider}
						tipFormatter={formatter}
						marks={marks}
						min={0}
						max={hours.length - 1}
						defaultValue={0}
						tooltipVisible={true}
						onChange={slideHandler}
					/>

					<div className={styles.view3d}>
						<div className={styles.axis}>
							<Counter
								type={t('lift')}
								value={Math.round(
									data[currentValue]?.analogInputs.find(
										a => a.name == 'Lift'
									)?.valueInUnits ?? 0
								)}
								units={t('meters')}
							/>
							<Counter
								type={t('trolley')}
								value={Math.round(
									data[currentValue]?.analogInputs.find(
										a => a.name == 'Cart'
									)?.valueInUnits ?? 0
								)}
								units={t('meters')}
							/>
							<Counter
								type={t('turn')}
								value={Math.round(
									data[currentValue]?.analogInputs.find(
										a => a.name == 'Turn'
									)?.valueInUnits ?? 0
								)}
								units={t('degrees')}
							/>
						</div>
						<div className={styles.sensors}>
							<Counter
								type={t('wind')}
								value={Math.round(
									data[currentValue]?.analogInputs.find(
										a => a.name == 'Wind'
									)?.valueInUnits ?? 0
								)}
								units={t('Km/h')}
							/>
							<Counter
								type={t('weight')}
								value={Math.round(
									data[currentValue]?.analogInputs.find(
										a => a.name == 'Weight'
									)?.valueInUnits ?? 0
								)}
								units={t('Kg')}
							/>
						</div>
						<Canvas
							camera={{
								fov: 65,
								position: [-2000, 1000, 1000],
								near: 0.1,
								far: 5000,
							}}
							style={{ borderRadius: '5px', height: '50vh' }}>
							<Suspense fallback={<Loader />}>
								<Model
									cart={1280 * cartInputValue + 170}
									turn={turnInputValue * 6.283}
									hook={1400 * hookInputValue - 200}
									fourCables={fourCables}
									offset={offset}
									towerSegmentsCounter={towerSegmentsCounter}
								/>
								<OrbitControls
									addEventListener={undefined}
									hasEventListener={undefined}
									removeEventListener={undefined}
									dispatchEvent={undefined}
								/>
								{/* <Environment preset='park' background /> */}
								<ambientLight intensity={0.6} />
								<directionalLight
									intensity={1}
									position={[0, 2, 2]}
									shadow-mapSize-width={2048}
									shadow-mapSize-height={2048}
									castShadow
								/>
							</Suspense>
						</Canvas>
					</div>
				</>
			) : (
				<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
			)}
		</div>
	);
};

export default Activity3d;
