import { Crane } from '../DataTypes/Crane';
import { OperationSummary } from '../DataTypes/OperationSummary';
import { useTranslation } from 'react-i18next';
import i18n from 'src/i18n/i18n';
import moment from 'moment';

/**
 * convert UTC date to string
 * **/
export function DateToString(date: Date | undefined): string {
	if (!date) {
		return '';
	}
	return `${date.getHours()}:${
		date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
	}   ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear() - 2000}`;
}

/**
 * convert UTC date to {HH:MM} string
 *  @param {Date} date *
 *  @returns {string} **/
export function DateToHourMinutes(date) {
	if (!date) {
		return '';
	}
	return `${date.getHours()}:${
		date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
	}`;
}

/**
 * convert UTC date to {DD/MM/YYYY} string
 *  @param {Date} date *
 *  @returns {string} **/
export function DateToDayMonthYear(date) {
	if (!date) {
		return '';
	}
	return `${date.getDate()}/${date.getMonth() + 1}/${
		date.getFullYear() - 2000
	}`;
}

/**
 * calculating hours between two dates
 * @param {Date} startTime
 * @param {Date} endTime
 * @returns {number}
 */
export function CraneActivityTimeCalc(startTime, endTime) {
	let res = (endTime.getTime() - startTime.getTime()) / (1000 * 60);
	if (res < 60) {
		return res.toFixed(0) + ' דקות ';
	} else return (res / 60).toFixed(1) + ' שעות ';
}

/**
 * filtering DeviceIds from crane array
 * @param {Crane[]} cranes
 * @return {string[]}
 */
export function ReturnCranesDeviceIds(cranes) {
	return cranes.filter(c => c.deviceId != null).map(c => c.deviceId);
}

/**
 * return months array with operation summary of each month
 * @param {OperationSummary[]} arr
 * @return {number[]}
 */
export function hoursPerMonthCalc(arr) {
	let hoursPerMonth: number[] = [];

	for (let i = 0; i < 12; i++) {
		let hours = 0;

		arr?.filter(x => x.date.getMonth() == i).forEach(e => {
			hours += e.SecondsOfOperation / 3600;
		});

		hoursPerMonth.push(hours);
	}
	return hoursPerMonth;
}

/**
 * return days array with operation summary of each day
 * @param {OperationSummary[]} arr
 * @return {number[]}
 */
export function hoursPerDayCalc(arr) {
	let hoursPerDay: number[] = [];

	for (let i = 0; i < 7; i++) {
		let hours = 0;

		let tmp = arr?.filter(x => x.createdTime.getDay() === i);
		tmp.forEach(e => {
			hours += e.SecondsOfOperation / 3600;
		});

		hoursPerDay.push(hours);
	}
	return hoursPerDay;
}

/**
 * converting number to direction string
 * @param {number} direction
 */
export function Compass(direction) {
	while (direction >= 360) direction -= 360;
	Math.abs(direction);
	if (direction == 0) return ' צפון ';
	else if (direction > 0 && direction < 90) return ' צפון-מזרח ';
	else if (direction == 90) return ' מזרח ';
	else if (direction > 90 && direction < 180) return ' דרום-מזרח ';
	else if (direction == 180) return ' דרום ';
	else if (direction > 180 && direction < 270) return ' דרום-מערב ';
	else if (direction == 270) return ' מערב ';
	else if (direction > 270 && direction < 360) return ' צפון-מערב ';
}

export function getWelcomeText(userName) {
	const { t } = useTranslation();
	const hour = new Date().getHours();
	let welcomeText = '';

	if (hour >= 21 || hour < 6) {
		welcomeText = t('goodNight');
	} else if (hour >= 6 && hour < 12) {
		welcomeText = t('goodMorning');
	} else if (hour >= 12 && hour < 16) {
		welcomeText = t('goodNoon');
	} else if (hour >= 16 && hour < 18) {
		welcomeText = t('goodAfternoon');
	} else if (hour >= 18 && hour <= 20) {
		welcomeText = t('goodEvening');
	} else {
		welcomeText = t('hello');
	}

	return `${welcomeText},  ${userName}`;
}

/**
 * @returns array with unique values
 */
export const onlyUnique = (value, index, self) => {
	return self.indexOf(value) === index;
};

/**
 * @returns array with unique values
 */
export function onlyUniqueBy<T>(array: T[]): T[] {
	return array.filter((v, i) => array.indexOf(v) === i);
}

/**
 * Pagination function
 * @returns current items range from the total items string
 */
export const showRangeAndTotal = (total, range) => {
	switch (i18n.language) {
		case 'he':
			return `${range[0]}-${range[1]} מתוך ${total} פריטים`;
		default:
			return `${range[0]}-${range[1]} from ${total} items`;
	}
};

/**
 * Disabling the unrelevant dates in DatePicker
 * @returns boolean
 */
export const disabledDate = current => {
	return current && current > moment().endOf('day').subtract(18, 'years');
};
