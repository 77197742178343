import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styles from '../AddContactModal/AddContactModal.module.scss';
import { ISiteUpdateDetails, Site } from '../../../DataTypes/Site';
import RTL from '../../RTL/RTL';
import { useTranslation } from 'react-i18next';

type props = {
	show: boolean;
	handleSubmit: (details: ISiteUpdateDetails) => void;
	handleClose: () => void;
	site: Site;
};

const phoneRegExp: RegExp = /^05\d{8}$/;

const SiteDetailsUpdateModal: React.FC<props> = props => {
	const { t } = useTranslation();

	const validationSchema = yup.object({
		name: yup.string().required(t('requiredField')),
		address: yup.string().required(t('requiredField')),
		managerName: yup.string().required(t('requiredField')),
		managerPhone: yup.string().matches(phoneRegExp, t('enterValidValue')).required(t('requiredField')),
	});
	const formik = useFormik({
		initialValues: {
			name: props.site.name,
			address: props.site.address,
			managerName: props.site.manager.name,
			managerPhone: props.site.manager.phone,
		},
		validationSchema: validationSchema,
		onSubmit: values => {
			const details: ISiteUpdateDetails = {
				name: values.name,
				address: values.address,
				manager: {
					name: values.managerName,
					phone: values.managerPhone,
				},
			};
			props.handleSubmit(details);
		},
	});

	return (
		<RTL>
			<Modal size='sm' className={styles.modal} aria-labelledby='contained-modal-title-vcenter' show={props.show} centered onHide={props.handleClose}>
				<Modal.Header style={{ border: 'none' }} closeButton />
				<div className={styles.title}>{t('updateSiteDetails')}</div>
				<Modal.Body className={styles.modalBody}>
					<form className={styles.formi} onSubmit={formik.handleSubmit}>
						<TextField id='name' label={t('company')} value={props.site.company.name} margin='dense' disabled fullWidth />
						<TextField
							id='name'
							label={t('name')}
							value={formik.values.name}
							helperText={formik.touched.name ? formik.errors.name : ''}
							error={formik.touched.name && Boolean(formik.errors.name)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth
						/>
						<TextField
							id='address'
							label={t('officeAddress')}
							value={formik.values.address}
							helperText={formik.touched.address ? formik.errors.address : ''}
							error={formik.touched.address && Boolean(formik.errors.address)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth
						/>
						<TextField
							id='managerName'
							label={t('contact')}
							value={formik.values.managerName}
							helperText={formik.touched.managerName ? formik.errors.managerName : ''}
							error={formik.touched.managerName && Boolean(formik.errors.managerName)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth
						/>
						<TextField
							id='managerPhone'
							label={t('phone')}
							value={formik.values.managerPhone}
							helperText={formik.touched.managerPhone ? formik.errors.managerPhone : ''}
							error={formik.touched.managerPhone && Boolean(formik.errors.managerPhone)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth
						/>
						<div className={styles.formButtons}>
							<Button type='submit' variant='outline-primary' className={styles.button}>
								{t('update')}
							</Button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</RTL>
	);
};

export default SiteDetailsUpdateModal;
