import React, { useState } from 'react';
import styles from './CraneDetailModal.module.scss';
import { Modal } from 'react-bootstrap';
import { Crane } from '../../../DataTypes/Crane';
import { useTranslation } from 'react-i18next';

type props = {
	show: boolean;
	closeHandler: () => void;
	crane: Crane;
};

const CraneDetailModal: React.FC<props> = props => {
	const { t } = useTranslation();

	return (
		<Modal className={styles.modal} aria-labelledby='contained-modal-title-vcenter' show={props.show} centered onHide={props.closeHandler}>
			<Modal.Header style={{ border: 'none' }} closeButton />

			<Modal.Body>
				<div className={styles.title}>{t('moreDetails')}</div>
				<div className={styles.detailsParagraph}>
					<br></br>
					<div>
						<span className={styles.detailsKey}>{t('company')}</span>
						<span className={styles.detailsValue}>{props.crane.company.name}</span>
					</div>
					<div>
						<span className={styles.detailsKey}>{t('site')}</span>
						<span className={styles.detailsValue}>{props.crane.site.name}</span>
					</div>
					<div>
						<span className={styles.detailsKey}>{t('craneNumber')}</span>
						<span className={styles.detailsValue}>{props.crane.number}</span>
					</div>
					<div className={styles.crane}>
						<div>
							<span className={styles.detailsKey}>{t('siteAddress')}</span>
							<span className={styles.detailsValue}>{props.crane.site.address}</span>
						</div>
						<div>
							<span className={styles.detailsKey}>{t('siteManager')}</span>
							<span className={styles.detailsValue}>{props.crane.site.manager.name}</span>
						</div>
						<div>
							<span className={styles.detailsKey}>{t('phone')}</span>
							<span className={styles.detailsValue}>{props.crane.site.manager.phone}</span>
						</div>
						<hr></hr>

						<div>
							<span className={styles.detailsKey}>{t('licenseNumber')}</span>
							<span className={styles.detailsValue}>{props.crane.sn}</span>
						</div>
						<div>
							<span className={styles.detailsKey}>{t('craneType')}</span>
							<span className={styles.detailsValue}>{t(props.crane.type.toLowerCase())}</span>
						</div>
						<div>
							<span className={styles.detailsKey}>{t('status')}</span>
							<span className={styles.detailsValue}>{t(props.crane.GetStatus())}</span>
						</div>
						<div>
							<span className={styles.detailsKey}>{t('model')}</span>
							<span className={styles.detailsValue}>{props.crane.model}</span>
						</div>
						<div>
							<span className={styles.detailsKey}>{t('manufacturer')}</span>
							<span className={styles.detailsValue}>{props.crane.manufacturer}</span>
						</div>
						<div>
							<span className={styles.detailsKey}>{t('craneHeight')}</span>
							<span className={styles.detailsValue}>{`${props.crane.height}  ${t('meter')}`}</span>
						</div>
						<div>
							<span className={styles.detailsKey}>{t('jibLength')}</span>
							<span className={styles.detailsValue}>{`${props.crane.length}  ${t('meter')}`}</span>
						</div>
						{/* <div>
							<span className={styles.detailsKey}>גובל כבל</span>
							מ-
							<span className={styles.detailsValue}>{value}</span>
							עד-
							<span className={styles.detailsValue}>{value}</span>
							מטר
						</div>
						<div>
							<span className={styles.detailsKey}>גובל סיבוב</span>
							מ-
							<span className={styles.detailsValue}>{value}</span>
							עד-
							<span className={styles.detailsValue}>{value}</span>
							מעלות
						</div>
						<div>
							<span className={styles.detailsKey}>גובל עגלה</span>
							מ-
							<span className={styles.detailsValue}>{value}</span>
							עד-
							<span className={styles.detailsValue}>{value}</span>
							מטר
						</div> */}
						<hr></hr>
						<div>
							<span className={styles.detailsKey}>{t('blackbox')}</span>
							<span className={styles.detailsValue}>{props.crane.blackboxId ?? t('notAssociated')}</span>
						</div>
						{/* <div>
							<span className={styles.detailsKey}>טסט אחרון</span>
							<span className={styles.detailsValue}>{value}</span>
						</div>
						<div>
							<span className={styles.detailsKey}>טסט הבא</span>
							<span className={styles.detailsValue}>{value}</span>
						</div> */}
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default CraneDetailModal;
