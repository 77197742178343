import React from 'react';
import styles from './Registration.module.scss';

const Eula = (
	<div className={styles.eula}>
		<h3>IOT DASHBOARD FOR CRANES END USER LICENSE AGREEMENT</h3>
		This software end user license agreement (this <strong>“EULA”</strong>) is an agreement between you (the “Licensee”) and Sky-Line Ltd. (<strong>“Skyline”</strong>) which sets forth the terms
		of the license granted by Skyline to the Licensee as to the Software (as defined below).
		<br />
		<strong>
			This EULA provides a license to use the Software and contains warranty information and liability disclaimers.
			<br /> Read this EULA carefully before using the Software.
			<br /> By clicking "I agree", you are confirming your acceptance of this license to use the Software and agreeing to become bound by the terms of this EULA.
			<br /> If you do not agree to the terms of this EULA, do not use the Software.
		</strong>
		The Software is owned and remains the property of Skyline or its third party licensors, is protected by copyright laws and international copyright treaties, as well as other intellectual
		property laws and treaties. The Software is licensed, not sold, solely to the Licensee, for the Permitted Use (as defined below) and only on the terms and conditions set forth below. Certain
		terms of this EULA do not apply to the Software if you are using it as part of a testing or "Beta" program with regard to which you have not paid consideration (a "Beta Program"). Each such
		term will be indicated below. The term "Software", means any software component provided to you by Skyline, any of its related entities, or any of its distributors, resellers or other
		authorized representatives and any copies of it, including any enhancements, derivative works, translations, modifications, updates, releases, versions, Third Party Components or other changes
		to such software programs, Documentation, sample files, extension files, or other tools and utilities which are provided under this EULA. In some cases, the Software may be composed of both a
		“client” component that is downloaded from a website and runs on your computers or cellphones (the “Client Component”) and a “server” component that is installed on your servers and that your
		computer or cellphone will access through the Internet or over an internal network (the “Server Component”). Any reference to installation of the Software in this EULA and any license related
		to such installation refers to both the Client Component and the Server Component. The term “Documentation” means any instructions or other materials (including online documentation) provided
		with the Software or on any website through which you may access it. The term “Third Party Components” means components of the Software, whether in the form of a runtime version or object
		code, provided by third party vendors to Skyline for inclusion as an embedded component of the Software object code or utilities.
		<br />
		<strong>LICENSE</strong>
		<br />
		Grant of License. Subject to the terms herein, Skyline grants the Licensee a non-exclusive, non-transferable, perpetual (except in the event of termination of this EULA) license to the
		Software solely for the Permitted Use, according to the instructions in the Documentation, and in strict compliance with this EULA.
		<br />
		License Fee. The license is conditional upon timely payment of agreed license fees (the “License Fee”). If for any reason the payment of the License Fee is invalidated, the license will be
		terminated immediately, and the Licensee will not be entitled to use the Software. This paragraph will not apply to use of the Software in the context of a Beta Program.
		<br />
		Permitted Use. The Licensee is permitted hereunder to use all capabilities of the Software subject to the terms and conditions contained herein and subject to any limitations as to number of
		installations, number of cranes covered, or other agreed limitations. The Software may be provided with internal or external controls that preclude use except within such agreed limitations.
		Notwithstanding the above, the Licensee <strong>may not:</strong>
		<ul>
			<li>
				Use the Software for commercial time-sharing, rental/service bureau use or for treatment of any person other than the user whose name was supplied as the user during the registration
				process;
			</li>
			<li>Create a derivative work of the Software;</li>
			<li>Use Third Party Components included in the Software as a separate, stand-alone product.</li>
		</ul>
		<br />
		Modification. Only Skyline is allowed to alter, maintain, enhance or otherwise modify the Software
		<br />
		Reverse Engineering. The Licensee will not cause, nor permit either through its direct efforts or through any third party, the disassembly, de-compilation, or reverse engineering of the
		Software. The Licensee will not attempt to create or permit others to attempt to create, by reverse compiling or disassembling or otherwise, any part of the source code of the Software from
		the object code or from other information made available to the Licensee. The Licensee will make no attempt to gain access to the object code or the source code of the Software.
		<br />
		Material Terms and Conditions. The Licensee specifically agrees that, without affecting the materiality of any other provision of this EULA, each of the terms and conditions of this section
		are material and that failure of the Licensee to comply with these terms and conditions will constitute sufficient cause for Skyline to immediately terminate this EULA, without waivering any
		of its other legal rights, or right to any other remedy.
		<br />
		<br />
		<h3>DECLARATION OF DATA OWNERSHIP AND PRIVACY</h3>
		Data Collection. The Licensee acknowledges and agrees that the Software includes a function which collects certain data resulting from the use of the Software, including information about the
		types and places of use, the extent to which a facility or equipment of the Licensee's is put to use, use patterns and other data driving from the use or performance of the Software, the
		Licensee's facilities and the Licensee's equipment (“Data”). That function will transmit the Data to Skyline or allow Skyline to collect it.
		<br />
		Skyline Ownership of Data. The Data will be Skyline’s exclusive property. Skyline may use the Data for any purpose, publish it and make it available to other researchers, regulatory agencies
		or others. Skyline will not be obligated to make any payment of any sort to the Licensee relating to such use or publication.
		<br />
		Non-Release of Identifying Information. Skyline will not release Data to third parties that contains identifying information that could associate the Licensee with the Data or identify
		facilities or equipment as belonging to the Licensee, unless the Licensee agrees in writing or unless Skyline is required to do so by law or court order. Skyline may also disclose Data that
		contains identifying information to third parties in the following circumstances:
		<br />
		1. in the event of a dispute, disagreement or any legal or quasi legal proceeding between the Licensee and Skyline; 2. in the event that Skyline believes that disclosure may prevent harm to
		the Licensee or its property or to any third party and its property;
		<br />
		In the event that all or some of the activities of Skyline are transferred to another corporation or due diligence is being conducted on Skyline in anticipation of a possible transfer, Skyline
		will be permitted to provide all the information in its possession to the corporation that will acquire Skyline’s activities or that is performing the due diligence, provided that the
		corporation accepts upon itself the provisions of privacy contained here.
		<br />
		The above reservation of rights by Skyline will not impose upon Skyline an obligation to report or provide information to any party, and no person will have any claim or suit against Skyline
		flowing from the transfer or provision of information or the non-transfer or non- provision of the aforesaid information.
		<br />
		<br />
		<h3>TITLE TO SOFTWARE AND PROPRIETY RIGHTS</h3>
		Ownership of the Software. The Software is proprietary to Skyline and title and remains vested with Skyline. All applicable rights to patents, copyrights, trademarks, trade secrets, moral
		rights and any other intellectual property rights in and to the Software belong to and will remain the sole property of Skyline.
		<br />
		The Licensee acknowledges that the license granted under this EULA: (i) does not provide the Licensee with title to or ownership of the Software and (ii) that the license granted under this
		EULA provides the Licensee only with a right of limited use under the terms and conditions of this EULA. All rights not expressively granted to the Licensee are reserved and retained by
		Skyline.
		<br />
		Trademarks: The Licensee acquires no rights of any sort to any Skyline trademarks, tradenames, logos or other markings. In no event will the Licensee remove Skyline’s logos, trademarks,
		copyright or patent symbol or any other Skyline or third party proprietary markings or proprietary legends placed upon or contained within the Software or automatically included with its
		output.
		<br />
		Material Terms and Conditions. The Licensee specifically agrees that, without affecting the materiality of any other provision of this EULA, each of the terms and conditions of this section
		are material and that failure of the Licensee to comply with these terms and conditions will constitute sufficient cause for Skyline to immediately terminate this EULA and the license granted
		under this EULA without waivering any of its other legal rights, or right to any other remedy.
		<br />
		<br />
		<h3>THIRD PARTY SOFTWARE COMPONENTS</h3>
		The Software may include certain software or software components which may have been developed, and the copyright to which may be held, by third parties ("Third Party Software Components"),
		and which may have been made available to the public according to certain terms and conditions (Third Party Licenses).
		<br />
		Therefore, this EULA will apply to Third Party Software Components only to the extent permitted by the respective Third Party Licenses. In any event of a conflict or contradiction between this
		EULA and the provisions of a Third Party License, the provisions of the respective Third Party License will prevail over the provisions of this EULA with regard to the respective Third Party
		Software Component.
		<br />
		<br />
		<h3>USE OF THE SOFTWARE</h3>
		Use According to Documentation. The Licensee acknowledges that, the Software must be used solely as permitted under this EULA and according to the instructions in the Documentation. Any
		attempt to use the Software in other ways may result in poor results or side effects. Minimum Requirements. System requirements detailing the minimum software and hardware requirements for the
		proper installation and use of the Software are contained in the Documentation. The Licensee warrants and represents that it has met the minimum software and hardware requirements.
		<br />
		<br />
		<h3>NO WARRANTIES</h3>
		No Uninterrupted or Error-Free Operation. Skyline does not warrant that the operation of the Software will be uninterrupted or error-free. The Licensee hereby irrevocably waives any claim for
		damages due to the use of the Software or due to any defect or malfunction resulting from or connected to the use of the Software, including damages related to or based upon:
		<br />
		<ul>
			<li>Limited functionality or non-functionality of the Software;</li>
			<li>Non-compatibility with the Licensee’s software or hardware systems or printers/press machines;</li>
			<li>Fitness to any of the Licensee’s needs, operational requirements or particular use;</li>
			<li>Loss of data or business opportunities due to software failures or hardware or software conflicts;</li>
			<li>The Documentation provided by Skyline.</li>
			<li>Any of the representations made by the Licensee hereinabove.</li>
		</ul>
		<br />
		Disclaimer of Warranty. THE SOFTWARE AND DOCUMENTATION ARE PROVIDED “AS IS”. SKYLINE MAKES NO PROMISES OR REPRESENTATIONS AND EXPRESSLY DISCLAIMS TO THE FULLEST EXTENT PERMITTED BY LAW ANY
		WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO THE SOFTWARE OTHER THAN THE REFUND IN ABSENCE OF MINIMUM IMPROVEMENT MENTIONED ABOVE. OTHER THAN THE SAID REFUND,
		ALL WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR AS TO NON-INFRINGEMENT OF THIRD PARTY RIGHTS ARE EXPRESSLY EXCLUDED, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
		<br />
		SKYLINE WILL HAVE NO LIABILITY FOR CONSEQUENTIAL, SPECIAL, INDIRECT, EXEMPLARY, PUNITIVE OR INCIDENTAL DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF REVENUE OR PROFIT OR COMMERCIAL LOSS OR
		LOSS OF DATA, OR DAMAGE TO OTHER SOFTWARE OR OTHER COMPUTERS OR COMPUTER MALFUNCTION OR DOWNTIME, IRRESPECTIVE OF THEORY UPON WHICH ANY CLAIM MAY BE BASED, EVEN IF SKYLINE HAS BEEN ADVISED OF
		THE POSSIBILITY OF SUCH DAMAGES.
		<br />
		WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, SKYLINE WILL HAVE NO LIABILITY FOR ANY DAMAGE AS A RESULT OF:
		<br />
		<ul>
			<li>• Misuse or abuse of the Software;</li>
			<li>• Service or modification made to the Software by anyone other than Skyline;</li>
			<li>• The Software being installed, implemented and operated other than in accordance with all the instructions in the Documentation;</li>
			<li>
				• The Licensee’s failure to properly install all updates made available with respect to the Software, and updates recommended by Skyline with respect to Third Party Components or third
				party software (including operating system software) that materially affect performance of the Software;
			</li>
			<li>The Licensee’s failure to properly maintain all associate equipment, software and environmental conditions in accordance with applicable specifications; </li>
		</ul>
		<br />
		The Licensee acknowledges that Skyline's Third Party Component licensors make no warranties to the Licensee under this EULA.
		<br />
		<br />
		<h3>TERMINATION</h3>
		Term. This EULA and the license granted hereunder will commence upon acceptance by the Licensee of this EULA and will continue until terminated by either party in accordance with the
		provisions contained herein.
		<br />
		Termination by Skyline. Skyline will have the right to terminate this EULA and the license granted herein immediately upon the Licensee's failure to comply with any of the material terms and
		conditions of this EULA;
		<br />
		Immediate Termination. Without prejudice to any other rights or remedies available hereunder or under any applicable law or treaty, the license will automatically terminate if the Licensee
		transfers possession of any copy, modification or module of the Software to any other party of if the Licensee makes any use of the Software other than the Permitted Use.
		<br />
		Termination for IP Violations. The foregoing notwithstanding, in the event Skyline exercises its right to terminate this EULA pursuant to sections “License” and “Title to Software” above,
		Skyline will have the right to take immediate action to prevent further use of the Software and the Documentation.
		<br />
		<br />
		<h3>MISCELLANEOUS</h3>
		Entire Agreement; Amendment and Waiver. This EULA constitutes the full and entire understanding and agreement between the parties with regard to the subject matters hereof, supersedes all
		prior courses of dealings, proposals and negotiations between Skyline and the Licensee and no representations, communications, negotiations or statements not expressed herein will be binding
		upon Skyline. Any term of this EULA may be ammended and the observance of any term hereof may be waived (either prospectively or retroactively and either generally or in a particular instance)
		only with the written consent of both of the parties to this EULA.
		<br />
		Delays or Omissions. No delay or omission to exercise any right, power, or remedy accruing to Skyline upon any breach or default under this EULA will be deemed a waiver of any other breach or
		default theretofore or thereafter occurring.
		<br />
		Severability. If any provision of this EULA is held by a court of competent jurisdiction to be unenforceable under applicable law, then such provision will be excluded from this EULA and the
		remainder of this EULA will be interpreted as if such provision were so excluded and will be enforceable in accordance with its terms; provided, however, that in such event this EULA will be
		interpreted so as to give effect, to the greatest extent consistent with and permitted by applicable law, to the meaning and intention of the excluded provision as determined by such court of
		competent jurisdiction.
		<br />
		Successors and Assigns; Assignment. The terms and provisions of this EULA will be binding upon and inure to the benefit of the parties to this EULA and to their respective heirs, successors,
		assigns and legal representatives, except that the Licensee will not assign, sub-license or otherwise transfer the Software or any rights, duties or obligations under this EULA to any person
		or entity except as expressly permitted hereunder. A Licensee who is a guardian of a minor user, and who acquires a license for the purpose of use by such a minor user, may allow such use by
		such minor user, in accordance with the provisions of this EULA.
		<br />
		Governing Law. This EULA will be construed in accordance with and governed for all purposes by the substantive laws of the State of Israel without regard to principles of conflicts of law. Any
		conventions regarding the international sale of goods or similar, including, without limitation, the United Nations Convention on Contracts for the International Sale of Goods, will not apply
		to this EULA.
		<br />
		Jurisdiction. The courts of the district of Tel-Aviv, Israel, will have the sole and exclusive jurisdiction over any dispute between the parties hereto, and any claim by the Licensee against
		Skyline’s employees, ex-employees, officers, agents, or affiliates. The Licensee hereby submits irrevocably to the jurisdiction of such venue. Notwithstanding the aforesaid, and without
		derogating from the effect thereof, Skyline may initiate proceedings against the Licensee in any other court which has competent jurisdiction over the licensee.
		<br />
	</div>
);

export default Eula;
