import axios from 'axios';
import { ERole, IUserData, IUserNotifications, User } from './User';

export interface IMyProfileUpdateDetails {
	fullName: string;
	notifications: IUserNotifications;
}

export interface ILocaleSettingsData {
	language: string;
}

class LocaleSettings implements ILocaleSettingsData {
	language: string;
	constructor(data: ILocaleSettingsData) {
		this.language = data?.language ?? 'en';
	}
}
export interface IMyProfileData extends IUserData {
	localeSettings: ILocaleSettingsData;
}

export class MyProfile extends User implements IMyProfileData {
	private static _current: MyProfile;
	static get Current(): MyProfile {
		return MyProfile._current;
	}

	localeSettings: LocaleSettings;

	permissions: Permissions;

	constructor(data: IMyProfileData) {
		super(data);
		this.localeSettings = new LocaleSettings(data.localeSettings);

		if (MyProfile._current) {
			throw new Error('MyProfile intstance already exists!');
		}
		MyProfile._current = this;

		this.permissions = new Permissions(this.role);
	}

	updateUserDetails = (details: any) => {
		return axios.put(`/User`, details).then(
			res => {
				const user: IUserData = res.data;
				this.fullName = user.fullName;
				this.notifications.canConfirmNotifications = user.notifications.canConfirmNotifications;
				this.notifications.isSendMail = user.notifications.isSendMail;
				this.notifications.isSendSms = user.notifications.isSendSms;
				return true;
			},
			err => {
				console.log(err);
				return false;
			}
		);
	};

	updateLastSeenDate(lastSeenDate: Date = new Date()) {
		axios.put(`User/LastSeenDate/${lastSeenDate.toISOString()}`).then(
			res => {
				const user: IUserData = res.data;
				this.notifications.lastSeenDate = user.notifications.lastSeenDate;
			},
			err => {
				console.log(err);
			}
		);
	}

	setName = (name: string) => {
		this.fullName = name;
	};

	changePassword = (password: string) => {
		let params = {
			password: password,
		};

		return axios.post(`/User/UpdatePassword`, null, { params: params });
	};

	changeLocaleSettings = async (data: ILocaleSettingsData) => {
		await axios.put(`/User/LocaleSettings`, data).then(
			res => {
				this.localeSettings.language = data.language;
			},
			err => {
				console.log(err);
			}
		);
	};
}

export class Permissions {
	companyCRUD: CRUD = new CRUD();
	siteCRUD: CRUD = new CRUD();
	craneCRUD: CRUD = new CRUD();
	cranePowerCRUD: CRUD = new CRUD();
	nightReleaseCRUD: CRUD = new CRUD();
	craneLockCRUD: CRUD = new CRUD();
	settingsCRUD: CRUD = new CRUD();
	notificationsConfirm: boolean;

	constructor(role: ERole) {
		//Company
		this.companyCRUD.create = this.PermissionBuilder([ERole.SuperAdmin, ERole.ServiceManager, ERole.ServiceManager2nd], role);
		this.companyCRUD.read = this.PermissionBuilder(
			[ERole.SuperAdmin, ERole.ServiceManager, ERole.ServiceManager2nd, ERole.SafetyManager, ERole.OperationManager, ERole.CranesManager, ERole.SiteManager],
			role
		);
		this.companyCRUD.update = this.PermissionBuilder([ERole.SuperAdmin, ERole.ServiceManager, ERole.ServiceManager2nd], role);
		this.companyCRUD.delete = this.PermissionBuilder([ERole.SuperAdmin, ERole.ServiceManager, ERole.ServiceManager2nd], role);

		//Site
		this.siteCRUD.create = this.PermissionBuilder([ERole.SuperAdmin, ERole.ServiceManager, ERole.ServiceManager2nd], role);
		this.siteCRUD.read = this.PermissionBuilder(
			[ERole.SuperAdmin, ERole.ServiceManager, ERole.ServiceManager2nd, ERole.SafetyManager, ERole.OperationManager, ERole.CranesManager, ERole.SiteManager],
			role
		);
		this.siteCRUD.update = this.PermissionBuilder([ERole.SuperAdmin, ERole.ServiceManager, ERole.ServiceManager2nd, ERole.OperationManager, ERole.CranesManager], role);
		this.siteCRUD.delete = this.PermissionBuilder([ERole.SuperAdmin, ERole.ServiceManager, ERole.ServiceManager2nd, ERole.OperationManager, ERole.CranesManager], role);

		//Crane
		this.craneCRUD.create = this.PermissionBuilder([ERole.SuperAdmin, ERole.ServiceManager, ERole.ServiceManager2nd], role);
		this.craneCRUD.read = this.PermissionBuilder(
			[ERole.SuperAdmin, ERole.ServiceManager, ERole.ServiceManager2nd, ERole.SafetyManager, ERole.OperationManager, ERole.CranesManager, ERole.SiteManager],
			role
		);
		this.craneCRUD.update = this.PermissionBuilder(
			[ERole.SuperAdmin, ERole.ServiceManager, ERole.ServiceManager2nd, ERole.SafetyManager, ERole.OperationManager, ERole.CranesManager, ERole.SiteManager],
			role
		);
		this.craneCRUD.delete = this.PermissionBuilder([ERole.SuperAdmin, ERole.ServiceManager, ERole.ServiceManager2nd, ERole.OperationManager, ERole.CranesManager], role);

		//Crane Power
		this.cranePowerCRUD.read = this.PermissionBuilder(
			[ERole.SuperAdmin, ERole.ServiceManager, ERole.ServiceManager2nd, ERole.SafetyManager, ERole.OperationManager, ERole.CranesManager, ERole.SiteManager],
			role
		);
		this.cranePowerCRUD.update = this.PermissionBuilder([ERole.ServiceManager], role);

		//Night Release
		this.nightReleaseCRUD.read = this.PermissionBuilder(
			[ERole.SuperAdmin, ERole.ServiceManager, ERole.ServiceManager2nd, ERole.SafetyManager, ERole.OperationManager, ERole.CranesManager, ERole.SiteManager],
			role
		);
		this.nightReleaseCRUD.update = this.PermissionBuilder([ERole.ServiceManager], role);

		//Crane Lock
		this.craneLockCRUD.read = this.PermissionBuilder([ERole.ServiceManager, ERole.ServiceManager2nd], role);
		this.craneLockCRUD.update = this.PermissionBuilder([ERole.ServiceManager], role);

		//Settings
		this.settingsCRUD.read = this.PermissionBuilder([ERole.SuperAdmin, ERole.ServiceManager], role);

		//Notifications
		this.notificationsConfirm = this.PermissionBuilder([ERole.SuperAdmin], role);
	}

	PermissionBuilder = (arr: ERole[], role: ERole) => {
		return arr.includes(role);
	};
}

export class CRUD {
	create = false;
	read = false;
	update = false;
	delete = false;
}
