import React, { useState, useContext, useEffect } from 'react';
import Spinner from '../UI/Spinner/Spinner';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import AddContactModal from '../Modals/AddContactModal/AddContactModal';
import DeleteContactModal from '../Modals/DeleteContactModal/DeleteContactModal';
import { DistributionList } from '../../DataTypes/DistributionList';
import { Crane } from '../../DataTypes/Crane';
import { Site } from '../../DataTypes/Site';
import { Company } from '../../DataTypes/Company';
import styles from './DistributionList.module.scss';
import { LoadingModalContext } from '../../contexts/LoadingModalProvider';
import { ReactComponent as RemoveIcon } from '../../../assets/icons/trash.svg';
import cn from 'classnames';
import DistributionListTable from '../Tables/DistributionListTable/DistributionListTable';
import MobileDistributionListTable from '../Mobile/Tables/MobileDistributionListTable/MobileDistributionListTable';
import { ERole, IUser, IUserNotifications, User } from 'src/DataTypes/User';
import { Phone } from 'src/DataTypes/Phone';
import { useTranslation } from 'react-i18next';
import { Hierarchy } from 'src/DataTypes/Hierarchy';

export interface ITableItemData extends IUser {
	displayName: string;
	isDeletable: boolean;
}

type props = {
	item: Company | Site | Crane;
	hierarchy: Hierarchy;
};

const DistributionListComponent: React.FC<props> = props => {
	const [loading, setLoading] = useState(true);
	const [addUserDetails, setAddUserDetails] = useState<Object | null>();
	const [userIdToDelete, setUserIdToDelete] = useState<string | null>(null);
	const [currentDistList, setCurrentDistList] = useState<DistributionList>();
	const [tableItems, setTableItems] = useState<ITableItemData[]>();
	const { ShowLoadingModal } = useContext(LoadingModalContext);
	const { t } = useTranslation();

	let ids: string[] = [];

	if (props.item instanceof Crane) {
		ids = [props.item.id, props.item.site.id, props.item.company.id];
	} else if (props.item instanceof Site) {
		ids = [props.item.id, props.item.company.id];
	} else if (props.item instanceof Company) {
		ids = [props.item.id];
	} else {
		throw 'is bad';
	}

	const loadData = (dls: DistributionList[] | null): void => {
		if (!dls) {
			setLoading(true);
			return;
		}

		const distList = dls.find(dl => dl.id === props.item.id);
		if (!distList) {
			throw 'is bad';
		}

		setCurrentDistList(distList);

		setTableItems(
			dls
				.map(dl => dl.users)
				.flat()
				.map(x => buildTableItemData(x, distList.users.includes(x)))
		);
		setLoading(false);
	};

	useEffect(() => {
		DistributionList.GetDistributionListMultiple(ids).then(loadData);
	}, []);

	const buildTableItemData = (user: IUser, isDeletable: boolean): ITableItemData => {
		let item: ITableItemData = {
			...user,
			displayName: user.isPending ? user.mail : `${user.mail}  (${user.fullName})`,
			isDeletable: isDeletable,
		};

		return item;
	};

	const toggleAddModalHandler = () => {
		setAddUserDetails({});
	};

	const addUserHandler = (userMail: string) => {
		ShowLoadingModal(
			currentDistList!.AddUser(userMail).then(
				response => {
					if (!response) {
						return null;
					}
					DistributionList.GetDistributionListMultiple(ids).then(loadData);
					return true;
				},
				reason => {
					return null;
				}
			),
			'DistributionListItemCreate'
		);
		setAddUserDetails(null);
	};

	const deleteHandler = () => {
		ShowLoadingModal(
			currentDistList!.DeleteUser(userIdToDelete!).then(
				response => {
					if (!response) {
						return null;
					}
					DistributionList.GetDistributionListMultiple(ids).then(loadData);
					return true;
				},
				reason => {}
			),
			'DistributionListItemRemove'
		);
		setUserIdToDelete(null);
	};

	if (loading || !currentDistList || !tableItems) {
		return <Spinner />;
	}

	return (
		<div className={styles.card}>
			<div className={styles.cardHeader}>
				<div className={styles.cardTitle}>{`${t('notifications')} - ${t('distributionList')}`}</div>
				<Button onClick={toggleAddModalHandler} type='primary' shape='circle' className='circular-add-btn' icon={<PlusOutlined />} />
			</div>
			<MobileDistributionListTable items={tableItems} setUserIdToDelete={setUserIdToDelete} />
			<DistributionListTable items={tableItems} setUserIdToDelete={setUserIdToDelete} />
			{addUserDetails != null && <AddContactModal items={tableItems} closeHandler={() => setAddUserDetails(null)} submitCallback={addUserHandler} hierarchy={props.hierarchy} />}
			{userIdToDelete != null && <DeleteContactModal closeHandler={() => setUserIdToDelete(null)} submitCallback={deleteHandler} />}
		</div>
	);
};

export default DistributionListComponent;
