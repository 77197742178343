import React from 'react';
import styles from './CraneDetails.module.scss';
import CraneDetailsTable from '../../Tables/CraneDetailsTable/CraneDetailsTable';
import CranePositionTable from '../../Tables/CranePositionTable/CranePositionTable';
import { Crane } from '../../../DataTypes/Crane';
import { Hierarchy } from '../../../DataTypes/Hierarchy';

type props = {
	hierarchy: Hierarchy;
	crane: Crane;
};

const CraneDetails: React.FC<props> = props => {
	return (
		<div className={styles.wrapper}>
			<CranePositionTable hierarchy={props.hierarchy} crane={props.crane}></CranePositionTable>
			<CraneDetailsTable hierarchy={props.hierarchy} crane={props.crane}></CraneDetailsTable>
		</div>
	);
};

export default CraneDetails;
