import { ConfigProvider } from 'antd';
import he_IL from 'antd/es/locale/he_IL';
import React from 'react';
import i18n from 'src/i18n/i18n';

const ConfigProviderWrapper: React.FC<any> = props => {
	return (
		<ConfigProvider
			direction={i18n.language == 'he' ? 'rtl' : 'ltr'}
			locale={i18n.language == 'he' ? he_IL : undefined}>
			{props.children}
		</ConfigProvider>
	);
};

export default ConfigProviderWrapper;
