import React, { useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styles from '../AddContactModal/AddContactModal.module.scss';
import RTL from '../../RTL/RTL';
import { EBlackboxType, IBlackboxAddDetails } from '../../../DataTypes/Blackbox';
import { SubscriptionCredit } from '../../../DataTypes/SubscriptionCredit';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { useTranslation } from 'react-i18next';

type props = {
	show: boolean;
	handleClose: () => void;
	subscriptionCredit: SubscriptionCredit;
};

const AddContactModal: React.FC<props> = props => {
	const { ShowLoadingModal } = useContext(LoadingModalContext);
	const { t } = useTranslation();

	const validationSchema = yup.object({
		type: yup.string().oneOf(Object.values(EBlackboxType)).required(t('requiredField')),
		friendlyName: yup.string().required(t('requiredField')),
	});

	const formik = useFormik({
		initialValues: {
			type: '',
			friendlyName: '',
		},
		validationSchema: validationSchema,
		onSubmit: (values: IBlackboxAddDetails) => {
			props.handleClose();
			ShowLoadingModal(props.subscriptionCredit.AddBlackbox(values), 'BBCreate');
		},
	});

	return (
		<RTL>
			<Modal size='sm' className={styles.modal} aria-labelledby='contained-modal-title-vcenter' show={props.show} centered onHide={props.handleClose}>
				<Modal.Header style={{ border: 'none' }} closeButton />
				<div className={styles.title}>{t('addingNewBlackbox')}</div>
				<Modal.Body className={styles.modalBody}>
					<form className={styles.formi} onSubmit={formik.handleSubmit}>
						<TextField
							id='friendlyName'
							label={t('sn')}
							value={formik.values.friendlyName}
							helperText={formik.touched.friendlyName ? formik.errors.friendlyName : ''}
							error={formik.touched.friendlyName && Boolean(formik.errors.friendlyName)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							variant='outlined'
							margin='dense'
							fullWidth
						/>
						<TextField
							id='type'
							name='type'
							label={t('type')}
							value={formik.values.type}
							select
							SelectProps={{
								native: true,
							}}
							helperText={formik.touched.type ? formik.errors.type : ''}
							error={formik.touched.type && Boolean(formik.errors.type)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth>
							<option value='' disabled />
							{Object.values(EBlackboxType).map(value => (
								<option key={value} id={value} value={value}>
									{value === 'Full' ? 'SBC' : value === 'Lite' ? 'SBL' : value === 'Feeder' ? 'SBF' : '-'}
								</option>
							))}
						</TextField>
						<div className={styles.formButtons}>
							<Button type='submit' variant='outline-primary' className={styles.button}>
								{t('add')}
							</Button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</RTL>
	);
};

export default AddContactModal;
