import { Empty } from 'antd';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

type props = {
	labels: string[];
	data: number[];
};

const OperationAverageChart: React.FC<props> = props => {
	const { t } = useTranslation();

	const data = {
		labels: props.labels,
		datasets: [
			{
				label: [t('activityHours')],
				data: props.data ?? null,
				// data: [100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111],
				backgroundColor: '#afdaf7',
				borderColor: 'rgb(54, 162, 235)',
				borderWidth: 1,
			},
		],
	};

	if (props.data.length === 0 || props.data.every(value => value === 0)) {
		return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('noData')} />;
	}

	return (
		<Bar
			data={data}
			options={{
				legend: {
					display: false,
				},
				scales: {
					yAxes: [
						{
							scaleLabel: {
								display: true,
								labelString: t('workingHours'),
								fontFamily: 'Rubik',
								fontSize: 15,
								fontColor: 'black',
							},
							gridLines: {
								display: true,
							},
							ticks: {
								beginAtZero: true,
							},
						},
					],
					xAxes: [
						{
							gridLines: {
								display: false,
							},
						},
					],
				},
				plugins: {
					datalabels: {
						display: false,
					},
				},
			}}
		/>
	);
};
export default OperationAverageChart;
