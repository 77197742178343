import React, { useEffect, useState } from 'react';
import styles from './Snapshots.module.scss';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './Snapshots.scss';
import Spinner from '../UI/Spinner/Spinner';
import { Empty, Timeline } from 'antd';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { ISnapshotsBulk } from 'src/DataTypes/Snapshot';
import Snapshot from './Snapshot';
import moment from 'moment';

type props = {
	snapshotsPromise: () => Promise<ISnapshotsBulk[] | null>;
};

//TODO INFINITE SCROLL
const Snapshots: React.FC<props> = ({ snapshotsPromise }) => {
	const [images, setImages] = useState<ISnapshotsBulk[] | null>();
	const { t } = useTranslation();

	useEffect(() => {
		async function fetchSnapshotImages() {
			const images = await snapshotsPromise();
			images?.sort((a, b) =>
				moment(b.createdTime).diff(moment(a.createdTime))
			);
			setImages(images);
		}

		fetchSnapshotImages();
	}, []);

	// Loading till images are defined
	if (!images) {
		return <Spinner />;
	}

	return (
		<div className={styles.wrapper}>
			{images.length === 0 ? (
				<Empty
					image={Empty.PRESENTED_IMAGE_SIMPLE}
					description={t('noSnapshots')}
				/>
			) : (
				images.map(i => (
					<Snapshot
						key={i.id}
						snapshotsBulk={i}
						date={i.createdTime}
					/>
				))
			)}
		</div>
	);
};

export default Snapshots;
