import React, { useContext } from 'react';
import styles from '../RemoveUserModal/RemoveUserModal.module.scss';
import { Button, Modal, ModalBody } from 'react-bootstrap';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { useTranslation } from 'react-i18next';
import { Operator } from 'src/DataTypes/Operator';

type props = {
	operator: Operator;
	closeHandler: () => void;
	submitCallback: () => void;
};

const RemoveOperatorModal: React.FC<props> = props => {
	const { ShowLoadingModal } = useContext(LoadingModalContext);
	const { t } = useTranslation();

	const handleRemove = () => {
		props.closeHandler();
		ShowLoadingModal(props.operator.Delete(), 'OperatorRemove').then(res => {
			if (!res.isError) {
				props.submitCallback();
			}
		});
	};

	return (
		<Modal size='sm' aria-labelledby='contained-modal-title-vcenter' show={true} onHide={props.closeHandler} centered>
			<Modal.Header style={{ border: 'none' }} closeButton />
			<div className={styles.title}>{t('removeOperatorConfirmation')}</div>
			<ModalBody>
				<div className={styles.buttons}>
					<Button variant='outline-danger' className={styles.button} onClick={handleRemove}>
						{t('yes&sure')}
					</Button>
					<Button variant='outline-primary' className={styles.button} onClick={props.closeHandler}>
						{t('no&cancel')}
					</Button>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default RemoveOperatorModal;
