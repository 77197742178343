import React, { useEffect, useRef, useState } from 'react';
import { useGLTF } from '@react-three/drei';
import { Crane } from 'src/DataTypes/Crane';

const TOWER_SEGMENT_HEIGHT: number = 210.81;

type props = {
	turn: number; //dev
	cart: number; //dev
	hook: number; //dev
	fourCables: boolean;
	offset: Object;
	towerSegmentsCounter: () => number;
};

const Model: React.FC<props> = props => {
	const group = useRef();
	//@ts-ignore
	const { nodes, materials } = useGLTF(`${location.origin}/scene.gltf`);
	const [segments, setSegments] = useState<JSX.Element[]>([]);

	const segmentCount = props.towerSegmentsCounter();

	const segmentBuilder = () => {
		let segs: JSX.Element[] = [];

		let bottom = -315.5;
		for (let i = 0; i < segmentCount; i++) {
			segs.push(
				<group key={i} position={[-0.14, -0.21, bottom]}>
					<group position={[-0.13, -0.15, -105.91]}>
						<mesh geometry={nodes.Box379_support_0.geometry} material={nodes.Box379_support_0.material} />
					</group>
					<group position={[37.69, -37.32, 51.54]} rotation={[0, -0.96, Math.PI]} scale={[1.26, 1.26, 1.26]}>
						<mesh geometry={nodes['Box383_corps-metal_0'].geometry} material={nodes['Box383_corps-metal_0'].material} />
					</group>
					<group position={[13.25, 4.34, -105.35]} rotation={[-0.17, 0, 0]}>
						<mesh geometry={nodes.Cylinder193_echelle_0.geometry} material={nodes.Cylinder193_echelle_0.material} />
					</group>
					<group position={[-40.02, 32.69, -105.12]} rotation={[0, 0, Math.PI / 2]} scale={1.26}>
						<mesh geometry={nodes['L-Ext043_corps-bord-metal_0'].geometry} material={nodes['L-Ext043_corps-bord-metal_0'].material} />
					</group>
				</group>
			);
			bottom += TOWER_SEGMENT_HEIGHT;
		}

		setSegments(segs);
	};

	useEffect(() => {
		segmentBuilder();
	}, []);

	return (
		<group ref={group} {...props} dispose={null}>
			<group rotation={[-Math.PI / 2, 0, 0]}>
				<group rotation={[Math.PI / 2, 0, 0]} scale={[0.4, 0.4, 0.4]}>
					{/*TOWER*/}
					<group position={[14.85, -211.61 - TOWER_SEGMENT_HEIGHT * (segmentCount - 4), -5.17]} rotation={[-Math.PI / 2, 0, 0]}>
						<group position={[0, 0, -338.48]} rotation={[0, 0, 275]}>
							<group position={[-38.11, -0.25, 70.92]}>
								<mesh geometry={nodes['Box401_pied-attache_0'].geometry} material={nodes['Box401_pied-attache_0'].material} />
							</group>
							<group position={[37.89, -0.25, 70.92]}>
								<mesh geometry={nodes['Box402_pied-attache_0'].geometry} material={nodes['Box402_pied-attache_0'].material} />
							</group>
							<group position={[-0.11, -38.26, 70.92]} rotation={[0, 0, Math.PI / 2]}>
								<mesh geometry={nodes['Box403_pied-attache_0'].geometry} material={nodes['Box403_pied-attache_0'].material} />
							</group>
							<group position={[-0.11, 37.75, 70.92]} rotation={[0, 0, Math.PI / 2]}>
								<mesh geometry={nodes['Box404_pied-attache_0'].geometry} material={nodes['Box404_pied-attache_0'].material} />
							</group>
							<group position={[63.19, -73.69, -15.97]} rotation={[0.96, 0.52, -2.8]}>
								<mesh geometry={nodes['Box405_pied-barre_0'].geometry} material={nodes['Box405_pied-barre_0'].material} />
							</group>
							<group position={[-63.14, -73.72, -15.97]} rotation={[0.96, -0.52, 2.8]}>
								<mesh geometry={nodes['Box406_pied-barre_0'].geometry} material={nodes['Box406_pied-barre_0'].material} />
							</group>
							<group position={[-63.19, 73.39, -15.97]} rotation={[-0.96, -0.52, 0.34]}>
								<mesh geometry={nodes['Box407_pied-barre_0'].geometry} material={nodes['Box407_pied-barre_0'].material} />
							</group>
							<group position={[63.14, 73.42, -15.97]} rotation={[-0.96, 0.52, -0.34]}>
								<mesh geometry={nodes['Box408_pied-barre_0'].geometry} material={nodes['Box408_pied-barre_0'].material} />
							</group>
							<group position={[0, 107.31, -84.37]}>
								<mesh geometry={nodes['Box409_pied-support_0'].geometry} material={nodes['Box409_pied-support_0'].material} />
							</group>
							<group position={[0, -107.99, -84.37]}>
								<mesh geometry={nodes['Box410_pied-support_0'].geometry} material={nodes['Box410_pied-support_0'].material} />
							</group>
							<group position={[-0.15, -115.39, -83.68]} scale={[0.68, 1, 1]}>
								<mesh geometry={nodes.Box411_beton_0.geometry} material={nodes.Box411_beton_0.material} />
							</group>
							<group position={[-0.15, -115.39, -64.24]} scale={[0.68, 1, 1]}>
								<mesh geometry={nodes.Box412_beton_0.geometry} material={nodes.Box412_beton_0.material} />
							</group>
							<group position={[-0.15, -115.39, -44.79]} scale={[0.68, 1, 1]}>
								<mesh geometry={nodes.Box413_beton_0.geometry} material={nodes.Box413_beton_0.material} />
							</group>
							<group position={[-0.15, -115.39, -25.35]} scale={[0.68, 1, 1]}>
								<mesh geometry={nodes.Box414_beton_0.geometry} material={nodes.Box414_beton_0.material} />
							</group>
							<group position={[-0.15, -115.39, -5.9]} scale={[0.68, 1, 1]}>
								<mesh geometry={nodes.Box415_beton_0.geometry} material={nodes.Box415_beton_0.material} />
							</group>
							<group position={[-0.15, -115.39, 13.54]} scale={[0.68, 1, 1]}>
								<mesh geometry={nodes.Box416_beton_0.geometry} material={nodes.Box416_beton_0.material} />
							</group>
							<group position={[0.1, 115.39, -83.68]} scale={[0.68, 1, 1]}>
								<mesh geometry={nodes.Box417_beton_0.geometry} material={nodes.Box417_beton_0.material} />
							</group>
							<group position={[0.1, 115.39, -64.24]} scale={[0.68, 1, 1]}>
								<mesh geometry={nodes.Box418_beton_0.geometry} material={nodes.Box418_beton_0.material} />
							</group>
							<group position={[0.1, 115.39, -44.79]} scale={[0.68, 1, 1]}>
								<mesh geometry={nodes.Box419_beton_0.geometry} material={nodes.Box419_beton_0.material} />
							</group>
							<group position={[0.1, 115.39, -25.35]} scale={[0.68, 1, 1]}>
								<mesh geometry={nodes.Box420_beton_0.geometry} material={nodes.Box420_beton_0.material} />
							</group>
							<group position={[0.1, 115.39, -5.9]} scale={[0.68, 1, 1]}>
								<mesh geometry={nodes.Box421_beton_0.geometry} material={nodes.Box421_beton_0.material} />
							</group>
							<group position={[0.1, 115.39, 13.54]} scale={[0.68, 1, 1]}>
								<mesh geometry={nodes.Box422_beton_0.geometry} material={nodes.Box422_beton_0.material} />
							</group>
							<group position={[39.72, -45.8, 58.76]} rotation={[-Math.PI / 2, 0.96, 2.62]}>
								<mesh geometry={nodes['Cylinder209_pied-barre_0'].geometry} material={nodes['Cylinder209_pied-barre_0'].material} />
							</group>
							<group position={[-44.96, -42.13, 58.76]} rotation={[Math.PI / 2, 0.96, -Math.PI / 6]}>
								<mesh geometry={nodes['Cylinder210_pied-barre_0'].geometry} material={nodes['Cylinder210_pied-barre_0'].material} />
							</group>
							<group position={[-39.73, 45.5, 58.76]} rotation={[Math.PI / 2, -0.96, -Math.PI / 6]}>
								<mesh geometry={nodes['Cylinder211_pied-barre_0'].geometry} material={nodes['Cylinder211_pied-barre_0'].material} />
							</group>
							<group position={[44.96, 41.83, 58.76]} rotation={[-Math.PI / 2, -0.96, 2.62]}>
								<mesh geometry={nodes['Cylinder212_pied-barre_0'].geometry} material={nodes['Cylinder212_pied-barre_0'].material} />
							</group>
						</group>
						{segments}
					</group>
					{/****************/}
				</group>
				<group rotation={[Math.PI / 2, props.turn, 0]} scale={[0.4, 0.4, 0.4]}>
					{/*HOOK*/}
					<group position={[13.44, -1 * props.hook, -1 * props.cart]} rotation={[-Math.PI / 2, 0, 0]}>
						<group position={[29.62, -17.85, 45.12]} rotation={[0, 1.57, 0]} scale={0.95}>
							<mesh geometry={nodes['Cylinder213_grue-accroche_0'].geometry} material={materials['grue-accroche']} />
						</group>
						<group position={[0.28, 5.88, 7.93]} rotation={[-2.78, -0.24, 1.01]}>
							<mesh geometry={nodes['Cylinder222_poulis-support_0'].geometry} material={materials['poulis-support']} />
						</group>
					</group>
					{/****************/}
					{/*JIB*/}
					<group position={[34.13, 456.92, 109.39]} rotation={[-0.88, 0.11, 0.13]} scale={[1.33, 1.33, 1.33]}>
						<mesh geometry={nodes['attaches-haut_attaches-haut_0'].geometry} material={materials['attaches-haut']} />
					</group>
					<group position={[49.39, 383.37, 224.57]} rotation={[-Math.PI / 2, 0, 0]}>
						<group position={[13.66, 116.62, -34.3]} rotation={[0, 0, Math.PI / 2]}>
							<mesh geometry={nodes['Cylinder001_grue-barrieres_0'].geometry} material={nodes['Cylinder001_grue-barrieres_0'].material} />
						</group>
						<group position={[13.66, 46.61, -34.3]} rotation={[0, 0, Math.PI / 2]}>
							<mesh geometry={nodes['Cylinder092_grue-barrieres_0'].geometry} material={nodes['Cylinder092_grue-barrieres_0'].material} />
						</group>
						<group position={[13.66, -23.94, -34.3]} rotation={[0, 0, Math.PI / 2]}>
							<mesh geometry={nodes['Cylinder095_grue-barrieres_0'].geometry} material={nodes['Cylinder095_grue-barrieres_0'].material} />
						</group>
						<group position={[13.66, -185.16, -34.3]} rotation={[0, 0, Math.PI / 2]}>
							<mesh geometry={nodes['Cylinder098_grue-barrieres_0'].geometry} material={nodes['Cylinder098_grue-barrieres_0'].material} />
						</group>
						<group position={[83.55, -104.17, -34.3]} rotation={[0, 0, Math.PI / 2]}>
							<mesh geometry={nodes['Cylinder101_grue-barrieres_0'].geometry} material={nodes['Cylinder101_grue-barrieres_0'].material} />
						</group>
						<group position={[15.09, -106.56, -34.3]}>
							<mesh geometry={nodes['Cylinder104_grue-barrieres_0'].geometry} material={nodes['Cylinder104_grue-barrieres_0'].material} />
						</group>
						<group position={[15.09, -33.22, -34.3]}>
							<mesh geometry={nodes['Cylinder107_grue-barrieres_0'].geometry} material={nodes['Cylinder107_grue-barrieres_0'].material} />
						</group>
						<group position={[-83.65, 116.62, -34.3]} rotation={[0, 0, Math.PI / 2]}>
							<mesh geometry={nodes['Cylinder110_grue-barrieres_0'].geometry} material={nodes['Cylinder110_grue-barrieres_0'].material} />
						</group>
						<group position={[-83.65, 46.62, -34.3]} rotation={[0, 0, Math.PI / 2]}>
							<mesh geometry={nodes['Cylinder113_grue-barrieres_0'].geometry} material={nodes['Cylinder113_grue-barrieres_0'].material} />
						</group>
						<group position={[-83.65, -23.38, -34.3]} rotation={[0, 0, Math.PI / 2]}>
							<mesh geometry={nodes['Cylinder116_grue-barrieres_0'].geometry} material={nodes['Cylinder116_grue-barrieres_0'].material} />
						</group>
						<group position={[-83.65, -93.38, -34.3]} rotation={[0, 0, Math.PI / 2]}>
							<mesh geometry={nodes['Cylinder119_grue-barrieres_0'].geometry} material={nodes['Cylinder119_grue-barrieres_0'].material} />
						</group>
						<group position={[-83.65, -184.84, -34.3]} rotation={[0, 0, Math.PI / 2]}>
							<mesh geometry={nodes['Cylinder122_grue-barrieres_0'].geometry} material={nodes['Cylinder122_grue-barrieres_0'].material} />
						</group>
					</group>
					<group position={[14.29, 318.92, 373.34]} rotation={[-Math.PI / 2, 0, 0]}>
						<group position={[0, -30.03, -64.96]}>
							<mesh geometry={nodes['Box151_beton-haut_0'].geometry} material={nodes['Box151_beton-haut_0'].material} />
						</group>
						<group position={[0, -14.97, -64.96]}>
							<mesh geometry={nodes['Box152_beton-haut_0'].geometry} material={nodes['Box152_beton-haut_0'].material} />
						</group>
						<group position={[0, -0.12, -64.96]}>
							<mesh geometry={nodes['Box153_beton-haut_0'].geometry} material={nodes['Box153_beton-haut_0'].material} />
						</group>
						<group position={[0, 14.87, -64.96]}>
							<mesh geometry={nodes['Box154_beton-haut_0'].geometry} material={nodes['Box154_beton-haut_0'].material} />
						</group>
						<group position={[0, 30.03, -64.96]}>
							<mesh geometry={nodes['Box155_beton-haut_0'].geometry} material={nodes['Box155_beton-haut_0'].material} />
						</group>
					</group>
					<group position={[14.5, 234.25, -65.9]} rotation={[-Math.PI / 2, 0, 0]}>
						<mesh geometry={nodes.cabine_cabine_0.geometry} material={materials.cabine} />
					</group>
					<group position={[14.32, 572.51, -94.88]} rotation={[3, 0, 0]}>
						<mesh geometry={nodes['cables-haut_cable-haut_0'].geometry} material={materials['cable-haut']} />
					</group>
					<group position={[42.93, 344.05, -42.6]} rotation={[Math.PI, 0, 0]} scale={1.26}>
						<mesh geometry={nodes['corps-haut-1_corps-haut-1_0'].geometry} material={nodes['corps-haut-1_corps-haut-1_0'].material} />
					</group>
					<group position={[42.93, 344.05, -1058.8]} rotation={[Math.PI, 0, 0]} scale={1.26}>
						<mesh geometry={nodes['corps-haut-2_corps-haut-1_0'].geometry} material={nodes['corps-haut-2_corps-haut-1_0'].material} />
					</group>
					<group position={[42.93, 344.05, -551.03]} rotation={[Math.PI, 0, 0]} scale={1.26}>
						<mesh geometry={nodes['corps-haut-3_corps-haut-3_0'].geometry} material={materials['corps-haut-3']} />
					</group>
					<group position={[39.26, 233.38, -38.35]} rotation={[-Math.PI / 2, 0, 0]} scale={1.26}>
						<mesh geometry={nodes['corps-top_corps-top_0'].geometry} material={materials['corps-top']} />
					</group>
					<group position={[62.9, 359.06, 57.17]} rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
						<mesh geometry={nodes['grue-barriere-support_grue-barrieres-support_0'].geometry} material={materials['grue-barrieres-support']} />
					</group>
					<group position={[57.33, 245.17, -6.02]} rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
						<mesh geometry={nodes['haut-gris_haut-gris_0'].geometry} material={materials['haut-gris']} />
					</group>
					{/*CORONA BASE*/}
					<group position={[14.1, 341.62, -6.2]} rotation={[-Math.PI / 2, 0, 0]} scale={0.8}>
						<mesh geometry={nodes['haut-jaune_haut-jaune_0'].geometry} material={materials['haut-jaune']} />
					</group>
					{/********/}
					{/****************/}
					{/*JIB TAIL*/}
					<group position={[55.64, 334.94, 228.16]} rotation={[-Math.PI / 2, 0, 0]}>
						<mesh geometry={nodes.plateforme_plateforme_0.geometry} material={materials.plateforme} />
					</group>
					<group position={[14.23, 358.39, 186.67]} rotation={[-Math.PI / 2, 0, 0]}>
						<mesh geometry={nodes['plateforme-haut_plateforme-haut_0'].geometry} material={materials['plateforme-haut']} />
					</group>
					{/****************/}
					{/*CART & CABLES*/}
					<group position={[52.06, 354.43, -1 * props.cart]} rotation={[-Math.PI / 2, 0, 0]} scale={[1, 0.83, 1]}>
						<mesh geometry={nodes.poulie_poulie_0.geometry} material={nodes.poulie_poulie_0.material} />
					</group>
					{props.fourCables && (
						<group position={[52.06, 354.43, -1 * props.cart]} rotation={[-Math.PI / 2, 0, 0]} scale={[1, 0.83, 0.00157691 * props.hook + 0.468977]}>
							<mesh geometry={nodes.poulie_poulie_0001.geometry} material={nodes.poulie_poulie_0001.material} />
						</group>
					)}
					<group position={[52.06, 354.43, -1 * props.cart]} rotation={[-Math.PI / 2, 0, 0]} scale={[1, 0.83, 0.00157691 * props.hook + 0.468977]}>
						<mesh geometry={nodes.poulie_poulie_0002.geometry} material={nodes.poulie_poulie_0002.material} />
					</group>
					{/****************/}
				</group>
			</group>
		</group>
	);
};
export default Model;

useGLTF.preload(`${location.origin}/scene.gltf`);
