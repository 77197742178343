import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from './ReadNotification.module.scss';
import { useTranslation } from 'react-i18next';
import { INotification } from 'src/DataTypes/Notification';

type props = {
	notification: INotification;
};

type notificationContentProps = {
	data: any;
	type: string;
};

const ReadNotification: React.FC<props> = props => {
	const { t } = useTranslation();

	return (
		<div className={styles.notificationCard}>
			<Row>
				<Col md={1}>
					<div className={styles.rightSection}>
						<div className={styles.crane}>{`${t('crane')} ${
							props.notification?.crane?.number
						}`}</div>
						<div className={styles.site}>
							{props.notification.crane?.site?.name}
						</div>
					</div>
				</Col>
				<Col md={11}>
					<div className={styles.leftSection}>
						<div className={styles.content}>
							<NotificationContent
								type={props.notification.type}
								data={props.notification?.data}
							/>
						</div>
						<div className={styles.time}>
							{props.notification?.createdTime}
						</div>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default ReadNotification;

export const NotificationContent: React.FC<
	notificationContentProps
> = props => {
	const { t } = useTranslation();

	const notificationDataToString = () => {
		switch (props.type) {
			case 'InputAlert':
				let value =
					props.data?.Data?.ValueInUnits ||
					props.data?.InputValue?.ValueInUnits;
				return t('windAlert', { value: value?.toFixed(0) ?? '??' });
			case 'Disconnected':
				return t('connectionAlert');
			default:
				return t('generalAlert');
		}
	};

	return <>{notificationDataToString()}</>;
};
