import axios from 'axios';
import { IUserData, User } from './User';

export interface IDistributionListData {
	id: string;
	users: User[];
	createdTime: Date;
}
export class DistributionList implements IDistributionListData {
	id: string;
	users: User[];
	createdTime: Date;

	constructor(data: IDistributionListData) {
		this.id = data.id;
		this.users = data.users?.map(user => new User(user));
		this.createdTime = data.createdTime ?? new Date();
	}

	static GetDistributionListMultiple(resourceIds: string[]): Promise<DistributionList[] | null> {
		return axios.get(`/DistributionList`, { params: { resourceIds: [...resourceIds] } }).then(
			response => {
				return resourceIds.map(id => {
					let found = response.data.find((x: IDistributionListData) => x.id == id);
					if (found) return new DistributionList(found);
					return new DistributionList({ id, users: [], createdTime: new Date() });
				});
			},
			reason => {
				return null;
			}
		);
	}

	AddUser(email: string): Promise<boolean> {
		return axios.post(`/DistributionList/${this.id}/${email}`).then(
			response => {
				return true;
			},
			reason => {
				return false;
			}
		);
	}

	DeleteUser(userId: string): Promise<boolean> {
		return axios.delete(`/DistributionList/${this.id}/${userId}`).then(
			response => {
				return true;
			},
			reason => {
				return false;
			}
		);
	}

	private _CallContactAPI(userId: string | null, method: import('axios').Method): Promise<DistributionList | null> {
		let url = `/DistributionList/${this.id}`;
		if (userId !== null) {
			url += `/${userId}`;
		}

		return axios.request({ method: method, url: url }).then(
			response => {
				this.users = response.data.users?.map((user: IUserData) => new User(user));
				return this;
			},
			reason => {
				return null;
			}
		);
	}
}
