import React from 'react';
import { Route, Switch, Link, Redirect } from 'react-router-dom';
import { Menu, ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './Crane.module.scss';
import CraneGeneral from '../Crane/CraneGeneral/CraneGeneral';
import CraneActivity from '../Crane/CraneActivity/CraneActivity';
import CraneDetails from '../Crane/CraneDetails/CraneDetails';
import DistributionList from '../DistributionList/DistributionList';
import Snapshots from '../Snapshots/Snapshots';
import { Crane as CraneType } from '../../DataTypes/Crane';
import { Hierarchy } from '../../DataTypes/Hierarchy';

type props = {
	crane: CraneType;
	match: any;
	location: any;
	hierarchy: Hierarchy;
};

const Crane: React.FC<props> = props => {
	const { t } = useTranslation();
	const menuItems = [
		<Menu.Item key='general' disabled={!props.crane.deviceStatus}>
			<Link to={`/crane/${props.match.params.id}`}>{t('general')}</Link>
		</Menu.Item>,
		<Menu.Item key='activity' disabled={!props.crane.deviceStatus}>
			<Link to={`${props.match.url}/crane-activity`}>{t('activity')}</Link>
		</Menu.Item>,
		<Menu.Item key='details'>
			<Link to={`${props.match.url}/crane-details`}>{t('craneDetails')}</Link>
		</Menu.Item>,
		<Menu.Item key='distributionList'>
			<Link to={`${props.match.url}/crane-distribution-list`}>{t('distributionList')}</Link>
		</Menu.Item>,
		<Menu.Item key='snapshots'>
			<Link to={`${props.match.url}/crane-snapshots`}>{t('snapshots')}</Link>
		</Menu.Item>,
	];
	const routes = [
		<Route key='general' exact path={props.match.url} render={() => <CraneGeneral {...props} />}>
			{!props.crane.deviceStatus && <Redirect to={`${props.match.url}/crane-details`} />}
		</Route>,
		<Route key='activity' path={props.match.url + '/crane-activity'} render={() => <CraneActivity {...props} />} />,
		<Route key='details' path={props.match.url + '/crane-details'} render={() => <CraneDetails {...props} />} />,
		<Route key='distributionList' path={props.match.url + '/crane-distribution-list'} render={() => <DistributionList item={props.crane} {...props} />} />,
		<Route key='snapshots' path={props.match.url + '/crane-snapshots'} render={() => <Snapshots snapshotsPromise={() => props.crane.GetSnapshotImages()} {...props} />} />,
	];

	const checkSelectedKey = pathname => {
		let key = 'general';
		if (pathname.includes('crane-activity')) {
			key = 'activity';
		} else if (pathname.includes('crane-details')) {
			key = 'details';
		} else if (pathname.includes('crane-distribution-list')) {
			key = 'distributionList';
		} else if (pathname.includes('snapshots')) {
			key = 'snapshots';
		}

		return [key];
	};

	return (
		<div className={styles.wrapper}>
			<Menu selectedKeys={checkSelectedKey(props.location.pathname)} mode='horizontal' style={{ background: 'none', border: 'none', marginRight: '0.5rem' }}>
				{menuItems}
			</Menu>
			<Switch>{routes}</Switch>
		</div>
	);
};

export default Crane;
