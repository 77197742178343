import React from 'react';
import { Notification } from '../../../DataTypes/Notification';
import { Empty } from 'antd';
import styles from './NotificationList.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
	notifications: Notification[];
	notificationToItemViewFunc: (n: Notification, index: any) => JSX.Element;
};

const NotificationList: React.FC<Props> = props => {
	const { t } = useTranslation();

	const populateNotifications = () => {
		let controlNotifications = props.notifications.sort((a, b) => b.createdDate.getTime() - a.createdDate.getTime()).map(props.notificationToItemViewFunc);
		if (controlNotifications !== null) {
			return controlNotifications;
		}
	};

	if (props.notifications.length == 0) {
		return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('noNotifications')} />;
	}

	return <div className={styles.main}>{populateNotifications()}</div>;
};

export default NotificationList;
