import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
	Table,
	ConfigProvider,
	Tag,
	Button,
	Popover,
	Input,
	Space,
} from 'antd';
import { MoreOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import he_IL from 'antd/es/locale/he_IL';
import { OperationSummary } from '../../../DataTypes/OperationSummary';
import styles from '../DesktopTable.module.scss';
import { showRangeAndTotal } from 'src/services/services';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

type props = {
	operationSummaryArr: OperationSummary[];
};

const CraneActivityTable = (props: props) => {
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const history = useHistory();
	const expandableData = useState([]);
	const dailyWorkingHours: number = 12;
	const { t } = useTranslation();

	// const getColumnSearchProps = dataIndex => ({
	// 	filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
	// 		<div style={{ padding: 8 }}>
	// 			<Input
	// 				placeholder={`${dict.texts.search}`}
	// 				value={selectedKeys[0]}
	// 				onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
	// 				onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
	// 				style={{ width: 188, marginBottom: 8, display: 'block' }}
	// 			/>
	// 			<Space>
	// 				<Button type='primary' onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size='small' style={{ width: 90 }}>
	// 					{dict.texts.search}
	// 				</Button>
	// 				<Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
	// 					{dict.texts.reset}
	// 				</Button>
	// 				{/* <Button
	// 					type='link'
	// 					size='small'
	// 					onClick={() => {
	// 						confirm({ closeDropdown: false });
	// 						setSearchText(selectedKeys[0]);
	// 						setSearchedColumn(dataIndex);
	// 					}}>
	// 					Filter
	// 				</Button> */}
	// 			</Space>
	// 		</div>
	// 	),
	// 	filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, marginRight: '1rem', fontSize: '18px' }} />,
	// 	onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),
	// 	render: text =>
	// 		searchedColumn === dataIndex ? (
	// 			<Highlighter highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }} searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''} />
	// 		) : (
	// 			text
	// 		),
	// });

	const columns: any = [
		{
			title: t('date'),
			dataIndex: 'date',
			key: 'date',
			defaultSortOrder: 'descend',
			width: '33%',
			isUseDefaultSearch: true,
			render: date => moment(date).format('DD/MM/YY'),
			sorter: (a, b) => sortFunction(a.date, b.date),
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: t('totalHours'),
			dataIndex: 'secondsOfOperation',
			key: 'secondsOfOperation',
			width: '33%',
			render: secondsOfOperation =>
				new Date(secondsOfOperation * 1000).toISOString().substr(11, 8),
			sorter: (a, b) =>
				sortFunction(a.secondsOfOperation, b.secondsOfOperation),
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: `${t('dailyActivityPercentage')} ${t('dailyWorkingHours')}`,
			dataIndex: 'secondsOfOperation',
			key: 'secondsOfOperation',
			width: '33%',
			render: (secondsOfOperation: number) =>
				`${(
					(secondsOfOperation / 3600 / dailyWorkingHours) *
					100
				).toFixed(2)}%`,
			sorter: (a, b) =>
				sortFunction(a.secondsOfOperation, b.secondsOfOperation),
			sortDirections: ['descend', 'ascend'],
		},
	];

	const expandedColumns = [
		{
			title: t('turnOn'),
			dataIndex: 'start',
			key: 'start',
			// defaultSortOrder: 'descend',
			width: '25%',
			render: start => moment(start).format('HH:mm:ss'),
		},
		{
			title: t('turnOff'),
			dataIndex: 'end',
			key: 'end',
			width: '25%',
			render: end => moment(end).format('HH:mm:ss'),
		},
		{
			title: t('total'),
			key: 'total',
			width: '25%',
			render: interval => {
				const diff = moment(interval.end).diff(moment(interval.start));
				return moment.utc(diff).format('HH:mm:ss');
			},
		},
		{
			title: t('operatorName'),
			dataIndex: 'operatorName',
			key: 'operatorName',
			width: '25%',
			render: () => t('unknown'),
		},
	];

	// const handleSearch = (selectedKeys, confirm, dataIndex) => {
	// 	confirm();
	// 	setSearchText(selectedKeys[0]);
	// 	setSearchedColumn(dataIndex);
	// };

	// const handleReset = clearFilters => {
	// 	clearFilters();
	// 	setSearchText('');
	// };

	const sortFunction = (a, b) => {
		let sortA;
		let sortB;

		switch (typeof a) {
			case 'string':
				sortA = a?.toLowerCase();
				sortB = b?.toLowerCase();
				break;

			default:
				sortA = a;
				sortB = b;
				break;
		}

		if (sortA < sortB) {
			return -1;
		}
		if (sortA > sortB) {
			return 1;
		}

		return 0;
	};

	const expandedRowRender = row => {
		return (
			<Table
				columns={expandedColumns}
				dataSource={row.intervals}
				pagination={false}
			/>
		);
	};

	return (
		<ConfigProvider
			direction={i18n.language == 'he' ? 'rtl' : 'ltr'}
			locale={i18n.language == 'he' ? he_IL : undefined}>
			{/* <Table className={styles.desktopTable} dataSource={props.dataSource} columns={columns} pagination={{ pageSize: 10 }} expandable={{ expandedRowRender }} /> */}
			<Table
				className={styles.desktopTable}
				dataSource={props.operationSummaryArr}
				rowKey={record => record.date.toString()}
				columns={columns}
				pagination={{ pageSize: 10, showTotal: showRangeAndTotal }}
				expandable={{ expandedRowRender }}
			/>
		</ConfigProvider>
	);
};

export default CraneActivityTable;
