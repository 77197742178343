import React, { useState, useEffect, useRef } from 'react';
import useOutsideClick from '../../../utils/useOutsideClick';
import Switch from 'react-ios-switch';
import { Crane } from '../../../DataTypes/Crane';
import { MyProfile } from '../../../DataTypes/MyProfile';
import styles from './ControlNotification.module.scss';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PowerIcon } from '../../../assets/icons/power.svg';
import { ReactComponent as NightIcon } from '../../../assets/icons/night.svg';
import { ReactComponent as LockIcon } from '../../../assets/icons/lock.svg';
import { ReactComponent as AlertIcon } from '../../../assets/icons/notification-alert-circle.svg';
import { ReactComponent as MoreIcon } from '../../../assets/icons/notification-more.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/sidebar-close.svg';
import CheckButton, { Size } from 'src/components/UI/CheckButton/Checkbutton';
import { NotificationContent } from '../ReadNotification/ReadNotification';

type props = {
	notification: any;
	crane?: Crane;
};
const ControlNotification: React.FC<props> = props => {
	const controlsRef = useRef<HTMLDivElement>(null);
	const [currentUser, setCurrentUser] = useState<MyProfile>(MyProfile.Current);
	const [controlsOpen, setControlsOpen] = useState(false);
	const { t } = useTranslation();

	const onScroll = () => {
		if (controlsOpen) setControlsOpen(false);
	};

	useEffect(() => {
		window.addEventListener('scroll', onScroll);

		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	});

	useOutsideClick(controlsRef, () => {
		if (controlsOpen) setControlsOpen(false);
	});

	return (
		<div className={styles.notificationCard}>
			<div className={styles.rightSectionControl}>
				<MoreIcon className={styles.moreIcon} onClick={() => setControlsOpen(true)} />
				<AlertIcon className={styles.alertIcon} />
				<div className={styles.crane}>{`${t('crane')} ${props.notification?.crane?.number}`}</div>
				<div className={styles.site}>{props.notification?.crane?.site?.name}</div>
			</div>

			<div className={styles.centerSectionControl}>
				<div className={styles.content}>
					<NotificationContent type={props.notification.type} data={props.notification?.data} />
				</div>
				<div className={styles.time}>{props.notification?.createdTime}</div>
			</div>

			<div className={cn(styles.leftSectionControl, controlsOpen && styles.open)} ref={controlsRef}>
				<CloseIcon className={styles.close} onClick={() => setControlsOpen(false)} />
				<div className={styles.switch}>
					<CheckButton
						icon={PowerIcon}
						checked={props.notification?.crane?.deviceStatus?.isPowered && props.notification.crane.deviceStatus.isConnected}
						disabled={!currentUser?.permissions.cranePowerCRUD.update || props.notification.crane.deviceId === null || !props.notification.crane.deviceStatus.isConnected}
						onClick={() => null}
						size={Size.md}
					/>
				</div>
				<div className={styles.switch}>
					<CheckButton
						icon={NightIcon}
						checked={props.crane?.deviceStatus?.isReleased && props.notification.crane.deviceStatus.isConnected}
						// disabled={props.crane?.deviceStatus?.isReleased || !currentUser?.permissions.nightReleaseCRUD.update}
						disabled={true}
						size={Size.md}
					/>
				</div>
				<div className={styles.switch} hidden={!currentUser?.permissions.craneLockCRUD.read}>
					<CheckButton
						icon={LockIcon}
						checked={false}
						// disabled={props.crane?.deviceStatus?.isReleased || !currentUser?.permissions.nightReleaseCRUD.update}
						disabled={true}
						size={Size.md}
					/>
				</div>
			</div>
		</div>
	);
};

export default ControlNotification;
