import React, { useState, useEffect } from 'react';
import Spinner from '../../UI/Spinner/Spinner';
import styles from './CompanyActiveSitesTable.module.scss';
import cn from 'classnames';
import { Company } from '../../../DataTypes/Company';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';

type props = {
	company: Company;
};

const CompanyActiveSitesTable: React.FC<props> = props => {
	const { t } = useTranslation();

	if (!props.company) {
		return <Spinner />;
	}
	return (
		<div className={styles.card}>
			<span className={styles.cardTitle}>{t('activeSites')}</span>
			{props.company.sites.length === 0 ? (
				<Empty description={t('noActiveSites')} image={Empty.PRESENTED_IMAGE_SIMPLE} />
			) : (
				<table className={cn('table', 'table-borderless', styles.mobileTable)}>
					<thead className={styles.thead}>
						<tr>
							<th scope='col'>{t('name')}</th>
							<th scope='col'>{t('address')}</th>
							<th scope='col'>{t('cranesQuantity')}</th>
							<th scope='col'>{t('contact')}</th>
							<th scope='col'>{t('phone')}</th>
						</tr>
					</thead>
					<tbody className={styles.tbody}>
						{props.company.sites.map((site, index) => {
							return (
								<tr key={index} className={styles.tcard}>
									<td className={styles.tname}>{site.name}</td>
									<td>
										<span className={styles.theaders}>{t('address')}</span>
										{site.address}
									</td>
									<td>
										<span className={styles.theaders}>{t('cranesQuantity')}</span>
										{site.cranes.length}
									</td>
									<td>
										<span className={styles.theaders}>{t('contact')}</span>
										{site.manager?.name}
									</td>
									<td>
										<span className={styles.theaders}>{t('phone')}</span>
										{site.manager?.phone}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			)}
		</div>
	);
};

export default CompanyActiveSitesTable;
