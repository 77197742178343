import { Crane } from './Crane';
import axios from 'axios';
import { EventDispatcher } from 'strongly-typed-events';
import { INotificationData, Notification } from './Notification';
import { ReturnCranesDeviceIds } from '../services/services';
import { MyProfile } from './MyProfile';

/**
 * Manges notifications
 */
export class NotificationManager {
	NotificationReceived = new EventDispatcher<NotificationManager, Notification>();
	NewNotificationsCountChanged = new EventDispatcher<NotificationManager, number>();

	notifications: Notification[] = [];
	allCranes: Crane[] = [];

	lastPolledDate: Date;

	_newNotificationsCount = 0;
	get newNotificationsCount() {
		return this._newNotificationsCount;
	}
	set newNotificationsCount(value) {
		if (this._newNotificationsCount == value) return;
		this._newNotificationsCount = value;
		this.NewNotificationsCountChanged.dispatch(this, this._newNotificationsCount);
	}

	/**
	 * Creates an instance of NotificationManager
	 */
	constructor(lastSeenDate: Date) {
		this.lastPolledDate = lastSeenDate;
	}

	onlyUnique(value: string | undefined, index: number, array: (string | undefined)[]) {
		return array.indexOf(value!) === index;
	}

	/**
	 * @returns number of notifications associated with the cranes
	 */
	CountUnique(cranes: Crane[]): number {
		let arr = cranes.map(n => n.deviceId);
		arr = arr.filter(this.onlyUnique);
		return arr.length;
	}

	static async FetchLast(cranes: Crane[], amount = 10, skip = 0): Promise<Notification[]> {
		//Find device ids
		let deviceIds = ReturnCranesDeviceIds(cranes);
		if (deviceIds.length == 0) {
			return [];
		}

		//TODO catch exceptions
		//Call notifications api
		const response = await axios.post(`/Notification/GetLast`, deviceIds, { params: { amount, skip } });

		let notifications = NotificationManager.NotificationsFromData(response.data, cranes);
		return notifications;
	}

	static NotificationsFromData(data: INotificationData[], cranes: Crane[]): Notification[] {
		//Create notification objects
		return <Notification[]>data
			.map(x => {
				const crane = cranes.find(c => c.deviceId === x.deviceId);
				if (crane == undefined) return null;
				const n = new Notification(x, crane);
				return n;
			})
			.filter(x => x != null);
	}

	/**
	 * @returns {Promise}
	 */
	async RealtimePoll() {
		//Find device ids
		let deviceIds = ReturnCranesDeviceIds(this.allCranes);
		if (deviceIds.length == 0) {
			return;
		}

		const startTime = this.lastPolledDate;
		const endTime = new Date();

		const response = await axios.post(`/Notification/GetMultiple`, deviceIds, {
			params: { startTime: startTime, endTime: endTime },
		});

		this.lastPolledDate = endTime;

		let newNotifications = NotificationManager.NotificationsFromData(response.data, this.allCranes);

		newNotifications.forEach(x => {
			this.notifications.push(x);
			this.NotificationReceived.dispatch(this, x);
		});

		this.newNotificationsCount += newNotifications.length;
	}
}
