import React, { useState, useContext } from 'react';
import { MyProfile } from '../../../DataTypes/MyProfile';
import Spinner from '../../UI/Spinner/Spinner';
import styles from './CompanyDetailsTable.module.scss';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { ReactComponent as Edit } from '../../../assets/icons/edit.svg';
import CompanyDetailsUpdateModal from '../../Modals/CompanyDetailsUpdateModal/CompanyDetailsUpdateModal';
import { Company, ICompanyUpdateDetails } from '../../../DataTypes/Company';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

type props = {
	company: Company;
};

const CompanyDetailsTable: React.FC<props> = props => {
	const [currentUser, setCurrentUser] = useState<MyProfile>(MyProfile.Current);
	const [showUpdateModal, setShowUpdateModal] = useState(false);
	const { ShowLoadingModal } = useContext(LoadingModalContext);
	const { t } = useTranslation();

	const handleShowUpdateModal = () => {
		setShowUpdateModal(!showUpdateModal);
	};

	const handleSubmit = (details: ICompanyUpdateDetails) => {
		handleShowUpdateModal();
		ShowLoadingModal(props.company.UpdateDetails(details), 'CompanyDetailsUpdate');
	};

	if (!props.company) {
		return <Spinner />;
	}
	return (
		<>
			{showUpdateModal && <CompanyDetailsUpdateModal company={props.company} handleClose={handleShowUpdateModal} handleSubmit={handleSubmit} show={showUpdateModal} />}
			<div className={styles.card}>
				<span className={styles.cardTitle}>{t('companyDetails')}</span>
				<table className={cn('table', 'table-borderless', styles.mobileTable)}>
					<thead>
						<tr>
							<th scope='col'>{t('name')}</th>
							<th scope='col'>{t('bn')}</th>
							<th scope='col'>{t('address')}</th>
							<th scope='col'>{t('contact')}</th>
							<th scope='col'>{t('phone')}</th>
							<th scope='col'></th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{props.company.name}</td>
							<td>{props.company.sn}</td>
							<td>{props.company.officeAddress}</td>
							<td>{props.company.manager?.name}</td>
							<td>{props.company.manager?.phone}</td>
							<td hidden={!currentUser?.permissions.companyCRUD.update} className={styles.tcontrols}>
								<Edit onClick={handleShowUpdateModal} className={styles.icon} />
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</>
	);
};

export default CompanyDetailsTable;
