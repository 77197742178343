import { IManagerData, Manager } from './Manager';
import { Site } from './Site';
import axios from 'axios';
import { Crane } from './Crane';
import { Notification } from './Notification';
import { ReturnCranesDeviceIds } from '../services/services';
import { NotificationManager } from './NotificationManager';
import { IQueryOperationSummary, OperationSummary } from './OperationSummary';

export interface ICompanyCreateDetails {
	name: string;
	sn: string;
	officeAddress: string;
	manager: IManagerData;
}

export interface ICompanyUpdateDetails {
	name: string;
	officeAddress: string;
	manager: IManagerData;
}

export interface ICompanyData {
	id: string;
	organization: string;
	name: string;
	officeAddress: string;
	sn: string;
	manager: Manager;
	sites: Site[];
}

export interface ICompany extends ICompanyData {
	cranes: Crane[];
}

export class Company implements ICompany, IQueryOperationSummary {
	id: string;

	organization: string;

	name: string;

	officeAddress: string;

	sn: string;

	manager: Manager;

	sites: Site[] = [];

	cranes: Crane[] = [];

	constructor(data: ICompanyData) {
		this.id = data.id;
		this.organization = data.organization;
		this.name = data.name;
		this.sn = data.sn;
		this.officeAddress = data.officeAddress;
		this.manager = data.manager;
		this.sites = data.sites.map(s => {
			const site = new Site(s, this);
			this.cranes = this.cranes.concat(site.cranes);
			return site;
		});
	}

	UpdateDetails = (details: ICompanyUpdateDetails) => {
		return axios.put(`/Company/${this.id}`, details);
	};

	GetNotifications(): Promise<Notification[]> {
		return NotificationManager.FetchLast(this.cranes);
	}

	GetActivitySummary(startTime: Date, endTime: Date) {
		return OperationSummary.Query(startTime, endTime, ReturnCranesDeviceIds(this.cranes));
	}
}
