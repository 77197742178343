import { ECraneType } from './Crane';
import axios from 'axios';
import { Interval, OperationSummary } from './OperationSummary';

export interface IOperatorCreateDetails {
	operatorId: string;
	fullName: string;
	birthday?: string;
	type: ECraneType;
	licenseExpirationDate?: string;
	licenseId: string;
}
export interface IOperatorEditDetails {
	fullName: string;
	type: ECraneType;
	licenseExpirationDate?: Date;
	licenseId: string;
}

export interface IOperatorData {
	id: string;
	operatorId: string;
	fullName: string;
	birthday: Date;
	type: ECraneType;
	licenseExpirationDate: Date;
	licenseId: string;
	imageCount: number;
}

export interface IOperatorDetails {
	id: string;
	fullName: string;
}
export interface IOperatorDetailsData {
	id: string;
	fullName: string;
}

export interface IOperator extends IOperatorData {}

export class Operator implements IOperator {
	id: string;
	fullName: string;
	operatorId: string;
	birthday: Date;
	type: ECraneType;
	licenseExpirationDate: Date;
	licenseId: string;
	imageCount: number;

	constructor(data: IOperatorData) {
		this.id = data?.id;
		this.fullName = data?.fullName;
		this.operatorId = data?.operatorId;
		this.birthday = data?.birthday;
		this.type = data?.type;
		this.licenseExpirationDate = data?.licenseExpirationDate;
		this.licenseId = data?.licenseId;
		this.imageCount = data?.imageCount;
	}

	Delete() {
		return axios.delete(`/Operators/${this.id}`);
	}

	RemoveImages() {
		return axios.delete(`/Operators/Images/${this.id}`);
	}

	UpdateDetails(details: IOperatorEditDetails) {
		return axios.put(`/Operators/${this.id}`, details);
	}

	static Create(details: IOperatorCreateDetails) {
		return axios.post('/Operators', details);
	}

	static async GetAllOperators() {
		const pagingData = {
			pageNumber: 1,
			pageLength: 100,
		};

		const res = await axios.post('/Operators/All', pagingData);
		return res.data.map(o => new Operator(o));
	}

	static async GetOperator(operatorId: string): Promise<Operator | null> {
		return await axios
			.get(`/Operators`, {
				params: {
					operatorId: operatorId,
				},
			})
			.then(
				res => {
					return new Operator(res.data);
				},
				rej => {
					return null;
				}
			);
	}

	UploadImage(img) {
		const formData = new FormData();
		formData.append('file', img);
		return axios.post(`/Operators/Images/${this.id}`, formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
			data: formData,
		});
	}

	GetSummary(startTime: string, endTime: string, deviceIds: string[]) {
		return axios
			.post(`/Summary/ByOperator`, null, {
				params: {
					operatorId: this.id,
					startTime,
					endTime,
				},
			})
			.then(
				res => {
					let operationSummaries: OperationSummary[] = res.data.map(
						o => new OperationSummary(o)
					);
					//Filter by known device ids
					return operationSummaries.filter(x =>
						deviceIds.includes(x.deviceId)
					);
				},
				rej => {
					console.log(rej);
					return [];
				}
			);
	}
}

export class OperatorDetails implements IOperatorDetails {
	id: string;
	fullName: string;

	constructor(data: IOperatorDetailsData) {
		this.id = data.id;
		this.fullName = data.fullName;
	}
}
