import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './Toolbar.module.scss';
import { MyProfile } from '../../DataTypes/MyProfile';
import { Badge, ConfigProvider, Popover } from 'antd';
import { Logout } from '../../auth';
import he_IL from 'antd/es/locale/he_IL';
import i18n from 'i18next';
import { useHistory } from 'react-router';

import { ReactComponent as HomeIcon } from '../../assets/icons/Home.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg';
import { ReactComponent as NotificationIcon } from '../../assets/icons/notification.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/profile.svg';
import { ReactComponent as GlobeIcon } from '../../assets/icons/globe.svg';
import { ReactComponent as IL } from '../../assets/icons/il.svg';
import { ReactComponent as US } from '../../assets/icons/us.svg';
import { getWelcomeText } from '../../services/services';
import { Hierarchy } from '../../DataTypes/Hierarchy';
import cn from 'classnames';
import BreadcrumbComponent from '../UI/Breadcrumb/Breadcrumb';
import { useTranslation } from 'react-i18next';

type props = {
	hierarchy: Hierarchy;
	myProfile: MyProfile;
};

const Toolbar: React.FC<props> = ({ hierarchy, myProfile }) => {
	const [visibleUserPopover, setVisibleUserPopover] = useState(false);
	const [visibleLangPopover, setVisibleLangPopover] = useState(false);
	const [newNotificationsCount, setNewNotificationsCount] = useState(hierarchy.notificationManager?.newNotificationsCount ?? 0);
	const { t } = useTranslation();

	useEffect(() => {
		const event = hierarchy.notificationManager?.NewNotificationsCountChanged;
		event?.subscribe(onNewNotificationsCountChanged);
		return () => {
			event?.unsubscribe(onNewNotificationsCountChanged);
		};
	}, [hierarchy.notificationManager?.NewNotificationsCountChanged]);

	const userPopoverContent = (
		<div className={styles.popoverContent}>
			<NavLink to='/userProfile'>
				<div className={styles.popoverItem} onClick={() => setVisibleUserPopover(current => !current)}>
					{t('userProfile')}
				</div>
			</NavLink>
			<NavLink to={useHistory().location.pathname}>
				<div className={styles.popoverItem} onClick={Logout}>
					{t('logout')}
				</div>
			</NavLink>
		</div>
	);

	const languagePopoverContent = (
		<div className={styles.popoverContent}>
			{i18n.language === 'he' ? (
				<div className={styles.popoverItem} onClick={() => changeLanguage('en')}>
					<US className={styles.flag} />
					English
				</div>
			) : (
				<div className={styles.popoverItem} onClick={() => changeLanguage('he')}>
					<IL className={styles.flag} />
					עברית
				</div>
			)}
		</div>
	);

	/**Event handler */
	const onNewNotificationsCountChanged = (_, count: number) => {
		setNewNotificationsCount(count);
	};

	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng);
		setVisibleLangPopover(false);
		myProfile.changeLocaleSettings({ language: lng });
	};

	return (
		<div className={styles.wrapperToolbar}>
			<div className={styles.navbar + ' navbar fixed-top navbar-expand-lg'}>
				<BreadcrumbComponent hierarchy={hierarchy} />

				<div className={styles.links}>
					<div className={styles.nav + ' nav'}>
						<div className={styles.welcomeText}>{getWelcomeText(myProfile.fullName)}</div>
						<NavLink to='/notifications' className={cn(styles.toolbarLink)} activeClassName={styles.active}>
							<div className={styles.notification}>
								<ConfigProvider direction={i18n.language == 'he' ? 'rtl' : 'ltr'}>
									<Badge count={newNotificationsCount} overflowCount={99} offset={[0, 0]} title={t('notificationsQuantity')}>
										<NotificationIcon className={cn(newNotificationsCount > 0 && styles.notificationActive, styles.shake)} />
									</Badge>
								</ConfigProvider>
							</div>
						</NavLink>
						<NavLink to='/settings' className={cn(styles.toolbarLink, styles.rotate)} activeClassName={styles.active} hidden={!myProfile.permissions.settingsCRUD.read}>
							<SettingsIcon />
						</NavLink>
						<NavLink to='/userProfile' className={cn(styles.toolbarLink)} activeClassName={styles.active}>
							<Popover
								content={userPopoverContent}
								placement='bottomLeft'
								trigger='click'
								visible={visibleUserPopover}
								onVisibleChange={() => setVisibleUserPopover(current => !current)}>
								<UserIcon />
							</Popover>
						</NavLink>
						<div className={cn(styles.toolbarLink, styles.desktopLngIcon)}>
							<Popover
								content={languagePopoverContent}
								placement='bottomLeft'
								trigger='click'
								visible={visibleLangPopover}
								onVisibleChange={() => setVisibleLangPopover(current => !current)}>
								<GlobeIcon />
							</Popover>
						</div>

						<div className={styles.mobileToolbar}>
							<NavLink to='/settings' activeClassName={styles.active} hidden={!myProfile.permissions.settingsCRUD.read}>
								<SettingsIcon />
							</NavLink>
							<NavLink exact to='/' activeClassName={styles.active}>
								<HomeIcon />
							</NavLink>
							<NavLink to='/notifications' activeClassName={styles.active}>
								<div className={styles.notification}>
									<ConfigProvider direction={i18n.language == 'he' ? 'rtl' : 'ltr'}>
										<Badge count={newNotificationsCount} overflowCount={99} offset={[0, 0]} title={t('notificationsQuantity')}>
											<NotificationIcon className={cn(newNotificationsCount > 0 && styles.notificationActive, styles.shake)} />
										</Badge>
									</ConfigProvider>
								</div>
							</NavLink>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Toolbar;
