import React from 'react';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';
import i18n from 'i18next';

type props = {};

const RTL: React.FC<props> = props => {
	const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
	const theme = createMuiTheme({
		direction: i18n.language == 'he' ? 'rtl' : 'ltr',
	});

	return (
		<StylesProvider jss={jss}>
			<ThemeProvider theme={theme}>{props.children}</ThemeProvider>
		</StylesProvider>
	);
};

export default RTL;
