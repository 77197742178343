import React from 'react';
import styles from './Counter.module.scss';

type props = {
	type: string;
	value: number;
	units?: string;
};

const Counter: React.FC<props> = props => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.type}>{props.type}</div>
			<div className={styles.value}>{props.value}</div>
			<div className={styles.units}>{props.units}</div>
		</div>
	);
};

export default Counter;
