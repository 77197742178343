import React, { useState, useEffect } from 'react';
import styles from './Registration.module.scss';
import { Modal, Button } from 'react-bootstrap';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import Eula from './Eula';
import RTL from '../RTL/RTL';
import i18n from 'i18next';
import { useFormik } from 'formik';
import { Popover, Spin } from 'antd';
import * as yup from 'yup';
import { RegistrationUser, IRegistrationUserDetails } from '../../DataTypes/RegistrationUser';
import { Phone } from '../../DataTypes/Phone';
import { ReactComponent as ArrowIcon } from '../../assets/icons/chevron-rounded.svg';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IL } from '../../assets/icons/il.svg';
import { ReactComponent as US } from '../../assets/icons/us.svg';
import { ReactComponent as GlobeIcon } from '../../assets/icons/globe.svg';

type props = {
	token: string;
};

const phoneRegExp: RegExp = /^05\d{8}$/;

const Registration: React.FC<props> = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [isCompleted, setIsCompleted] = useState(false);
	const [currentScreen, setCurrentScreen] = useState<number>(1);
	const [hiddenPhone, setHiddenPhone] = useState<string | null>(null);
	const [isShowEulaModal, setIsShowEulaModal] = useState(false);
	const [user, setUser] = useState<RegistrationUser>();
	const [validationCode, setValidationCode] = useState<string>('');
	const [visibleLangPopover, setVisibleLangPopover] = useState(false);

	const { t } = useTranslation();

	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng);
		setVisibleLangPopover(false);
		// myProfile.changeLocaleSettings({ language: lng });
	};
	const languagePopoverContent = (
		<div className={styles.popoverContent}>
			{i18n.language === 'he' ? (
				<div className={styles.popoverItem} onClick={() => changeLanguage('en')}>
					<US className={styles.flag} />
					English
				</div>
			) : (
				<div className={styles.popoverItem} onClick={() => changeLanguage('he')}>
					<IL className={styles.flag} />
					עברית
				</div>
			)}
		</div>
	);

	const validationSchema = yup.object({
		fullName: yup.string().required(t('requiredField')),
		phoneNumber: yup.string().matches(phoneRegExp, t('enterValidValue')).required(t('requiredField')),
		password: yup
			.string()
			.required(t('requiredField'))
			.matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\S+$).{8,}$/, t('passwordStrength')),
		rePassword: yup.string().oneOf([yup.ref('password'), ''], t('passwordMustMatch')),
		isSendMail: yup.boolean(),
		isSendSms: yup.boolean(),
	});
	const validationCodeSchema = yup.object({
		validationCode: yup.string().length(7, t('EnterA7CharacterVerificationCode')).required(t('requiredField')),
	});

	const formik = useFormik({
		initialValues: {
			fullName: '',
			phoneNumber: '',
			password: '',
			rePassword: '',
			isSendMail: false,
			isSendSms: false,
		},
		validationSchema: validationSchema,
		onSubmit: values => {
			const details: IRegistrationUserDetails = {
				fullName: values.fullName,
				phone: new Phone({ number: values.phoneNumber, countryCode: '972' }),
				password: values.password,
				notifications: {
					isSendMail: values.isSendMail,
					isSendSms: values.isSendSms,
				},
			};
			setIsLoading(true);

			user?.editDetails(details);
			user?.register().then(
				result => {
					setIsLoading(false);
					console.log('Register succeeded, result:');
					console.log(result);
					hidePhone();
					nextScreen();
				},
				reason => {
					setIsLoading(false);
					console.log('Register failed, reason:');
					console.log(reason);
					window.alert(reason);
				}
			);
		},
	});

	const validationCodeForm = useFormik({
		initialValues: {
			validationCode: '',
		},
		validationSchema: validationCodeSchema,
		onSubmit: values => {
			setIsLoading(true);
			user?.registerFinish(values.validationCode).then(
				result => {
					console.log('RegisterFinish succeeded, result:', result);
					setCurrentScreen(0);
					setIsLoading(false);
					setIsCompleted(true);
					setTimeout(() => {
						//Redirect to main app
						window.location.href = window.location.origin;
					}, 4000);
				},
				reason => {
					setIsLoading(false);
					console.log('RegisterFinish failed, reason:');
					console.log(reason);
					window.alert(reason);
				}
			);
		},
	});

	const hidePhone = () => {
		let hide = `***-***${user?.phone?.number?.toString().substring(6, 10)}`;
		setHiddenPhone(hide);
	};

	useEffect(() => {
		RegistrationUser.getInviteDetails(props.token).then(
			result => {
				console.log('result:');
				console.log(result);
				setUser(new RegistrationUser(result.data));
				setIsLoading(false);
			},
			reason => {
				console.log('reason:');
				console.log(reason);
				window.alert(reason);
				setIsLoading(false);
			}
		);
	}, []);

	const nextScreen = () => {
		setCurrentScreen(current => current + 1);
	};

	const prevScreen = () => {
		setCurrentScreen(current => current - 1);
	};

	const eulaModalHandler = () => {
		const show = isShowEulaModal;
		setIsShowEulaModal(!show);
	};

	const screen1 = (
		<>
			<div className={styles.title}>{t('createAccount')}</div>
			<p className={styles.subtitle}>{t('registrationWelcome')}</p>
			<div className={styles.inputs}>
				<div className={styles.input}>
					<span className={styles.key}>{t('mail')}</span>
					<div className={styles.value}>{user?.mail}</div>
				</div>
				<div className={styles.input}>
					<span className={styles.key}>{t('company')}</span>
					<div className={styles.value}>{user?.company}</div>
				</div>
				<div className={styles.input}>
					<span className={styles.key}>{t('role')}</span>
					<div className={styles.value}>{t(user?.role!)}</div>
				</div>
			</div>
			<div className={styles.resending}>
				{t('theDetailsDontMatch')}
				<span role='button'>{' ' + t('SendRequestForUpdate')}</span>
			</div>
			<Button variant='outline-primary' type='submit' onClick={nextScreen} className={styles.button}>
				{t('confirmDetails')}
			</Button>
			<div className={styles.policy}>
				{t('registrationDetailsConfirmation')}
				<span role='button' onClick={eulaModalHandler}>
					{t('termsOfUseAndPrivacyOfTheCompany')}
				</span>
			</div>
		</>
	);

	const screen2 = (
		<>
			<div className={styles.prevButton} onClick={prevScreen}>
				{t('back')}
			</div>
			<div className={styles.title}>{t('JustAFewMoreDetails')}</div>
			<RTL>
				<form className={styles.formi} onSubmit={formik.handleSubmit}>
					<TextField
						id='fullName'
						label={t('fullName')}
						value={formik.values.fullName}
						helperText={formik.touched.fullName ? formik.errors.fullName : ''}
						error={formik.touched.fullName && Boolean(formik.errors.fullName)}
						margin='dense'
						onBlur={formik.handleBlur}
						onChange={formik.handleChange}
						variant='outlined'
						fullWidth
					/>
					<TextField
						id='phoneNumber'
						label={t('phone')}
						value={formik.values.phoneNumber}
						helperText={formik.touched.phoneNumber ? formik.errors.phoneNumber : ''}
						error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
						margin='dense'
						onBlur={formik.handleBlur}
						onChange={formik.handleChange}
						variant='outlined'
						fullWidth
						type='phone'
					/>
					{/* <div className={styles.subtitle2}>{t('mobileUpdate')}</div> */}
					<TextField
						id='password'
						label={t('password')}
						value={formik.values.password}
						type='password'
						helperText={formik.touched.password ? formik.errors.password : ''}
						error={formik.touched.password && Boolean(formik.errors.password)}
						margin='dense'
						onBlur={formik.handleBlur}
						onChange={formik.handleChange}
						variant='outlined'
						fullWidth
					/>
					<TextField
						id='rePassword'
						label={t('rePassword')}
						value={formik.values.rePassword}
						type='password'
						helperText={formik.touched.rePassword ? formik.errors.rePassword : ''}
						error={formik.touched.rePassword && Boolean(formik.errors.rePassword)}
						margin='dense'
						onBlur={formik.handleBlur}
						onChange={formik.handleChange}
						variant='outlined'
						disabled={!formik.values.password}
						fullWidth
					/>
					<div className={styles.checkboxParagraph}>
						<FormControlLabel
							control={<Checkbox checked={formik.values.isSendMail} onChange={formik.handleChange} name='isSendMail' color='primary' />}
							label={t('receiveEmailNotifications')}
						/>
						<FormControlLabel
							control={<Checkbox checked={formik.values.isSendSms} onChange={formik.handleChange} name='isSendSms' color='primary' />}
							label={t('receiveSmsNotifications')}
						/>
					</div>

					<div className={styles.formButtons}>
						<Button type='submit' variant='outline-primary' className={styles.button}>
							{t('sendVerificationCodeToMobile')}
						</Button>
					</div>
				</form>
			</RTL>
		</>
	);
	const screen3 = (
		<>
			<div className={styles.prevButton} onClick={prevScreen}>
				{t('back')}
			</div>
			<div className={styles.title}>{t('enterVerificationCode')}</div>
			<span className={styles.subtitle3}>{t('recievedVerificationSms')}</span>
			<p className={styles.userPhone}>{user?.phone?.number}</p>
			<RTL>
				<form className={styles.formi} onSubmit={validationCodeForm.handleSubmit}>
					<TextField
						id='validationCode'
						label={t('verificationCode')}
						value={validationCodeForm.values.validationCode}
						helperText={validationCodeForm.touched.validationCode ? validationCodeForm.errors.validationCode : ''}
						error={validationCodeForm.touched.validationCode && Boolean(validationCodeForm.errors.validationCode)}
						margin='dense'
						onBlur={validationCodeForm.handleBlur}
						onChange={validationCodeForm.handleChange}
						variant='outlined'
						fullWidth
					/>
					{/* <div className={styles.resending3}>{' לשליחת קוד חוזרת או קבלת קוד בשיחה'}</div> */}
					<div className={styles.formButtons}>
						<Button type='submit' variant='outline-primary' className={styles.button}>
							{t('confirmCode')}
						</Button>
					</div>
				</form>
			</RTL>
		</>
	);

	const completedScreen = (
		<>
			<div className={styles.title} style={{ marginTop: '3rem' }}>
				{t('registrationCompletedSuccessfully')}
			</div>
			<span className={styles.subtitle3}>{t('youAreRedirectedToTheSite')}</span>
		</>
	);

	const eulaModal = (
		<Modal onClick={eulaModalHandler} size='xl' className={styles.modal} aria-labelledby='contained-modal-title-vcenter' centered show={isShowEulaModal} onHide={eulaModalHandler}>
			{Eula}
		</Modal>
	);

	return (
		<div className={styles.wrapper} dir={i18n.dir()}>
			<Popover
				className={styles.popover}
				content={languagePopoverContent}
				placement='bottom'
				trigger='click'
				visible={visibleLangPopover}
				onVisibleChange={() => setVisibleLangPopover(current => !current)}>
				<GlobeIcon className={styles.icon} />
			</Popover>
			<div className={styles.card}>
				{isLoading && <Spin size='large' className={styles.spin} tip={t('loading')} />}
				<div className={styles.logo} />
				{!isLoading && currentScreen === 1 && screen1}
				{!isLoading && currentScreen === 2 && screen2}
				{!isLoading && !isCompleted && currentScreen === 3 && screen3}
				{!isLoading && isCompleted && completedScreen}
				{eulaModal}
			</div>
		</div>
	);
};

export default Registration;
