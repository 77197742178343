import React, { useContext, useState } from 'react';
import { Modal, Button } from 'antd';
import styles from './UploadOperatorImage.module.scss';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { useTranslation } from 'react-i18next';
import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { Operator } from 'src/DataTypes/Operator';
import axios from 'axios';
import ConfigProviderWrapper from 'src/components/ConfigProviderWrapper/ConfigProviderWrapper';

type props = {
	closeHandler: () => void;
	operator: Operator;
	submitCallback: () => void;
};

const UploadOperatorImage: React.FC<props> = props => {
	const { ShowLoadingModal } = useContext(LoadingModalContext);
	const { t } = useTranslation();

	const [defaultFileList, setDefaultFileList] = useState([]);

	const uploadImage = async options => {
		const { onSuccess, onError, file, onProgress } = options;

		const fmData = new FormData();

		fmData.append('formFile', file);
		try {
			const res = await axios({
				method: 'POST',
				url: `/Operators/Images/${props.operator.id}`,
				data: fmData,
				headers: { 'Content-Type': 'multipart/form-data' },
			});
			onSuccess('Ok');
			props.submitCallback();
			console.log('server res: ', res);
		} catch (err) {
			console.log('Error: ', err);
			const error = new Error('Some error');
			onError({ err });
		}
	};

	const handleOnChange = ({ fileList }) => {
		// console.log(file, fileList, event);
		//Using Hooks to update the state to the current filelist
		setDefaultFileList(fileList);
		//filelist - [{uid: "-1",url:'Some url to image'}]
	};

	return (
		<ConfigProviderWrapper>
			<Modal
				className={styles.modal}
				title={t('uploadOperatorImage')}
				// zIndex={1031}
				onCancel={props.closeHandler}
				closable
				visible
				centered
				destroyOnClose
				onOk={props.closeHandler}>
				<ImgCrop rotate>
					<Upload
						listType='picture-card'
						onChange={handleOnChange}
						disabled={defaultFileList.length > 0}
						customRequest={uploadImage}>
						{'+ Upload'}
					</Upload>
				</ImgCrop>
			</Modal>
		</ConfigProviderWrapper>
	);
};

export default UploadOperatorImage;
