import React, { useContext } from 'react';
import styles from '../RemoveUserModal/RemoveUserModal.module.scss';
import { Modal, ModalBody, Button } from 'react-bootstrap';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { License } from '../../../DataTypes/License';
import { useTranslation } from 'react-i18next';

type props = {
	show: boolean;
	license: License;
	unassignModalHandler: () => void;
};

const LicenseUnassignmentModal: React.FC<props> = props => {
	const { ShowLoadingModal } = useContext(LoadingModalContext);
	const { t } = useTranslation();

	const unassignHandler = () => {
		if (props.license.blackboxBinding?.craneBinding !== null) {
			alert(t('licenseAssociatedWithBox'));
		} else {
			props.unassignModalHandler();
			ShowLoadingModal(props.license.UnbindBlackbox(), 'LicenseUnassign');
		}
	};

	return (
		<Modal size='sm' aria-labelledby='contained-modal-title-vcenter' show={props.show} onHide={props.unassignModalHandler} centered>
			<Modal.Header style={{ border: 'none' }} closeButton />
			<div className={styles.title}>{t('unassociate')}</div>

			<ModalBody>
				<div className={styles.buttons}>
					<Button variant='outline-danger' className={styles.button} onClick={unassignHandler}>
						{t('yes&sure')}
					</Button>
					<Button variant='outline-primary' className={styles.button} onClick={props.unassignModalHandler}>
						{t('no&cancel')}
					</Button>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default LicenseUnassignmentModal;
