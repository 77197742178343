import React, { useContext, useState } from 'react';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { Modal, Button } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styles from '../AddContactModal/AddContactModal.module.scss';
import { Crane, ICraneMoveDetails } from '../../../DataTypes/Crane';
import RTL from '../../RTL/RTL';
import { Hierarchy } from '../../../DataTypes/Hierarchy';
import { generateCranesNumberOption } from 'src/components/NewItem/NewCrane/NewCraneModal';
import { useTranslation } from 'react-i18next';

type props = {
	hierarchy: Hierarchy;
	crane: Crane;
	showModalHandler: any;
	show: boolean;
};

const CranePositionModal: React.FC<props> = props => {
	const { ShowLoadingModal } = useContext(LoadingModalContext);
	const { t } = useTranslation();

	const validationSchema = yup.object({
		companyId: yup.string().required(t('requiredField')),
		siteId: yup.string().required(t('requiredField')),
		craneNumber: yup.string().required(t('requiredField')),
	});

	const formik = useFormik({
		initialValues: {
			companyId: '',
			siteId: '',
			craneNumber: '',
		},
		validationSchema: validationSchema,
		onSubmit: (values: ICraneMoveDetails) => {
			handleSubmit(values);
		},
	});

	const handleSubmit = details => {
		props.showModalHandler();
		ShowLoadingModal(props.crane.Move(details), 'CraneAssignUpdate');
	};

	return (
		<RTL>
			<Modal size='sm' className={styles.modal} aria-labelledby='contained-modal-title-vcenter' show={props.show} centered onHide={props.showModalHandler}>
				<Modal.Header style={{ border: 'none' }} closeButton />
				<div className={styles.title}>{t('updateCranePosition')}</div>
				<Modal.Body className={styles.modalBody}>
					<form className={styles.formi} onSubmit={formik.handleSubmit}>
						<TextField
							id='companyId'
							name='companyId'
							label={t('company')}
							value={formik.values.companyId}
							select
							SelectProps={{
								native: true,
							}}
							helperText={formik.touched.companyId ? formik.errors.companyId : ''}
							error={formik.touched.companyId && Boolean(formik.errors.companyId)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth>
							<option value='' disabled />

							{props.hierarchy.companies
								.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
								.map(company => (
									<option key={company.id} id={company.id} value={company.id}>
										{company.name}
									</option>
								))}
						</TextField>
						<TextField
							id='siteId'
							name='siteId'
							label={t('site')}
							value={formik.values.siteId}
							select
							SelectProps={{
								native: true,
							}}
							helperText={formik.touched.siteId ? formik.errors.siteId : ''}
							error={formik.touched.siteId && Boolean(formik.errors.siteId)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth
							disabled={formik.values.companyId === '' || props.hierarchy.companies.find(company => company.id === formik.values.companyId)?.sites.length === 0}>
							<option value='' disabled />

							{formik.values.companyId !== '' ? (
								props.hierarchy.companies
									.find(company => company.id === formik.values.companyId)
									?.sites.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
									.map(site => (
										<option key={site.id} id={site.id} value={site.id}>
											{site.name}
										</option>
									))
							) : (
								<option id={'none'} value={'none'} disabled>
									{'none'}
								</option>
							)}
						</TextField>
						<TextField
							id='craneNumber'
							name='craneNumber'
							label={t('craneNumber')}
							value={formik.values.craneNumber}
							select
							SelectProps={{
								native: true,
							}}
							helperText={formik.touched.craneNumber ? formik.errors.craneNumber : ''}
							error={formik.touched.craneNumber && Boolean(formik.errors.craneNumber)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth
							disabled={!formik.values.companyId || !formik.values.siteId}>
							<option value='' disabled />
							{generateCranesNumberOption(props.hierarchy.companies, formik.values.companyId, formik.values.siteId)}
						</TextField>
						<div className={styles.formButtons}>
							<Button type='submit' variant='outline-primary' className={styles.button}>
								{t('update')}
							</Button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</RTL>
	);
};

export default CranePositionModal;
