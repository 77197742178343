import React, { useState } from 'react';
import {
	Table,
	ConfigProvider,
	Tag,
	Button,
	Popover,
	Input,
	Space,
} from 'antd';
import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	MoreOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import he_IL from 'antd/es/locale/he_IL';
import styles from '../DesktopTable.module.scss';
import { showRangeAndTotal } from 'src/services/services';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { Operator } from 'src/DataTypes/Operator';
import moment from 'moment';
import { ECraneType } from 'src/DataTypes/Crane';

type Props = {
	dataSource?: Operator[];
	setCurrentOperator: (operator: Operator) => void;
	popoverContent: any;
};

export const expDateTag = (date: Date) => {
	const now = moment(new Date());
	const exp = moment(date);
	const diff = exp.diff(now, 'days');
	if (diff < 1) {
		return <Tag color='error'>{exp.format('DD/MM/YYYY')}</Tag>;
	} else if (diff >= 1 && diff < 100) {
		return <Tag color='warning'>{exp.format('DD/MM/YYYY')}</Tag>;
	} else {
		return <Tag color='blue'>{exp.format('DD/MM/YYYY')}</Tag>;
	}
};

export const imageCountTag = (imageCount: number) => {
	return imageCount > 0 ? (
		<Tag style={{ maxWidth: 'max-content' }} color='blue'>
			{imageCount}
		</Tag>
	) : (
		<Tag style={{ maxWidth: 'max-content' }} color='error'>
			{imageCount}
		</Tag>
	);
};

const UsersSettingsTable = (props: Props) => {
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const { t } = useTranslation();

	const getColumnSearchProps = dataIndex => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
		}) => (
			<div style={{ padding: 8 }}>
				<Input
					placeholder={`${t('search')} ${t(dataIndex)}`}
					value={selectedKeys[0]}
					onChange={e =>
						setSelectedKeys(e.target.value ? [e.target.value] : [])
					}
					onPressEnter={() =>
						handleSearch(selectedKeys, confirm, dataIndex)
					}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type='primary'
						onClick={() =>
							handleSearch(selectedKeys, confirm, dataIndex)
						}
						icon={<SearchOutlined />}
						size='small'
						style={{ width: 90 }}>
						{t('search')}
					</Button>
					<Button
						onClick={() => handleReset(clearFilters)}
						size='small'
						style={{ width: 90 }}>
						{t('reset')}
					</Button>
					{/* <Button
						type='link'
						size='small'
						onClick={() => {
							confirm({ closeDropdown: false });
							setSearchText(selectedKeys[0]);
							setSearchedColumn(dataIndex);
						}}>
						Filter
					</Button> */}
				</Space>
			</div>
		),
		filterIcon: filtered => (
			<SearchOutlined
				style={{
					color: filtered ? '#1890ff' : undefined,
					marginRight: '1rem',
					fontSize: '18px',
				}}
			/>
		),
		onFilter: (value, record) =>
			record[dataIndex]
				? record[dataIndex]
						.toString()
						.toLowerCase()
						.includes(value.toLowerCase())
				: '',
		render: text =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			),
	});

	const columns: any = [
		{
			title: `${t('fullName')}`,
			dataIndex: 'fullName',
			key: 'fullName',
			defaultSortOrder: 'ascend',
			width: '15%',
			isUseDefaultSearch: true,
			sorter: (a: Operator, b: Operator) =>
				sortFunction(a.fullName, b.fullName),
			sortDirections: ['descend', 'ascend'],
			...getColumnSearchProps('fullName'),
		},
		{
			title: `${t('licenseId')}`,
			dataIndex: 'licenseId',
			key: 'licenseId',
			width: '15%',
			sorter: (a: Operator, b: Operator) =>
				sortFunction(a.licenseId, b.licenseId),
			sortDirections: ['descend', 'ascend'],
			...getColumnSearchProps('licenseId'),
		},
		{
			title: `${t('operatorId')}`,
			dataIndex: 'operatorId',
			key: 'operatorId',
			width: '15%',
			sorter: (a: Operator, b: Operator) =>
				sortFunction(a.operatorId, b.operatorId),
			sortDirections: ['descend', 'ascend'],
			...getColumnSearchProps('operatorId'),
		},

		{
			title: `${t('birthday')}`,
			dataIndex: 'birthday',
			key: 'birthday',
			width: '15%',
			sorter: (a: Operator, b: Operator) =>
				sortFunction(a.birthday, b.birthday),
			sortDirections: ['descend', 'ascend'],
			render: (date: Date) => <>{moment(date).format('DD/MM/YYYY')}</>,
		},
		{
			title: t('licenseExpirationDate'),
			dataIndex: 'licenseExpirationDate',
			key: 'licenseExpirationDate',
			width: '15%',
			sorter: (a: Operator, b: Operator) =>
				sortFunction(a.licenseExpirationDate, b.licenseExpirationDate),
			sortDirections: ['descend', 'ascend'],
			render: (date: Date) => {
				return expDateTag(date);
			},
		},
		{
			title: t('type'),
			dataIndex: 'type',
			key: 'type',
			width: '15%',
			sorter: (a: Operator, b: Operator) => sortFunction(a.type, b.type),
			sortDirections: ['descend', 'ascend'],
			...getColumnSearchProps('type'),
			render: (type: ECraneType) => <>{t(type?.toLowerCase())}</>,
		},
		{
			title: t('imageCount'),
			dataIndex: 'imageCount',
			key: 'imageCount',
			width: '10%',
			sorter: (a: Operator, b: Operator) =>
				sortFunction(a.imageCount, b.imageCount),
			sortDirections: ['descend', 'ascend'],
			render: (imageCount: number) => imageCountTag(imageCount),
		},
		{
			render: (operator: Operator) => (
				<Popover
					placement='right'
					trigger='click'
					content={props.popoverContent}>
					<MoreOutlined
						onClick={() => props.setCurrentOperator(operator)}
					/>
				</Popover>
			),
		},
	];

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = clearFilters => {
		clearFilters();
		setSearchText('');
	};

	const sortFunction = (a, b) => {
		let sortA;
		let sortB;

		switch (typeof a) {
			case 'string':
				sortA = t(a?.toLowerCase());
				sortB = t(b?.toLowerCase());
				break;

			default:
				sortA = a;
				sortB = b;
				break;
		}

		if (sortA < sortB) {
			return -1;
		}
		if (sortA > sortB) {
			return 1;
		}

		return 0;
	};

	return (
		<ConfigProvider
			direction={i18n.language == 'he' ? 'rtl' : 'ltr'}
			locale={i18n.language == 'he' ? he_IL : undefined}>
			<Table
				className={styles.desktopTable}
				dataSource={props.dataSource}
				columns={columns}
				pagination={{ pageSize: 10, showTotal: showRangeAndTotal }}
			/>
		</ConfigProvider>
	);
};

export default UsersSettingsTable;
