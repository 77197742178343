import axios from 'axios';
import moment from 'moment';
import { OperatorDetails } from './Operator';

export interface IOperationSummary {
	deviceId: string;
	craneId: string;
	date: Date | string;
	id: string;
	createdTime: Date | string;
	intervals: Interval[];
	secondsOfOperation: number;
}
export interface IOperationSummaryData {
	deviceId: string;
	craneId: string;
	date: Date | string;
	id: string;
	createdTime: Date | string;
	intervals: Interval[];
	secondsOfOperation: number;
}

export interface IInterval {
	start: Date;
	end: Date;
	operator?: OperatorDetails;
}
export interface IIntervalData {
	start: Date;
	end: Date;
	operator?: OperatorDetails;
}

export interface IQueryOperationSummary {
	GetActivitySummary(startTime: Date, endTime: Date);
}

export class OperationSummary implements IOperationSummary {
	deviceId: string;
	craneId: string;
	date: Date | string;
	id: string;
	createdTime: Date | string;
	intervals: Interval[];
	secondsOfOperation: number;

	constructor(data: IOperationSummaryData) {
		this.deviceId = data.deviceId;
		this.craneId = data.craneId;
		this.date = new Date(data.date);
		this.id = data.id;
		this.createdTime = new Date();
		this.intervals = data.intervals.map((i: IIntervalData) => new Interval(i)).sort((a, b) => (a.start < b.start ? 1 : -1));
		this.secondsOfOperation = data.secondsOfOperation;
	}

	static Query(startTime: Date, endTime: Date, deviceIds?: string[]) {
		// return new Promise<{ data }>((resolve, reject) => {
		// 	setTimeout(() => resolve({ data: testData }), 1000);
		// });
		return axios.post(`/Summary/GetMultiple`, deviceIds, {
			params: {
				startTime,
				endTime,
			},
		});
	}

	static CalcTotalHours(operationSummaryArr: OperationSummary[]) {
		return (operationSummaryArr.map((item: OperationSummary) => item.secondsOfOperation).reduce((a, b) => a + b, 0) / 3600).toFixed(2);
	}

	CalcDailyPercent(dailyWorkingHours: number) {
		return `${((this.secondsOfOperation / 3600 / (dailyWorkingHours ?? 12)) * 100).toFixed(2)}%`;
	}

	CalcTotalDailyHours() {
		// return new Date(this.secondsOfOperation * 1000).toISOString().substr(11, 8);
		return moment.utc(this.secondsOfOperation * 1000).format('HH:mm:ss');
	}
}

export class Interval implements IInterval {
	start: Date;
	end: Date;
	operator?: OperatorDetails;

	constructor(data: IIntervalData) {
		this.start = new Date(data.start);
		this.end = new Date(data.end);
		this.operator = data.operator ? new OperatorDetails(data.operator) : undefined;
	}
}
