import { Crane } from './Crane';
import { DateToString } from '../services/services';

export interface INotificationData {
	id: string;
	deviceId: string;
	type: string;
	dataJson: string;
	createdTime: string;
	createdDate: Date;
}

export interface INotification {
	id: string;
	deviceId: string;
	type: string;
	createdTime: string;
	createdDate: Date;
	data: any;
	crane: Crane;
}

/**
 * Represents a single notification
 */
export class Notification implements INotification {
	id: string;
	deviceId: string;
	type: string;
	createdTime: string;
	createdDate: Date;
	data: any;
	crane: Crane;

	constructor(data: INotificationData, crane: Crane) {
		this.id = data.id;
		this.deviceId = data.deviceId;
		this.type = data.type;
		this.data = JSON.parse(data.dataJson);
		this.createdTime = DateToString(new Date(data.createdTime));
		this.createdDate = new Date(data.createdTime);
		this.crane = crane;
	}
}
