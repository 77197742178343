import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from 'react-i18next';

import './App.scss';
import Toolbar from './components/Toolbar/Toolbar';
import Sidebar from './components/Sidebar/Sidebar';
import Crane from '../src/components/Crane/Crane';
import NotificationsPage from './components/NotificationsPage/NotificationsPage';
import UserProfile from '../src/components/UserProfile/UserProfile';
import Settings from './components/Settings/Settings';
import Spinner from './components/UI/Spinner/Spinner';
import NewItem from './components/NewItem/NewItem';

import { Hierarchy } from './DataTypes/Hierarchy';
import { MyProfile } from './DataTypes/MyProfile';
import { NotificationManager } from './DataTypes/NotificationManager';
import ErrorModal from './components/Modals/ErrorModal/ErrorModal';
import LoadingModal from './components/UI/LoadingModal/LoadingModal';
import LoadingModalProvider from './contexts/LoadingModalProvider';
import { Logout } from './auth';
import Wrapper from './components/Wrapper/Wrapper';
import { Config } from './DataTypes/Config';

type Props = {
	account: any;
};

const App: React.FC<Props> = props => {
	const [showAddItem, setShowAddItem] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [hierarchy, setHierarchy] = useState<Hierarchy>();
	const [myProfile, setMyProfile] = useState<MyProfile>();
	const [config, setConfig] = useState<Config>();
	const { t, i18n } = useTranslation();
	document.body.dir = i18n.dir();

	useEffect(() => {
		axios.defaults.headers.common['access_token'] =
			props.account.accountIdentifier;
		//axios.defaults.baseURL = 'https://vl-app-dev.azurewebsites.net';

		axios
			.get('/User')
			.then(response => {
				const profile = new MyProfile(response.data);
				setMyProfile(profile);
				i18n.changeLanguage(profile.localeSettings.language);
			})
			.catch(error => (
				<ErrorModal
					statusCode={error.response?.status}
					content={error}
				/>
			))
			.then(() => {
				return loadData();
			});

		Config.FetchConfig().then(cfg => {
			if (cfg == null) {
				Logout();
				return;
			}
			setConfig(cfg);
		});
	}, []);

	const loadData = async (hierarchy?: Hierarchy) => {
		let notificationsCache =
			hierarchy?.notificationManager ??
			new NotificationManager(
				MyProfile.Current.notifications.lastSeenDate
			);

		let fetchedHierarchy = await Hierarchy.FetchHierarchy();
		if (fetchedHierarchy == null) {
			Logout();
			return;
		}

		fetchedHierarchy.notificationManager = notificationsCache;
		fetchedHierarchy.notificationManager.allCranes =
			fetchedHierarchy.cranes;

		// setState({ loading: false });

		let sc = await fetchedHierarchy.FetchSubscriptionCredit();
		if (sc == null) {
			apiFailed(fetchedHierarchy, 'Failed to FetchSubscriptionCredit');
			return;
		}

		let deviceStatuses = await fetchedHierarchy.FetchDeviceStatuses();
		if (deviceStatuses == null) {
			apiFailed(fetchedHierarchy, 'Failed to FetchDeviceStatuses');
			return;
		}

		setHierarchy(fetchedHierarchy);
		setIsLoading(false);

		await fetchedHierarchy.notificationManager.RealtimePoll();

		setTimeout(loadData, 2000, fetchedHierarchy);
	};

	const apiFailed = (fetchedHierarchy: Hierarchy, reason: string) => {
		console.log(reason);
		setIsLoading(true);

		setTimeout(loadData, 8000, fetchedHierarchy);
	};

	const newItemHandler = () => {
		const show = showAddItem;
		setShowAddItem(!show);
	};

	if (isLoading || hierarchy === undefined || myProfile === undefined) {
		return <Spinner />;
	}

	return (
		<LoadingModalProvider>
			<div className='App'>
				<Router>
					<LoadingModal />
					{/* <HierarchyProvider value={hierarchy}> */}
					<Sidebar
						hierarchy={hierarchy}
						openHandler={newItemHandler}
						currentUser={myProfile}
					/>
					<Toolbar
						hierarchy={hierarchy}
						myProfile={myProfile}
						{...props}
					/>
					<div className='main'>
						<NewItem
							show={showAddItem}
							hierarchy={hierarchy}
							handleClose={newItemHandler}
						/>

						<Route
							exact
							path='/'
							render={props => (
								<Wrapper hierarchy={hierarchy} {...props} />
							)}
						/>
						<Route
							exact
							path='/cranes-status'
							render={props => (
								<Wrapper hierarchy={hierarchy} {...props} />
							)}
						/>
						<Route
							path='/comp/:id'
							render={props => {
								let company = hierarchy.companies.find(
									c => c.id === props.match.params.id
								);
								if (!company) {
									return 'Company not found';
								}

								return (
									<Wrapper
										hierarchy={hierarchy}
										item={company}
										{...props}
									/>
								);
							}}
						/>
						<Route
							path='/site/:id'
							render={props => {
								let site = hierarchy.sites.find(
									s => s.id === props.match.params.id
								);
								if (!site) {
									return 'Site not found';
								}

								return (
									<Wrapper
										hierarchy={hierarchy}
										item={site}
										{...props}></Wrapper>
								);
							}}
						/>
						<Route
							path='/crane/:id'
							render={props => {
								let crane = hierarchy.cranes.find(
									c => c.id === props.match.params.id
								);
								if (!crane) {
									return 'Crane not found';
								}

								return (
									<Crane
										hierarchy={hierarchy}
										crane={crane}
										{...props}></Crane>
								);
							}}
						/>
						<Route
							path='/notifications'
							render={() => (
								<NotificationsPage hierarchy={hierarchy} />
							)}
						/>
						<Route
							path='/userProfile'
							render={() => (
								<UserProfile
									hierarchy={hierarchy}
									myProfile={myProfile}
								/>
							)}
						/>
						{myProfile?.permissions?.settingsCRUD?.read && (
							<Route
								path='/settings'
								render={() => (
									<Settings
										hierarchy={hierarchy}
										myProfile={myProfile}
									/>
								)}
							/>
						)}
					</div>

					{/* </HierarchyProvider> */}
				</Router>
			</div>
		</LoadingModalProvider>
	);
};

export default App;
