import React from 'react';
import { INotification } from 'src/DataTypes/Notification';
import { NotificationContent } from '../../ReadNotification/ReadNotification';
import styles from './CraneNotification.module.scss';

type props = {
	notification: INotification;
};

const CraneNotification: React.FC<props> = props => {
	return (
		<div className={styles.notificationCard}>
			<div className={styles.content}>
				<NotificationContent type={props.notification.type} data={props.notification?.data} />
			</div>
			<div className={styles.time}>{props.notification?.createdTime}</div>
		</div>
	);
};

export default CraneNotification;
