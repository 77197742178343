import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from '../CraneActivity/CraneActivity.module.scss';
import OperationAverage from '../../Charts/OperationAverage/OperationAverage';
import CraneActivityTable from '../../Tables/CraneActivityTable/CraneActivityTable';
import { Crane } from '../../../DataTypes/Crane';
import MobileCraneActivityTable from 'src/components/Mobile/Tables/MobileCraneActivityTable/MobileCraneActivityTable';
import { OperationSummary } from 'src/DataTypes/OperationSummary';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PDFlogo } from '../../../assets/icons/pdf.svg';
import ReportGeneratorModal, { Dates } from 'src/components/Modals/ReportGeneratorModal/ReportGeneratorModal';
import Activity3d from '../Activity3d/Activity3d';
import cn from 'classnames';
import CraneActivityReport from 'src/components/PdfDocuments/CraneActivityReport';

type props = {
	crane: Crane;
};

const CraneActivity: React.FC<props> = props => {
	const [operationSummaryArr, setOperationSummaryArr] = useState<OperationSummary[]>([]);
	const { t } = useTranslation();
	const [showReportGeneratorModal, setShowReportGeneratorModal] = useState(false);

	useEffect(() => {
		let promise = props.crane.GetActivitySummary(new Date('1970-01-01T00:00:00.000Z'), new Date());
		promise
			.then(result => {
				setOperationSummaryArr(result.data.map(c => new OperationSummary(c)));
			})
			.catch(err => {
				setOperationSummaryArr([]);
				console.log(err);
			});
	}, []);

	//TODO
	const generateDocFunc = async (dates: Dates): Promise<JSX.Element> => {
		const arr = operationSummaryArr.filter(o => new Date(o.date) > new Date(dates.startTime) && new Date(o.date) < new Date(dates.endTime));
		return <CraneActivityReport operationSummaryArr={arr} crane={props.crane} dailyWorkingHours={12} dates={dates} />;
	};

	return (
		<div className={styles.wrapper}>
			{showReportGeneratorModal && <ReportGeneratorModal crane={props.crane} generateDocFunc={generateDocFunc} dailyWorkingHours={12} closeHandler={() => setShowReportGeneratorModal(false)} />}

			<div className={cn(styles.card, styles.a)}>
				<div className={styles.title}>{t('activityHistory')}</div>
				{/* <OperationAverage item={props.crane} /> */}
				<Activity3d crane={props.crane} />
			</div>

			<div className={cn(styles.card, styles.b)}>
				<div className={styles.header}>
					<div className={styles.title}>{t('activityHoursTable')}</div>
					<PDFlogo className={styles.generatePdfButton} onClick={() => setShowReportGeneratorModal(true)} />
				</div>
				<div className={styles.activityTable}>
					{!operationSummaryArr || operationSummaryArr.length === 0 ? (
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('noData')} />
					) : (
						<>
							<CraneActivityTable operationSummaryArr={operationSummaryArr} />
							<MobileCraneActivityTable operationSummaryArr={operationSummaryArr} />
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default CraneActivity;
