import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { Container, Row, Col } from 'react-bootstrap';
import Switch from 'react-ios-switch';
import styles from './CraneGeneral.module.scss';
import './../../../css/mixins.scss';
import cn from 'classnames';
import { Modal } from 'react-bootstrap';
import { Empty, Tag, Tooltip } from 'antd';
import ImgLoader from '../../UI/ImgLoader/ImgLoader';
import MfaModal from '../../Modals/MfaModal/MfaModal';
import { MyProfile } from '../../../DataTypes/MyProfile';
import CraneNotifications from '../../Notification/CraneNotifications/CraneNotifications';
import { DateToString, Compass } from '../../../services/services';
import {
	DeviceStatus,
	ERecognitionStatus,
} from '../../../DataTypes/DeviceStatus';
import { Crane, ECraneStatus, EDeviceStatus } from '../../../DataTypes/Crane';
import { useTranslation } from 'react-i18next';

import { ReactComponent as AlertIcon } from '../../../assets/icons/alert-circle.svg';
import { ReactComponent as ChevronIcon } from '../../../assets/icons/chevron-rounded.svg';
import { ReactComponent as MoreIcon } from '../../../assets/icons/notification-more.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/sidebar-close.svg';
import { ReactComponent as OperatorIcon } from '../../../assets/icons/operator.svg';
import { ReactComponent as WindIcon } from '../../../assets/icons/air.svg';
import { ReactComponent as ToolIcon } from '../../../assets/icons/tool.svg';
import { ReactComponent as SensorIcon } from '../../../assets/icons/sensor.svg';
import { ReactComponent as PowerIcon } from '../../../assets/icons/power.svg';
import { ReactComponent as NightIcon } from '../../../assets/icons/night.svg';
import { ReactComponent as LockIcon } from '../../../assets/icons/lock.svg';
import { ReactComponent as DisconnectedIcon } from '../../../assets/icons/disconnected.svg';
import { ReactComponent as OfflineCloudIcon } from '../../../assets/icons/offline-cloud.svg';

import useOutsideClick from '../../../utils/useOutsideClick';
import CraneNotification from 'src/components/Notification/CraneNotifications/CraneNotification/CraneNotification';
import moment from 'moment';
import { OperationSummary } from 'src/DataTypes/OperationSummary';
import CheckButton, { Size } from 'src/components/UI/CheckButton/Checkbutton';
import OperationAverage from 'src/components/Charts/OperationAverage/OperationAverage';
import { Operator } from 'src/DataTypes/Operator';

type props = {
	crane: Crane;
};

const CraneGeneral: React.FC<props> = props => {
	const history = useHistory();
	const controlsRef = useRef<HTMLDivElement>(null);
	const { t } = useTranslation();

	const [imageData, setImageData] = useState(null);
	const [isShowImage, setIsShowImage] = useState(false);
	const [undefined, setUndefined] = useState(t('undefined'));
	const [showPowerMfa, setShowPowerMfa] = useState(false);
	const [showPowerMfaValue, setShowPowerMfaValue] = useState(false);
	const [onOff, setOnOff] = useState(false);
	const [currentUser, setCurrentUser] = useState<MyProfile>(
		MyProfile.Current
	);
	const [operator, setOperator] = useState<Operator | null>(null);
	const [totalHoursOfOperation, setTotalHoursOfOperation] =
		useState<number>();

	const [openCraneStatus, setOpenCraneStatus] = useState(false);
	const [openCraneControl, setOpenCraneControl] = useState(false);

	const takeSnapshot = () => {
		if (props.crane.deviceId == null) {
			window.alert('Device Id not found');
			//TODO
			return;
		}

		setImageData(null);
		setIsShowImage(true);

		props.crane.GetOperatorImage().then(response => {
			let data = response.data;
			setImageData(data);
			setIsShowImage(true);
		});
	};

	const closeModalHandler = () => {
		setIsShowImage(false);
	};

	const SetPower = checked => {
		setShowPowerMfa(true);
		setShowPowerMfaValue(checked);
	};
	const sendTokenFunc = () => {
		return props.crane.SetPower(true);
	};

	const verifyTokenFunc = token => {
		return props.crane.SetPowerVerify(token);
	};

	const handleClose = () => {
		setShowPowerMfa(false);
	};

	const onScroll = () => {
		if (openCraneControl) setOpenCraneControl(false);
	};

	useEffect(() => {
		window.addEventListener('scroll', onScroll);

		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	});

	useEffect(() => {
		let promise = props.crane.GetActivitySummary(
			new Date('1970-01-01T00:00:00.000Z'),
			new Date()
		);
		promise
			.then(result => {
				setTotalHoursOfOperation(
					Math.ceil(
						result.data
							.map(
								(item: OperationSummary) =>
									item.secondsOfOperation
							)
							.reduce((a, b) => a + b) / 3600
					) ?? 0
				);
			})
			.catch(err => {
				setTotalHoursOfOperation(0);
				console.log(err);
			});

		let operatorId = props.crane.deviceStatus?.recognition?.operatorId;
		if (!operatorId) {
			setOperator(null);
		} else {
			Operator.GetOperator(operatorId).then(res => {
				setOperator(res);
			});
		}
	}, []);

	useOutsideClick(controlsRef, () => {
		if (openCraneControl) setOpenCraneControl(false);
	});

	const getLastOperatedElement = () => {
		const result = props.crane?.getLastOperated();
		if (!result) {
			return '-';
		}
		if (result instanceof Date) {
			return moment(result).format('HH:mm:ss DD/MM/YYYY');
		}

		return <Tag color='blue'>{t('activeNow')}</Tag>;
	};

	const generateNotification = (n: any, index) => {
		return <CraneNotification notification={n} key={index} />;
	};

	const getAnalogInputText = (inputName, units) => {
		const input =
			props.crane.deviceStatus?.GetAnalogInputValueInUnits(inputName);
		if (!input) {
			return t('undefined');
		}
		return `${input?.toFixed(0)} ${t(units)}`;
	};

	const getAverageInputText = (inputName, units) => {
		const input =
			props.crane.deviceStatus?.GetAverageInputValueInUnits(inputName);
		if (!input) {
			return t('undefined');
		}
		return `${input?.toFixed(0)} ${t(units)}`;
	};

	const getTotalHoursOfOperationText = () => {
		if (!totalHoursOfOperation) {
			return `${t('total')} - ${t('unknown')} ${t('hours')}`;
		}
		return `${t('total')} - ${totalHoursOfOperation} ${t('hours')}`;
	};

	return (
		<Container fluid className={styles.wrapper}>
			<MfaModal
				show={showPowerMfa}
				handleClose={handleClose}
				sendTokenFunc={() => sendTokenFunc()}
				verifyTokenFunc={t => verifyTokenFunc(t)}
				userPhone={MyProfile.Current.phone.number}
			/>
			<Modal
				onClick={() => setIsShowImage(false)}
				className={styles.modal}
				aria-labelledby='contained-modal-title-vcenter'
				centered
				show={isShowImage}
				onHide={closeModalHandler}>
				{imageData ? (
					<img
						className={styles.operatorImg}
						src={`data:image/jpeg;base64,${imageData}`}
						onClick={closeModalHandler}
					/>
				) : (
					<div className={styles.imgLoading}>
						<ImgLoader />
						<div className={styles.loading}>
							{t('loadingOperatorImage')}
						</div>
					</div>
				)}
			</Modal>

			<div
				className={cn(
					styles.mainCard,
					!props.crane.deviceStatus?.isConnected
						? styles.disconnected
						: props.crane.deviceStatus?.isPowered
						? styles.isPowered
						: styles.connected
				)}>
				{/*Circle Alert (Crane isNotConnected) */}
				<AlertIcon
					className={styles.badge}
					display={
						props.crane.deviceStatus?.isConnected
							? 'none'
							: 'initial'
					}
				/>
				{!props.crane.deviceStatus ? (
					<Empty
						image={
							<OfflineCloudIcon
								style={{
									marginTop: '1rem',
									width: '70px',
									height: '70px',
									opacity: '0.5',
								}}
							/>
						}
						description={t('craneNotAssociatedToBB')}
						style={{ marginTop: '1rem', color: '#ff5143' }}
						imageStyle={{}}
					/>
				) : props.crane.deviceStatus?.isConnected ? (
					<>
						<div className={styles.top}>
							{/*Operator */}
							<div>
								{props.crane.deviceStatus?.recognition?.isStatusOk() ? (
									<Tooltip
										placement='top'
										title={t('clickToViewOperator')}>
										<OperatorIcon
											className={styles.craneIcon}
											style={{ cursor: 'pointer' }}
											onClick={takeSnapshot}
										/>
									</Tooltip>
								) : (
									<OperatorIcon
										className={cn(
											styles.craneIcon,
											styles.inactive
										)}
									/>
								)}
								<p className={styles.craneP}>
									{props.crane.deviceStatus?.recognition?.isStatusOk()
										? t('operatorInCabin')
										: t('noOperatorInCabin')}
								</p>
							</div>

							{/*Wind */}
							<div>
								<Tooltip
									placement='top'
									title={t('averageWindSpeed')}>
									<WindIcon className={styles.craneIcon} />
								</Tooltip>

								<p className={styles.craneP}>
									{getAverageInputText('Wind', 'km/h')}
								</p>
							</div>
							{/*Crane isFault? */}
							<div>
								<Tooltip
									placement='top'
									title={t('craneMaintenanceStatus')}>
									<ToolIcon className={styles.craneIcon} />
								</Tooltip>

								<p className={styles.craneP}>
									{t(props.crane.GetStatus())}
								</p>
							</div>
							<div
								className={cn(
									styles.switchButtons,
									openCraneControl && styles.open
								)}
								ref={controlsRef}>
								<CloseIcon
									className={styles.closeCraneControls}
									onClick={() => setOpenCraneControl(false)}
								/>
								<div className={styles.switchButton}>
									<CheckButton
										icon={PowerIcon}
										checked={
											props.crane.deviceStatus
												?.isPowered! &&
											props.crane.deviceStatus.isConnected
										}
										disabled={
											!currentUser?.permissions
												.cranePowerCRUD.update ||
											props.crane.deviceId === null ||
											!props.crane.deviceStatus
												?.isConnected
										}
										// disabled={false}
										size={Size.md}
										onClick={() =>
											SetPower(
												!props.crane.deviceStatus
													?.isPowered
											)
										}
									/>
								</div>
								<div className={styles.switchButton}>
									<CheckButton
										icon={NightIcon}
										checked={
											props.crane.deviceStatus
												?.isReleased! &&
											props.crane.deviceStatus.isConnected
										}
										disabled={
											props.crane.deviceStatus
												?.isReleased ||
											!currentUser?.permissions
												.nightReleaseCRUD.update ||
											props.crane.deviceId === null ||
											!props.crane.deviceStatus
												?.isConnected
										}
										size={Size.md}
									/>
								</div>
								<div
									className={styles.switchButton}
									hidden={
										!currentUser?.permissions.craneLockCRUD
											.read
									}>
									<CheckButton
										icon={LockIcon}
										checked={
											props.crane.deviceStatus?.isLocked!
										}
										disabled={
											!currentUser?.permissions
												.craneLockCRUD.update ||
											props.crane.deviceId === null ||
											!props.crane.deviceStatus
												?.isConnected
										}
										size={Size.md}
									/>
								</div>
							</div>
						</div>
						<MoreIcon
							className={styles.moreControl}
							onClick={() => setOpenCraneControl(true)}
						/>
						<div
							className={styles.expandStatus}
							onClick={() =>
								setOpenCraneStatus(!openCraneStatus)
							}>
							<ChevronIcon
								className={cn(openCraneStatus && styles.open)}
							/>
						</div>
						<div
							className={cn(
								styles.bottom,
								openCraneStatus && styles.open
							)}>
							<div className={styles.detail}>
								<span className={styles.detailKey}>
									<b>{t('turn')}</b>
								</span>
								<span className={styles.detailValue}>
									{getAnalogInputText('Turn', 'degrees')}
								</span>
							</div>
							<div className={styles.detail}>
								<span className={styles.detailKey}>
									<b>{t('trolley')}</b>
								</span>
								<span className={styles.detailValue}>
									{getAnalogInputText('Cart', 'meter')}
								</span>
							</div>
							<div className={styles.detail}>
								<span className={styles.detailKey}>
									<b>{t('lift')}</b>
								</span>
								<span className={styles.detailValue}>
									{getAnalogInputText('Lift', 'meter')}
								</span>
							</div>
							<div className={styles.detail}>
								<span className={styles.detailKey}>
									<b>{t('weight')}</b>
								</span>
								<span className={styles.detailValue}>
									{getAnalogInputText('Weight', 'kg')}
								</span>
							</div>
							{/* <div className={styles.detail}>
						<span className={styles.detailKey}>
							<b>{t('moment')}</b>
						</span>
						<span className={styles.detailValue}>{getText("Moment", "n/m")}</span>
					</div> */}
						</div>
					</>
				) : (
					<Empty
						image={
							<DisconnectedIcon
								style={{
									marginTop: '1rem',
									width: '70px',
									height: '70px',
									opacity: '0.5',
								}}
							/>
						}
						description={t('noConnection')}
						style={{ marginTop: '1rem', color: '#ff5143' }}
					/>
				)}
			</div>

			<div className={cn(styles.craneNotifications)}>
				<div className={styles.title}>{t('latestNotifications')}</div>
				<div className={styles.notifications}>
					<CraneNotifications
						pollingInterval={2000}
						cranes={[props.crane]}
						notificationToItemViewFunc={generateNotification}
					/>
				</div>
			</div>
			<div className={cn(styles.operationAverage)}>
				<div className={styles.title}>{t('activityHoursGraph')}</div>
				<OperationAverage item={props.crane} />
			</div>
			<div className={cn(styles.craneActivity)}>
				<div className={styles.title}>{t('activity')}</div>
				<div>
					<span className={styles.activityKey}>
						{t('lastOperation')}
					</span>
					<span className={styles.activityValue}>
						{getLastOperatedElement()}
					</span>
				</div>
				<div>
					<span className={styles.activityKey}>
						{t('operatorName')}
					</span>
					<span className={styles.activityValue}>
						{operator?.fullName ?? '-'}
					</span>
				</div>

				<div
					className={styles.footerActivity}
					onClick={() =>
						history.push(`${props.crane.id}/crane-activity`)
					}>
					<span>{getTotalHoursOfOperationText()}</span>
				</div>
			</div>
			<div className={cn(styles.craneContact)}>
				<div className={styles.title}>{t('contact')}</div>
				<div>
					<span className={styles.contactKey}>{t('name')}</span>
					<span className={styles.contactValue}>
						{props.crane.site.manager?.name}
					</span>
				</div>
				<div>
					<span className={styles.contactKey}>{t('phone')}</span>
					<span className={styles.contactValue}>
						{props.crane.site.manager?.phone}
					</span>
				</div>
			</div>
		</Container>
	);
};

export default CraneGeneral;
