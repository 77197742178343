export interface IPhone {
	number: string;
	countryCode: string;
}

export class Phone implements IPhone {
	number: string;
	countryCode: string;

	constructor(data: IPhone) {
		this.number = data?.number;
		this.countryCode = data?.countryCode ?? '972';
	}
}
