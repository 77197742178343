import axios from 'axios';

export interface IConfigData {
	googleMapsApiKey: string;
}

export interface IConfig extends IConfigData {}

export class Config implements IConfig {
	private static _current: Config;

	googleMapsApiKey: string;

	private constructor(data: IConfigData) {
		if (Config._current) {
			throw new Error('Config intstance already exists!');
		}

		this.googleMapsApiKey = data.googleMapsApiKey;

		Config._current = this;
	}

	static get Current(): Config {
		return Config._current;
	}

	static FetchConfig(): Promise<Config | null> {
		if (Config._current) {
			return Promise.resolve(Config.Current);
		}
		return axios
			.get('/Config')
			.then(
				res => new Config(res.data),
				rej => {
					console.log(rej);
					return null;
				}
			)
			.catch(res => null);
	}
}
