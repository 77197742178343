import React from 'react';
import styles from './CompanyDetails.module.scss';
import CompanyActiveSitesTable from '../../Tables/CompanyActiveSitesTable/CompanyActiveSitesTable';
import CompanyDetailsTable from '../../Tables/CompanyDetailsTable/CompanyDetailsTable';
import Spinner from '../../UI/Spinner/Spinner';
import { Company } from '../../../DataTypes/Company';

type props = {
	item: Company | undefined;
};

const CompanyDetails: React.FC<props> = props => {
	if (!props.item) {
		return <Spinner />;
	}
	return (
		<div className={styles.wrapper}>
			<CompanyDetailsTable company={props.item}></CompanyDetailsTable>
			<CompanyActiveSitesTable company={props.item}></CompanyActiveSitesTable>
		</div>
	);
};

export default CompanyDetails;
