import React, { useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styles from '../AddContactModal/AddContactModal.module.scss';
import RTL from '../../RTL/RTL';
import { ERole, IUserCreateDetails } from '../../../DataTypes/User';
import { Hierarchy } from '../../../DataTypes/Hierarchy';
import { User } from '../../../DataTypes/User';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { useTranslation } from 'react-i18next';

type props = {
	show: boolean;
	closeHandler: () => void;
	hierarchy: Hierarchy;
	users: string[];
	fromDl?: boolean;
	submitCallback?: (values) => void;
};

interface IDetails {
	mail: string;
	company: string;
	role: ERole;
	canConfirmNotifications: boolean;
}

const NewUserModal: React.FC<props> = props => {
	const { ShowLoadingModal } = useContext(LoadingModalContext);
	const { t } = useTranslation();

	const formik = useFormik({
		initialValues: {
			mail: '',
			company: '',
			role: ERole.CranesManager,
			canConfirmNotifications: false,
		},
		initialTouched: {
			mail: true,
		},
		validationSchema: yup.object({
			mail: yup.string().email().notOneOf(props.users, t('existingUserMail')).required(t('requiredField')),
			company: yup.string().required(t('requiredField')),
			role: yup.string().oneOf(Object.values(ERole)).required(t('requiredField')),
		}),
		onSubmit: (values: IDetails) => {
			const details: IUserCreateDetails = {
				company: values.company,
				mail: values.mail,
				role: values.role ?? ERole.CranesManager,
				notifications: {
					canConfirmNotifications: values.canConfirmNotifications,
				},
			};
			ShowLoadingModal(User.CreateNewUser(details), 'UserCreate').then(res => {
				if (!res.isError && props.fromDl && props.submitCallback) {
					props.submitCallback(details.mail);
					return true;
				}
				return false;
			});
			props.closeHandler();
		},
	});

	return (
		<RTL>
			<Modal size='sm' className={styles.modal} aria-labelledby='contained-modal-title-vcenter' show={props.show} centered onHide={props.closeHandler}>
				<Modal.Header style={{ border: 'none' }} closeButton />
				<div className={styles.title}>{t('UserCreate.Generic')}</div>
				<Modal.Body className={styles.modalBody}>
					<form className={styles.formi} onSubmit={formik.handleSubmit}>
						<TextField
							id='mail'
							label={t('mail')}
							value={formik.values.mail}
							helperText={formik.errors.mail}
							error={formik.touched.mail && Boolean(formik.errors.mail)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth
						/>
						<TextField
							id='company'
							name='company'
							label={t('company')}
							value={formik.values.company}
							select
							SelectProps={{
								native: true,
							}}
							helperText={formik.touched.company ? formik.errors.company : ''}
							error={formik.touched.company && Boolean(formik.errors.company)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth>
							<option value='' disabled />

							{props.hierarchy.companies.map(company => (
								<option key={company.id} id={company.id} value={company.id}>
									{company.name}
								</option>
							))}
						</TextField>
						<TextField
							id='role'
							name='role'
							label={t('role')}
							value={formik.values.role}
							select
							SelectProps={{
								native: true,
							}}
							disabled={props.fromDl}
							helperText={formik.touched.role ? formik.errors.role : ''}
							error={formik.touched.role && Boolean(formik.errors.role)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth>
							<option value='' disabled />
							{Object.values(ERole).map(value => (
								<option key={value} id={value} value={value}>
									{t(value)}
								</option>
							))}
						</TextField>
						<div className={styles.subtitle}>{t('notifications')}</div>
						<div className={styles.checkboxParagraph}>
							<FormControlLabel
								control={<Checkbox checked={formik.values.canConfirmNotifications} onChange={formik.handleChange} name='canConfirmNotifications' color='primary' />}
								label={t('canConfirmNotifications')}
							/>
						</div>

						<div className={styles.formButtons}>
							<Button type='submit' variant='outline-primary' className={styles.button}>
								{t('add')}
							</Button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</RTL>
	);
};

export default NewUserModal;
