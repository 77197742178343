import React, { useState, useEffect } from 'react';
import styles from './Cranes.module.scss';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import NewCraneModal from '../../NewItem/NewCrane/NewCraneModal';
import CraneDetailModal from '../../Modals/CraneDetailModal/CraneDetailModal';
import CraneRemoveModal from '../../Modals/CraneRemoveModal/CraneRemoveModal';
import CraneEditDetailsModal from '../../Modals/CraneEditDetailsModal/CraneEditDetailsModal';
import CranePositionModal from '../../Modals/CranePositionModal/CranePositionModal';
import CraneSettingsTable from '../../Tables/CraneSettingsTable/CraneSettingsTable';
import MobileCraneSettingsTable from '../../Mobile/Tables/MobileCraneSettingsTable/MobileCraneSettingsTable';
import { Hierarchy } from '../../../DataTypes/Hierarchy';
import { Crane } from 'src/DataTypes/Crane';
import { useTranslation } from 'react-i18next';

type Props = {
	hierarchy: Hierarchy;
};

const Cranes: React.FC<Props> = props => {
	const [showNewCraneModal, setShowNewCraneModal] = useState(false);
	const [showCraneDetailModal, setShowCraneDetailModal] = useState(false);
	const [showCraneEditModal, setShowCraneEditModal] = useState(false);
	const [showCranePositionModal, setShowCranePositionModal] = useState(false);
	const [showCraneRemoveModal, setShowCraneRemoveModal] = useState(false);
	const [currentCrane, setCurrentCrane] = useState<Crane>();
	const { t } = useTranslation();

	const newCraneModalHandler = () => {
		const show = showNewCraneModal;
		setShowNewCraneModal(!show);
	};

	const CraneDetailModalHandler = () => {
		const show = showCraneDetailModal;
		setShowCraneDetailModal(!show);
	};
	const CraneEditModalHandler = () => {
		const show = showCraneEditModal;
		setShowCraneEditModal(!show);
	};
	const CranePositionModalHandler = () => {
		const show = showCranePositionModal;
		setShowCranePositionModal(!show);
	};
	const CraneRemoveModalHandler = () => {
		const show = showCraneRemoveModal;
		setShowCraneRemoveModal(!show);
	};

	const popover = (
		<div className={styles.moreActions}>
			<div role='button' className={styles.action} onClick={CraneDetailModalHandler}>
				<span>{t('moreDetails')}</span>
			</div>
			<div role='button' className={styles.action} onClick={CraneEditModalHandler}>
				<span>{t('editCraneDetails')}</span>
			</div>
			<div role='button' className={styles.action} onClick={CranePositionModalHandler}>
				<span>{t('changeCranePosition')}</span>
			</div>
			{/* <div role='button' className={styles.action} onClick={CraneRemoveModalHandler}>
				<span>הסרת המנוף</span>
			</div> */}
		</div>
	);

	return (
		<>
			{showNewCraneModal && <NewCraneModal handleClose={newCraneModalHandler} show={showNewCraneModal} hierarchy={props.hierarchy} />}
			{showCraneDetailModal && <CraneDetailModal show={showCraneDetailModal} closeHandler={CraneDetailModalHandler} crane={currentCrane!} />}
			{showCraneEditModal && <CraneEditDetailsModal show={showCraneEditModal} showEditModalHandler={CraneEditModalHandler} crane={currentCrane!} />}
			{showCranePositionModal && <CranePositionModal show={showCranePositionModal} showModalHandler={CranePositionModalHandler} crane={currentCrane!} hierarchy={props.hierarchy} />}
			{showCraneRemoveModal && <CraneRemoveModal show={showCraneRemoveModal} showRemoveModalHandler={CraneRemoveModalHandler} />}
			<div className={styles.card}>
				<div className={styles.cardHeader}>
					<div className={styles.cardTitle}>{t('cranes')}</div>
					<Button onClick={newCraneModalHandler} type='primary' shape='circle' className='circular-add-btn' icon={<PlusOutlined />} />
				</div>

				<CraneSettingsTable dataSource={props.hierarchy.cranes} popover={popover} CraneDetailModalHandler={CraneDetailModalHandler} setCurrentCrane={setCurrentCrane} />
				<MobileCraneSettingsTable
					popover={popover}
					cranes={props.hierarchy.cranes}
					CraneDetailModalHandler={CraneDetailModalHandler}
					CraneEditModalHandler={CraneEditModalHandler}
					CranePositionModalHandler={CranePositionModalHandler}
					setCurrentCrane={setCurrentCrane}
				/>
			</div>
		</>
	);
};

export default Cranes;
