import React, { useState } from 'react';
import Spinner from '../../UI/Spinner/Spinner';
import styles from './SiteCranesTable.module.scss';
import { ReactComponent as ChevronIcon } from '../../../assets/icons/chevron-rounded.svg';
import cn from 'classnames';
import { Site } from '../../../DataTypes/Site';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';

type props = {
	site: Site;
};

const SiteCranesTable: React.FC<props> = props => {
	const [openCraneIds, setOpenCraneIds] = useState<string[]>([]);
	const { t } = useTranslation();

	const toggleOpenCraneId = (id: string) => {
		if (window.innerWidth >= 1200) return;

		if (openCraneIds.includes(id)) {
			setOpenCraneIds(openCraneIds.filter(_id => _id !== id));
		} else {
			setOpenCraneIds([...openCraneIds, id]);
		}
	};

	if (!props.site) {
		return <Spinner />;
	}
	return (
		<div className={styles.card}>
			<span className={styles.cardTitle}>{t('activeCranes')}</span>
			{props.site.cranes.length === 0 ? (
				<Empty description={t('noCranes')} image={Empty.PRESENTED_IMAGE_SIMPLE} />
			) : (
				<table className={cn('table', 'table-borderless', styles.mobileTable)}>
					<thead className={styles.thead}>
						<tr>
							<th scope='col'>{t('number')}</th>
							<th scope='col'>{t('model')}</th>
							<th scope='col'>{t('craneType')}</th>
							<th scope='col'>{t('sn')}</th>
							<th scope='col'>{t('manufacturer')}</th>
							<th scope='col'>{t('blackbox')}</th>
						</tr>
					</thead>
					<tbody className={styles.tbody}>
						{props.site.cranes.map((crane, index) => {
							return (
								<tr key={index} className={cn(styles.tcard, openCraneIds.includes(crane.id) && styles.open)}>
									<td className={styles.tname} onClick={() => toggleOpenCraneId(crane.id)}>
										<ChevronIcon />
										<span>{crane.number}</span>
									</td>
									<td>
										<span className={styles.theaders}>{t('model')}</span>
										<span>{crane.model}</span>
									</td>
									<td>
										<span className={styles.theaders}>{t('craneType')}</span>
										<span>{t(crane.type.toLowerCase())}</span>
									</td>
									<td>
										<span className={styles.theaders}>{t('sn')}</span>
										<span>{crane.sn}</span>
									</td>
									<td>
										<span className={styles.theaders}>{t('manufacturer')}</span>
										<span>{crane.manufacturer}</span>
									</td>
									<td>
										<span className={styles.theaders}>{t('blackbox')}</span>
										<span>{crane.blackboxId || t('notAssociated')}</span>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			)}
		</div>
	);
};

export default SiteCranesTable;
