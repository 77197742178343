import React, { Component, useState, useEffect } from 'react';
import styles from './NewItem.module.scss';
import { Modal, Button } from 'react-bootstrap';
import NewCompanyModal from './NewCompany/NewCompanyModal';
import NewSiteModal from './NewSite/NewSiteModal';
import NewCraneModal from './NewCrane/NewCraneModal';
import { MyProfile } from '../../DataTypes/MyProfile';
import Spinner from '../UI/Spinner/Spinner';
import { Hierarchy } from '../../DataTypes/Hierarchy';
import { useTranslation } from 'react-i18next';

type props = {
	hierarchy: Hierarchy;
	show: boolean;
	handleClose: () => void;
};

const NewItem: React.FC<props> = props => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [showMainModal, setShowMainModal] = useState<boolean>(true);
	const [showCrane, setShowCrane] = useState<boolean>(false);
	const [showSite, setShowSite] = useState<boolean>(false);
	const [showCompany, setShowCompany] = useState<boolean>(false);
	const [currentUser, setCurrentUser] = useState<MyProfile>(MyProfile.Current);
	const { t } = useTranslation();

	useEffect(() => {
		setIsLoading(false);
	}, []);

	const handleClose = () => {
		setShowCrane(false);
		setShowSite(false);
		setShowCompany(false);
		props.handleClose();
	};
	if (isLoading) {
		return <Spinner />;
	}
	const mainModal = (
		<div>
			<Modal size='sm' className={styles.modal} aria-labelledby='contained-modal-title-vcenter' show={props.show} onHide={props.handleClose} centered>
				<Modal.Header style={{ border: 'none' }} closeButton />

				<Modal.Body>
					<div className={styles.title}>{t('chooseNewItem')}</div>
					<div className={styles.buttons}>
						<Button
							variant='outline-primary'
							onClick={() => {
								setShowCrane(true);
								setShowSite(false);
								setShowCompany(false);
							}}
							disabled={!currentUser.permissions.craneCRUD.create}>
							{t('crane')}
						</Button>
						<Button
							variant='outline-primary'
							onClick={() => {
								setShowCrane(false);
								setShowSite(true);
								setShowCompany(false);
							}}
							disabled={!currentUser.permissions.siteCRUD.create}>
							{t('site')}
						</Button>
						<Button
							variant='outline-primary'
							onClick={() => {
								setShowCrane(false);
								setShowSite(false);
								setShowCompany(true);
							}}
							disabled={!currentUser.permissions.companyCRUD.create}>
							{t('company')}
						</Button>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);

	return (
		<div>
			{showMainModal && mainModal}
			{showCompany && <NewCompanyModal handleClose={handleClose} show={showCompany} hierarchy={props.hierarchy} />}
			{showSite && <NewSiteModal handleClose={handleClose} show={showSite} hierarchy={props.hierarchy} />}
			{showCrane && <NewCraneModal handleClose={handleClose} show={showCrane} hierarchy={props.hierarchy} />}
		</div>
	);
};

export default NewItem;
