import React, { useState } from 'react';
import { Table, ConfigProvider, Tooltip } from 'antd';
import he_IL from 'antd/es/locale/he_IL';
import cn from 'classnames';
import styles from './DistributionListTable.module.scss';
import { ReactComponent as RemoveIcon } from '../../../assets/icons/trash.svg';
import { ReactComponent as AlertIcon } from '../../../assets/icons/alert-circle.svg';
import { ReactComponent as SmsIcon } from '../../../assets/icons/sms.svg';
import { ReactComponent as MailIcon } from '../../../assets/icons/email.svg';
import { ReactComponent as ConfirmIcon } from '../../../assets/icons/confirm.svg';
import { ITableItemData } from 'src/components/DistributionList/DistributionList';
import { ColumnsType } from 'antd/lib/table';
import { showRangeAndTotal } from 'src/services/services';
import { TFunction, useTranslation } from 'react-i18next';
import i18n from 'i18next';

type props = {
	items: ITableItemData[];
	setUserIdToDelete: any;
};

const buildIcon = (Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>, tooltip: { enabled: string; disabled: string }, isEnabled: boolean, isVisible: boolean = true) =>
	isVisible && (
		<Tooltip title={isEnabled ? tooltip.enabled : tooltip.disabled}>
			<Icon className={cn(styles.notificationsIcon, isEnabled ? null : styles.disabled)} />
		</Tooltip>
	);

export const buildIcons = (item: ITableItemData, t: TFunction) => {
	return (
		<>
			{buildIcon(MailIcon, { enabled: t('receiveEmailNotifications'), disabled: t('notReceiveEmailNotifications') }, item.notifications.isSendMail)}
			{buildIcon(SmsIcon, { enabled: t('receiveSmsNotifications'), disabled: t('NotReceiveSmsNotifications') }, item.notifications.isSendSms)}
			{buildIcon(ConfirmIcon, { enabled: t('authorizedToConfirmNotifications'), disabled: t('notAuthorizedToConfirmNotifications') }, item.notifications.canConfirmNotifications)}
			{buildIcon(AlertIcon, { enabled: t('userNotCompleteRegistration'), disabled: '' }, true, item.isPending)}
		</>
	);
};

const DistributionListTable: React.FC<props> = props => {
	const { t } = useTranslation();

	const columns: ColumnsType<ITableItemData> = [
		{
			key: 'id',
			title: t('user'),
			defaultSortOrder: 'ascend',
			width: '20%',
			render: (item: ITableItemData) => item.displayName,
		},
		{
			key: 'role',
			title: t('role'),
			width: '20%',
			render: (item: ITableItemData) => t(item.role),
		},
		{
			key: 'phone',
			title: t('phone'),
			width: '20%',
			render: (item: ITableItemData) => (item.isPending ? t('notEnteredYet') : item.phone.number),
		},
		{
			key: 'notifications',
			title: t('notifications'),
			width: '20%',
			render: (item: ITableItemData) => buildIcons(item, t),
		},
		{
			render: (item: ITableItemData) => (
				<Tooltip title={item.isDeletable ? t('remove') : t('canRemoveFromRelevantDB')}>
					<RemoveIcon className={cn(styles.icon, !item.isDeletable && styles.unclickable, !item.isDeletable && styles.disabled)} onClick={() => props.setUserIdToDelete(item.id)} />
				</Tooltip>
			),
			width: '5%',
		},
	];

	return (
		<ConfigProvider direction={i18n.language == 'he' ? 'rtl' : 'ltr'} locale={i18n.language == 'he' ? he_IL : undefined}>
			<Table className={styles.desktopTable} dataSource={props.items} columns={columns} pagination={{ pageSize: 10, showTotal: showRangeAndTotal }} />
		</ConfigProvider>
	);
};

export default DistributionListTable;
