import React from 'react';
import styles from './CheckButton.module.scss';
import cn from 'classnames';

export enum Size {
	lg = 3,
	md = 2.2,
	sm = 1.8,
}

type Props = {
	icon: React.FC;
	checked: boolean;
	disabled: boolean;
	size: Size;
	onClick?: () => void;
};

const CheckButton: React.FC<Props> = props => {
	return (
		<div
			className={cn(styles.neumorphic, props.checked && styles.neumorphicChecked, props.disabled && styles.disabled)}
			onClick={props.onClick}
			style={{ height: `${props.size}rem`, width: `${props.size}rem` }}>
			<props.icon />
		</div>
	);
};

export default CheckButton;
