import React, { useState, useEffect } from 'react';
import Spinner from '../../UI/Spinner/Spinner';
import styles from './CranePositionTable.module.scss';
import cn from 'classnames';
import CranePositionModal from '../../Modals/CranePositionModal/CranePositionModal';
import { Crane } from '../../../DataTypes/Crane';
import { Hierarchy } from '../../../DataTypes/Hierarchy';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { useTranslation } from 'react-i18next';

type props = {
	crane: Crane;
	hierarchy: Hierarchy;
};

const CranePositionTable: React.FC<props> = props => {
	const [loading, setLoading] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		setLoading(false);
	});

	const showModalHandler = () => {
		const show = showModal;
		setShowModal(!show);
	};

	if (loading) {
		return <Spinner />;
	}

	return (
		<div className={styles.card}>
			<div className={styles.cardHeader}>
				<div className={styles.cardTitle}>{t('cranePositioning')}</div>
				<EditIcon onClick={showModalHandler} className={styles.craneIcon} />
			</div>
			<table className={cn('table table-borderless ', styles.moveCraneTable, styles.mobileTable)}>
				<thead>
					<tr>
						<th scope='col'>{t('company')}</th>
						<th scope='col'>{t('site')}</th>
						<th scope='col'>{t('siteAddress')}</th>
						<th scope='col'>{t('craneNumber')}</th>
						<th scope='col'>{t('responsibleManager')}</th>
						<th scope='col'>{t('phone')}</th>
						<th scope='col'></th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<span className={styles.mobileTitle}>{t('company')}</span>
							<span>{props.crane.company.name}</span>
						</td>
						<td>
							<span className={styles.mobileTitle}>{t('site')}</span>
							<span>{props.crane.site.name}</span>
						</td>
						<td>
							<span className={styles.mobileTitle}>{t('siteAddress')}</span>
							<span>{props.crane.site.address}</span>
						</td>
						<td>
							<span className={styles.mobileTitle}>{t('craneNumber')}</span>
							<span>{props.crane.number}</span>
						</td>
						<td>
							<span className={styles.mobileTitle}>{t('responsibleManager')}</span>
							<span>{props.crane.site.manager?.name}</span>
						</td>
						<td>
							<span className={styles.mobileTitle}>{t('phone')}</span>
							<span>{props.crane.site.manager?.phone}</span>
						</td>
					</tr>
				</tbody>
			</table>
			{showModal && <CranePositionModal hierarchy={props.hierarchy} crane={props.crane} show={showModal} showModalHandler={showModalHandler} />}
		</div>
	);
};

export default CranePositionTable;
