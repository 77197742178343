import React, { useContext } from 'react';
import styles from '../RemoveUserModal/RemoveUserModal.module.scss';
import { Button, Modal, ModalBody } from 'react-bootstrap';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { License } from '../../../DataTypes/License';
import { useTranslation } from 'react-i18next';

type props = {
	license: License;
	blackbox: any;
	companyId: string;
	siteId: string;
	craneId: string;
	craneSn: string;
	show: boolean;
	handleClose: () => void;
};

const BBUnassignmentModal: React.FC<props> = props => {
	const { ShowLoadingModal } = useContext(LoadingModalContext);
	const { t, i18n } = useTranslation();

	const removeHandler = () => {
		props.handleClose();
		ShowLoadingModal(props.license.UnbindCrane(props.blackbox.id, props.companyId, props.siteId, props.craneId), 'BBUnassign');
	};

	return (
		<Modal size='sm' aria-labelledby='contained-modal-title-vcenter' show={props.show} onHide={props.handleClose} centered>
			<Modal.Header style={{ border: 'none' }} closeButton />
			<div className={styles.title}>{t('BBUnassign.Generic')}</div>
			<div className={styles.subtitle}>{`${t('thisBbAssociatedToCrane')} ${props.craneSn} ${t('unassociatedConfirmation')}`}</div>

			<ModalBody>
				<br />
				<div className={styles.buttons}>
					<Button variant='outline-danger' className={styles.button} onClick={removeHandler}>
						{t('yes&sure')}
					</Button>
					<Button variant='outline-primary' className={styles.button} onClick={props.handleClose}>
						{t('no&cancel')}
					</Button>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default BBUnassignmentModal;
