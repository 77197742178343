import React, { useEffect, useState } from 'react';
import { Notification } from 'src/DataTypes/Notification';
import { NotificationManager } from 'src/DataTypes/NotificationManager';
import { boolean } from 'yup';
import { Crane } from '../../../DataTypes/Crane';
import NotificationList from '../NotificationList/NotificationList';
import styles from './CraneNotifications.module.scss';
import { useTranslation } from 'react-i18next';

type props = {
	cranes: Crane[];
	notificationToItemViewFunc: (n: Notification, index: any) => JSX.Element;
	pollingInterval: number;
};

const CraneNotifications: React.FC<props> = props => {
	const [notifications, setNotifications] = useState<Notification[]>([]);
	const { t } = useTranslation();

	let timeoutHandle: NodeJS.Timeout;

	const fetchNotifications = () => {
		NotificationManager.FetchLast(props.cranes, 10, 0).then(ntfs => {
			setNotifications(ntfs);
			timeoutHandle = setTimeout(() => {
				fetchNotifications();
			}, props.pollingInterval);
		});
	};

	useEffect(() => {
		fetchNotifications();

		return () => {
			clearTimeout(timeoutHandle);
		};
	}, [...props.cranes.map(x => x.id)]);

	return (
		<div className={styles.craneNotifications}>
			<div className={styles.notifications}>
				{
					<NotificationList
						notifications={notifications}
						notificationToItemViewFunc={
							props.notificationToItemViewFunc
						}
					/>
				}
			</div>
		</div>
	);
};

export default CraneNotifications;
