import React from 'react';
import styles from '../RemoveUserModal/RemoveUserModal.module.scss';
import { Button, Modal, ModalBody } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type props = {
	submitCallback: any;
	closeHandler: any;
};
const DeleteContactModal: React.FC<props> = props => {
	const { t } = useTranslation();

	return (
		<Modal size='sm' aria-labelledby='contained-modal-title-vcenter' show onHide={props.closeHandler} centered>
			<Modal.Header style={{ border: 'none' }} closeButton />
			<div className={styles.title}>{t('DistributionListItemRemove.Generic')}</div>
			<ModalBody>
				<div className={styles.buttons}>
					<Button variant='outline-danger' className={styles.button} onClick={props.submitCallback}>
						{t('yes&sure')}
					</Button>
					<Button variant='outline-primary' className={styles.button} onClick={props.closeHandler}>
						{t('no&cancel')}
					</Button>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default DeleteContactModal;
