import React from 'react';
import styles from './ImgLoader.module.scss';
import { useTranslation } from 'react-i18next';

const ImgLoader = () => {
	const { t } = useTranslation();

	return <div className={styles.imgLoader}></div>;
};

export default ImgLoader;
