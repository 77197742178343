import React, { useContext } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import '../NewOperatorModal/NewOperatorModal.scss';
import { Modal, Form, Input, Select, DatePicker } from 'antd';
import { IOperatorEditDetails } from '../../../DataTypes/Operator';
import { Operator } from '../../../DataTypes/Operator';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { useTranslation } from 'react-i18next';
import { ECraneType } from 'src/DataTypes/Crane';
import ConfigProviderWrapper from 'src/components/ConfigProviderWrapper/ConfigProviderWrapper';
import moment from 'moment';
import { disabledDate } from 'src/services/services';

type props = {
	closeHandler: () => void;
	operators: Operator[];
	submitCallback: () => void;
	operator: Operator;
};

const { Option } = Select;

const EditOperatorModal: React.FC<props> = props => {
	const { ShowLoadingModal } = useContext(LoadingModalContext);
	const { t } = useTranslation();
	const [form] = Form.useForm();

	const formik = useFormik({
		initialValues: {
			operatorId: props.operator.operatorId,
			fullName: props.operator.fullName,
			birthday: props.operator.birthday,
			type: props.operator.type,
			licenseExpirationDate: props.operator.licenseExpirationDate,
			licenseId: props.operator.licenseId,
		},
		initialTouched: {},
		validationSchema: yup.object({
			operatorId: yup.string().required(t('requiredField')),
			fullName: yup.string().required(t('requiredField')),
			birthday: yup
				.date()
				.typeError(t('validDateFormat'))
				.required(t('requiredField')),
			type: yup
				.string()
				.oneOf(Object.values(ECraneType))
				.required(t('requiredField')),
			licenseExpirationDate: yup
				.date()
				.typeError(t('validDateFormat'))
				.required(t('requiredField')),
			licenseId: yup.string().required(t('requiredField')),
		}),

		onSubmit: values => {
			const details: IOperatorEditDetails = {
				fullName: values.fullName,
				type: values.type,
				licenseExpirationDate: values.licenseExpirationDate!,
				licenseId: values.licenseId,
			};
			ShowLoadingModal(
				props.operator.UpdateDetails(details),
				'OperatorCreate'
			).then(res => {
				if (!res.isError) {
					props.submitCallback();
				}
			});
			props.closeHandler();
		},
	});

	return (
		<ConfigProviderWrapper>
			<Modal
				zIndex={1031}
				title={t('OperatorUpdate.Generic')}
				visible={true}
				onOk={() => formik.handleSubmit()}
				onCancel={props.closeHandler}>
				<Form form={form}>
					<Form.Item
						required
						name='operatorId'
						label={t('operatorId')}
						help={
							formik.touched.operatorId &&
							formik.errors.operatorId
								? formik.errors.operatorId
								: ''
						}>
						<Input
							value={formik.values.operatorId}
							defaultValue={formik.values.operatorId}
							placeholder={t('operatorId')}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
					</Form.Item>
					<Form.Item
						required
						name='fullName'
						label={t('fullName')}
						help={
							formik.touched.fullName && formik.errors.fullName
								? formik.errors.fullName
								: ''
						}>
						<Input
							value={formik.values.fullName}
							defaultValue={formik.values.fullName}
							placeholder={t('fullName')}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
					</Form.Item>
					<Form.Item
						required
						name='licenseId'
						label={t('licenseId')}
						help={
							formik.touched.licenseId && formik.errors.licenseId
								? formik.errors.licenseId
								: ''
						}>
						<Input
							value={formik.values.licenseId}
							defaultValue={formik.values.licenseId}
							placeholder={t('licenseId')}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
					</Form.Item>
					<Form.Item
						required
						name='type'
						label={t('type')}
						help={
							formik.touched.type && formik.errors.type
								? formik.errors.type
								: ''
						}>
						<Select
							value={formik.values.type}
							defaultValue={formik.values.type}
							placeholder={t('type')}
							onChange={value =>
								formik.setFieldValue('type', value)
							}
							allowClear>
							{Object.values(ECraneType).map(value => (
								<Option key={value} value={value}>
									{t(value.toLowerCase())}
								</Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item
						required
						name='birthday'
						label={t('birthday')}
						help={
							formik.touched.birthday && formik.errors.birthday
								? formik.errors.birthday
								: ''
						}>
						<DatePicker
							defaultValue={moment(formik.values.birthday)}
							style={{ width: '100%' }}
							format={'DD-MM-YYYY'}
							disabledDate={disabledDate}
							onChange={value =>
								formik.setFieldValue('birthday', value)
							}
						/>
					</Form.Item>
					<Form.Item
						required
						name='licenseExpirationDate'
						label={t('licenseExpirationDate')}
						help={
							formik.touched.licenseExpirationDate &&
							formik.errors.licenseExpirationDate
								? formik.errors.licenseExpirationDate
								: ''
						}>
						<DatePicker
							defaultValue={moment(
								formik.values.licenseExpirationDate
							)}
							style={{ width: '100%' }}
							format={'DD-MM-YYYY'}
							onChange={value =>
								formik.setFieldValue(
									'licenseExpirationDate',
									value
								)
							}
						/>
					</Form.Item>
				</Form>
			</Modal>
		</ConfigProviderWrapper>
	);
};

export default EditOperatorModal;
