import axios from 'axios';

export enum EBlackboxType {
	Full = 'Full',
	Feeder = 'Feeder',
	Lite = 'Lite',
}

export interface IBlackboxAddDetails {
	type: string;
	friendlyName: string;
}

export interface IBlackboxData {
	friendlyName: string;
	type: EBlackboxType;
	id: string;
	createdTime: string;
}

export interface IBlackbox extends IBlackboxData {}

export class Blackbox implements IBlackbox {
	friendlyName: string;

	type: EBlackboxType;

	id: string;

	createdTime: string;

	license?: any = null;

	constructor(data: IBlackboxData) {
		this.friendlyName = data.friendlyName;
		this.type = data.type;
		this.id = data.id;
		this.createdTime = data.createdTime;
	}

	RemoveBlackbox = () => {
		return axios.delete(`/Blackbox/${this.id}`);
	};
}
