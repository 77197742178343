import React from 'react';
import { Crane, ECraneType } from 'src/DataTypes/Crane';
import styles from './MapView.module.scss';
import { ReactComponent as CraneIcon } from '../../assets/icons/crane.svg';
import { ReactComponent as LufferIcon } from '../../assets/icons/luffer.svg';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

type props = {
	crane: Crane;
	lat: number;
	lng: number;
};

const CraneMarker: React.FC<props> = props => {
	const { t } = useTranslation();
	const history = useHistory();

	const iconView = (type: ECraneType) => {
		switch (type) {
			case ECraneType.Tower:
				return <CraneIcon onClick={() => history.push(`/crane/${props.crane.id}`)} className={styles.marker} />;
			case ECraneType.Luffer:
				return <LufferIcon onClick={() => history.push(`/crane/${props.crane.id}`)} className={styles.marker} />;
			default:
				return <CraneIcon onClick={() => history.push(`/crane/${props.crane.id}`)} className={styles.marker} />;
		}
	};

	return (
		<Tooltip placement='top' title={t('craneMarker', { site: props.crane.site.name, crane: props.crane.number })}>
			{iconView(props.crane.type)}
		</Tooltip>
	);
};

export default CraneMarker;
