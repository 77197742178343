import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Table, ConfigProvider, Tag, Button, Popover, Input, Space, Tooltip, Breadcrumb } from 'antd';
import { MoreOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import he_IL from 'antd/es/locale/he_IL';
import { License } from '../../../DataTypes/License';
import { Hierarchy } from '../../../DataTypes/Hierarchy';
import styles from '../DesktopTable.module.scss';
import { showRangeAndTotal } from 'src/services/services';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Crane } from 'src/DataTypes/Crane';
import CranePathBreadcrumb from 'src/components/UI/Breadcrumb/CranePathBreadcrumb';

type Props = {
	dataSource: License[];
	assignPopover: any;
	unassignPopover: any;
	hierarchy: Hierarchy;
	setCurrentLicense: (license: License) => void;
	licenseExpirationCalc: (a, b) => number | string;
};

const LicenseSettingsTable = (props: Props) => {
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const history = useHistory();
	const { t } = useTranslation();

	const getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					placeholder={t('search')}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button type='primary' onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size='small' style={{ width: 90 }}>
						{t('search')}
					</Button>
					<Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
						{t('reset')}
					</Button>
					{/* <Button
						type='link'
						size='small'
						onClick={() => {
							confirm({ closeDropdown: false });
							setSearchText(selectedKeys[0]);
							setSearchedColumn(dataIndex);
						}}>
						Filter
					</Button> */}
				</Space>
			</div>
		),
		filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, marginRight: '1rem', fontSize: '18px' }} />,
		onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),
		render: text =>
			searchedColumn === dataIndex ? (
				<Highlighter highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }} searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''} />
			) : (
				text
			),
	});

	const columns: any = [
		{
			title: t('licenseType'),
			dataIndex: 'type',
			key: 'type',
			defaultSortOrder: 'ascend',
			width: '10%',
			isUseDefaultSearch: true,
			render: type => (type === 'Full' ? 'SBC' : type === 'Lite' ? 'SBL' : type === 'Feeder' ? 'SBF' : '-'),
			sorter: (a, b) => sortFunction(a.type, b.type),
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: t('licenseNumber'),
			dataIndex: 'id',
			key: 'id',
			width: '30%',
			isUseDefaultSearch: true,
			sorter: (a, b) => sortFunction(a.id, b.id),
			sortDirections: ['descend', 'ascend'],
			...getColumnSearchProps('id'),
			render: licenseId => (
				<CopyToClipboard text={licenseId}>
					<div style={{ userSelect: 'text', cursor: 'pointer' }}>{licenseId}</div>
				</CopyToClipboard>
			),
		},
		{
			title: t('expirationInDays'),
			width: '15%',
			render: (license: License) => {
				if (license.blackboxBinding) {
					const expirationInDays = props.licenseExpirationCalc(license.durationInDays, license.blackboxBinding?.bindTime);
					const expirationDate = `${t('validTo')}:   ${moment().add(expirationInDays, 'days').format('DD/MM/YYYY')}`;
					if (expirationInDays > 0) {
						return (
							<Tooltip placement='top' title={expirationDate}>
								<Tag color='green'>{expirationInDays}</Tag>
							</Tooltip>
						);
					} else {
						return <Tag color='red'>{expirationInDays}</Tag>;
					}
				} else {
					return <Tag color='green'>{license.durationInDays}</Tag>;
				}
			},
			sorter: (a, b) => sortFunction(a.blackboxBinding?.bindTime, b.blackboxBinding?.bindTime),
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: t('blackbox'),
			dataIndex: ['blackboxBinding', 'blackbox', 'friendlyName'],
			width: '15%',
			sorter: (a, b) => sortFunction(a.blackboxBinding?.blackbox?.friendlyName, b.blackboxBinding?.blackbox?.friendlyName),
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: t('crane'),
			width: '30%',
			render: (license: License) =>
				license ? (
					<Button type='link' size='small' onClick={() => history.push(`/crane/${license.blackboxBinding?.craneBinding?.craneId}`)}>
						<CranePathBreadcrumb crane={props.hierarchy.cranes?.find(c => c.id === license.blackboxBinding?.craneBinding?.craneId)} />
					</Button>
				) : (
					t('craneNotAssociated')
				),
		},
		{
			render: license => (
				<Popover placement='right' trigger='click' content={license.blackboxBinding ? props.unassignPopover : props.assignPopover}>
					<MoreOutlined onClick={() => props.setCurrentLicense(license)} />
				</Popover>
			),
		},
	];
	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = clearFilters => {
		clearFilters();
		setSearchText('');
	};

	const sortFunction = (a, b) => {
		let sortA;
		let sortB;

		switch (typeof a) {
			case 'string':
				sortA = a?.toLowerCase();
				sortB = b?.toLowerCase();
				break;

			default:
				sortA = a;
				sortB = b;
				break;
		}

		if (sortA < sortB) {
			return -1;
		}
		if (sortA > sortB) {
			return 1;
		}

		return 0;
	};

	return (
		<ConfigProvider direction={i18n.language == 'he' ? 'rtl' : 'ltr'} locale={i18n.language == 'he' ? he_IL : undefined}>
			<Table className={styles.desktopTable} dataSource={props.dataSource} columns={columns} pagination={{ pageSize: 10, showTotal: showRangeAndTotal }} />
		</ConfigProvider>
	);
};

export default LicenseSettingsTable;
