import { Company, ICompanyCreateDetails } from './Company';
import { Crane, ICraneCreateDetails } from './Crane';
import { Site } from './Site';
import { SubscriptionCredit } from './SubscriptionCredit';
import axios from 'axios';
import { ReturnCranesDeviceIds } from '../services/services';
import { NotificationManager } from './NotificationManager';
import { IDeviceStatusData } from './DeviceStatus';
import { IQueryOperationSummary, OperationSummary } from './OperationSummary';
import { BoundDevice } from './BoundDevice';

export interface IHirerachyData {
	id: string;
	companies: Company[];
}

export interface IHierarchy {
	sites: Site[];
	cranes: Crane[];
	subscriptionCredit?: SubscriptionCredit;
	notificationManager?: NotificationManager;
}

export class Hierarchy implements IHierarchy, IQueryOperationSummary {
	id: string;

	companies: Company[] = [];

	sites: Site[] = [];

	cranes: Crane[] = [];

	subscriptionCredit?: SubscriptionCredit;

	notificationManager?: NotificationManager;

	constructor(data: IHirerachyData) {
		this.id = data.id;

		this.companies = data.companies.map(c => {
			let company = new Company(c);

			//Fill sites
			this.sites = this.sites.concat(company.sites);

			//Fill cranes
			company.sites.forEach(s =>
				s.cranes.forEach(c => this.cranes.push(c))
			);

			return company;
		});
	}

	static FetchHierarchy(): Promise<Hierarchy | null> {
		return axios.get('/Hierarchy').then(
			response => {
				if (typeof response.data !== 'object') {
					//data is not an object, that means its an error!
					return null;
				}

				return new Hierarchy(response.data);
			},
			reason => {
				return null;
			}
		);
	}

	FetchSubscriptionCredit(): Promise<SubscriptionCredit | null> {
		return axios.get('/License').then(
			response => {
				this.subscriptionCredit = new SubscriptionCredit(response.data);
				return this.subscriptionCredit;
			},
			reason => {
				return null;
			}
		);
	}

	async FetchDeviceStatuses(): Promise<any | null> {
		if (this.subscriptionCredit == null) {
			return null;
		}

		const license = await axios.get('/License');
		if (license === null) return null;

		const boundDevices = await axios.get<BoundDevice[]>('/License/BoundDevices');
		if (boundDevices === null) return null;

		const relevantDevices: string[] = [];

		// Inject deviceId into bound cranes
		boundDevices.data.forEach(({ craneId, deviceId }) => {
			let found = this.cranes.find(
				cr => cr.id == craneId);
			if (found == null) return;
			found.deviceId = deviceId;
			relevantDevices.push(deviceId);
		});

		return axios
			.post('/Device/GetMultiple', relevantDevices)
			.then(
				response => {
					this._deviceStatesHandle(response.data);
					return true;
				},
				reason => {
					return null;
				}
			)
			.catch(reason => {
				return null;
			});
	}

	private _deviceStatesHandle(deviceStatuses: IDeviceStatusData[]) {
		deviceStatuses?.forEach(ds => {
			let found = this.cranes.find(cr => cr.deviceId === ds.deviceId);
			if (!found) return;
			found.UpdateDeviceStatus(ds);
		});
	}

	CreateCompany(details: ICompanyCreateDetails) {
		return axios.post('/Company', details);
	}

	CreateSite(details: {
		name?: string;
		company: any;
		officeAddress?: string;
		manager?: { name: string; phone: string };
	}) {
		return axios.post(`/Company/${details.company}/Site`, details);
	}

	CreateCrane(details: ICraneCreateDetails) {
		return axios.post(
			`/Company/${details.company}/Site/${details.site}/Crane`,
			details
		);
	}

	GetLastNotifications(amount: number, skip: number) {
		return axios.post(
			`/Notification/GetLast`,
			ReturnCranesDeviceIds(this.cranes),
			{ params: { amount, skip } }
		);
	}

	// GetActivitySummary(startTime, endTime) {
	// 	return axios.post(`/Summary/GetMultiple`, ReturnCranesDeviceIds(this.cranes), { params: { startTime, endTime } });
	// }

	GetActivitySummary(startTime: Date, endTime: Date) {
		return OperationSummary.Query(
			startTime,
			endTime,
			ReturnCranesDeviceIds(this.cranes)
		);
	}
}
