import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import styles from '../AddContactModal/AddContactModal.module.scss';
import RTL from '../../RTL/RTL';
import { useTranslation } from 'react-i18next';
import { Radio, DatePicker, ConfigProvider, Spin } from 'antd';
import i18n from 'src/i18n/i18n';
import he_IL from 'antd/es/locale/he_IL';
import { BlobProvider, PDFDownloadLink } from '@react-pdf/renderer';
import { Crane } from 'src/DataTypes/Crane';
import { Operator } from 'src/DataTypes/Operator';

type props = {
	closeHandler: () => void;
	generateDocFunc: (dates) => Promise<JSX.Element>;
	dailyWorkingHours: number;
	crane?: Crane;
	operator?: Operator;
};

export type Dates = {
	startTime: string;
	endTime: string;
};

const { RangePicker } = DatePicker;

const ReportGeneratorModal: React.FC<props> = props => {
	const { t } = useTranslation();
	const [value, setValue] = useState(1);
	const [dates, setDates] = useState<Dates>({ startTime: new Date('01/01/2000').toISOString(), endTime: new Date().toISOString() });
	const [isFiltered, setIsFiltered] = useState(false);
	const [doc, setDoc] = useState<any>(undefined);

	useEffect(() => {
		props.generateDocFunc(dates).then(
			res => {
				setDoc(res);
			},
			rej => {
				console.log(rej);
			}
		);
	}, [dates]);

	return (
		<RTL>
			<ConfigProvider direction={i18n.language == 'he' ? 'rtl' : 'ltr'} locale={i18n.language == 'he' ? he_IL : undefined}>
				<Modal size='sm' className={styles.modal} aria-labelledby='contained-modal-title-vcenter' show centered onHide={props.closeHandler}>
					<Modal.Header style={{ border: 'none' }} closeButton />
					<div className={styles.title}>{t('generateActivityHoursReport')}</div>
					<Modal.Body className={styles.modalBody}>
						<form>
							<Radio.Group
								onChange={e => {
									setValue(e.target.value);
									e.target.value === 1 && setDates({ startTime: new Date('01/01/1970').toISOString(), endTime: new Date().toISOString() });
								}}
								value={value}
								style={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginInlineStart: 0, marginBottom: '2rem' }}>
								<Radio style={{ width: '100%' }} value={1}>
									{t('allActivities')}
								</Radio>
								<Radio style={{ width: '100%' }} value={2}>
									{t('filterByDate')}
								</Radio>
							</Radio.Group>
							{value === 2 && (
								<RangePicker
									separator={false}
									disabled={value != 2}
									onChange={dates => {
										setIsFiltered(true);
										if (dates != null) {
											setDates({
												startTime: dates[0]!.toISOString() ?? new Date('01/01/1970').toISOString(),
												endTime: dates[1]!.toISOString() ?? new Date().toISOString(),
											});
										}
									}}
								/>
							)}
							{doc && (
								<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '2rem 0', gap: '1rem' }}>
									<BlobProvider document={doc}>
										{({ blob, url, loading, error }) => {
											return (
												<Button variant='outline-warning' href={url!} target='_blank' className={styles.button} disabled={(!isFiltered && value === 2) || loading}>
													{loading ? <Spin size='small' /> : t('preview')}
												</Button>
											);
										}}
									</BlobProvider>
									<PDFDownloadLink document={doc} fileName='activityHoursReport.pdf'>
										{({ blob, url, loading, error }) => {
											return (
												<Button variant='outline-primary' className={styles.button} disabled={(!isFiltered && value === 2) || loading}>
													{loading ? <Spin size='small' /> : t('generate')}
												</Button>
											);
										}}
									</PDFDownloadLink>
								</div>
							)}
						</form>
					</Modal.Body>
				</Modal>
			</ConfigProvider>
		</RTL>
	);
};

export default ReportGeneratorModal;
