import React, { useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styles from '../../Modals/AddContactModal/AddContactModal.module.scss';
import RTL from '../../RTL/RTL';
import { Hierarchy } from '../../../DataTypes/Hierarchy';
import { LoadingModalContext } from '../../../contexts/LoadingModalProvider';
import { useTranslation } from 'react-i18next';

type props = {
	show: boolean;
	handleClose: () => void;
	hierarchy: Hierarchy;
};

type inputs = {
	name: string;
	sn: string;
	officeAddress: string;
	managerName: string;
	managerPhone: string;
};

const phoneRegExp: RegExp = /^05\d{8}$/;
const snRegExp: RegExp = /^51\d{7}$/;

const NewCompanyModal: React.FC<props> = props => {
	const { ShowLoadingModal } = useContext(LoadingModalContext);
	const { t } = useTranslation();

	const validationSchema = yup.object({
		name: yup
			.string()
			.notOneOf(
				props.hierarchy.companies.map(company => company.name),
				t('existingCompanyName')
			)
			.required(t('requiredField')),
		sn: yup
			.string()
			.notOneOf(
				props.hierarchy.companies.map(company => company.sn),
				t('existingCompanySn')
			)
			.matches(snRegExp, t('enterValidValue'))
			.required(t('requiredField')),
		officeAddress: yup.string().required(t('requiredField')),
		managerName: yup.string().required(t('requiredField')),
		managerPhone: yup.string().matches(phoneRegExp, t('enterValidValue')).required(t('requiredField')),
	});

	const formik = useFormik({
		initialValues: {
			name: '',
			sn: '',
			officeAddress: '',
			managerName: '',
			managerPhone: '',
		},
		validationSchema: validationSchema,
		onSubmit: (values: inputs) => {
			const details = {
				name: values.name,
				sn: values.sn,
				officeAddress: values.officeAddress,
				manager: { name: values.managerName, phone: values.managerPhone },
			};
			props.handleClose();
			ShowLoadingModal(props.hierarchy.CreateCompany(details), 'CompanyCreate');
		},
	});

	return (
		<RTL>
			<Modal size='sm' className={styles.modal} aria-labelledby='contained-modal-title-vcenter' show={props.show} centered onHide={props.handleClose}>
				<Modal.Header style={{ border: 'none' }} closeButton />
				<div className={styles.title}>{t('addingCompany')}</div>
				<Modal.Body className={styles.modalBody}>
					<form className={styles.formi} onSubmit={formik.handleSubmit}>
						<TextField
							id='name'
							label={t('name')}
							value={formik.values.name}
							helperText={formik.touched.name ? formik.errors.name : ''}
							error={formik.touched.name && Boolean(formik.errors.name)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth
						/>
						<TextField
							id='sn'
							label={t('sn')}
							value={formik.values.sn}
							helperText={formik.touched.sn ? formik.errors.sn : ''}
							error={formik.touched.sn && Boolean(formik.errors.sn)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth
						/>
						<TextField
							id='officeAddress'
							label={t('officeAddress')}
							value={formik.values.officeAddress}
							helperText={formik.touched.officeAddress ? formik.errors.officeAddress : ''}
							error={formik.touched.officeAddress && Boolean(formik.errors.officeAddress)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth
						/>
						<TextField
							id='managerName'
							label={t('contact')}
							value={formik.values.managerName}
							helperText={formik.touched.managerName ? formik.errors.managerName : ''}
							error={formik.touched.managerName && Boolean(formik.errors.managerName)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth
						/>
						<TextField
							id='managerPhone'
							label={t('phone')}
							value={formik.values.managerPhone}
							helperText={formik.touched.managerPhone ? formik.errors.managerPhone : ''}
							error={formik.touched.managerPhone && Boolean(formik.errors.managerPhone)}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							margin='dense'
							variant='outlined'
							fullWidth
						/>
						<div className={styles.formButtons}>
							<Button type='submit' variant='outline-primary' className={styles.button}>
								{t('add')}
							</Button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</RTL>
	);
};

export default NewCompanyModal;
